import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { Enum_Day_Daycode } from '@app/infrastructureLayer/generated/graphql';

export const ShopWorkTimeItemDayOff = t
  .model('ShopWorkTimeItemDayOff', {
    id: t.identifier,
    type: t.frozen<'ShopWorkTimeItemDayOff'>(),
    day: t.frozen<Enum_Day_Daycode>(),
  });

export interface ShopWorkTimeItemDayOffInstance extends Instance<typeof ShopWorkTimeItemDayOff> {
}

export interface ShopWorkTimeItemDayOffSnapshotIn extends SnapshotIn<typeof ShopWorkTimeItemDayOff> {
}

export interface ShopWorkTimeItemDayOffSnapshotOut extends SnapshotOut<typeof ShopWorkTimeItemDayOff> {
}
