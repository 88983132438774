import AddPriceCounter from '@screens/Tabs/MenuScreen/includes/AddPriceCounter';
import PriceCounterWithModifiers from '@screens/Tabs/MenuScreen/includes/PriceCounterWithModifiers';
import { StyleSheet, View } from 'react-native';
import React, { FC, useCallback, useMemo } from 'react';
import { MenuItemInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuItem';
import generalConfig from '@constants/generalConfig';
import useStore from '@app/domain/store/useStore';
import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';

const s = StyleSheet.create({
  counterContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    width: '100%',
  },
});

interface IProps {
  menuItem: MenuItemInstance,
}

const MenuItemControls: FC<IProps> = (
  {
    menuItem,
  },
) => {
  const {
    appStore: {
      activeMenu,
      increaseCartItemAmount,
      reduceCartItemAmount,
      // getAmountOfCartItemsWithSameProduct,
    },
  } = useStore();

  const navigation = useNavigation();

  const shopId = activeMenu?.shopId;

  const { product } = menuItem;
  const productId = product?.id;

  const {
    increase,
    reduce,
  } = useMemo(() => ({
    increase: () => !!productId && increaseCartItemAmount({ productId }),
    reduce: () => !!productId && reduceCartItemAmount({ productId }),
  }), [productId, increaseCartItemAmount, reduceCartItemAmount]);

  const priceTitle = `${menuItem?.price || '???'} ${generalConfig.currencyLabel}`;

  const goToProduct = useCallback(() => {
    if (!menuItem || !shopId) {
      return;
    }

    navigation.navigate('MenuItemScreen', {
      shopId,
      menuItemId: menuItem.id,
    });
  }, [navigation, shopId, menuItem]);

  if (!product) {
    return null;
  }

  return (
    <View style={s.counterContainer}>
      {!menuItem.hasModifiers && (
        <AddPriceCounter
          // Причина оптимизации заменой getAmountOfCartItemsWithSameProduct на product.inCartCount
          // функция getAmountOfCartItemsWithSameProduct ранее используемая в компоненте MenuItem мутирует и вызывает рендер каждого элемента в меню.
          // count={getAmountOfCartItemsWithSameProduct(productId, false)}
          count={product.inCartCount}
          price={priceTitle}
          increase={increase}
          reduce={reduce}
        />
      )}
      {menuItem.hasModifiers && (
        <PriceCounterWithModifiers
          // Причина оптимизации заменой getAmountOfCartItemsWithSameProduct на product.inCartCount
          // функция getAmountOfCartItemsWithSameProduct ранее используемая в компоненте MenuItem мутирует и вызывает рендер каждого элемента в меню.
          // count={getAmountOfCartItemsWithSameProduct(productId, true)}
          count={product.inCartCount}
          price={priceTitle}
          onPress={goToProduct}
        />
      )}
    </View>
  );
};

export default observer(MenuItemControls);
