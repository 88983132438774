import React, {
  FC, useCallback,
} from 'react';
import { observer } from 'mobx-react-lite';
import {
  StyleSheet,
  View,
} from 'react-native';
import SelectDeliveryAddressOnMapHOC
  from '@screens/SelectDeliveryAddressScreen/includes/SelectDeliveryAddressOnMap/SelectDeliveryAddressOnMapHOC';
import Screen from '@components/layout/Screen';
import Back from '@components/layout/Back';
import { useNavigation } from '@react-navigation/native';
import { RootStackScreenProps } from '@navigation/types';

const s = StyleSheet.create({
  flex: {
    flex: 1,
  },
});

const CreateMyAddressOnMapScreen: FC<RootStackScreenProps<'CreateMyAddressOnMapScreen'>> = () => {
  const navigation = useNavigation();

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }
    navigation.navigate('MyAddressesScreen');
  }, [navigation]);

  return (
    <Screen>
      <Back
        title="Назад"
        onPress={onBack}
      />
      <View style={s.flex}>
        <SelectDeliveryAddressOnMapHOC />
      </View>
    </Screen>
  );
};

export default observer(CreateMyAddressOnMapScreen);
