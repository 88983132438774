import { useAddExpoPushTokenMutation } from '@app/infrastructureLayer/generated/graphql';
import { useCallback, useEffect } from 'react';
import usePushNotification from '@hooks/usePushNotification';
import useStore from '@app/domain/store/useStore';
import * as Device from 'expo-device';

const useSyncPushToken = () => {
  const store = useStore();

  const { expoPushToken } = usePushNotification();
  const { hasAuthToken } = store.authStore.auth;

  const [addExpoPushTokenMutation] = useAddExpoPushTokenMutation();

  const sendTokenToApi = useCallback(async (token: string) => {
    try {
      await addExpoPushTokenMutation({ variables: { input: { token } } });
    } catch (error) {
      console.error(error);
    }
  }, [addExpoPushTokenMutation]);

  useEffect(() => {
    if (hasAuthToken && Device.isDevice && expoPushToken) {
      (async () => {
        await sendTokenToApi(expoPushToken);
      })();
    }
  }, [hasAuthToken, sendTokenToApi, expoPushToken]);
};

export default useSyncPushToken;
