import { AxiosRequestConfig } from 'axios';

import { Api } from './generated/Api';
import { getApiBase } from '../../utils/env';
import { ITokenProvider } from './TokenProvider';

class ApiClientFactory {
  static create = (tokenProvider: ITokenProvider): Api<unknown> => (
    new Api({
      baseURL: `${getApiBase()}/api`,
      securityWorker: (): AxiosRequestConfig => {
        const headers: AxiosRequestConfig['headers'] = {};

        const accessToken = tokenProvider.token;

        if (accessToken) {
          headers.Authorization = `Bearer ${accessToken}`;
        }

        return {
          headers,
        };
      },
    })
  );
}

export default ApiClientFactory;
