import React, { FC, useCallback } from 'react';
import { RootStackScreenProps } from '@navigation/types';
import { observer } from 'mobx-react-lite';
import FullScreenLoading from '@components/common/FullScreenLoading';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import useStore from '@app/domain/store/useStore';

const IndexScreen: FC<RootStackScreenProps<'IndexScreen'>> = () => {
  const navigation = useNavigation();

  const {
    appStore: { selectedShopId },
    ready,
  } = useStore();

  useFocusEffect(useCallback(() => {
    if (selectedShopId && ready) {
      // TODO: Определять дефолтное меню как-то и указывать правильно, например по локации или из админки забирать
      // Отправляем пользователя на дефолтное меню
      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'Tabs',
            params: {
              screen: 'MenuScreen',
              params: { shopId: selectedShopId },
            },
          },
        ],
      });
    }
  }, [ready, navigation, selectedShopId]));

  return <FullScreenLoading />;
};

export default observer(IndexScreen);
