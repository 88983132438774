import {
  Instance,
  resolveIdentifier,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { Category } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Category';
import type { Product as ProductTS } from '@app//infrastructureLayer/generated/Api';
import { BaseItemWithParams } from '@app/domain/store/CoreStore/AppStore/entities/Menu/BaseItemWithParams';

export const Product = BaseItemWithParams
  .named('Product')
  .props({
    id: t.identifier,
    title: t.string,
    categoryId: t.string,
    description: t.string,
    imageUrl: t.string,
    imageUrl_100: t.string,
    imageUrl_200: t.string,
    imageUrl_500: t.string,
    type: t.frozen<ProductTS['type']>(),
    inCartCount: t.optional(t.number, 0), // Используется для оптимизации отображения кол-ва товара в корзине на главном экране меню
  })
  .views((self) => ({
    get category() {
      return resolveIdentifier(Category, self, self.categoryId);
    },
  }))
  .actions((self) => ({
    setInCartCount(value: number) {
      self.inCartCount = value;
    },
  }));

export interface ProductInstance extends Instance<typeof Product> {
}

export interface ProductSnapshotIn extends SnapshotIn<typeof Product> {
}

export interface ProductSnapshotOut extends SnapshotOut<typeof Product> {
}
