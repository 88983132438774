import React, {
  FC, useCallback, useRef,
} from 'react';
import {
  StyleSheet,
  View,
  Text, Platform,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
} from 'react-native-actions-sheet';
import Button from '@components/common/Button';
import { observer } from 'mobx-react-lite';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import { useAddCreditCardMutation } from '@app/infrastructureLayer/generated/graphql';
import crossAlert from '@utils/crossAlert';
import navigationRef from '@navigation/navigationRef';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 20,
  },
  text: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
    marginBottom: 40,
  },
});

const snapPoints = [100];

const AddNewCardViaAlfaSheet: FC<SheetProps> = (
  {
    sheetId,
  },
) => {
  const [addCreditCard, { loading }] = useAddCreditCardMutation();

  const actionSheetRef = useRef<ActionSheetRef>(null);

  const onAddCardPress = useCallback(async () => {
    const res = await addCreditCard();

    if (res.data?.addCreditCard.formUrl) {
      if (Platform.OS === 'web') {
        window.location.href = res.data.addCreditCard.formUrl;

        return;
      }

      actionSheetRef.current?.hide();
      navigationRef.navigate('CardAddScreen', {
        formUrl: res.data.addCreditCard.formUrl,
      });
    } else {
      crossAlert('Ошибка, попробуйте снова позже.');
    }
  }, [addCreditCard]);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      <View style={s.root}>
        <Text style={s.text}>
          Для добавления карты мы сделаем тестовый платёж на 0 рублей.
          {'\n'}
          При успешной оплате, этой картой можно будет
          пользоваться для оплаты покупок без повторного ввода данных карты.
        </Text>
        <Button
          onPress={onAddCardPress}
          title="Добавить карту"
          loading={loading}
          disabled={loading}
        />
      </View>
    </ActionSheet>
  );
};

export default observer(AddNewCardViaAlfaSheet);
