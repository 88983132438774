import React, {
  FC, useCallback,
} from 'react';
import {
  StyleSheet,
  View,
  Text,
  // ActivityIndicator,
} from 'react-native';
import ActionSheet, {
  SheetManager,
  SheetProps,
} from 'react-native-actions-sheet';
import { observer } from 'mobx-react-lite';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import Button from '@components/common/Button';
import navigationRef from '@navigation/navigationRef';
import ButtonOutline from '@components/common/ButtonOutline';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 40,
  },
  text: {
    fontSize: 20,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
    textAlign: 'center',
    marginBottom: 40,
  },
  buttons: {
    gap: 10,
  },
});

const snapPoints = [100];

const OrderCreatedSheet: FC<SheetProps> = (
  {
    sheetId,
  },
) => {
  const onGoToMenu = useCallback(() => {
    SheetManager.hide(sheetId);
    navigationRef.navigate('IndexScreen');
  }, [sheetId]);

  const onGoToOrders = useCallback(() => {
    SheetManager.hide(sheetId);
    navigationRef.navigate('OrdersHistoryScreen');
  }, [sheetId]);

  return (
    <ActionSheet
      id={sheetId}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      <View style={s.root}>
        <Text style={s.text}>
          Ваш заказ отправлен
        </Text>
        <View style={s.buttons}>
          <ButtonOutline
            title="Сделать ещё один заказ"
            onPress={onGoToMenu}
          />
          <Button
            title="Перейти к моим заказам"
            onPress={onGoToOrders}
          />
        </View>
      </View>
    </ActionSheet>
  );
};

export default observer(OrderCreatedSheet);
