import { FC } from 'react';
import useProfileSync from '@hooks/useProfileSync/useProfileSync';
import { observer } from 'mobx-react-lite';

const ProfileSync: FC = () => {
  useProfileSync();

  return null;
};

export default observer(ProfileSync);
