import React, { FC, useCallback } from 'react';
import {
  AuthStackParamList,
  RootStackParamList,
  RootStackScreenProps,
  TabsStackParamList,
} from '@navigation/types';
import { NativeStackNavigationOptions, createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationContainer, Theme } from '@react-navigation/native';
import type { NavigationAction } from '@react-navigation/routers';
import { observer } from 'mobx-react-lite';
import { Platform } from 'react-native';
import useSyncPushToken from '@hooks/useSyncPushToken/useSyncPushToken';
import ProfileSync from '@navigation/includes/ProfileSync';
import IndexScreen from '@screens/IndexScreen';
import MenuScreen from '@screens/Tabs/MenuScreen/MenuScreen';
import {
  BottomTabBarProps,
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import colors from '@constants/colors';
import TabBar from '@components/layout/TabBar/TabBar';
import ProfileScreen from '@screens/Tabs/ProfileScreen';
import ParamsScreen from '@screens/Tabs/ParamsScreen';
import BeanSvg from '@svg/tabBar/BeanSvg';
import PersonSvg from '@svg/tabBar/PersonSvg';
import ThreeHorLineSvg from '@svg/tabBar/ThreeHorLineSvg';
import linkingConfiguration from '@navigation/linkingConfiguration';
import useInitApp from '@hooks/useInitApp/useInitApp';
import useStore from '@app/domain/store/useStore';
import FullScreenLoading from '@components/common/FullScreenLoading';
import MenuItemScreen from '@screens/MenuItemScreen/MenuItemScreen';
import CartScreen from '@screens/CartScreen/CartScreen';
import LoginScreen from '@screens/Auth/LoginScreen';
import SmsLoginScreen from '@screens/Auth/SmsLoginScreen';
import RegistrationScreen from '@screens/Auth/RegistrationScreen/RegistrationScreen';
import GoToProfile from '@screens/Auth/GoToProfile';
import EditMyAddressScreen from '@screens/EditMyAddressScreen/EditMyAddressScreen';
import MyAddressesScreen from '@screens/MyAddressesScreen';
import MyCardsScreen from '@screens/MyCardsScreen/MyCardsScreen';
import OrdersHistoryScreen from '@screens/OrdersHistoryScreen/OrdersHistoryScreen';
import OrderScreen from '@screens/OrderScreen/OrderScreen';
import CreateMyAddressScreen from '@screens/CreateMyAddressScreen/CreateMyAddressScreen';
import PaymentSuccessScreen from '@screens/PaymentStatus/PaymentSuccessScreen';
import PaymentFailScreen from '@screens/PaymentStatus/PaymentFailScreen';
import FAQScreen from '@screens/Info/FaqScreen/FaqScreen';
import CustomScreen from '@screens/Info/CustomScreen';
import AddressesScreen from '@screens/Info/AddressesScreen/AddressesScreen';
import AddCardFailScreen from '@screens/CardAdd/AddCardFailScreen';
import AddCardSuccessScreen from '@screens/CardAdd/AddCardSuccessScreen';
import navigationRef from '@navigation/navigationRef';
import CardAddScreen from '@screens/CardAdd/CardAddScreen';
import SelectDeliveryAddressScreen from '@screens/SelectDeliveryAddressScreen/SelectDeliveryAddressScreen';
import CreateMyAddressOnMapScreen from '@screens/CreateMyAddressScreen/CreateMyAddressOnMapScreen';
import EditProfileScreen from '@screens/Info/EditProfileScreen/EditProfileScreen';
import HeartSvg from '@svg/tabBar/HeartSvg';
import FavoritesScreen from '@screens/Tabs/FavoritesScreen';
import AccountRemoveRequest from '@screens/Info/AccountRemoveRequest/AccountRemoveRequest';
import Constants from 'expo-constants';

const RootStack = createNativeStackNavigator<RootStackParamList>();
const AuthStack = createNativeStackNavigator<AuthStackParamList>();
const TabNav = createBottomTabNavigator<TabsStackParamList>();

const screenOptions: NativeStackNavigationOptions = {
  headerShown: false,
  title: Constants.expoConfig?.name || '',
  // animation: Platform.OS === 'android' ? 'none' : undefined,
  // animation: 'none',
};

export const containerTheme: Theme = {
  dark: false,
  colors: {
    primary: '#007AFF',
    background: '#FFFFFF',
    card: '#FFFFFF',
    text: '#000000',
    border: '#000000',
    notification: '#FF3B30',
  },
};

const getTabsOptionsMap = () => {
  const map: Map<keyof TabsStackParamList, BottomTabNavigationOptions> = new Map();

  map.set('MenuScreen', {
    headerShown: false,
    tabBarLabel: 'Меню',
    tabBarIcon: BeanSvg,
    title: 'Меню',
    freezeOnBlur: true,
  });

  map.set('FavoritesScreen', {
    headerShown: false,
    tabBarLabel: 'Избранное',
    tabBarIcon: HeartSvg,
    title: 'Избранное',
  });

  map.set('ProfileScreen', {
    headerShown: false,
    tabBarLabel: 'Профиль',
    tabBarIcon: PersonSvg,
    title: 'Профиль',
  });

  map.set('ParamsScreen', {
    headerShown: false,
    tabBarLabel: 'Настройки',
    tabBarIcon: ThreeHorLineSvg,
    title: 'Настройки',
  });

  return map;
};

const getRootStackOptionsMap = () => {
  const map: Map<keyof RootStackParamList, NativeStackNavigationOptions> = new Map();

  map.set('Tabs', {
    title: Constants.expoConfig?.name || '',
    headerShown: false,
  });

  map.set('IndexScreen', {
    title: 'Меню',
  });

  map.set('PaymentSuccessScreen', {
    title: 'Оплата прошла успешно',
  });

  map.set('PaymentFailScreen', {
    title: 'Оплата не прошла',
  });

  map.set('MenuItemScreen', {
    title: 'Товар',
  });

  map.set('CartScreen', {
    title: 'Корзина',
  });

  map.set('Auth', {
    title: 'Авторизация',
    headerShown: false,
  });

  map.set('FAQScreen', {
    title: 'Часто задаваемые вопросы',
    headerShown: false,
  });

  map.set('CustomScreen', {
    title: 'Информация',
    headerShown: false,
  });

  map.set('AddressesScreen', {
    title: 'Наши адреса',
    headerShown: false,
  });

  map.set('AccountRemoveRequest', {
    title: 'Запросить удаление аккаунта',
    headerShown: false,
  });

  map.set('AddCardFailScreen', {
    title: 'Ошибка, карта не добавлена',
    headerShown: false,
  });

  map.set('AddCardSuccessScreen', {
    title: 'Карта добавлена',
    headerShown: false,
  });

  map.set('CardAddScreen', {
    title: 'Добавление карты',
    headerShown: false,
  });

  map.set('SelectDeliveryAddressScreen', {
    title: 'Выбор адреса доставки',
    headerShown: false,
  });

  map.set('CreateMyAddressOnMapScreen', {
    title: 'Добавление адреса',
    headerShown: false,
  });

  map.set('EditProfileScreen', {
    title: 'Редактирование профиля',
    headerShown: false,
  });

  return map;
};

const getAuthStackOptionsMap = () => {
  const map: Map<keyof AuthStackParamList, NativeStackNavigationOptions> = new Map();

  map.set('LoginScreen', {
    title: 'Авторизация',
    headerShown: false,
  });

  map.set('SmsLoginScreen', {
    title: 'Введите код из смс',
    headerShown: false,
  });

  map.set('RegistrationScreen', {
    title: 'Регистрация',
    headerShown: false,
  });

  return map;
};

const bottomTabNavOptions: BottomTabNavigationOptions = {
  headerShown: false,
  // header: (props) => <CommonLayoutHeader {...props} />,
  tabBarStyle: {
    paddingBottom: Platform.OS === 'ios' ? 10 : 0,
    paddingTop: Platform.OS === 'ios' ? 10 : 0,
    height: 72,
  },
  tabBarInactiveTintColor: colors.tabBar.tabBarInactiveTintColor,
  tabBarActiveTintColor: colors.tabBar.tabBarActiveTintColor,
  tabBarItemStyle: {
    marginTop: 10,
  },
} as const;

const tabsOptions = getTabsOptionsMap();
const rootStackOptions = getRootStackOptionsMap();
const authStackOptions = getAuthStackOptionsMap();

const Tabs: FC<RootStackScreenProps<'Tabs'>> = () => {
  const renderTabBar = useCallback(
    (props: BottomTabBarProps) => <TabBar {...props} />,
    [],
  );

  return (
    <TabNav.Navigator
      backBehavior="none"
      tabBar={renderTabBar}
      screenOptions={bottomTabNavOptions}
    >
      <TabNav.Screen
        name="MenuScreen"
        component={MenuScreen}
        options={tabsOptions.get('MenuScreen')}
      />
      <TabNav.Screen
        name="FavoritesScreen"
        component={FavoritesScreen}
        options={tabsOptions.get('FavoritesScreen')}
      />
      <TabNav.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={tabsOptions.get('ProfileScreen')}
      />
      <TabNav.Screen
        name="ParamsScreen"
        component={ParamsScreen}
        options={tabsOptions.get('ParamsScreen')}
      />
    </TabNav.Navigator>
  );
};

const noAnimation: NativeStackNavigationOptions = {
  // animation: Platform.OS === 'android' ? 'none' : undefined,
};

const Auth: FC<RootStackScreenProps<'Auth'>> = observer(() => {
  const { authStore: { auth: { hasAuthToken } } } = useStore();

  return (
    <AuthStack.Navigator
      screenOptions={noAnimation}
      initialRouteName="LoginScreen"
    >
      <AuthStack.Screen
        name="LoginScreen"
        component={!hasAuthToken ? LoginScreen : GoToProfile}
        options={authStackOptions.get('LoginScreen')}
      />

      <AuthStack.Screen
        name="SmsLoginScreen"
        component={!hasAuthToken ? SmsLoginScreen : GoToProfile}
        options={authStackOptions.get('SmsLoginScreen')}
      />

      <AuthStack.Screen
        name="RegistrationScreen"
        component={RegistrationScreen}
        options={authStackOptions.get('RegistrationScreen')}
      />
    </AuthStack.Navigator>
  );
});

const Navigation: FC = () => {
  useSyncPushToken();
  const store = useStore();

  useInitApp();

  const onUnhandledAction = useCallback((action: NavigationAction) => {
    console.error(`UnhandledAction ${JSON.stringify(action)}`);
  }, []);

  if (!store.appStore.ready) {
    return <FullScreenLoading />;
  }

  return (
    <NavigationContainer
      linking={linkingConfiguration}
      onUnhandledAction={onUnhandledAction}
      theme={containerTheme}
      ref={navigationRef}
    >
      <ProfileSync />

      <RootStack.Navigator
        screenOptions={screenOptions}
        initialRouteName="IndexScreen"
      >
        <RootStack.Screen
          name="IndexScreen"
          component={IndexScreen}
          options={rootStackOptions.get('IndexScreen')}
        />
        <RootStack.Screen
          name="PaymentSuccessScreen"
          component={PaymentSuccessScreen}
          options={rootStackOptions.get('PaymentSuccessScreen')}
        />
        <RootStack.Screen
          name="PaymentFailScreen"
          component={PaymentFailScreen}
          options={rootStackOptions.get('PaymentFailScreen')}
        />

        <RootStack.Screen
          name="EditMyAddressScreen"
          component={EditMyAddressScreen}
          options={rootStackOptions.get('EditMyAddressScreen')}
        />

        <RootStack.Screen
          name="CreateMyAddressScreen"
          component={CreateMyAddressScreen}
          options={rootStackOptions.get('CreateMyAddressScreen')}
        />

        <RootStack.Screen
          name="OrderScreen"
          component={OrderScreen}
          options={rootStackOptions.get('OrderScreen')}
        />

        <RootStack.Screen
          name="OrdersHistoryScreen"
          component={OrdersHistoryScreen}
          options={rootStackOptions.get('OrdersHistoryScreen')}
        />

        <RootStack.Screen
          name="MyAddressesScreen"
          component={MyAddressesScreen}
          options={rootStackOptions.get('MyAddressesScreen')}
        />

        <RootStack.Screen
          name="MyCardsScreen"
          component={MyCardsScreen}
          options={rootStackOptions.get('MyCardsScreen')}
        />

        <RootStack.Screen
          name="Tabs"
          component={Tabs}
          options={rootStackOptions.get('Tabs')}
        />

        <RootStack.Screen
          name="Auth"
          component={Auth}
          options={rootStackOptions.get('Auth')}
        />

        <RootStack.Screen
          name="MenuItemScreen"
          component={MenuItemScreen}
          options={rootStackOptions.get('MenuItemScreen')}
        />

        <RootStack.Screen
          name="CartScreen"
          component={CartScreen}
          options={rootStackOptions.get('CartScreen')}
        />

        <RootStack.Screen
          name="FAQScreen"
          component={FAQScreen}
          options={rootStackOptions.get('FAQScreen')}
        />

        <RootStack.Screen
          name="CustomScreen"
          component={CustomScreen}
          options={rootStackOptions.get('CustomScreen')}
        />

        <RootStack.Screen
          name="EditProfileScreen"
          component={EditProfileScreen}
          options={rootStackOptions.get('EditProfileScreen')}
        />

        <RootStack.Screen
          name="AddressesScreen"
          component={AddressesScreen}
          options={rootStackOptions.get('AddressesScreen')}
        />

        <RootStack.Screen
          name="AccountRemoveRequest"
          component={AccountRemoveRequest}
          options={rootStackOptions.get('AccountRemoveRequest')}
        />

        <RootStack.Screen
          name="AddCardFailScreen"
          component={AddCardFailScreen}
          options={rootStackOptions.get('AddCardFailScreen')}
        />

        <RootStack.Screen
          name="AddCardSuccessScreen"
          component={AddCardSuccessScreen}
          options={rootStackOptions.get('AddCardSuccessScreen')}
        />

        <RootStack.Screen
          name="CardAddScreen"
          component={CardAddScreen}
          options={rootStackOptions.get('CardAddScreen')}
        />

        <RootStack.Screen
          name="SelectDeliveryAddressScreen"
          component={SelectDeliveryAddressScreen}
          options={rootStackOptions.get('SelectDeliveryAddressScreen')}
        />

        <RootStack.Screen
          name="CreateMyAddressOnMapScreen"
          component={CreateMyAddressOnMapScreen}
          options={rootStackOptions.get('CreateMyAddressOnMapScreen')}
        />
      </RootStack.Navigator>
    </NavigationContainer>
  );
};

export default observer(Navigation);
