import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import colors from '@constants/colors';

const PlaceMarkSvg = () => (
  <Svg
    width="64px"
    height="64px"
    viewBox="-5 0 32 32"
  >
    <Path
      d="M11 1A10 10 0 001 11c0 5.523 10 20 10 20s10-14.477 10-20A10 10 0 0011 1zm0 13a3 3 0 113-3 3 3 0 01-3 3z"
      fill={colors.primary}
      stroke={colors.text}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
    />
  </Svg>
);

export default PlaceMarkSvg;
