import {
  useMyProfileLazyQuery,
} from '@app/infrastructureLayer/generated/graphql';
import { useCallback, useEffect } from 'react';
import useStore from '@app/domain/store/useStore';
import { ProfileEntitySnapshotIn } from '@app/domain/store/CoreStore/ProfileStore/entities/ProfileEntity';
import moment from 'moment-timezone';
import { DeliveryAddressSnapshotIn } from '@app/domain/store/CoreStore/ProfileStore/entities/DeliveryAddress';
import { IKeyValueMap } from 'mobx';
import compact from 'lodash/compact';

/**
 * Автоматически записывает в ProfileStore пользователя, достаточно задать токен авторизации.
 *
 * Возвращает метод refetchProfile который можно вызвать
 * для принудительного переполучения профиля пользователя с бека, результат будет записан в ProfileStore.
 */
const useProfileSync = () => {
  const {
    hydrated,
    authStore: {
      auth: {
        token,
      },
    },
    profileStore,
  } = useStore();

  const [getCurrentUser, { stopPolling, startPolling }] = useMyProfileLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!hydrated || !token) {
      stopPolling();

      return;
    }

    startPolling(30000);
  }, [
    hydrated, token,
    stopPolling, startPolling,
  ]);

  const refetchProfile = useCallback(async () => {
    if (!hydrated) {
      return;
    }

    if (!token) {
      profileStore.setProfile(null);

      return;
    }

    const { data: currentUserData, error } = await getCurrentUser();

    if (!currentUserData || error?.networkError) {
      return;
    }

    const user = currentUserData.myProfile;

    const deliveryAddressesArray: DeliveryAddressSnapshotIn[] = compact(user.deliveryAddresses?.map((da) => {
      if (!da.city?.id || !da.id || !da.coordinates?.latitude || !da.coordinates?.longitude) {
        return null;
      }

      return {
        id: da.id,
        cityId: da.city.id,
        address: da.address,
        coordinates: {
          latitude: Number(da.coordinates.latitude),
          longitude: Number(da.coordinates.longitude),
        },
        entrance: da.entrance ?? undefined,
        comment: da.comment ?? undefined,
        floor: da.floor ?? undefined,
        room: da.room ?? undefined,
        intercomCode: da.intercomCode ?? undefined,
        title: da.title ?? undefined,
        type: da.type ?? undefined,
      };
    })) || [];

    const deliveryAddresses: IKeyValueMap<DeliveryAddressSnapshotIn> = deliveryAddressesArray.reduce((acc, da) => {
      acc[String(da.id)] = da;

      return acc;
    }, {} as IKeyValueMap<DeliveryAddressSnapshotIn>);

    const profileSnapshot: ProfileEntitySnapshotIn = {
      id: user.id,
      fio: user.fio,
      phone: user.phone,
      birthday: user.birthday ? moment(user.birthday, 'YYYY-MM-DD')
        .toDate() : undefined,
      broadcastOfferAccepted: user.broadcastOfferAccepted,
      sex: user.sex,
      registrationCompleted: user.registrationCompleted,
      deliveryAddresses,
    };

    profileStore.setProfile(profileSnapshot);
  }, [getCurrentUser, hydrated, profileStore, token]);

  useEffect(() => {
    (async () => {
      await refetchProfile();
    })();
  }, [refetchProfile]);

  return {
    refetchProfile,
  };
};

export default useProfileSync;
