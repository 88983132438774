import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';

export const LegalEntity = t
  .model('LegalEntity', {
    name: t.optional(t.string, ''),
    inn: t.optional(t.string, ''),
    kpp: t.optional(t.string, ''),
    legalAddress: t.optional(t.string, ''),
    phone: t.optional(t.string, ''),
    email: t.optional(t.string, ''),
  })
  .views((self) => ({
    get isValid() {
      return self.name.trim().length > 0
        && self.inn.trim().length > 0
        && self.legalAddress.trim().length > 0
        && self.phone.trim().length > 0;
    },
  }))
  .actions((self) => ({
    setName: (value: string) => {
      self.name = value;
    },
    setInn: (value: string) => {
      self.inn = value;
    },
    setKpp: (value: string) => {
      self.kpp = value;
    },
    setLegalAddress: (value: string) => {
      self.legalAddress = value;
    },
    setPhone: (value: string) => {
      self.phone = value;
    },
    setEmail: (value: string) => {
      self.email = value;
    },
  }));

export interface LegalEntityInstance extends Instance<typeof LegalEntity> {
}

export interface LegalEntitySnapshotIn extends SnapshotIn<typeof LegalEntity> {
}

export interface LegalEntitySnapshotOut extends SnapshotOut<typeof LegalEntity> {
}
