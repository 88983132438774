const getOrderStatusName = (type?: string) => {
  switch (type) {
    case 'WAIT_PAYMENT':
      return 'Ожидает оплаты';
    case 'PAID':
      return 'Оплачен';
    case 'CANCELED':
      return 'Отменён';
    default:
      return type;
  }
};

export default getOrderStatusName;
