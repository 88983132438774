import React, {
  FC,
  PropsWithChildren,
  useMemo,
} from 'react';
import { StyleSheet, View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    backgroundColor: colors.background,
    height: '100%',
    justifyContent: 'space-between',
  },
});

interface IProps {
  inTabs?: boolean;
}

const Screen: FC<PropsWithChildren<IProps>> = ({ children, inTabs = false }) => {
  const insets = useSafeAreaInsets();

  const rootStyles = useMemo(() => (
    StyleSheet.flatten([
      s.root,
      {
        paddingTop: insets.top,
        paddingBottom: !inTabs ? insets.bottom : 0,
      },
    ])
  ), [inTabs, insets.bottom, insets.top]);

  return (
    <View style={rootStyles}>
      {children}
    </View>
  );
};

export default Screen;
