import {
  ActivityIndicator,
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import React, { FC } from 'react';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

interface IProps {
  title: string,
  onPress: () => void,
  disabled?: boolean,
  loading?: boolean,
}

const s = StyleSheet.create({
  button: {
    flexDirection: 'row',
    width: '100%',
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    borderColor: colors.primary,
    borderWidth: 1,
    marginVertical: 10,
  },
  buttonText: {
    textAlign: 'center',
    paddingVertical: 5,
    paddingHorizontal: 20,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
    fontSize: 16,
  },
  buttonDisabled: {
    borderColor: colors.grey,
  },
  buttonTextDisabled: {
    color: colors.grey,
  },
  wrapper: {
    width: '100%',
  },
});

const ButtonOutline: FC<IProps> = (
  {
    onPress,
    title,
    disabled = false,
    loading = false,
  },
) => (
  <TouchableOpacity onPress={onPress} disabled={disabled} style={s.wrapper}>
    <View style={StyleSheet.flatten([s.button, disabled && s.buttonDisabled])}>
      {loading && (
        <ActivityIndicator color={colors.primary} />
      )}
      <Text style={StyleSheet.flatten([s.buttonText, disabled && s.buttonTextDisabled])}>
        {title}
      </Text>
    </View>
  </TouchableOpacity>
);

export default ButtonOutline;
