import React, { FC } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import XSvg from '@svg/XSvg';
import ArrowRightSvg from '@svg/ArrowRightSvg';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 31,
    borderRadius: 10,
    paddingHorizontal: 10,
    columnGap: 10,
  },
  priceContent: {
    gap: 5,
    backgroundColor: colors.midGrey,
  },
  price: {
    fontFamily: ubuntuFont.regular,
    fontSize: 12,
  },
  counterContent: {
    gap: 10,
    backgroundColor: colors.primary,
  },
  countText: {
    color: colors.white,
    fontSize: 12,
    fontFamily: ubuntuFont.regular,
    height: 14,
  },
  countRoot: {
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    gap: 5,
  },
  arrowRight: {
    marginLeft: 10,
  },
});

interface IProps {
  count: number;
  price: string;
  onPress: () => void,
}

const PriceCounterWithModifiers: FC<IProps> = (
  {
    count,
    price,
    onPress,
  },
) => {
  if (count === 0) {
    return (
      <TouchableOpacity onPress={onPress}>
        <View
          style={StyleSheet.flatten([s.root, s.priceContent])}
        >
          <Text style={s.price}>
            {`от ${price}`}
          </Text>

          <View style={s.arrowRight}>
            <ArrowRightSvg color={colors.text} />
          </View>
        </View>
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={StyleSheet.flatten([s.root, s.counterContent])}
      >
        <View style={s.countRoot}>
          <Text style={s.countText}>
            {count}
          </Text>
          <XSvg />
          <Text style={s.countText}>
            {`от ${price}`}
          </Text>

          <View style={s.arrowRight}>
            <ArrowRightSvg color={colors.white} />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default PriceCounterWithModifiers;
