import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import GreyBlock from '@components/layout/GreyBlock';
import useStore from '@app/domain/store/useStore';
import Input from '@components/form/Input';
import { StyleSheet } from 'react-native';

const s = StyleSheet.create({
  root: {
    gap: 5,
  },
});

const OrderComment: FC = () => {
  const {
    appStore: {
      cartStates,
      selectedShopId,
    },
  } = useStore();

  const cartState = selectedShopId ? cartStates.get(selectedShopId) : undefined;

  const onChange = useCallback((value: string) => {
    cartState?.setComment(value);
  }, [cartState]);

  if (!cartState) {
    return null;
  }

  return (
    <GreyBlock style={s.root}>
      <Input
        multiline
        numberOfLines={3}
        type="text"
        label="Комментарий"
        placeholder="Введите комментарий"
        value={cartState.comment}
        onChangeText={onChange}
        // style={{
        //   backgroundColor: 'red',
        //   minHeight: 100,
        // }}
      />
    </GreyBlock>
  );
};

export default observer(OrderComment);
