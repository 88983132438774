import { Platform } from 'react-native';
import SecureStoreMstPersistAdapter from './SecureStoreMstPersistAdapter';
import AsyncNativeStoreMstPersistAdapter from './AsyncNativeStoreMstPersistAdapter';

const getPersistAdapter = ({ secure }: { secure: boolean }) => {
  if (secure && ['android', 'ios'].includes(Platform.OS)) {
    return SecureStoreMstPersistAdapter;
  }

  return AsyncNativeStoreMstPersistAdapter;
};

export default getPersistAdapter;
