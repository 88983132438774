import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  ActivityIndicator, StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import {
  useDeleteMyFavoriteItemMutation,
  useAddMyFavoriteItemMutation,
  GetMyFavoritesQuery,
} from '@app/infrastructureLayer/generated/graphql';
import type { ModifierMenuItemInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/ModifierMenuItem';
import type { MenuItemInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuItem';
import { ApolloQueryResult } from '@apollo/client';
import { IFavoriteItem } from '@screens/Tabs/includes/favorite.types';
import HeartEmptySvg from '@svg/HeartEmptySvg';
import generalConfig from '@constants/generalConfig';
import HeartFullSvg from '@svg/HeartFullSvg';
import ubuntuFont from '@constants/ubuntuFont';
import useStore from '@app/domain/store/useStore';
import currency from 'currency.js';
import Picture from '@components/common/Picture/Picture';
import colors from '@constants/colors';
import some from 'lodash/some';

const s = StyleSheet.create({
  title: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
  },
  root: {
    rowGap: 5,
    marginBottom: 5,
  },
  wrapper: {
    flexDirection: 'row',
  },
  heart: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
  },
  picture: {
    alignSelf: 'flex-start',
  },
  left: {
    marginLeft: 10,
    marginBottom: 5,
  },
  right: {
    backgroundColor: colors.lightGrey,
    alignSelf: 'flex-end',
    marginTop: 10,
    paddingTop: 9,
    paddingBottom: 8,
    paddingHorizontal: 15,
    borderRadius: 10,
  },
  addonsView: {
    width: '90%',
  },
  addons: {
    fontFamily: ubuntuFont.light,
    color: colors.primary,
    fontSize: 12,
    marginLeft: 10,
  },
  wrapData: {
    flex: 1,
    justifyContent: 'space-between',
  },
});

type ItemData = {
  id: string;
  amount: number;
  price: number;
  snapshot: any;
  orderItemModifiers?: {
    id: string;
    amount: number;
    price: number;
    snapshot: any;
  }[] | null | undefined;
};

interface Props {
  item: ItemData;
  favorites: IFavoriteItem[];
  onRefetchFavorites: () => Promise<ApolloQueryResult<GetMyFavoritesQuery>>;
}

const OrderItem: FC<Props> = ({ item, favorites, onRefetchFavorites }) => {
  const { appStore: { activeMenu } } = useStore();

  const [useLoading, setUseLoading] = useState(false);

  const [addMyFavoriteItem] = useAddMyFavoriteItemMutation();
  const [deleteMyFavoriteItem] = useDeleteMyFavoriteItemMutation();

  let priceData = currency(item.snapshot.price).multiply(item.amount);

  if (item.orderItemModifiers) {
    item.orderItemModifiers.forEach((x) => {
      priceData = priceData.add(currency(x.snapshot.price).multiply(x.amount));
    });
  }

  const isFavorite = !favorites ? false : some(favorites, { productId: `${item.snapshot.product.id}` });

  const favoriteId = ((): string => {
    if (!favorites) {
      return '';
    }

    const match = favorites.find((obj) => (
      obj.productId === `${item.snapshot.product.id}`
    ));
    if (match) {
      return match.id;
    }

    return '';
  })();

  const activeMenuProductItems: MenuItemInstance[] = !activeMenu ? [] : [...activeMenu.menuItems.values()];

  const activeMenuModifierItems: ModifierMenuItemInstance[] = (
    !activeMenu ? [] : [...activeMenu.modifierMenuItems.values()]
  );

  let hasUnknownModifiers = false;
  if (item.orderItemModifiers) {
    item.orderItemModifiers.filter((mod) => {
      if (!mod.snapshot.modifier.id) {
        hasUnknownModifiers = true;
        return null;
      }

      if (some(activeMenuModifierItems, { modifierId: `${mod.snapshot.modifier.id}` })) {
        return mod;
      }

      hasUnknownModifiers = true;
      return null;
    });

    if (!item.snapshot.product.id || !some(activeMenuProductItems, { productId: `${item.snapshot.product.id}` })) {
      hasUnknownModifiers = true;
    }
  }

  const showFullHeart = Boolean(isFavorite && !useLoading && !hasUnknownModifiers);
  const showEmptyHeart = Boolean(!isFavorite && !useLoading && !hasUnknownModifiers);

  const onHeartPress = useCallback(async () => {
    if (!item || !item.snapshot || !item.snapshot.product) {
      return;
    }

    const modifiersData = !item.orderItemModifiers ? [] : item.orderItemModifiers.map((mod) => ({
      modifierId: `${mod.snapshot.modifier.id}`,
      modifierAmount: `${mod.amount}`,
    }));

    setUseLoading(true);

    if (!isFavorite) {
      await addMyFavoriteItem({
        variables: {
          input: {
            productId: `${item.snapshot.product.id}`,
            productAmount: `${item.amount}`,
            modifiersData,
          },
        },
        onCompleted: async (response) => {
          if (response.addMyFavoriteItem.resultCode === 'SUCCESS') {
            await onRefetchFavorites();
          }
          setUseLoading(false);
        },
        onError: () => setUseLoading(false),
      });
    } else {
      await deleteMyFavoriteItem({
        variables: { id: favoriteId },
        onCompleted: async (response) => {
          if (response.deleteMyFavoriteItem.resultCode === 'SUCCESS') {
            await onRefetchFavorites();
          }
          setUseLoading(false);
        },
        onError: () => setUseLoading(false),
      });
    }
  }, [
    addMyFavoriteItem, deleteMyFavoriteItem, item,
    isFavorite, onRefetchFavorites, favoriteId,
  ]);

  const imageURL = useMemo(() => (
    !item.snapshot.product.imageUrl_100
      ? item.snapshot.product.imageUrl
      : item.snapshot.product.imageUrl_100
  ), [item.snapshot.product.imageUrl, item.snapshot.product.imageUrl_100]);

  return (
    <View style={s.root} key={item.id}>
      <View style={s.wrapper}>
        <TouchableOpacity
          hitSlop={5}
          style={s.heart}
          onPress={onHeartPress}
        >
          {showEmptyHeart && <HeartEmptySvg />}
          {showFullHeart && <HeartFullSvg />}
          {useLoading && <ActivityIndicator size={24} color={colors.primary} />}
        </TouchableOpacity>

        <View style={s.picture}>
          <Picture
            image={imageURL}
            size={80}
          />
        </View>

        <View style={s.wrapData}>
          <View style={s.left}>
            <Text style={s.title}>
              {`${item.snapshot.product.title} ${item.amount > 1 ? `x${item.amount}` : ''}`}
            </Text>
          </View>

          <View style={s.addonsView}>
            {item.orderItemModifiers && item.orderItemModifiers.map((elem) => (
              <Text style={s.addons} key={elem.id}>
                {`${elem.snapshot.modifier.title} ${elem.amount > 1 ? `x${elem.amount}` : ''}`}
              </Text>
            ))}
          </View>

          <View style={s.right}>
            <Text>
              {`${item.amount} × ${priceData.value} ${generalConfig.currencyLabel}`}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default OrderItem;
