import React, { FC, useCallback } from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    borderRadius: 10,
    backgroundColor: colors.lightGrey,
    padding: 10,
    height: 38,
    alignItems: 'center',
    borderWidth: 1,
    borderColor: colors.lightGrey,
  },
  selected: {
    backgroundColor: colors.transparent,
    borderWidth: 1,
    borderColor: colors.primary,
  },
  text: {
    fontSize: 12,
    fontFamily: ubuntuFont.light,
    color: colors.text,
  },
  textSelected: {
    color: colors.primary,
  },
});

const DeliveryDayVariant: FC<{
  title: string,
  value: string | null,
  selected: boolean,
  onSelect: (date: string | null) => void,
}> = (
  {
    title,
    selected,
    onSelect,
    value,
  },
) => {
  const onPress = useCallback(
    () => onSelect(value),
    [onSelect, value],
  );

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={StyleSheet.flatten([s.root, selected ? s.selected : null])}>
        <Text style={StyleSheet.flatten([s.text, selected ? s.textSelected : null])}>
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default DeliveryDayVariant;
