import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import type { OrderType as OrderTypeTS } from '@app/infrastructureLayer/generated/Api';
// import { Enum_Ordertype_Ordertypecode } from '@app/infrastructureLayer/generated/graphql';

export const OrderType = t
  .model('OrderType', {
    id: t.identifier,
    title: t.string,
    // type: t.frozen<Enum_Ordertype_Ordertypecode>(),
    type: t.frozen<OrderTypeTS['orderTypeCode']>(),
  });

export interface OrderTypeInstance extends Instance<typeof OrderType> {
}

export interface OrderTypeSnapshotIn extends SnapshotIn<typeof OrderType> {
}

export interface OrderTypeSnapshotOut extends SnapshotOut<typeof OrderType> {
}
