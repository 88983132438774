import React, {
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  TouchableOpacity,
  View,
  StyleSheet,
  Text, useWindowDimensions,
} from 'react-native';
import { Notifier, Easing } from 'react-native-notifier';
import { compare } from 'semver';
import ubuntuFont from '@constants/ubuntuFont';
import Constants from 'expo-constants';
import colors from '@constants/colors';
import axios from 'axios';

const s = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    marginHorizontal: 10,
    marginTop: 15,
    padding: 15,
    paddingTop: 20,
    borderRadius: 15,
    shadowColor: colors.text,
    shadowOpacity: 0.5,
    shadowRadius: 10,
  },
  title: {
    fontSize: 16,
    fontFamily: ubuntuFont.medium,
    color: colors.primary,
    marginBottom: 10,
  },
  description: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
    marginBottom: 15,
  },
  buttons: {
    flexDirection: 'row',
    columnGap: 5,
  },
  buttonLeft: {
    flex: 1,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    borderColor: colors.primary,
    borderWidth: 1,
  },
  buttonRight: {
    flex: 1,
    minHeight: 40,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.primary,
  },
  refresh: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.white,
  },
  later: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
  },
  width: {
    width: 380,
  },
});

const WebAppVersionSynchronizer: FC = () => {
  const { width: W_WIDTH } = useWindowDimensions();

  const [skipChecking, setSkipChecking] = useState(false);

  const refreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  const hidePopUp = useCallback(() => {
    setSkipChecking(true);
    Notifier.clearQueue();
    Notifier.hideNotification();
  }, []);

  const checkVersion = useCallback(async () => {
    if (skipChecking || !Constants.expoConfig?.version) {
      return null;
    }

    const { data: WebConfig } = await axios.get('version.json');

    if (!WebConfig || !WebConfig.version) {
      return null;
    }

    const comparison = compare(Constants.expoConfig.version, WebConfig.version);

    if (comparison === 0) {
      // У пользователя и version.json версии равны
    } else if (comparison === 1) {
      console.error(`Каким-то образом пользователь опережает версию сайта, user: ${Constants.expoConfig.version}, config: ${WebConfig.version}`);
    } else {
      Notifier.showNotification({
        title: 'Внимание',
        description: 'Данные на странице устарели, нажмите чтобы обновить страницу',
        duration: 0,
        showAnimationDuration: 500,
        showEasing: Easing.ease,
        onPress: refreshPage,
        hideOnPress: false,
        queueMode: 'standby',
        swipeEnabled: false,
        Component: () => (
          <View style={StyleSheet.flatten([s.container, W_WIDTH > 540 && s.width])}>
            <Text style={s.title}>Внимание!</Text>
            <Text style={s.description}>Данные на странице устарели, нажмите, чтобы обновить страницу</Text>
            <View style={s.buttons}>
              <TouchableOpacity style={s.buttonLeft} onPress={hidePopUp}>
                <Text style={s.later}>ОБНОВИТЬ ПОЗЖЕ</Text>
              </TouchableOpacity>
              <TouchableOpacity style={s.buttonRight} onPress={refreshPage}>
                <Text style={s.refresh}>ОБНОВИТЬ СЕЙЧАС</Text>
              </TouchableOpacity>
            </View>
          </View>
        ),
        componentProps: {
          titleStyle: {
            color: colors.warning,
          },
        },
      });
    }

    return null;
  }, [hidePopUp, refreshPage, skipChecking, W_WIDTH]);

  useEffect(() => {
    setTimeout(checkVersion, 1500);

    const interval = setInterval(checkVersion, 10000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [checkVersion]);

  return null;
};

export default WebAppVersionSynchronizer;
