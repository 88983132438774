import * as React from 'react';
import Svg, {
  Path,
} from 'react-native-svg';
import { FC } from 'react';

interface IProps {
  color: string,
}

const BeanSvg: FC<IProps> = ({ color }) => (
  <Svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
  >
    <Path
      d="M19.017 4.017c-1.032-.579-2.173-.904-3.443-.98-.628-.033-2.041.103-2.696.267C7.961 4.547 4.17 9.16 3.924 14.208c-.027.471.069 1.713.16 2.18.176.872.535 1.829.959 2.53.374.635.547.608.812-.147.827-2.341 2.845-4.28 5.808-5.565a27.447 27.447 0 011.785-.675c2.133-.706 3.352-1.419 4.44-2.62.941-1.04 1.484-2.124 1.751-3.498.123-.598.172-1.465.107-1.703-.069-.22-.308-.455-.73-.693z"
      fill={color}
    />
    <Path
      d="M21.397 6.36c-.206-.07-.294.063-.43.65-.58 2.445-1.957 4.29-4.202 5.637-.684.404-1.696.842-2.844 1.224-1.968.647-3.38 1.383-4.58 2.376-.554.455-1.47 1.56-1.764 2.13-.435.82-.729 1.964-.633 2.412.041.184.076.225.366.468.687.565 1.953 1.03 3.223 1.187 1.15.137 2.509.024 3.71-.325 3.115-.903 5.983-3.417 7.437-6.52 1.363-2.926 1.434-6.19.18-8.586-.299-.562-.328-.61-.463-.654z"
      fill={color}
    />
  </Svg>
);

export default BeanSvg;
