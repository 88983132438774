import React, { FC } from 'react';
import { Enum_Componentuserdeliveryaddress_Type } from '@app/infrastructureLayer/generated/graphql';
import { View, StyleSheet } from 'react-native';
import colors from '@constants/colors';
import HomeSvg from '@svg/HomeSvg';
import WorkSvg from '@svg/WorkSvg';
import BookmarkSvg from '@svg/BookmarkSvg';

interface IProps {
  type?: Enum_Componentuserdeliveryaddress_Type,
  color?: string
}

const s = StyleSheet.create({
  other: {
    backgroundColor: colors.text,
    width: 6,
    height: 6,
    borderRadius: 6,
  },
  icon: {
    width: 22,
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const DeliveryAddressTypeSvg: FC<IProps> = (
  {
    type,
    color = colors.text,
  },
) => {
  switch (type) {
    case Enum_Componentuserdeliveryaddress_Type.Other:
      return <View style={s.icon}><BookmarkSvg color={color} /></View>;
    case Enum_Componentuserdeliveryaddress_Type.Home:
      return <View style={s.icon}><HomeSvg color={color} /></View>;
    case Enum_Componentuserdeliveryaddress_Type.Work:
      return <View style={s.icon}><WorkSvg color={color} /></View>;
    case undefined:
      return null;
    default:
      return (
        <View
          style={StyleSheet.flatten([
            s.other,
            !!color && { backgroundColor: color },
          ])}
        />
      );
  }
};

export default DeliveryAddressTypeSvg;
