/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import { getCustomConfig, getLinkingScheme } from '@utils/env';

import { RootStackParamList } from './types';

const scheme = getLinkingScheme();
const extra = getCustomConfig();

const linkingConfiguration: LinkingOptions<RootStackParamList> = {
  enabled: true,
  prefixes: [Linking.createURL('/'), extra.LINKING_URL, `${scheme}://`],
  config: {
    initialRouteName: 'IndexScreen',
    screens: {
      IndexScreen: '/',
      SelectDeliveryAddressScreen: '/selectDeliveryAddress',
      PaymentFailScreen: '/payment/fail',
      PaymentSuccessScreen: '/payment/success',
      AddCardFailScreen: '/card-add/fail',
      AddCardSuccessScreen: '/card-add/success',
      Tabs: {
        initialRouteName: 'MenuScreen',
        screens: {
          MenuScreen: '/menu',
          FavoritesScreen: '/favorites',
          ParamsScreen: '/info',
          ProfileScreen: '/profile',
        },
      },
      Auth: {
        initialRouteName: 'LoginScreen',
        screens: {
          LoginScreen: '/auth/login',
          SmsLoginScreen: '/auth/sms',
          RegistrationScreen: '/registration',
        },
      },
      MenuItemScreen: '/item',
      CartScreen: '/cart',
      MyAddressesScreen: '/profile/locations',
      EditMyAddressScreen: '/profile/locations/edit',
      EditProfileScreen: '/profile/edit',
      CreateMyAddressOnMapScreen: '/profile/locations/map/add',
      CreateMyAddressScreen: '/profile/locations/add',
      MyCardsScreen: '/profile/cards',
      OrderScreen: '/profile/order',
      OrdersHistoryScreen: '/profile/orders',

      FAQScreen: '/info/faq',
      AddressesScreen: '/info/addresses',
      AccountRemoveRequest: '/info/accountRemoveRequest',
      CustomScreen: '/info/page',

      // TODO: Возможно эти экраны нам нужны будет позже
      // Camera: '/camera',
      // QrLanding: '/qr_landing',
    },
  },
};

export default linkingConfiguration;
