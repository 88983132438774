import React, {
  forwardRef, useImperativeHandle, useRef,
} from 'react';
import MaskInput, { createNumberMask } from 'react-native-mask-input';
import { Mask } from 'react-native-mask-input/src/formatWithMask.types';
import {
  TextInput,
  StyleSheet,
  View,
  ViewStyle,
  Text,
  Platform,
} from 'react-native';
import { TextInputProps } from 'react-native/Libraries/Components/TextInput/TextInput';
import { StyleProp } from 'react-native/Libraries/StyleSheet/StyleSheet';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';

// Скрывает подсветку outline при вводе на вебе
const hideInputOutlineOnWeb = Platform.select({
  web: {
    outlineWidth: 0,
  },
  default: {},
});

const integerMask = createNumberMask({
  delimiter: '',
  separator: '',
  precision: 0,
});

const s = StyleSheet.create({
  root: {
    gap: 5,
  },
  input: {
    flex: 1,
    color: colors.text,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 11,
    paddingBottom: 11,
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
    ...hideInputOutlineOnWeb,
    // height: 38,
    borderRadius: 10,
    borderWidth: 1,
    flexDirection: 'row',
    borderColor: colors.grey,
    paddingVertical: 10,
    minHeight: 38,
  },
  inputError: {
    borderColor: colors.error,
  },
  label: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
  },
  required: {
    fontFamily: ubuntuFont.light,
    color: colors.error,
    fontSize: 12,
  },
  errorMessage: {
    fontSize: 9,
    color: colors.error,
    fontFamily: ubuntuFont.light,
  },
});

type BaseProps = {
  rootStyle?: StyleProp<ViewStyle>,
  type: 'text' | 'phone' | 'integer' | 'date'
  label?: string,
  errorMessage?: string | null,
  required?: boolean,

};

type IProps = (TextInputProps & BaseProps & { mask?: never }) | (TextInputProps & BaseProps & {
  type: 'text',
  mask?: Mask,
});

export interface InputRef {
  focus: () => void;
  blur: () => void;
}

const PHONE_MASK: Mask = (text = '') => {
  const cleanText = text.replace(/\D+/g, '');

  let fistDigitMask: RegExp | string = /[7,8]/;

  if (!['8'].includes(cleanText.charAt(0))) {
    fistDigitMask = '8';
  }

  return [fistDigitMask, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
};

// From import Masks from 'react-native-mask-input/src/Masks';
const DATE_DDMMYYYY_MASK: Mask = (text = '') => {
  const cleanText = text.replace(/\D+/g, '');

  let secondDigitDayMask = /\d/;

  if (cleanText.charAt(0) === '0') {
    secondDigitDayMask = /[1-9]/;
  }
  if (cleanText.charAt(0) === '3') {
    secondDigitDayMask = /[01]/;
  }

  let secondDigitMonthMask = /\d/;

  if (cleanText.charAt(2) === '0') {
    secondDigitMonthMask = /[1-9]/;
  }
  if (cleanText.charAt(2) === '1') {
    secondDigitMonthMask = /[012]/;
  }

  return [
    /[0-3]/,
    secondDigitDayMask,
    '.',
    /[0-1]/,
    secondDigitMonthMask,
    '.',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
};

const Input = forwardRef<InputRef, IProps>((
  {
    style,
    rootStyle,
    type = 'text',
    label,
    placeholderTextColor,
    errorMessage,
    required = false,
    ...rest
  },
  forwardedRef,
) => {
  const inputRef = useRef<TextInput>(null);

  useImperativeHandle(forwardedRef, () => ({
    focus: () => inputRef.current?.focus(),
    blur: () => inputRef.current?.blur(),
  }));

  const isError = !!errorMessage;

  return (
    <View style={StyleSheet.flatten([s.root, rootStyle])}>
      {!!label && (
        <Text style={s.label}>
          {label}
          {required && (
            <Text style={s.required}>
              {' '}
              *
            </Text>
          )}
        </Text>
      )}
      {type === 'text' && !rest.mask && (
        <TextInput
          ref={inputRef}
          placeholderTextColor={colors.grey || placeholderTextColor}
          style={StyleSheet.flatten([s.input, isError && s.inputError, style])}
          {...rest}
        />
      )}
      {type === 'text' && !!rest.mask && (
        <MaskInput
          ref={inputRef}
          maskAutoComplete
          placeholderTextColor={colors.grey || placeholderTextColor}
          style={StyleSheet.flatten([s.input, isError && s.inputError, style])}
          {...rest}
        />
      )}
      {type === 'phone' && (
        <MaskInput
          ref={inputRef}
          mask={PHONE_MASK}
          obfuscationCharacter="."
          placeholderTextColor={colors.grey || placeholderTextColor}
          style={StyleSheet.flatten([s.input, isError && s.inputError, style])}
          {...rest}
          keyboardType="number-pad"
        />
      )}
      {type === 'date' && (
        <MaskInput
          ref={inputRef}
          mask={DATE_DDMMYYYY_MASK}
          maskAutoComplete
          obfuscationCharacter="."
          placeholderTextColor={colors.grey || placeholderTextColor}
          style={StyleSheet.flatten([s.input, isError && s.inputError, style])}
          {...rest}
          keyboardType="number-pad"
        />
      )}
      {type === 'integer' && (
        <MaskInput
          ref={inputRef}
          mask={integerMask}
          obfuscationCharacter="."
          placeholderTextColor={colors.grey || placeholderTextColor}
          style={StyleSheet.flatten([s.input, isError && s.inputError, style])}
          {...rest}
          keyboardType="number-pad"
        />
      )}
      {!!errorMessage && (
        <Text style={s.errorMessage}>
          {errorMessage}
        </Text>
      )}
    </View>
  );
});

export default Input;
