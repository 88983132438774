import {
  ModifiersStateModelInstance,
} from '@screens/MenuItemScreen/model/ModifiersStateModel';
import React, { useContext } from 'react';

export const ModifiersStateModelContext = React
  .createContext<ModifiersStateModelInstance | undefined>(undefined);

export const useModifiersState = () => {
  const state = useContext(ModifiersStateModelContext);

  if (!state) {
    throw new Error('Вы пытаетесь получить состояние модификаторов вне контекста, или значение в контекст не было передано');
  }

  return state;
};
