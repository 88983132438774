import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { observer } from 'mobx-react-lite';
import Screen from '@components/layout/Screen';
import useStore from '@app/domain/store/useStore';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import FullScreenLoading from '@components/common/FullScreenLoading';
// import InfoSvg from '@svg/InfoSvg';
import DashedLine from '@components/common/DashedLine';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import { TabsScreenProps } from '@navigation/types';
import ArrowRightSvg from '@svg/ArrowRightSvg';
import BluePlaneSvg from '@svg/BluePlaneSvg';
// import { SheetManager } from 'react-native-actions-sheet';
import useProfileSync from '@hooks/useProfileSync/useProfileSync';
// import Picture from '@components/common/Picture/Picture';
import { useMyBonusesQuery } from '@app/infrastructureLayer/generated/graphql';
import QRCode from 'react-native-qrcode-svg';

const s = StyleSheet.create({
  root: {
    height: '100%',
    justifyContent: 'space-between',
  },
  container: {
    marginHorizontal: 16,
    gap: 5,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  nameContainer: {
    flexDirection: 'row',
    flexShrink: 1,
    flex: 1,
    gap: 5,
  },
  welcome: {
    fontSize: 16,
    fontWeight: '300',
    whiteSpace: 'nowrap',
  },
  name: {
    color: colors.primary,
    flexShrink: 1,
    fontWeight: '400',
    fontSize: 16,
  },
  body: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 15,
  },
  title: {
    marginBottom: 10,
    fontSize: 14,
    fontFamily: ubuntuFont.light,
  },
  qrView: {
    backgroundColor: colors.white,
    borderRadius: 10,
    padding: 15,
  },
  qrBlock: {
    marginBottom: 10,
    width: '100%',
    backgroundColor: colors.lightGrey,
    padding: 10,
    borderRadius: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  points: {
    fontSize: 36,
    fontFamily: ubuntuFont.medium,
    color: colors.primary,
  },
  bonusText: {
    textTransform: 'uppercase',
    fontSize: 24,
    fontFamily: ubuntuFont.light,
    marginTop: 10,
    marginBottom: 22,
  },
  actions: {
    marginTop: 25,
    rowGap: 15,
  },
  action: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    backgroundColor: colors.lightGrey,
    padding: 15,
  },
  navigation: {
    flexDirection: 'row',
    columnGap: 15,
    alignItems: 'center',
    marginBottom: 20,
  },
  navigationText: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
  },
  actionTitle: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
  },
  bottomRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 16,
    marginHorizontal: 16,
  },
  logoutButton: {},
  logoutButtonText: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
    textDecorationLine: 'underline',
    textDecorationColor: colors.primary,
  },
});

const welcomeTexts = {
  day: 'Добрый день,',
  evening: 'Добрый вечер,',
  night: 'Доброй ночи,',
  morning: 'Доброе утро,',
} as const;

const ProfileScreen: FC<TabsScreenProps<'ProfileScreen'>> = (
  {
    navigation: localNavigation,
  },
) => {
  const { refetchProfile } = useProfileSync();

  const {
    authStore: {
      auth: {
        hasAuthToken,
        removeToken,
      },
    },
    profileStore: {
      profile,
    },
    appStore: {
      city,
      globalSettings,
    },
  } = useStore();

  const phone = profile?.phone;

  const {
    client,
    data: bonusesData,
    loading: bonusesLoading,
    refetch: refetchBonuses,
  } = useMyBonusesQuery({
    skip: !globalSettings || globalSettings.useBonuses === false,
    fetchPolicy: 'network-only',
  });

  useFocusEffect(useCallback(() => {
    refetchProfile();
    refetchBonuses();
  }, [refetchProfile, refetchBonuses]));

  const bonuses = bonusesData?.myBonuses.value || 0;

  const needGoToRegistration = profile?.registrationCompleted === false;

  const isFocused = useIsFocused();

  useEffect(() => {
    if (!hasAuthToken && isFocused) {
      localNavigation.replace('Auth', { screen: 'LoginScreen' });
    }
  }, [hasAuthToken, isFocused, localNavigation]);

  const navigation = useNavigation();

  useFocusEffect(useCallback(() => {
    if (needGoToRegistration) {
      navigation.navigate('Auth', {
        screen: 'RegistrationScreen',
      });
    }
  }, [navigation, needGoToRegistration]));

  const welcomeText = useMemo(() => {
    const hour = new Date().getHours();
    if (hour < 6) {
      return welcomeTexts.night;
    }
    if (hour < 12) {
      return welcomeTexts.morning;
    }
    if (hour < 18) {
      return welcomeTexts.day;
    }
    return welcomeTexts.evening;
  }, []);

  const navigateToOrderHistory = useCallback(() => {
    navigation.navigate('OrdersHistoryScreen');
  }, [navigation]);

  const navigateToOrderMyAddresses = useCallback(() => {
    navigation.navigate('MyAddressesScreen');
  }, [navigation]);

  const navigateToMyCarts = useCallback(() => {
    navigation.navigate('MyCardsScreen');
  }, [navigation]);

  const navigateToEditProfile = useCallback(() => {
    navigation.navigate('EditProfileScreen');
  }, [navigation]);

  // const openBottomSheet = useCallback(() => {
  //   SheetManager.show('bonus-program-info');
  // }, []);

  const onPressLogout = useCallback(() => {
    removeToken();
    client.resetStore();
  }, [removeToken, client]);

  if (!hasAuthToken || needGoToRegistration) {
    return <FullScreenLoading />;
  }

  return (
    <Screen inTabs>
      <View style={s.root}>
        <View style={s.container}>
          <View style={s.header}>
            <View style={s.nameContainer}>
              <Text style={s.welcome}>
                {welcomeText}
              </Text>

              <Text style={s.name} numberOfLines={1} ellipsizeMode="tail">
                {profile?.fio}
              </Text>
            </View>
          </View>

          <DashedLine />
        </View>

        <View style={s.body}>

          {globalSettings?.useBonuses && (
            <>
              <Text style={s.title}>Ваша карта лояльности</Text>
              <View style={s.qrBlock}>
                <View>
                  <Text style={s.points}>
                    {bonusesLoading && <ActivityIndicator color={colors.primary} />}
                    {!bonusesLoading && bonuses}
                  </Text>
                  <Text style={s.bonusText}>
                    бонусных
                    {'\n'}
                    баллов
                  </Text>
                </View>

                {!!phone && (
                  <View style={s.qrView}>
                    <QRCode
                      size={120}
                      value={`7${String(phone)
                        .slice(1, 11)}`}
                      color="black"
                      backgroundColor={colors.lightGrey}
                    />
                  </View>
                )}
              </View>
            </>
          )}

          <View style={s.actions}>
            <Pressable style={s.action} onPress={navigateToEditProfile}>
              <Text style={s.actionTitle}>Редактировать профиль</Text>
              <ArrowRightSvg />
            </Pressable>
            <Pressable style={s.action} onPress={navigateToOrderMyAddresses}>
              <Text style={s.actionTitle}>Мои адреса</Text>
              <ArrowRightSvg />
            </Pressable>
            <Pressable style={s.action} onPress={navigateToOrderHistory}>
              <Text style={s.actionTitle}>История заказов</Text>
              <ArrowRightSvg />
            </Pressable>
            <Pressable style={s.action} onPress={navigateToMyCarts}>
              <Text style={s.actionTitle}>Мои карты</Text>
              <ArrowRightSvg />
            </Pressable>
          </View>

        </View>

        <View style={s.bottomRow}>

          <View style={s.navigation}>
            {!!city && (
              <>
                <BluePlaneSvg />
                <Text style={s.navigationText}>{city.title}</Text>
              </>
            )}
          </View>
          <Pressable style={s.logoutButton} onPress={onPressLogout} hitSlop={10}>
            <Text style={s.logoutButtonText}>
              Выйти
            </Text>
          </Pressable>
        </View>
      </View>
    </Screen>
  );
};

export default observer(ProfileScreen);
