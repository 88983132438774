import React, { JSX } from 'react';
import {
  View,
  StyleSheet,
} from 'react-native';
import colors from '@constants/colors';
import { observer } from 'mobx-react-lite';
import SegmentedItem from '@components/common/Segmented/includes/SegmentedItem';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRadius: 10,
    gap: 5,
    rowGap: 15,
    minHeight: 30,
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,
    backgroundColor: colors.switch.bg,
  },
});

export type SegmentedOption<T> = {
  value: T,
  title: string,
};

type IProps<TValue> = {
  value: TValue,
  options: SegmentedOption<TValue>[],
  onChange: (value: TValue) => void,
};

// eslint-disable-next-line @typescript-eslint/comma-dangle
const Segmented = <T, >({ value, options, onChange }: IProps<T>): JSX.Element => (
  <View style={s.root}>
    {options.map((op) => (
      <SegmentedItem
        key={op.title}
        title={op.title}
        onPress={onChange}
        isSelected={op.value === value}
        value={op.value}
      />
    ))}
  </View>
);

export default observer(Segmented);
