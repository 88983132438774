import React, { FC, PropsWithChildren } from 'react';
import useCheckout from '@hooks/useCheckout';
import { CheckoutContext } from '@components/CheckoutContext';
import { observer } from 'mobx-react-lite';

const CheckoutContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const contextValue = useCheckout();

  return (
    <CheckoutContext.Provider value={contextValue}>
      {children}
    </CheckoutContext.Provider>
  );
};

export default observer(CheckoutContextProvider);
