import React, { FC } from 'react';
import { View, StyleSheet } from 'react-native';
import { ProductInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Product';
import { observer } from 'mobx-react-lite';
import orderBy from 'lodash/orderBy';
import Property from './includes/Property';

const s = StyleSheet.create({
  properties: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    width: '100%',
    justifyContent: 'space-around',
  },
});

interface IProps {
  product: ProductInstance;
  full?: boolean,
}

const Properties: FC<IProps> = (
  {
    product,
    full = false,
  },
) => {
  if (!product.params.length) {
    return null;
  }

  const paramPriority = {
    CALORIES: 1, FAT: 2, PROTEIN: 3, CARBOHYDRATE: 4, WEIGHT: 5, VOLUME: 6,
  };

  const sortedList = orderBy(product.params, [
    (item) => paramPriority[item.type],
    'routeIndex',
  ]);

  return (
    <View style={s.properties}>
      {sortedList.map((param) => (
        <Property
          product={product}
          property={param}
          key={param.id}
          full={full}
        />
      ))}
    </View>
  );
};

export default observer(Properties);
