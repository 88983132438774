import { observer } from 'mobx-react-lite';
import React, { FC, useMemo } from 'react';
import GreyBlock from '@components/layout/GreyBlock';
import {
  View,
  Text,
  Pressable,
  StyleSheet,
} from 'react-native';
import RoundMinusSvg from '@svg/RoundMinusSvg';
import RoundPlusSvg from '@svg/RoundPlusSvg';
import useStore from '@app/domain/store/useStore';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
  },
  title: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 14,
    flex: 1,
    lineHeight: 20,
  },
  amount: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 14,
    lineHeight: 20,
  },
  right: {
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const Cutlery: FC = () => {
  const {
    appStore: {
      cartStates,
      selectedShopId,
    },
  } = useStore();

  const cartState = selectedShopId ? cartStates.get(selectedShopId) : undefined;

  const { reduce, increase } = useMemo(() => ({
    reduce: () => cartState?.reduceCutleryCount(),
    increase: () => cartState?.increaseCutleryCount(),
  }), [cartState]);

  if (!cartState) {
    return null;
  }

  return (
    <GreyBlock style={s.root}>
      <Text style={s.title}>Количество приборов</Text>
      <View style={s.right}>
        <Pressable onPress={reduce} hitSlop={8}>
          <RoundMinusSvg />
        </Pressable>
        <Text style={s.amount}>
          {cartState.cutleryCount}
        </Text>
        <Pressable onPress={increase} hitSlop={8}>
          <RoundPlusSvg />
        </Pressable>
      </View>
    </GreyBlock>
  );
};

export default observer(Cutlery);
