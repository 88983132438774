import React, { useCallback, useEffect, useState } from 'react';
import * as Updates from 'expo-updates';
import {
  TouchableOpacity, Text, StyleSheet,
} from 'react-native';
import { getEnvironment } from '@utils/env';
import Constants from 'expo-constants';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';
import crossAlert from '@utils/crossAlert';

const s = StyleSheet.create({
  text: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 14,
  },
});

const AppVersion = () => {
  const [pressCount, setPressCount] = useState(0);

  useEffect(() => {
    if (pressCount > 0 && pressCount % 5 === 0) {
      crossAlert(
        `Updates.channel=${Updates.channel}\n${JSON.stringify(getEnvironment())}`,
      );
    }
  }, [pressCount]);

  const onPress = useCallback(() => {
    setPressCount((prev) => prev + 1);
  }, []);

  return (
    <TouchableOpacity
      hitSlop={10}
      onPress={onPress}
    >
      <Text style={s.text}>
        {Constants.expoConfig?.version}
      </Text>
    </TouchableOpacity>
  );
};

export default AppVersion;
