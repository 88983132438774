import React, {
  FC,
  useCallback,
  useEffect,
} from 'react';
import { observer } from 'mobx-react-lite';
import {
  StyleSheet,
  View,
} from 'react-native';
import useStore from '@app/domain/store/useStore';
import SelectDeliveryAddressOnMapHOC
  from '@screens/SelectDeliveryAddressScreen/includes/SelectDeliveryAddressOnMap/SelectDeliveryAddressOnMapHOC';
import Screen from '@components/layout/Screen';
import Back from '@components/layout/Back';
import { RootStackScreenProps } from '@navigation/types';
import FullScreenLoading from '@components/common/FullScreenLoading';

const s = StyleSheet.create({
  flex: {
    flex: 1,
  },
});

const SelectDeliveryAddressScreen: FC<RootStackScreenProps<'SelectDeliveryAddressScreen'>> = (
  {
    navigation,
  },
) => {
  const {
    profileStore: {
      profile,
    },
  } = useStore();

  useEffect(() => {
    if (!profile) {
      navigation.replace('Auth', { screen: 'LoginScreen' });
    }
  }, [profile, navigation]);

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }
    navigation.navigate('IndexScreen');
  }, [navigation]);

  if (!profile) {
    return <FullScreenLoading />;
  }

  return (
    <Screen>
      <Back
        title="Назад"
        onPress={onBack}
      />
      <View style={s.flex}>
        <SelectDeliveryAddressOnMapHOC />
      </View>
    </Screen>
  );
};

export default observer(SelectDeliveryAddressScreen);
