import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

const HeartEmptySvg = () => (
  <Svg viewBox="0 0 32 32" height="24" width="24">
    <G data-name="Layer 51" id="Layer_51">
      <Path
        fill="#6E9672"
        d="M21.84,3.88a7.94,7.94,0,0,0-5.78,2.5L16,6.44l-.06-.06a7.94,7.94,0,0,0-11.57,0,8.78,8.78,0,0,0,0,12l.78.82.07.07,10.13,8.62a1,1,0,0,0,1.3,0l10.12-8.62.08-.07.78-.82a8.78,8.78,0,0,0,0-12A8,8,0,0,0,21.84,3.88ZM26.18,17l-.74.78-9.44,8-9.44-8L5.81,17a6.79,6.79,0,0,1,0-9.24,5.95,5.95,0,0,1,8.68,0l.79.82a1,1,0,0,0,1.44,0l.78-.82a5.95,5.95,0,0,1,8.68,0,6.77,6.77,0,0,1,0,9.24Z"
      />
    </G>
  </Svg>
);

export default HeartEmptySvg;
