import * as React from 'react';
import Svg, {
  ClipPath, Defs, G, Path, Rect,
} from 'react-native-svg';

const DeleteSvg = () => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <G clip-path="url(#clip0_74_122)">
      <Path
        d="M16.8113 18.5724H16.7771V18.6724C16.7771 19.1958 16.375 19.5804 15.9201 19.5804H7.85429C7.36977 19.5804 6.99733 19.1645 6.99733 18.6724V7.07239H16.8113V18.5724ZM10.1075 3.51639V3.41639H10.0899C10.1296 3.30115 10.2351 3.22039 10.3492 3.22039H13.391C13.536 3.22039 13.667 3.35066 13.667 3.51639V4.35239H10.1075V3.51639ZM7.85429 20.5004H15.9201C16.9043 20.5004 17.6606 19.6573 17.6606 18.6724V7.07239H18.2783C18.4052 7.07239 18.5179 7.02726 18.5987 6.94224C18.6789 6.85769 18.7201 6.74161 18.7201 6.61239V5.78439C18.7201 4.97632 18.0974 4.35239 17.3556 4.35239H14.5505V3.51639C14.5505 2.85412 14.0321 2.30039 13.391 2.30039H10.3492C9.70816 2.30039 9.18974 2.85412 9.18974 3.51639V4.35239H6.38467C5.60519 4.35239 5.02012 5.01593 5.02012 5.78439V6.61239C5.02012 6.74161 5.06131 6.85769 5.14158 6.94224C5.2223 7.02726 5.33502 7.07239 5.46189 7.07239H6.07961V18.6724C6.07961 19.66 6.87275 20.5004 7.85429 20.5004ZM5.90366 5.78439C5.90366 5.47857 6.13335 5.27239 6.38467 5.27239H17.3214C17.5994 5.27239 17.8024 5.50669 17.8024 5.78439V6.15239H5.90366V5.78439Z"
        fill="#2B353C"
        stroke="#2B353C"
        strokeWidth="0.2"
      />
      <Path
        d="M15.1605 20.3692C15.3032 20.3692 15.4299 20.3212 15.5212 20.2299C15.6125 20.1387 15.6605 20.0119 15.6605 19.8692V10.3492C15.6605 10.2065 15.6125 10.0798 15.5212 9.98851C15.4299 9.89723 15.3032 9.84922 15.1605 9.84922C15.0177 9.84922 14.891 9.89723 14.7998 9.98851C14.7085 10.0798 14.6605 10.2065 14.6605 10.3492V19.8692C14.6605 20.1708 14.9119 20.3692 15.1605 20.3692ZM12.0405 20.3692C12.1832 20.3692 12.3099 20.3212 12.4012 20.2299C12.4925 20.1387 12.5405 20.0119 12.5405 19.8692V10.3492C12.5405 10.2065 12.4925 10.0798 12.4012 9.98851C12.3099 9.89723 12.1832 9.84922 12.0405 9.84922C11.8977 9.84922 11.771 9.89723 11.6798 9.98851C11.5885 10.0798 11.5405 10.2065 11.5405 10.3492V19.8692C11.5405 20.0119 11.5885 20.1387 11.6798 20.2299C11.771 20.3212 11.8977 20.3692 12.0405 20.3692ZM8.88047 20.3692C9.02319 20.3692 9.1499 20.3212 9.24118 20.2299C9.33246 20.1387 9.38047 20.0119 9.38047 19.8692V10.3492C9.38047 10.2065 9.33246 10.0798 9.24118 9.98851C9.1499 9.89723 9.02319 9.84922 8.88047 9.84922C8.73774 9.84922 8.61104 9.89723 8.51976 9.98851C8.42848 10.0798 8.38047 10.2065 8.38047 10.3492V19.8692C8.38047 20.0119 8.42848 20.1387 8.51976 20.2299C8.61104 20.3212 8.73774 20.3692 8.88047 20.3692Z"
        fill="#2B353C"
        stroke="#2B353C"
        strokeWidth="0.2"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_74_122">
        <Rect width="24" height="24" fill="white" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default DeleteSvg;
