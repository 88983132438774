import React, {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  ScrollView,
  StyleSheet, useWindowDimensions,
  View,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
  useScrollHandlers,
} from 'react-native-actions-sheet';
import Button from '@components/common/Button';
import useStore from '@app/domain/store/useStore';
import { observer } from 'mobx-react-lite';
import SheetSelectItem from '@components/common/SheetSelectItem';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 20,
  },
  scrollview: {
    width: '100%',
    paddingBottom: 40,
  },
});

const snapPoints = [100];

const SelectPaymentTypeSheet: FC<SheetProps> = (
  {
    sheetId,
  },
) => {
  const { height } = useWindowDimensions();
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const scrollHandlers = useScrollHandlers<ScrollView>(sheetId, actionSheetRef);

  const {
    appStore: {
      selectedShopId,
      cartStates,
      paymentTypes,
      shop,
    },
  } = useStore();

  const cartState = selectedShopId ? cartStates.get(selectedShopId) : undefined;
  const currentPaymentTypeId = cartState?.paymentTypeId || undefined;

  const [paymentTypeId, setPaymentTypeId] = useState<string | undefined>(currentPaymentTypeId);

  useEffect(() => {
    setPaymentTypeId(currentPaymentTypeId);
  }, [currentPaymentTypeId]);

  const onConfirm = useCallback(() => {
    if (paymentTypeId) {
      cartState?.setPaymentTypeId(paymentTypeId);
    }
    actionSheetRef.current?.hide();
  }, [cartState, paymentTypeId]);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      <View style={s.root}>
        <ScrollView
          {...scrollHandlers}
          style={StyleSheet.flatten([s.scrollview, { maxHeight: height * 0.5 }])}
          alwaysBounceVertical={false}
        >
          {shop?.allowedPaymentTypes?.map((paymentType, index) => {
            if (!paymentType) {
              return null;
            }

            return (
              <SheetSelectItem
                key={paymentType.id}
                id={paymentType.id}
                title={paymentType.title}
                onSelect={setPaymentTypeId}
                isSelected={paymentType.id === paymentTypeId}
                isLast={index + 1 === paymentTypes.size}
              />
            );
          })}
        </ScrollView>
        <Button
          onPress={onConfirm}
          title="Подтвердить"
        />
      </View>
    </ActionSheet>
  );
};

export default observer(SelectPaymentTypeSheet);
