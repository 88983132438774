import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const ArrowDownSvg = () => (
  <Svg
    width={13}
    height={7}
    viewBox="0 0 13 7"
    fill="none"
  >
    <Path
      d="M1.5 1l4.93 4.93a.1.1 0 00.14 0L11.5 1"
      stroke="#000"
      strokeWidth={1.2}
      strokeLinecap="round"
    />
  </Svg>
);

export default ArrowDownSvg;
