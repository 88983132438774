import React, {
  FC,
  useCallback,
  useRef,
} from 'react';
import {
  LayoutChangeEvent, NativeScrollEvent, View,
  NativeSyntheticEvent, ScrollView, StyleSheet,
} from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { TabsScreenProps } from '@navigation/types';
import { observer } from 'mobx-react-lite';
import SearchInMenu from '@widgets/SearchInMenu';
import TopBlock from '@screens/Tabs/MenuScreen/includes/TopBlock';
import useStore from '@app/domain/store/useStore';
import Screen from '@components/layout/Screen';
import colors from '@constants/colors';
import useShopIdSync from '@hooks/useShopIdSync';
import BottomCartBlock from './includes/BottomCartBlock';
import Categories from './includes/Categories/Categories';
import Menu from './includes/Menu/Menu';

const s = StyleSheet.create({
  root: {
    backgroundColor: colors.background,
    paddingBottom: 20,
  },
  categories: {
    paddingBottom: 10,
    backgroundColor: colors.categoriesBackground,
  },
  lowerBlock: {
    marginHorizontal: 16,
    marginBottom: 16,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const stickyHeaderIndices = [1];

const MenuScreen: FC<TabsScreenProps<'MenuScreen'>> = (
  {
    route,
  },
) => {
  const scrollRef = useRef<ScrollView>(null);
  const { UIStore: { menuScreenUIStore } } = useStore();
  useShopIdSync(route.params?.shopId);
  // const navigation = useNavigation();
  const roomNumber = route.params?.roomNumber;

  // const initialShopId = useRef(route.params?.shopId).current;
  // const [initialShopIdAccepted, setInitialShopIdAccepted] = useState(false);

  const {
    appStore: {
      // selectedShopId,
      // setSelectedShopId,
      hasSearchQuery,
      activeMenu,
    },
    deviceVarsStore: {
      setRoomNumber,
    },
  } = useStore();

  useFocusEffect(useCallback(() => {
    if (typeof roomNumber !== 'undefined' && roomNumber.trim().length > 0) {
      setRoomNumber(roomNumber.trim());
    }
  }, [setRoomNumber, roomNumber]));

  // const setShopIdInRoute = useCallback((newShopId: string) => {
  //   localNavigation.setParams({
  //     shopId: newShopId,
  //   });
  // }, [localNavigation]);

  // useEffect(() => {
  //   if (initialShopIdAccepted && selectedShopId) {
  //     setShopIdInRoute(selectedShopId);
  //   }
  // }, [selectedShopId, setShopIdInRoute, initialShopIdAccepted]);

  // useEffect(() => {
  //   if (!initialShopIdAccepted && initialShopId) {
  //     setSelectedShopId(initialShopId);
  //     setInitialShopIdAccepted(true);
  //   }
  // }, [initialShopId, setSelectedShopId, initialShopIdAccepted]);
  //
  // useEffect(() => {
  //   if (!initialShopId) {
  //     navigation.reset({
  //       index: 0,
  //       routes: [{ name: 'IndexScreen' }],
  //     });
  //   }
  // }, [initialShopId, navigation]);

  const onScroll = useCallback((event: NativeSyntheticEvent<NativeScrollEvent>) => {
    menuScreenUIStore.setScrollYPosition(event.nativeEvent.contentOffset.y);
  }, [menuScreenUIStore]);

  const onLayoutMenu = useCallback((event: LayoutChangeEvent) => {
    menuScreenUIStore.setMenuOffset(event.nativeEvent.layout.y);
  }, [menuScreenUIStore]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onCategoryPress = useCallback((categoryId: string) => {
    const pos = menuScreenUIStore.categoryPositions.get(categoryId);
    if (pos !== undefined) {
      menuScreenUIStore.setWaitScrollAnimation(true);
      scrollRef.current?.scrollTo({
        x: 0,
        y: pos + menuScreenUIStore.menuOffset - menuScreenUIStore.categoryOffset,
        animated: true,
      });

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        menuScreenUIStore.setWaitScrollAnimation(false);
        timeoutRef.current = null;
      }, 750);
    }

    return undefined;
  }, [menuScreenUIStore]);

  return (
    <Screen inTabs>
      <ScrollView
        ref={scrollRef}
        onScroll={onScroll}
        contentContainerStyle={s.root}
        scrollEventThrottle={17}
        stickyHeaderIndices={stickyHeaderIndices}
        stickyHeaderHiddenOnScroll={false}
        keyboardDismissMode="on-drag"
      >
        <TopBlock />
        <View style={s.categories}>
          {!!activeMenu && (
            <SearchInMenu />
          )}
          {!hasSearchQuery && (
            <Categories
              onCategoryPress={onCategoryPress}
            />
          )}
        </View>
        <View onLayout={onLayoutMenu}>
          <Menu />
        </View>
      </ScrollView>
      <View style={s.lowerBlock}>
        <BottomCartBlock />
      </View>
    </Screen>
  );
};

export default observer(MenuScreen);
