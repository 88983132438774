import React, { FC, useCallback, useMemo } from 'react';
import {
  View, StyleSheet, useWindowDimensions,
  Image, Pressable, ScrollView, Text,
} from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { TabsScreenProps } from '@navigation/types';
import { useInstance } from 'react-ioc';
import { observer } from 'mobx-react-lite';
import { Api } from '@app/infrastructureLayer/generated/Api';
import ArrowRightSvg from '@svg/ArrowRightSvg';
import AppVersion from '@components/common/AppVersion';
import useStore from '@app/domain/store/useStore';
import compact from 'lodash/compact';
import Screen from '@components/layout/Screen';
import colors from '@constants/colors';
import images from '@constants/images';
import useSWR from 'swr';

const s = StyleSheet.create({
  container: {
    marginBottom: 30,
    backgroundColor: colors.background,
    alignItems: 'center',
    marginTop: 15,
    paddingHorizontal: 15,
    paddingBottom: 40,
  },
  actions: {
    marginTop: 25,
    rowGap: 15,
    width: '100%',
  },
  action: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 10,
    backgroundColor: colors.lightGrey,
    padding: 15,
  },
});

const ParamsScreen: FC<TabsScreenProps<'ParamsScreen'>> = () => {
  const navigation = useNavigation();
  const { width } = useWindowDimensions();

  const {
    appStore: {
      globalSettings,
    },
  } = useStore();

  const apiClient = useInstance(Api);

  const {
    data,
    mutate,
  } = useSWR(
    'custom-screens',
    async () => {
      const res = await apiClient.customScreens.getCustomScreens({
        sort: 'rank',
      });

      return res.data.data;
    },
    {
      refreshInterval: 30000,
      errorRetryInterval: 500,
      errorRetryCount: Infinity,
      shouldRetryOnError: true,
    },
  );

  useFocusEffect(useCallback(() => {
    mutate();
  }, [mutate]));

  const styles = useMemo(() => ({
    width: width * 0.6,
    height: width * 0.6,
    maxWidth: 300,
    maxHeight: 300,
  }), [width]);

  const navigateToFAQ = useCallback(() => {
    navigation.navigate('FAQScreen');
  }, [navigation]);

  const navigateToAddresses = useCallback(() => {
    navigation.navigate('AddressesScreen');
  }, [navigation]);

  const navigateToAccRemove = useCallback(() => {
    navigation.navigate('AccountRemoveRequest');
  }, [navigation]);

  const customPages = useMemo(() => {
    if (!data) {
      return [];
    }

    return compact(data.map((page) => {
      if (!page.attributes || !page.id) {
        return null;
      }

      return ({
        id: page.id,
        title: page.attributes.title,
        content: page.attributes.content,
        rank: page.attributes.rank,
        onPress: () => navigation.navigate('CustomScreen', { id: String(page.id) }),
      });
    }));
  }, [data, navigation]);

  return (
    <Screen inTabs>
      <ScrollView
        contentContainerStyle={s.container}
      >
        <Image source={images.companyLogoSource} style={styles} />
        <View style={s.actions}>

          <Pressable style={s.action} onPress={navigateToAddresses}>
            <Text>Наши адреса</Text>
            <ArrowRightSvg />
          </Pressable>

          <Pressable style={s.action} onPress={navigateToAccRemove}>
            <Text>Запросить удаление аккаунта</Text>
            <ArrowRightSvg />
          </Pressable>

          {globalSettings?.useFaq && (
            <Pressable style={s.action} onPress={navigateToFAQ}>
              <Text>Вопросы и ответы</Text>
              <ArrowRightSvg />
            </Pressable>
          )}

          {customPages.map((page) => (
            <Pressable
              style={s.action}
              onPress={page.onPress}
              key={page.id}
            >
              <Text>{page.title}</Text>
              <ArrowRightSvg />
            </Pressable>
          ))}

          <View style={s.action}>
            <Text>Версия приложения</Text>
            <AppVersion />
          </View>
        </View>
      </ScrollView>
    </Screen>
  );
};

export default observer(ParamsScreen);
