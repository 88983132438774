const formatAlfaMaskedPan = (value: string) => {
  const first6 = value.slice(0, 6);
  const last4 = value.slice(8, 12);

  const p1 = first6.slice(0, 4);
  const p2 = first6.slice(4, 6);

  return `${p1} ${p2}** **** ${last4}`;
};

export default formatAlfaMaskedPan;
