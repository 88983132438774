import React, { FC, useCallback } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import SelectCheckboxSvg from '@svg/SelectChekboxSvg';
import DashedLine from '@components/common/DashedLine';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 20,
    paddingRight: 10,
  },
  rootLast: {
    marginBottom: 10,
  },
  text: {
    fontFamily: ubuntuFont.light,
    fontSize: 14,
    color: colors.text,
  },
  subtitle: {
    fontFamily: ubuntuFont.light,
    fontSize: 12,
    color: colors.grey,
  },
  textSelected: {
    color: colors.primary,
  },
  textRoot: {
    gap: 5,
  },
  dash: {
    marginBottom: 10,
    marginTop: 10,
  },
  rootWithSubtitle: {
    minHeight: 35,
  },
});

interface IProps {
  id: string,
  title: string,
  subtitle?: string,
  onSelect: (value: string) => void;
  isSelected: boolean,
  isLast: boolean,
}

const SheetSelectItem: FC<IProps> = (
  {
    id,
    title,
    subtitle,
    onSelect,
    isSelected,
    isLast,
  },
) => {
  const onPress = useCallback(() => {
    onSelect(id);
  }, [onSelect, id]);

  return (
    <TouchableOpacity onPress={onPress}>
      <View>
        <View style={StyleSheet.flatten([s.root, isLast && s.rootLast, !!subtitle && s.rootWithSubtitle])}>
          <View style={s.textRoot}>
            <Text style={StyleSheet.flatten([s.text, isSelected && s.textSelected])}>
              {title}
            </Text>
            {!!subtitle && (
              <Text style={StyleSheet.flatten([s.subtitle])}>
                {subtitle}
              </Text>
            )}
          </View>
          <SelectCheckboxSvg checked={isSelected} />
        </View>
        {!isLast && (
          <View style={s.dash}>
            <DashedLine />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default SheetSelectItem;
