const getOrderCookingStatusName = (orderCookingStatus: string) => {
  switch (orderCookingStatus) {
    case '':
    case 'Unconfirmed':
    case 'WaitCooking':
    case 'ReadyForCooking':
    case 'Waiting':
      return 'Скоро начнем готовить';
    case 'CookingStarted':
      return 'Готовится';
    case 'CookingCompleted':
      return 'Приготовлен';
    case 'OnWay':
      return 'В пути';
    case 'Delivered':
      return 'Доставлен';
    case 'Closed':
      return 'Закрыт';
    case 'Cancelled':
      return 'Отменён';
    default:
      return '';
  }
};

export default getOrderCookingStatusName;
