import React, { FC } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Segmented from '@components/common/Segmented/Segmented';
import { Enum_Userspermissionsuser_Sex } from '@app/infrastructureLayer/generated/graphql';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    gap: 5,
  },
  box: {
    borderWidth: 1,
    borderColor: colors.grey,
    borderRadius: 10,
  },
  title: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
  },
});

export type IGenderSwitchProps = {
  value: Enum_Userspermissionsuser_Sex;
  onChange: (newValue: Enum_Userspermissionsuser_Sex) => void;
};

const variants: {
  value: Enum_Userspermissionsuser_Sex,
  title: string,
}[] = [
  { value: Enum_Userspermissionsuser_Sex.Male, title: 'мужской' },
  { value: Enum_Userspermissionsuser_Sex.Female, title: 'женский' },
  // { value: Enum_Userspermissionsuser_Sex.NotSelected, title: 'не выбрано' },
];

const GenderSegmented: FC<IGenderSwitchProps> = (
  {
    value,
    onChange,
  },
) => (
  <View style={s.root}>
    <Text style={s.title}>
      Пол
    </Text>
    <View style={s.box}>
      <Segmented
        value={value}
        onChange={onChange}
        options={variants}
      />
    </View>
  </View>
);

export default GenderSegmented;
