import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import useStore from '@app/domain/store/useStore';
import colors from '@constants/colors';
import PlusSvg from '@svg/PlusSvg';
import MinusSvg from '@svg/MinusSvg';
import generalConfig from '@constants/generalConfig';
import ubuntuFont from '@constants/ubuntuFont';
import { useModifiersState } from '@screens/MenuItemScreen/model/ModifiersStateModelContext';
import { computed } from 'mobx';
import currency from 'currency.js';

interface IProps {
  menuItemId: string,
  disabled?: boolean,
}

const s = StyleSheet.create({
  wrapper: {
    flex: 1,
  },
  button: {
    width: '100%',
    minHeight: 40,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  editableButton: {
    width: '100%',
    minHeight: 40,
    borderRadius: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
  },
  buttonEnabled: {
    backgroundColor: colors.primary,
  },
  buttonDisabled: {
    backgroundColor: colors.grey,
  },
  buttonText: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    fontFamily: ubuntuFont.medium,
    color: colors.white,
    fontSize: 14,
    alignItems: 'center',
  },
});

const CartButton: FC<IProps> = (
  {
    menuItemId,
    disabled = false,
  },
) => {
  const {
    appStore: {
      increaseCartItemAmount,
      reduceCartItemAmount,
      findCartItem,
      activeMenu,
      pricesByModifierId,
    },
  } = useStore();

  const menuItem = activeMenu?.menuItems.get(menuItemId);

  const modifiersStateModel = useModifiersState();

  const cartItem = menuItem?.product?.id ? findCartItem({
    productId: menuItem.product.id,
    modifiers: modifiersStateModel.keyValueState,
  }) : undefined;

  const productId = menuItem?.product?.id;

  const { increase, reduce } = useMemo(() => ({
    increase: () => !!productId && increaseCartItemAmount({
      productId,
      modifiers: modifiersStateModel.keyValueState,
    }),
    reduce: () => !!productId && reduceCartItemAmount({
      productId,
      modifiers: modifiersStateModel.keyValueState,
    }),
  }), [productId, increaseCartItemAmount, modifiersStateModel.keyValueState, reduceCartItemAmount]);

  const count = cartItem ? cartItem.amount : 0;

  const menuItemPrice = menuItem?.price;

  const modifiersPrice = computed(() => {
    const modifiersIds = Object.keys(modifiersStateModel.keyValueState);
    if (modifiersIds.length === 0) {
      return 0;
    }

    return modifiersIds.reduce((sum, modifierId) => {
      const price = pricesByModifierId.get(modifierId);

      if (!price) {
        return sum;
      }

      const amount = modifiersStateModel.keyValueState[modifierId] || 0;

      return sum.add(
        currency(price)
          .multiply(amount),
      );
    }, currency(0)).value;
  })
    .get();

  const price = currency(menuItemPrice || 0).add(modifiersPrice).value;

  return (
    <View style={s.wrapper}>
      {count === 0 && (
        <TouchableOpacity
          style={StyleSheet.flatten([s.button, disabled ? s.buttonDisabled : s.buttonEnabled])}
          onPress={increase}
          disabled={disabled}
        >
          <Text style={s.buttonText}>{`${price} ${generalConfig.currencyLabel}`}</Text>
          <PlusSvg />
        </TouchableOpacity>
      )}
      {count > 0 && (
        <View style={StyleSheet.flatten([s.editableButton, disabled ? s.buttonDisabled : s.buttonEnabled])}>
          <TouchableOpacity onPress={reduce} hitSlop={12}>
            <MinusSvg />
          </TouchableOpacity>
          <Text style={s.buttonText}>{`${count} x ${price} ${generalConfig.currencyLabel}`}</Text>
          <TouchableOpacity onPress={increase} hitSlop={12}>
            <PlusSvg />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

export default observer(CartButton);
