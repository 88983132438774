import { observer } from 'mobx-react-lite';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import GreyBlock from '@components/layout/GreyBlock';
import {
  ActivityIndicator,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import useStore from '@app/domain/store/useStore';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import ArrowRightSvg from '@svg/ArrowRightSvg';
import { SheetManager } from 'react-native-actions-sheet';
import { Enum_Paymenttype_Paymenttypecode, useGetMyCreditCardsQuery } from '@app/infrastructureLayer/generated/graphql';
import compact from 'lodash/compact';
import CreditCard from '@components/CreditCard';

const s = StyleSheet.create({
  root: {},
  row: {
    flexDirection: 'row',
  },
  text: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 14,
    flex: 1,
    lineHeight: 20,
  },
  title: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
  },
  right: {
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    padding: 0,
    paddingTop: 10,
  },
});

const CardSelector: FC = () => {
  const {
    profileStore: {
      profile,
    },
    appStore: {
      activeCartState,
      paymentTypes,
    },
    authStore: { auth: { hasAuthToken } },
  } = useStore();

  const {
    data,
    loading,
  } = useGetMyCreditCardsQuery({
    pollInterval: 1000,
    skip: !profile,
  });

  const cards = useMemo(() => compact(data?.myCreditCards?.data.map(
    (card) => (
      {
        id: card.id,
        maskedPan: card.maskedPan,
        expiryDate: card.expiryDate,
      }
    ),
  )), [data]);

  const isCardsLoaded = !!data?.myCreditCards?.data;

  useEffect(() => {
    // Проверим что карта выбранная есть у пользователя
    if (isCardsLoaded && activeCartState?.cardId) {
      const selectedCartCorrect = cards.some((c) => c.id === activeCartState?.cardId);
      if (!selectedCartCorrect) {
        activeCartState.setCardId(null);
      }
    }
  }, [activeCartState, cards, isCardsLoaded]);

  const onPress = useCallback(() => {
    SheetManager.show('select-card-sheet');
  }, []);

  const card = useMemo(() => {
    if (!activeCartState?.cardId) {
      return undefined;
    }

    return cards.filter((c) => c.id === activeCartState?.cardId)[0];
  }, [cards, activeCartState?.cardId]);

  const paymentType = activeCartState?.paymentTypeId ? paymentTypes.get(activeCartState.paymentTypeId) : undefined;

  const isCardPaymentSelected = paymentType?.type === Enum_Paymenttype_Paymenttypecode.Card;

  useEffect(() => {
    if (isCardPaymentSelected && !activeCartState?.cardId && cards.length === 1) {
      activeCartState?.setCardId(cards[0].id);
    }
  }, [activeCartState, cards, isCardPaymentSelected]);

  if (!isCardPaymentSelected || !hasAuthToken) {
    return null;
  }

  return (
    <Pressable onPress={onPress}>
      <GreyBlock
        style={s.root}
        focusUserAttention={!card}
      >
        <Text style={s.title}>
          Карта для оплаты
        </Text>
        {loading && (
          <ActivityIndicator color={colors.text} />
        )}
        {!loading && (
          <>
            {!card && (
              <View style={s.row}>
                <Text style={s.text}>
                  Выберете карту для оплаты
                </Text>
                <View style={s.right}>
                  <ArrowRightSvg
                    color={colors.text}
                  />
                </View>
              </View>
            )}
            {!!card && (
              <CreditCard
                rootStyle={s.card}
                maskedPan={card.maskedPan}
                expiryDate={card.expiryDate}
              />
            )}
          </>
        )}
      </GreyBlock>
    </Pressable>
  );
};

export default observer(CardSelector);
