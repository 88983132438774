import React, {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View,
  Text,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef, SheetManager,
  SheetProps,
  useScrollHandlers,
} from 'react-native-actions-sheet';
import Button from '@components/common/Button';
import useStore from '@app/domain/store/useStore';
import { observer } from 'mobx-react-lite';
import SheetSelectItem from '@components/common/SheetSelectItem';
import { computed } from 'mobx';
import orderBy from 'lodash/orderBy';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import navigationRef from '@navigation/navigationRef';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 20,
  },
  scrollview: {
    width: '100%',
    paddingBottom: 40,
  },
  root2: {
    gap: 10,
  },
  text: {
    fontFamily: ubuntuFont.light,
    fontSize: 14,
    color: colors.text,
    textAlign: 'center',
  },
});

const snapPoints = [100];

export type SelectDeliveryLocationSheetPayload = {
  goToAddNewAddress: boolean,
};

const SelectDeliveryLocationSheet: FC<SheetProps<SelectDeliveryLocationSheetPayload>> = (
  {
    sheetId,
  },
) => {
  const { height } = useWindowDimensions();
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const scrollHandlers = useScrollHandlers<ScrollView>(sheetId, actionSheetRef);

  const {
    appStore: {
      selectedDeliveryAddressId,
      setSelectedDeliveryAddressId,
      selectedCityId,
    },
    profileStore: {
      profile,
    },
  } = useStore();

  const locations = computed(() => {
    const deliveryAddressesByCityId = profile?.deliveryAddressesByCityId;

    if (!deliveryAddressesByCityId || !selectedCityId) {
      return [];
    }

    return orderBy(deliveryAddressesByCityId[selectedCityId] || [], 'id');
  })
    .get();

  const [deliveryAddressId, setDeliveryAddressId] = useState<string | null>(selectedDeliveryAddressId);

  useEffect(() => {
    setDeliveryAddressId(selectedDeliveryAddressId);
  }, [selectedDeliveryAddressId]);

  const onConfirm = useCallback(() => {
    SheetManager.hide(sheetId);
    setSelectedDeliveryAddressId(deliveryAddressId);
  }, [sheetId, deliveryAddressId, setSelectedDeliveryAddressId]);

  const onAddDeliveryAddress = useCallback(async () => {
    await SheetManager.hide(sheetId);
    navigationRef.navigate('SelectDeliveryAddressScreen');
  }, [sheetId]);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      <View style={s.root}>
        <ScrollView
          {...scrollHandlers}
          style={StyleSheet.flatten([s.scrollview, { maxHeight: height * 0.5 }])}
          alwaysBounceVertical={false}
        >
          {locations.map((address, index) => (
            <SheetSelectItem
              key={address.id}
              id={address.id}
              title={address.addressTitle}
              subtitle={address.title}
              onSelect={setDeliveryAddressId}
              isSelected={address.id === deliveryAddressId}
              isLast={index + 1 === locations.length}
            />
          ))}
          {locations.length === 0 && (
            <Text style={s.text}>Нет сохранённых адресов</Text>
          )}
        </ScrollView>
        <View style={s.root2}>
          {locations.length > 0 && (
            <>
              <Button
                onPress={onConfirm}
                title="Подтвердить"
              />
              <Text style={s.text}>или</Text>
            </>
          )}
          <Button
            onPress={onAddDeliveryAddress}
            title="+ Добавить новый адрес доставки"
          />
        </View>
      </View>
    </ActionSheet>
  );
};

export default observer(SelectDeliveryLocationSheet);
