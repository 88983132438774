import * as Sentry from 'sentry-expo';
import { CaptureConsole } from '@sentry/integrations';
import { getSentryDSN } from '@utils/env';

const initSentry = () => {
  const SENTRY_DSN = getSentryDSN();

  if (!SENTRY_DSN) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    enabled: true,
    environment: process.env.NODE_ENV,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    integrations: [new CaptureConsole(
      {
        levels: ['error', 'assert'],
      },
    )],
    enableInExpoDevelopment: false,
    debug: false, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
  });
};

export default initSentry;
