import { ProductParamInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/ProductParam';
import React, { FC } from 'react';
import { Text, StyleSheet } from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

interface IProps {
  property: ProductParamInstance,
}

const s = StyleSheet.create({
  text: {
    fontFamily: ubuntuFont.light,
    color: colors.grey,
    fontSize: 14,
  },
});

const ProductVolumeOrWeight: FC<IProps> = ({ property }) => (
  <Text style={s.text}>
    {`${property.value} ${property.unit}`}
  </Text>
);

export default ProductVolumeOrWeight;
