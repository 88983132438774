import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const BluePlaneSvg = () => (
  <Svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
  >
    <Path
      d="M10.9792 0.0518545C11.5857 -0.18163 12.1816 0.41433 11.9481 1.02079L7.91026 11.5191C7.64927 12.199 6.67029 12.1445 6.4853 11.4406L5.30633 6.96089C5.28938 6.89669 5.25568 6.83815 5.20868 6.79124C5.16168 6.74433 5.10307 6.71074 5.03884 6.69391L0.558967 5.51499C-0.145013 5.33 -0.198511 4.35107 0.480969 4.09008L10.9792 0.0518545Z"
      fill="#79B5B6"
    />
  </Svg>
);
export default BluePlaneSvg;
