import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect } from 'react';
import GreyBlock from '@components/layout/GreyBlock';
import {
  Text,
  StyleSheet,
  ActivityIndicator,
} from 'react-native';
import useStore from '@app/domain/store/useStore';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import Input from '@components/form/Input';
import { useMyBonusesQuery } from '@app/infrastructureLayer/generated/graphql';
import { useCheckoutState } from '@components/CheckoutContext';

const s = StyleSheet.create({
  root: {
    gap: 5,
  },
  loading: {
    marginLeft: 5,
  },
  title: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
  },
  accent: {
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
    color: colors.primary,
  },
});

const Bonuses: FC = () => {
  const { data: checkoutData } = useCheckoutState();

  const {
    profileStore: {
      profile,
    },
    appStore: {
      cartStates,
      selectedShopId,
    },
  } = useStore();

  const {
    data,
    loading,
  } = useMyBonusesQuery({
    skip: !profile,
    fetchPolicy: 'cache-and-network',
  });

  // const totalItemCostForPay = checkoutData?.totalItemCostForPay;
  const bonuses = checkoutData?.bonuses;

  const allValue = data?.myBonuses.value || 0;

  const cartState = selectedShopId ? cartStates.get(selectedShopId) : undefined;

  const promoCode = cartState?.promoCode;

  const hasPromoCode = !!promoCode?.length;

  const onChange = useCallback((value: string) => cartState?.setBonusesToPayAmount(Number(value)), [cartState]);

  useEffect(() => {
    if (!cartState || typeof allValue === 'undefined') {
      return undefined;
    }

    if (typeof bonuses !== 'undefined') {
      cartState?.setBonusesToPayAmount(bonuses);
    }

    if (allValue < cartState.bonusesToPayAmount) {
      cartState?.setBonusesToPayAmount(allValue);
    }
    //
    // if (typeof totalItemCostForPay !== 'undefined' && cartState.bonusesToPayAmount > totalItemCostForPay) {
    //   cartState?.setBonusesToPayAmount(totalItemCostForPay);
    // }

    return undefined;
  }, [cartState, cartState?.setBonusesToPayAmount, bonuses, allValue, cartState?.bonusesToPayAmount]);

  if (!cartState) {
    return null;
  }

  return (
    <GreyBlock style={s.root}>
      {!profile && (
        <>
          <Text style={s.title}>
            Бонусы
          </Text>
          <Text style={s.title}>
            Авторизуйтесь чтобы воспользоваться бонусной программой
          </Text>
        </>
      )}
      {!!profile && (
        <>
          <Text style={s.title}>
            Бонусов, доступных к списанию:
            {' '}
            {!!allValue && (
              <Text style={s.accent}>{allValue}</Text>
            )}
            {allValue === 0 && (
              <Text style={s.accent}>нет бонусов</Text>
            )}
            {loading && (
              <ActivityIndicator style={s.loading} color={colors.primary} size="small" />
            )}
          </Text>
          {!!allValue && (
            <>
              {hasPromoCode && (
                <Text style={s.title}>
                  Бонусы нельзя использовать вместе с промокодом
                </Text>
              )}
              {!hasPromoCode && (
                <Input
                  type="integer"
                  placeholder="Количество бонусов для списания"
                  value={cartState.bonusesToPayAmount > 0 ? String(cartState.bonusesToPayAmount) : ''}
                  onChangeText={onChange}
                />
              )}
            </>
          )}
        </>
      )}
    </GreyBlock>
  );
};

export default observer(Bonuses);
