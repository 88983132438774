import { View, StyleSheet } from 'react-native';
import React, { FC } from 'react';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    overflow: 'hidden',
    height: 1,
  },
  line: {
    borderWidth: 1,
    borderColor: colors.grey,
    borderStyle: 'dashed',
    height: 2,
  },
});

const DashedLine: FC = () => (
  <View style={s.root}>
    <View style={s.line} />
  </View>
);

export default DashedLine;
