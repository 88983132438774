import persist from 'mst-persist';
import getPersistAdapter from '@lib/adapters/getPersistAdapter';
import { Platform } from 'react-native';

import { CoreStore } from './CoreStore';
import type { CoreStoreInstance } from './CoreStore';
import { Env } from './env.type';

export default class CoreStoreFactory {
  static create(env: Env): CoreStoreInstance {
    const StoreInstance = CoreStore.create({}, env);

    persist(
      'authStore',
      StoreInstance.authStore,
      {
        storage: getPersistAdapter({ secure: true }),
        jsonify: true,
        whitelist: [
          'auth',
        ],
      },
    )
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        StoreInstance.authStore.setHydrated(true);
      });

    persist(
      'deviceVarsStore',
      StoreInstance.deviceVarsStore,
      {
        storage: getPersistAdapter({ secure: true }),
        jsonify: true,
        blacklist: [
          'id',
          'hydrated',
        ],
      },
    )
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        StoreInstance.deviceVarsStore.setHydrated(true);
      });

    persist(
      'profileStore',
      StoreInstance.profileStore,
      {
        storage: getPersistAdapter({ secure: false }),
        jsonify: true,
        whitelist: [
          'id',
          'profile',
        ],
      },
    )
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
      });

    persist(
      'appStore-v2',
      StoreInstance.appStore,
      {
        storage: getPersistAdapter({ secure: false }),
        jsonify: true,
        whitelist: Platform.OS === 'web'
          ? [
            'selectedOrderTypeId',
            'cart', // TODO: Корзины должны хранится на беке
            'cartStates',
            'selectedDeliveryAddressId',
          ]
          : [
            'selectedCityId',
            'selectedShopId',
            'selectedOrderTypeId',
            'selectedDeliveryAddressId',
            'cart', // TODO: Корзины должны хранится на беке
            'cartStates',
          ],
      },
    )
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
      });

    return StoreInstance;
  }
}
