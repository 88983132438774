import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';

export const TimeLimit = t
  .model('TimeLimit', {
    id: t.identifier,
    startTime: t.string, // hh:mm:ss
    endTime: t.string, // hh:mm:ss
  });

export interface TimeLimitInstance extends Instance<typeof TimeLimit> {
}

export interface TimeLimitSnapshotIn extends SnapshotIn<typeof TimeLimit> {
}

export interface TimeLimitSnapshotOut extends SnapshotOut<typeof TimeLimit> {
}
