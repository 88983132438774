import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { Enum_Day_Daycode } from '@app/infrastructureLayer/generated/graphql';

export const ShopWorkTimeItemAroundAClock = t
  .model('ShopWorkTimeItemAroundAClock', {
    id: t.identifier,
    type: t.frozen<'ShopWorkTimeItemAroundAClock'>(),
    day: t.frozen<Enum_Day_Daycode>(),
  });

export interface ShopWorkTimeItemAroundAClockInstance extends Instance<typeof ShopWorkTimeItemAroundAClock> {
}

export interface ShopWorkTimeItemAroundAClockSnapshotIn extends SnapshotIn<typeof ShopWorkTimeItemAroundAClock> {
}

export interface ShopWorkTimeItemAroundAClockSnapshotOut extends SnapshotOut<typeof ShopWorkTimeItemAroundAClock> {
}
