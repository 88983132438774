import { useEffect } from 'react';
import * as Sentry from 'sentry-expo';
import { getSentryDSN } from '@utils/env';
import useStore from '@app/domain/store/useStore';

const useIdentifySentryUser = () => {
  const SENTRY_DSN = getSentryDSN();

  const { profileStore: { profile } } = useStore();

  useEffect(() => {
    if (SENTRY_DSN) {
      Sentry.Browser.configureScope((scope) => {
        if (!profile) {
          scope.setUser(null);
          return;
        }

        scope.setUser({
          id: String(profile.id),
          name: profile.fio,
          phone: profile.phone,
        });
      });
    }
  }, [SENTRY_DSN, profile]);
};

export default useIdentifySentryUser;
