import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { Enum_Day_Daycode } from '@app/infrastructureLayer/generated/graphql';

export const ShopWorkTimeItemTimed = t
  .model('ShopWorkTimeItemTimed', {
    id: t.identifier,
    type: t.frozen<'ShopWorkTimeItemTimed'>(),
    day: t.frozen<Enum_Day_Daycode>(),
    startTime: t.string, // HH:mm:ss
    endTime: t.string, // HH:mm:ss
  });

export interface ShopWorkTimeItemTimedInstance extends Instance<typeof ShopWorkTimeItemTimed> {
}

export interface ShopWorkTimeItemTimedSnapshotIn extends SnapshotIn<typeof ShopWorkTimeItemTimed> {
}

export interface ShopWorkTimeItemTimedSnapshotOut extends SnapshotOut<typeof ShopWorkTimeItemTimed> {
}
