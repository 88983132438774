import React, { FC, useCallback } from 'react';
import {
  View, Text, StyleSheet, Pressable,
} from 'react-native';
import { SheetManager } from 'react-native-actions-sheet';
import { observer } from 'mobx-react-lite';
import getDeliveryDateTitle from '@components/sheets/SelectOrderDateTimeSheet/getDeliveryDateTitle';
import ArrowRightSvg from '@svg/ArrowRightSvg';
import ubuntuFont from '@constants/ubuntuFont';
import GreyBlock from '@components/layout/GreyBlock';
import useStore from '@app/domain/store/useStore';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {},
  row: {
    flexDirection: 'row',
  },
  title: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
  },
  text: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 14,
    flex: 1,
    lineHeight: 20,
  },
  right: {
    gap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const DateSelector: FC = () => {
  const {
    appStore: {
      cartStates,
      selectedShopId,
    },
  } = useStore();

  const openOrderDateTimeSelectorSheet = useCallback(() => {
    SheetManager.show('select-order-date-time-sheet');
  }, []);

  if (!selectedShopId) {
    return null;
  }

  const cartState = cartStates.get(selectedShopId);

  if (!cartState) {
    return null;
  }

  const isEmpty = (cartState.isAsap && typeof cartState.asapTime === 'undefined')
    || (!cartState.isAsap && (!cartState.date || !cartState.time));

  return (
    <Pressable onPress={openOrderDateTimeSelectorSheet}>
      <GreyBlock
        style={s.root}
        focusUserAttention={isEmpty}
      >
        <Text style={s.title}>
          Дата и время заказа
        </Text>
        <View style={s.row}>
          <Text
            style={s.text}
          >
            {isEmpty && 'Укажите дату и время'}
            {!isEmpty && (
              <>
                {cartState.isAsap && (
                  // <Text>{`Через ${cartState.asapTime} минут`}</Text>
                  <>
                    {!cartState.selectedAsapOption && (
                      <Text>???</Text>
                    )}
                    {cartState.selectedAsapOption && (
                      <Text>{`Сегодня ${cartState.selectedAsapOption.title}`}</Text>
                    )}
                  </>
                )}
                {(!cartState.isAsap && !!cartState.date && !!cartState.time) && (
                  <Text>{`${getDeliveryDateTitle(cartState.date)} ${cartState.time}`}</Text>
                )}
              </>
            )}
          </Text>
          <View style={s.right}>
            <ArrowRightSvg
              color={colors.text}
            />
          </View>
        </View>
      </GreyBlock>
    </Pressable>
  );
};

export default observer(DateSelector);
