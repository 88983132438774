import React, { FC, useCallback } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';
import { Enum_Componentuserdeliveryaddress_Type } from '@app/infrastructureLayer/generated/graphql';
import getDeliveryAddressTitle from '@utils/mappers/deliveryAddressTitle';
import DeliveryAddressTypeSvg from '@svg/DeliveryAddressTypeSvg';

const s = StyleSheet.create({
  wrapper: {
    borderRadius: 10,
    backgroundColor: colors.white,
    width: 120,
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 15,
    columnGap: 15,
    borderWidth: 1,
    borderColor: colors.text,
  },
  selectedWrapper: {
    backgroundColor: colors.primary,
    borderColor: colors.primary,
  },
  text: {
    color: colors.text,
    fontSize: 14,
    fontFamily: ubuntuFont.light,
  },
  selectedText: {
    color: colors.white,
  },
});

interface IProps {
  type: Enum_Componentuserdeliveryaddress_Type,
  selected: boolean,
  color?: string,
  onSelect: (type: Enum_Componentuserdeliveryaddress_Type) => void,
}

const AddressCategory: FC<IProps> = (
  {
    type,
    selected,
    onSelect,
    color = colors.text,
  },
) => {
  const onPress = useCallback(
    () => onSelect(type),
    [onSelect, type],
  );

  const title = getDeliveryAddressTitle(type);

  return (
    <TouchableOpacity
      onPress={onPress}
      style={StyleSheet.flatten([s.wrapper, selected && s.selectedWrapper])}
    >
      <DeliveryAddressTypeSvg type={type} color={color} />
      <Text style={StyleSheet.flatten([s.text, selected && s.selectedText])}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export default AddressCategory;
