import { SheetManager } from 'react-native-actions-sheet';
import React, { PropsWithChildren, FC, useCallback } from 'react';
import { Pressable } from 'react-native';
import { PressableProps } from 'react-native/Libraries/Components/Pressable/Pressable';
import { GroupModifierInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/GroupModifier';
import { observer } from 'mobx-react-lite';
import { useModifiersState } from '@screens/MenuItemScreen/model/ModifiersStateModelContext';

type IProps = Omit<PressableProps, 'onPress'> & {
  gm: GroupModifierInstance,
};

const OpenModifiersSheetButton: FC<PropsWithChildren<IProps>> = (
  {
    children,
    gm,
    ...rest
  },
) => {
  const modifiersStateModel = useModifiersState();

  const onPress = useCallback(() => {
    SheetManager.show('menu-item-group-modifier-sheet', {
      payload: {
        gm,
        modifiersStateModel,
      },
    });
  }, [gm, modifiersStateModel]);

  return (
    <Pressable
      onPress={onPress}
      {...rest}
    >
      {children}
    </Pressable>
  );
};

export default observer(OpenModifiersSheetButton);
