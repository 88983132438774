import React, { FC, useCallback } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { useAccountRemoveRequestMutation } from '@app/infrastructureLayer/generated/graphql';
import { RootStackScreenProps } from '@navigation/types';
import { observer } from 'mobx-react-lite';
import ButtonOutline from '@components/common/ButtonOutline';
import BinaryAlert from '@utils/BinaryAlert';
import ubuntuFont from '@constants/ubuntuFont';
import crossAlert from '@utils/crossAlert';
import Screen from '@components/layout/Screen';
import colors from '@constants/colors';
import Back from '@components/layout/Back';

const s = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: 24,
    color: colors.primary,
    fontFamily: ubuntuFont.medium,
    marginBottom: 15,
    textAlign: 'center',
  },
  description: {
    fontSize: 16,
    color: colors.text,
    fontFamily: ubuntuFont.light,
    marginBottom: 50,
    textAlign: 'center',
  },
  button: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    left: 20,
  },
});

const AccountRemoveRequest: FC<RootStackScreenProps<'AccountRemoveRequest'>> = () => {
  const [removeAccount] = useAccountRemoveRequestMutation({
    onCompleted: () => console.info('Запрос на удаление аккаунта отправлен'),
  });

  const executeAccountRemoving = useCallback(async () => {
    const { data } = await removeAccount();
    if (!data) {
      return;
    }

    if (data?.accountRemoveRequest.resultCode === 'FAIL') {
      crossAlert(data?.accountRemoveRequest.message);
      return;
    }

    if (data?.accountRemoveRequest.resultCode === 'ALREADY_ADDED') {
      crossAlert(data?.accountRemoveRequest.message);
      return;
    }

    crossAlert(data?.accountRemoveRequest.message);
  }, [removeAccount]);

  const onRemove = useCallback(() => {
    BinaryAlert({
      title: 'Удаление аккаунта',
      desc: 'Вы уверены что хотите удалить аккаунт?',
      onPositivePress: executeAccountRemoving,
      onNegativePress: () => {},
    });
  }, [executeAccountRemoving]);

  return (
    <Screen>
      <View style={s.root}>
        <Back title="Назад" />
        <View style={s.container}>
          <Text style={s.header}>
            Запрос на удаление аккаунта
          </Text>
          <Text style={s.description}>
            При удалении аккаунта будет удалена вся персонализированная информация связанная с вашим аккаунтом.
          </Text>

          <View style={s.button}>
            <ButtonOutline title="УДАЛИТЬ" onPress={onRemove} />
          </View>
        </View>
      </View>
    </Screen>
  );
};

export default observer(AccountRemoveRequest);
