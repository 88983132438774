import { Enum_Componentuserdeliveryaddress_Type } from '@app/infrastructureLayer/generated/graphql';
import { absurd } from 'fp-ts/function';

const getDeliveryAddressTitle = (type?: Enum_Componentuserdeliveryaddress_Type) => {
  switch (type) {
    case undefined:
      return '';
    case Enum_Componentuserdeliveryaddress_Type.Home:
      return 'Дом';
    case Enum_Componentuserdeliveryaddress_Type.Work:
      return 'Работа';
    case Enum_Componentuserdeliveryaddress_Type.Other:
      return 'Другое';
    default:
      absurd(type);
      return 'Другое';
  }
};

export default getDeliveryAddressTitle;
