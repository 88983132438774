import { z } from 'zod';
import { Enum_Userspermissionsuser_Sex } from '@app/infrastructureLayer/generated/graphql';
import moment from 'moment/moment';

const isDateValid = (date: string): boolean => (
  moment(date, 'DD.MM.YYYY')
    .format('DD.MM.YYYY') === date
);

const registrationSchema = z.object({
  fio: z.string()
    .trim()
    .nonempty('Обязательное поле'),
  birthday: z.string()
    .trim()
    .nonempty('Обязательное поле')
    .refine(isDateValid, { message: 'Не правильная дата' }),
  sex: z.nativeEnum(Enum_Userspermissionsuser_Sex)
    .optional(),
});

const getRegistrationSchema = (
  {
    useBroadcastOfferAccept,
    broadcastOfferAcceptedRequired,
  }: {
    useBroadcastOfferAccept: boolean,
    broadcastOfferAcceptedRequired: boolean,
  },
) => {
  if (!useBroadcastOfferAccept) {
    return registrationSchema;
  }

  if (broadcastOfferAcceptedRequired) {
    return registrationSchema.merge(z.object({
      broadcastOfferAccepted: z.boolean({ required_error: 'Обязательное поле' })
        .refine((value) => value, { message: 'Необходимо дать согласие' }),
    }));
  }

  return registrationSchema.merge(z.object({
    broadcastOfferAccepted: z.boolean()
      .optional(),
  }));
};

export default getRegistrationSchema;
