import React, { FC } from 'react';
import Svg, { Path } from 'react-native-svg';

type IProps = {
  color?: string
};

const ArrowRightSvg: FC<IProps> = ({ color = 'black' }) => (
  <Svg
    width={7}
    height={12}
    viewBox="0 0 7 12"
    fill="none"
  >
    <Path
      d="M1 11L5.92929 6.07071C5.96834 6.03166 5.96834 5.96834 5.92929 5.92929L1 1"
      stroke={color}
      strokeWidth={1.2}
      strokeLinecap="round"
    />
  </Svg>
);
export default ArrowRightSvg;
