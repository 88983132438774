import React from 'react';
import type { CellRendererProps } from '@react-native/virtualized-lists/Lists/VirtualizedList';
import { MenuItemInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuItem';
import { View } from 'react-native';
import { observer } from 'mobx-react-lite';
import useStore from '@app/domain/store/useStore';

const CellRendererComponent: React.ComponentType<CellRendererProps<MenuItemInstance>> = (
  {
    onLayout,
    ...rest
  },
) => {
  const {
    UIStore: {
      menuScreenUIStore: {
        onLayoutCategory,
      },
    },
  } = useStore();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const categoryId: string | undefined = rest.item?.categoryId;

  let customOnLayout = onLayout;

  if (categoryId && rest.cellKey.includes(':header')) {
    // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
    customOnLayout = (event) => {
      if (onLayout) {
        onLayout(event);
      }
      onLayoutCategory(categoryId)(event);
    };
  }
  return (
    <View {...rest} onLayout={customOnLayout} />
  );
};

export default observer(CellRendererComponent);
