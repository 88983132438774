import { getCustomConfig } from '@utils/env';

const images = {
  companyLogoUrl: getCustomConfig().COMPANY_LOGO,
  companyLogoSource: {
    uri: getCustomConfig().COMPANY_LOGO,
  },
};

export default images;
