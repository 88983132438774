import moment from 'moment-timezone';

export default function getDeliveryDateTitle(date: string) {
  const currentDate = moment().startOf('day');
  const givenDate = moment(date, 'DD.MM.YYYY').startOf('day');
  const diffDays = givenDate.diff(currentDate, 'day');

  if (diffDays === 0) {
    return 'Сегодня';
  }

  if (diffDays === 1) {
    return 'Завтра';
  }

  return givenDate.format('dd, D MMMM');
}
