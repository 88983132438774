import React, {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  ScrollView,
  StyleSheet, useWindowDimensions,
  View,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
  useScrollHandlers,
} from 'react-native-actions-sheet';
import Button from '@components/common/Button';
import useStore from '@app/domain/store/useStore';
import { observer } from 'mobx-react-lite';
import SheetSelectItem from '@components/common/SheetSelectItem';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 20,
  },
  scrollview: {
    width: '100%',
    paddingBottom: 40,
  },
});

// const snapPoints = [50, 100];
const snapPoints = [100];

const SelectCitySheet: FC<SheetProps> = (
  {
    sheetId,
  },
) => {
  const { height } = useWindowDimensions();
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const scrollHandlers = useScrollHandlers<ScrollView>(sheetId, actionSheetRef);

  const {
    appStore: {
      selectedCityId,
      citiesWithShops,
      setSelectedCityId,
      setSelectedShopId,
      shop,
      getFirstShopForCity,
    },
  } = useStore();

  const [cityId, setCityId] = useState(selectedCityId);

  useEffect(() => {
    setCityId(selectedCityId);
  }, [selectedCityId]);

  const onConfirm = useCallback(() => {
    if (cityId) {
      setSelectedCityId(cityId);
      if (shop?.city?.id !== cityId) {
        const firstShop = getFirstShopForCity(cityId);
        if (!firstShop) {
          setSelectedShopId(null);
        } else {
          setSelectedShopId(firstShop.id);
        }
      }
    }
    actionSheetRef.current?.hide();
  }, [cityId, getFirstShopForCity, setSelectedCityId, setSelectedShopId, shop?.city?.id]);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      <View style={s.root}>
        <ScrollView
          {...scrollHandlers}
          style={StyleSheet.flatten([s.scrollview, { maxHeight: height * 0.5 }])}
          alwaysBounceVertical={false}
        >
          {citiesWithShops.map((city, index) => (
            <SheetSelectItem
              key={city.id}
              id={city.id}
              title={city.title}
              onSelect={setCityId}
              isSelected={city.id === cityId}
              isLast={index + 1 === citiesWithShops.length}
            />
          ))}
        </ScrollView>
        <Button
          onPress={onConfirm}
          title="Подтвердить"
        />
      </View>
    </ActionSheet>
  );
};

export default observer(SelectCitySheet);
