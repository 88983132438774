import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
  destroy,
} from 'mobx-state-tree';

export const ModifiersStateItemModel = t
  .model('ModifiersStateItemModel', {
    modifierId: t.identifier,
    amount: t.refinement(t.number, (value) => value >= 1),
  })
  .actions((self) => ({
    increaseAmount: () => {
      self.amount += 1;
    },
    reduceAmount: () => {
      if (self.amount === 1) {
        destroy(self);

        return;
      }

      self.amount -= 1;
    },
  }));

export interface ModifiersStateItemModelInstance extends Instance<typeof ModifiersStateItemModel> {
}

export interface ModifiersStateItemModelSnapshotIn extends SnapshotIn<typeof ModifiersStateItemModel> {
}

export interface ModifiersStateItemModelSnapshotOut extends SnapshotOut<typeof ModifiersStateItemModel> {
}
