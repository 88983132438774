import * as React from 'react';
import Svg, { Path, Circle } from 'react-native-svg';

const RoundPlusSvg = () => (
  <Svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <Circle cx={10} cy={10} r={10} fill="#D9D9D9" />
    <Path
      d="M10 6.5V13.5"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M6.5 10H13.5"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </Svg>
);

export default RoundPlusSvg;
