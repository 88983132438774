import { Enum_Componentorderorderparam_Type } from '@app/infrastructureLayer/generated/graphql';
import { absurd } from 'fp-ts/function';

const getOrderParamName = (type: Enum_Componentorderorderparam_Type) => {
  switch (type) {
    case Enum_Componentorderorderparam_Type.RoomNumber:
      return 'Номер';
    default:
      absurd(type);
      return '';
  }
};

export default getOrderParamName;
