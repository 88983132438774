import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { TimeLimit } from '@app/domain/store/CoreStore/AppStore/entities/Menu/TimeLimit';

export const Category = t
  .model('Category', {
    id: t.identifier,
    title: t.string,
    timeLimit: t.maybeNull(TimeLimit),
  });

export interface CategoryInstance extends Instance<typeof Category> {
}

export interface CategorySnapshotIn extends SnapshotIn<typeof Category> {
}

export interface CategorySnapshotOut extends SnapshotOut<typeof Category> {
}
