import {
  Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

export const DeviceVarsStore = t
  .model('DeviceVarsStore', {
    id: t.optional(t.identifier, 'DeviceVarsStore'),
    hydrated: t.optional(t.boolean, false),
    lastShopId: t.maybe(t.string),
    roomNumber: t.maybe(t.string),
    deviceId: t.optional(t.string, uuidv4()),
  })
  .actions((self) => ({
    setHydrated: (value: boolean) => {
      self.hydrated = value;
    },
    setLastShopId: (value: string | undefined) => {
      self.lastShopId = value;
    },
    setRoomNumber: (value: string | undefined) => {
      self.roomNumber = value;
    },
  }));

export interface DeviceVarsStoreInstance extends Instance<typeof DeviceVarsStore> {
}

export interface DeviceVarsStoreModelSnapshotIn extends SnapshotIn<typeof DeviceVarsStore> {
}

export interface DeviceVarsStoreModelSnapshotOut extends SnapshotOut<typeof DeviceVarsStore> {
}
