import { StyleSheet, Dimensions } from 'react-native';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';

export default StyleSheet.create({
  autolink: {
    color: colors.primary,
  },
  blockQuoteText: {
    color: colors.text,
  },
  blockQuoteSection: {
    flexDirection: 'row',
  },
  blockQuoteSectionBar: {
    width: 3,
    height: null,
    backgroundColor: colors.lightGrey,
    marginRight: 15,
  },
  bgImage: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  bgImageView: {
    flex: 1,
    overflow: 'hidden',
  },
  view: {
    alignSelf: 'stretch',
  },
  codeBlock: {
    fontFamily: ubuntuFont.regular,
    backgroundColor: colors.lightGrey,
  },
  del: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },
  em: {
    fontStyle: 'italic',
  },
  heading: {
    fontWeight: '200',
  },
  heading1: {
    fontSize: 32,
  },
  heading2: {
    fontSize: 24,
  },
  heading3: {
    fontSize: 18,
  },
  heading4: {
    fontSize: 16,
  },
  heading5: {
    fontSize: 13,
  },
  heading6: {
    fontSize: 11,
  },
  hr: {
    backgroundColor: colors.text,
    height: 1,
  },
  image: {
    height: 200,
    width: Dimensions.get('screen').width - 30,
    alignSelf: 'center',
    resizeMode: 'contain',
  },
  imageBox: {
    flex: 1,
    resizeMode: 'cover',
  },
  inlineCode: {
    backgroundColor: colors.lightGrey,
    borderColor: colors.text,
    borderRadius: 3,
    borderWidth: 1,
    fontFamily: ubuntuFont.regular,
    fontWeight: 'bold',
  },
  list: {
  },
  sublist: {
    paddingLeft: 20,
    width: Dimensions.get('screen').width - 60,
  },
  listItem: {
    flexDirection: 'row',
  },
  listItemText: {
    flex: 1,
  },
  listItemBullet: {
    fontSize: 20,
    lineHeight: 20,
  },
  listItemNumber: {
    fontWeight: 'bold',
  },
  listRow: {
    flexDirection: 'row',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  paragraphCenter: {
    marginTop: 10,
    marginBottom: 10,
    flexWrap: 'wrap',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  paragraphWithImage: {
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  noMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
  strong: {
    fontWeight: 'bold',
  },
  table: {
    borderWidth: 1,
    borderColor: colors.text,
    borderRadius: 3,
  },
  tableHeader: {
    backgroundColor: colors.text,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  tableHeaderCell: {
    color: colors.text,
    fontWeight: 'bold',
    padding: 5,
  },
  tableRow: {
    borderColor: colors.text,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  tableRowLast: {
    borderColor: colors.transparent,
  },
  tableRowCell: {
    padding: 5,
  },
  text: {
    color: colors.text,
    fontSize: 14,
    lineHeight: 22,
    fontFamily: ubuntuFont.regular,
  },
  textRow: {
    flexDirection: 'row',
  },
  u: {
    borderColor: colors.text,
    borderBottomWidth: 1,
  },
});
