import React, { FC } from 'react';
import {
  StyleSheet,
  View,
  Text,
} from 'react-native';
import Picker from 'react-mobile-picker';
import ubuntuFont from '@constants/ubuntuFont';

import colors from './colors';
import {
  WheelTimePickerProps,
} from './types';
import useTimePickerLogic from './includes/useTimePickeLogic';

const s = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.backgroundColor,
    borderRadius: 12,
  },
  item: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  textSelected: {
    color: colors.selectedText,
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
  },
  text: {
    color: colors.text,
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
  },
  dotsRoot: {
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 24,
  },
  dots: {
    color: colors.selectedText,
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
  },
});

/**
 * Only for Web
 * https://reactnative.dev/docs/0.71/platform-specific-code#native-specific-extensions-ie-sharing-code-with-nodejs-and-web
 */
const WheelTimePicker: FC<WheelTimePickerProps> = (props) => {
  const [pickerValue, setPickerValue] = useTimePickerLogic(props);

  const { timeSlots } = props;

  const minutesForThisHour = timeSlots.get(pickerValue.hour);
  const hoursOptions = [...timeSlots.keys()];

  return (
    <View style={s.container}>
      <Picker
        value={pickerValue}
        onChange={setPickerValue}
        wheelMode="normal"
        height={140}
        itemHeight={40}
      >
        <Picker.Column name="hour">
          {hoursOptions.map((option) => (
            <Picker.Item
              key={option}
              value={option}
              style={s.item}
            >
              {({ selected }) => (
                <Text style={selected ? s.textSelected : s.text}>
                  {option}
                </Text>
              )}
            </Picker.Item>
          ))}
        </Picker.Column>
        <View style={s.dotsRoot}>
          <Text style={s.dots}>
            :
          </Text>
        </View>
        <Picker.Column name="minutes">
          {minutesForThisHour?.map((option) => (
            <Picker.Item
              key={option}
              value={option}
              style={s.item}
            >
              {({ selected }) => (
                <Text style={selected ? s.textSelected : s.text}>
                  {option}
                </Text>
              )}
            </Picker.Item>
          ))}
        </Picker.Column>
      </Picker>
    </View>
  );
};

export default WheelTimePicker;
