import {
  StyleSheet,
  Text,
  View,
  Pressable,
} from 'react-native';
import React, { FC, useCallback } from 'react';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';
import ArrowRightSvg from '@svg/ArrowRightSvg';
import generalConfig from '@constants/generalConfig';
import getOrderStatusName from '@utils/mappers/getOrderStatusName';

const s = StyleSheet.create({
  action: {
    backgroundColor: colors.lightGrey,
    borderRadius: 10,
    padding: 11,
    paddingRight: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftSide: {
    rowGap: 5,
  },
  rightSide: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 15,
  },
  price: {
    color: colors.primary,
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
  },
  title: {
    fontSize: 14,
    fontFamily: ubuntuFont.light,
    color: colors.text,
  },
  date: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
  },
});

type IProps = {
  orderId: string,
  onPress: (orderId: string) => void,
  header: string,
  shopTitle: string,
  date: string,
  status: string,
  total: number,
};

const HistoryRow: FC<IProps> = (
  {
    orderId,
    onPress: _onPress,
    header,
    date,
    total,
    status,
    shopTitle,
  },
) => {
  const onPress = useCallback(() => {
    _onPress(orderId);
  }, [_onPress, orderId]);

  return (
    <Pressable style={s.action} onPress={onPress}>
      <View style={s.leftSide}>
        <Text style={s.title}>{`№${orderId} ${getOrderStatusName(status)}`}</Text>
        <Text style={s.title}>{header}</Text>
        <Text style={s.title}>{shopTitle}</Text>
        <Text style={s.date}>{date}</Text>
      </View>
      <View style={s.rightSide}>
        <Text style={s.price}>{`${total} ${generalConfig.currencyLabel}`}</Text>
        <ArrowRightSvg />
      </View>
    </Pressable>
  );
};

export default HistoryRow;
