const ubuntuFont = {
  light: 'Ubuntu_300Light',
  regular: 'Ubuntu_400Regular',
  medium: 'Ubuntu_500Medium',
  // mediumItalic: 'Ubuntu-MediumItalic',
  bold: 'Ubuntu_700Bold',
  // boldItalic: 'Ubuntu-BoldItalic',
  // lightItalic: 'Ubuntu-LightItalic',
  // italic: 'Ubuntu-Italic',
} as const;

export default ubuntuFont;
