import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { MenuItem, MenuItemSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuItem';
import {
  ModifierMenuItem,
  ModifierMenuItemSnapshotIn,
} from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/ModifierMenuItem';

export const MenuForShop = t
  .model('MenuForShop', {
    shopId: t.identifier,
    selectedCategoryId: t.maybeNull(t.string),
    ready: t.optional(t.boolean, false),
    menuItems: t.optional(t.map(MenuItem), {}),
    modifierMenuItems: t.optional(t.map(ModifierMenuItem), {}),
    categoriesIds: t.optional(t.array(t.string), []),
  })
  .actions((self) => ({
    setSelectedCategoryId: (categoryId: string | null) => {
      self.selectedCategoryId = categoryId;
    },
    setReady: (value: boolean) => {
      self.ready = value;
    },
    setMenu: (menuItems: MenuItemSnapshotIn[], modifierMenuItems: ModifierMenuItemSnapshotIn[]) => {
      self.menuItems.clear();
      self.modifierMenuItems.clear();
      menuItems.forEach((menuItem) => {
        self.menuItems.set(menuItem.id, menuItem);
      });
      modifierMenuItems.forEach((modifierMenuItem) => {
        self.modifierMenuItems.set(modifierMenuItem.id, modifierMenuItem);
      });
    },
  }));

export interface MenuForShopInstance extends Instance<typeof MenuForShop> {
}

export interface MenuForShopSnapshotIn extends SnapshotIn<typeof MenuForShop> {
}

export interface MenuForShopSnapshotOut extends SnapshotOut<typeof MenuForShop> {
}
