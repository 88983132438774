import useStore from '@app/domain/store/useStore';
import { useCallback, useEffect } from 'react';
// import compact from 'lodash/compact';
// import uniqBy from 'lodash/uniqBy';
// import { MenuItemSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuItem';
// import { ProductSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Product';
// import { ModifierSnapshotIn } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/Modifier';
// import {
//   ModifierMenuItemSnapshotIn,
// } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/ModifierMenuItem';
// import { EnumProductParamCalculationType } from '@app/domain/store/CoreStore/AppStore/entities/Menu/BaseItemWithParams';
import { useInstance } from 'react-ioc';
import { Api } from '@app/infrastructureLayer/generated/Api';

import useSWR from 'swr';
// import { getImageProxy } from '@utils/env';

// const imageProxy = getImageProxy();

const useInitApp = () => {
  const {
    appStore: {
      setCities,
      setOrderTypes,
      setShops,
      setCategories,
      selectedShopId,
      setProducts,
      setMenuItemsForShop,
      setPaymentTypes,
      setGlobalSettings,
      setModifierCategories,
      setModifiers,
    },
  } = useStore();

  const apiClient = useInstance(Api);

  const commonDataFetcher = useCallback(async () => {
    const res = await Promise.all([
      apiClient.instance.get<any>('/initial'),
    ]);

    return {
      shopsData: res[0].data.shops,
      categoriesData: res[0].data.categories,
      citiesData: res[0].data.cities,
      modifierCategoriesData: res[0].data.modifierCategories,
      globalSettingData: res[0].data.globalSettings,
      orderTypesData: res[0].data.orderTypes,
      paymentTypesData: res[0].data.paymentTypes,
    };
  }, [apiClient]);

  const {
    data,
    error,
  } = useSWR(
    'commonData',
    commonDataFetcher,
    {
      refreshInterval: 30000,
      errorRetryInterval: 500,
      errorRetryCount: Infinity,
      shouldRetryOnError: true,
    },
  );

  const menuDataFetcher = useCallback(async (shopId: string) => {
    const res = await Promise.all([
      /**
       * {
       *   filters: {
       *     shop: {
       *       id: {
       *         $eq: 'SHOP_ID',
       *       }
       *     },
       *   },
       *   populate: {
       *     shop: {
       *       fields: ['id']
       *     },
       *     product: {
       *       fields: [
       *         'id',
       *         'type',
       *         'paramCalculationType',
       *         'title',
       *         'description',
       *         'imageUrl'
       *       ],
       *       populate: {
       *         params: true,
       *         category: {
       *           fields: ['id']
       *         },
       *       },
       *     },
       *     groupModifiers: {
       *       fields: [
       *         'id',
       *         'required',
       *         'freeOfChargeAmount',
       *         'minAmount',
       *         'maxAmount',
       *         'defaultAmount'
       *       ],
       *       populate: {
       *         modifier_category: {
       *           fields: ['id'],
       *         },
       *         childModifiers: {
       *           fields: [
       *             'id',
       *             'required',
       *             'freeOfChargeAmount',
       *             'minAmount',
       *             'maxAmount',
       *             'defaultAmount',
       *           ],
       *           populate: {
       *             modifier: {
       *               fields: [
       *                 'id'
       *               ]
       *             }
       *           }
       *         }
       *       },
       *     }
       *
       *   },
       *   fields: [
       *     'id',
       *     'price'
       *   ],
       *   pagination: {
       *     limit: 1000000,
       *   }
       * }
       */
      apiClient.instance.get<{
        menuItems: any[],
        products: any[],
        modifiersMenuItems: any[],
        modifiers: any[],
        categoriesIds: any[],
      }>(`/shop-menu/${shopId}`),
      // apiClient.instance.get<MenuItemListResponse>(`/menu-items?filters[shop][id][$eq]=${shopId}&populate[shop][fields][0]=id&populate[product][fields][0]=id&populate[product][fields][1]=type&populate[product][fields][2]=paramCalculationType&populate[product][fields][3]=title&populate[product][fields][4]=description&populate[product][fields][5]=imageUrl&populate[product][populate][params]=true&populate[product][populate][category][fields][0]=id&populate[groupModifiers][fields][0]=id&populate[groupModifiers][fields][1]=required&populate[groupModifiers][fields][2]=freeOfChargeAmount&populate[groupModifiers][fields][3]=minAmount&populate[groupModifiers][fields][4]=maxAmount&populate[groupModifiers][fields][5]=defaultAmount&populate[groupModifiers][populate][modifier_category][fields][0]=id&populate[groupModifiers][populate][childModifiers][fields][0]=id&populate[groupModifiers][populate][childModifiers][fields][1]=required&populate[groupModifiers][populate][childModifiers][fields][2]=freeOfChargeAmount&populate[groupModifiers][populate][childModifiers][fields][3]=minAmount&populate[groupModifiers][populate][childModifiers][fields][4]=maxAmount&populate[groupModifiers][populate][childModifiers][fields][5]=defaultAmount&populate[groupModifiers][populate][childModifiers][populate][modifier][fields][0]=id&fields[0]=id&fields[1]=price&pagination[limit]=1000000&populate[product][fields][6]=imageUrl_100&populate[product][fields][7]=imageUrl_200&populate[product][fields][8]=imageUrl_500`),
      /**
       * {
       *   filters: {
       *     shop: {
       *       id: {
       *         $eq: 'SHOP_ID',
       *       }
       *     },
       *   },
       *   populate: {
       *     shop: {
       *       fields: ['id']
       *     },
       *     modifier: {
       *       fields: [
       *         'id',
       *         'paramCalculationType',
       *         'title',
       *         'description',
       *         'imageUrl'
       *       ],
       *       populate: {
       *         params: true,
       *       },
       *     },
       *   },
       *   fields: [
       *     'id',
       *     'price'
       *   ],
       *   pagination: {
       *     limit: 1000000,
       *   }
       * }
       */
      // apiClient.instance.get<MenuModifierItemListResponse>(`/menu-modifier-items?filters[shop][id][$eq]=${shopId}&populate[shop][fields][0]=id&populate[modifier][fields][0]=id&populate[modifier][fields][1]=paramCalculationType&populate[modifier][fields][2]=title&populate[modifier][fields][3]=description&populate[modifier][fields][4]=imageUrl&populate[modifier][populate][params]=true&fields[0]=id&fields[1]=price&pagination[limit]=1000000`),
    ]);

    return {
      menuItemsData: {
        menuItems: res[0].data.menuItems,
        products: res[0].data.products,
      },
      menuModifierItemsData: {
        modifiersMenuItems: res[0].data.modifiersMenuItems,
        modifiers: res[0].data.modifiers,
      },
      categoriesIds: res[0].data.categoriesIds,
    };
  }, [apiClient]);

  const {
    data: menuData,
    error: menuError,
  } = useSWR(
    selectedShopId ? ['menuDataFetcher', selectedShopId] : null,
    ([, shopId]) => menuDataFetcher(shopId),
    {
      refreshInterval: 30000,
      errorRetryInterval: 500,
      errorRetryCount: Infinity,
      shouldRetryOnError: true,
    },
  );

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (menuError) {
      console.error(menuError);
    }
  }, [menuError]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const {
      paymentTypesData,
      shopsData,
      categoriesData,
      modifierCategoriesData,
      citiesData,
      globalSettingData,
      orderTypesData,
    } = data;

    if (
      !globalSettingData
      || !orderTypesData
      || !paymentTypesData
      || !citiesData
      || !shopsData
      || !categoriesData
      || !modifierCategoriesData
    ) {
      console.error('Чего-то не хватает для инициализации приложения');
      return;
    }

    // const orderTypes = compact(orderTypesData.map((item): OrderTypeSnapshotIn | null => {
    //   if (
    //     !item.attributes?.orderTypeCode
    //     || !item.attributes?.title
    //     || !item.id
    //   ) {
    //     return null;
    //   }
    //
    //   return ({
    //     id: String(item.id),
    //     title: item.attributes.title,
    //     type: item.attributes.orderTypeCode,
    //   });
    // }));
    //
    // const paymentTypes = compact(paymentTypesData.map((item): PaymentTypeSnapshotIn | null => {
    //   if (
    //     !item.attributes?.paymentTypeCode
    //     || !item.attributes?.title
    //     || !item.id
    //   ) {
    //     return null;
    //   }
    //
    //   return ({
    //     id: String(item.id),
    //     title: item.attributes.title,
    //     type: item.attributes.paymentTypeCode,
    //   });
    // }));
    //
    // const cities = compact(citiesData.map((city): CitySnapshotIn | null => {
    //   if (!city.id || !city.attributes?.title) {
    //     return null;
    //   }
    //
    //   return ({
    //     id: String(city.id),
    //     title: city.attributes.title,
    //   });
    // }));

    // const shops = compact(shopsData.map((shop): ShopSnapshotIn | null => {
    //   const cityId = shop?.attributes?.city?.data?.id;
    //   if (
    //     !shop.id
    //     || !shop.attributes?.title
    //     || !shop.attributes?.address
    //     || !cityId
    //   ) {
    //     return null;
    //   }
    //
    //   let coordinates = null;
    //
    //   if (shop.attributes.coordinates?.latitude && shop.attributes.coordinates?.longitude) {
    //     coordinates = {
    //       latitude: shop.attributes.coordinates.latitude,
    //       longitude: shop.attributes.coordinates.longitude,
    //     };
    //   }
    //
    //   const wtItems: ShopWorkTimeSnapshotIn['items'] = compact(shop.attributes.workTime.map((wt) => {
    //     switch (wt.__component) {
    //       case 'common.shop-work-day-off':
    //         return {
    //           id: String(wt.id),
    //           type: 'ShopWorkTimeItemDayOff',
    //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //           // @ts-ignore
    //           day: wt.day.data.attributes.dayCode as Enum_Day_Daycode,
    //         };
    //       case 'common.shop-work-day-around-the-clock':
    //         return {
    //           id: String(wt.id),
    //           type: 'ShopWorkTimeItemAroundAClock',
    //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //           // @ts-ignore
    //           day: wt.day.data.attributes.dayCode as Enum_Day_Daycode,
    //         };
    //       case 'common.shop-work-day-timed':
    //         return {
    //           id: String(wt.id),
    //           type: 'ShopWorkTimeItemTimed',
    //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //           // @ts-ignore
    //           day: wt.day.data.attributes.dayCode as Enum_Day_Daycode,
    //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //           // @ts-ignore
    //           startTime: wt.time.start as string, // HH:mm:ss
    //           // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //           // @ts-ignore
    //           endTime: wt.time.end as string, // HH:mm:ss
    //         };
    //       default:
    //         console.error('Неизвестный тип настройка рабочего дня магазина');
    //         return null;
    //     }
    //   }));
    //
    //   const shopWorkTime: ShopWorkTimeSnapshotIn = {
    //     shopId: String(shop.id),
    //     timezone: shop.attributes.timezone || 'Europe/Moscow',
    //     minOrderDelayInMinutes: shop.attributes.minOrderDelayInMinutes || 0,
    //     firstOrderDelayInMinutes: shop.attributes.firstOrderDelayInMinutes || 0,
    //     asapStepInMinutes: shop.attributes.asapStepInMinutes || 5,
    //     items: wtItems,
    //   };
    //
    //   return ({
    //     id: String(shop.id),
    //     title: shop.attributes.title,
    //     address: shop.attributes.address,
    //     city: cityId,
    //     coordinates,
    //     workTimeTitle: shop.attributes.workTimeTitle,
    //     useBonuses: shop.attributes.useBonuses,
    //     useCutlery: shop.attributes.useCutlery,
    //     usePromoCodes: shop.attributes.usePromoCodes,
    //     allowedPaymentTypes: compact(shop.attributes.allowedPaymentTypes?.map((p) => p?.payment_type?.data?.id)),
    //     allowedOrderTypes: compact(shop.attributes.allowedOrderTypes?.data?.map(({ id }) => id)),
    //     workTime: shopWorkTime,
    //   });
    // }));

    // const categories = compact(categoriesData.map((category): CategorySnapshotIn | null => {
    //   if (!category.id || !category.attributes?.title) {
    //     return null;
    //   }
    //
    //   return ({
    //     id: String(category.id),
    //     title: category.attributes.title,
    //   });
    // }));

    // const modifierCategories = compact(
    //   modifierCategoriesData
    //     .map((modifierCategory): ModifierCategorySnapshotIn | null => {
    //       if (!modifierCategory.id || !modifierCategory.attributes?.title) {
    //         return null;
    //       }
    //
    //       return ({
    //         id: String(modifierCategory.id),
    //         title: modifierCategory.attributes.title,
    //       });
    //     }),
    // );

    setModifierCategories(modifierCategoriesData);
    setOrderTypes(orderTypesData);
    setGlobalSettings(globalSettingData);
    setPaymentTypes(paymentTypesData);
    setCities(citiesData);
    setCategories(categoriesData);
    setShops(shopsData);
  }, [
    data,
    setGlobalSettings,
    setOrderTypes,
    setPaymentTypes,
    setCities,
    setShops,
    setCategories,
    setModifierCategories,
  ]);

  // const { data: menuData } = useGetMenuByShopIdQuery({
  //   variables: {
  //     shopId: selectedShopId || '',
  //   },
  //   skip: !selectedShopId,
  // });

  // const menuAndProducts: {
  //   menuItems: MenuItemSnapshotIn[],
  //   products: ProductSnapshotIn[]
  // } = useMemo(() => {
  //   if (!menuData || !menuData.menuItemsData) {
  //     return {
  //       menuItems: [],
  //       products: [],
  //     };
  //   }
  //
  //   return {
  //     menuItems: compact(menuData.menuItemsData.map((item): MenuItemSnapshotIn | null => {
  //       if (
  //         !item.attributes?.price
  //         || item.attributes.price < 0
  //         || !item.attributes?.product?.data?.id
  //         || !item.id
  //         || !item.attributes.shop?.data?.id
  //       ) {
  //         return null;
  //       }
  //
  //       if (String(item.attributes.shop.data.id) !== selectedShopId) {
  //         console.error('Товар из другого меню!');
  //         return null;
  //       }
  //
  //       const groupModifiersArray = compact(item.attributes.groupModifiers?.map((gm) => {
  //         if (!gm || !gm.modifier_category?.data?.id) {
  //           return null;
  //         }
  //
  //         return ({
  //           id: String(gm.id),
  //           minAmount: gm.minAmount || 0,
  //           maxAmount: gm.maxAmount || 0,
  //           required: gm.required || false,
  //           defaultAmount: gm.defaultAmount || 0,
  //           freeOfChargeAmount: gm.freeOfChargeAmount || 0,
  //           modifierCategoryId: String(gm.modifier_category.data.id),
  //           childModifiers: compact(gm.childModifiers?.map((cm) => {
  //             if (!cm || !cm.modifier?.data?.id) {
  //               return null;
  //             }
  //
  //             return {
  //               id: String(cm.id),
  //               minAmount: cm.minAmount || 0,
  //               maxAmount: cm.maxAmount || 0,
  //               required: cm.required || false,
  //               defaultAmount: cm.defaultAmount || 0,
  //               freeOfChargeAmount: cm.freeOfChargeAmount || 0,
  //               modifierId: String(cm.modifier.data.id),
  //               modifier: String(cm.modifier.data.id),
  //             };
  //           })),
  //         });
  //       }));
  //
  //       return ({
  //         id: String(item.id),
  //         productId: String(item.attributes.product.data.id),
  //         price: item.attributes.price,
  //         groupModifiers: groupModifiersArray,
  //       });
  //     })),
  //     products: uniqBy(compact(menuData.menuItemsData.map((item): ProductSnapshotIn | null => {
  //       const product = item.attributes?.product?.data;
  //       if (
  //         !product
  //         || !product.id
  //         || !product.attributes?.category?.data?.id
  //         || !product.attributes?.description
  //         || !product.attributes?.imageUrl
  //         || !product.attributes.title
  //         || !product.attributes.type
  //         || !product.id
  //       ) {
  //         return null;
  //       }
  //
  //       const imageUrl_100 = imageProxy ? `${imageProxy}/w100/${product.attributes.imageUrl}` : product.attributes.imageUrl;
  //       const imageUrl_200 = imageProxy ? `${imageProxy}/w200/${product.attributes.imageUrl}` : product.attributes.imageUrl;
  //       const imageUrl_500 = imageProxy ? `${imageProxy}/w500/${product.attributes.imageUrl}` : product.attributes.imageUrl;
  //
  //       return ({
  //         id: String(product.id),
  //         title: product.attributes.title,
  //         categoryId: String(product.attributes.category.data.id),
  //         description: product.attributes.description,
  //         imageUrl: product.attributes.imageUrl,
  //         imageUrl_100: product.attributes.imageUrl_100 ?? imageUrl_100,
  //         imageUrl_200: product.attributes.imageUrl_200 ?? imageUrl_200,
  //         imageUrl_500: product.attributes.imageUrl_500 ?? imageUrl_500,
  //         type: product.attributes.type,
  //         paramCalculationType: product.attributes.paramCalculationType as unknown as EnumProductParamCalculationType,
  //         params: compact(product.attributes.params?.map((param) => {
  //           if (!param.id || !param.type || typeof param.value === 'undefined') {
  //             return null;
  //           }
  //
  //           return {
  //             id: String(param.id),
  //             type: param.type,
  //             value: param.value,
  //           };
  //         })),
  //       });
  //     })), (item) => item.id),
  //   };
  // }, [menuData, selectedShopId]);

  // const menuAndModifiers: {
  //   modifiersMenuItems: ModifierMenuItemSnapshotIn[],
  //   modifiers: ModifierSnapshotIn[]
  // } = useMemo(() => {
  //   if (!menuData || !menuData.menuModifierItemsData) {
  //     return {
  //       modifiersMenuItems: [],
  //       modifiers: [],
  //     };
  //   }
  //
  //   return {
  //     modifiersMenuItems: compact(menuData.menuModifierItemsData.map((item): ModifierMenuItemSnapshotIn | null => {
  //       if (
  //         typeof item.attributes?.price === 'undefined'
  //         || item.attributes.price < 0
  //         || !item.attributes?.modifier?.data?.id
  //         || !item.id
  //         || !item.attributes.shop?.data?.id
  //       ) {
  //         return null;
  //       }
  //
  //       if (String(item.attributes.shop.data.id) !== selectedShopId) {
  //         console.error('Модификатор из другого меню!');
  //         return null;
  //       }
  //
  //       return ({
  //         id: String(item.id),
  //         modifierId: String(item.attributes.modifier.data.id),
  //         price: item.attributes.price,
  //       });
  //     })),
  //     modifiers: uniqBy(compact(menuData.menuModifierItemsData.map((item): ModifierSnapshotIn | null => {
  //       const modifier = item.attributes?.modifier?.data;
  //       if (
  //         !modifier
  //         || !modifier.id
  //         || !modifier.attributes
  //         || !modifier.attributes.title
  //       ) {
  //         return null;
  //       }
  //
  //       return ({
  //         id: String(modifier.id),
  //         title: modifier.attributes.title,
  //         description: modifier.attributes?.description || undefined,
  //         imageUrl: modifier.attributes?.imageUrl || undefined,
  //         paramCalculationType: modifier.attributes.paramCalculationType as unknown as EnumProductParamCalculationType,
  //         params: compact(modifier.attributes.params?.map((param) => {
  //           if (!param.id || !param.type || typeof param.value === 'undefined') {
  //             return null;
  //           }
  //
  //           return {
  //             id: String(param.id),
  //             type: param.type,
  //             value: param.value,
  //           };
  //         })),
  //       });
  //     })), (item) => item.id),
  //   };
  // }, [menuData, selectedShopId]);

  useEffect(() => {
    if (menuData) {
      setModifiers(menuData.menuModifierItemsData.modifiers);
    }
  }, [menuData?.menuModifierItemsData.modifiers, menuData, setModifiers]);

  useEffect(() => {
    if (menuData) {
      setProducts(menuData.menuItemsData.products);
    }
  }, [menuData?.menuItemsData.products, setProducts, menuData]);

  // Шестой useEffect для установки элементов меню для выбранного магазина:
  useEffect(() => {
    if (selectedShopId && !!menuData?.menuItemsData.menuItems) {
      setMenuItemsForShop({
        shopId: selectedShopId,
        menuItems: menuData.menuItemsData.menuItems,
        modifierMenuItems: menuData.menuModifierItemsData.modifiersMenuItems,
        categoriesIds: menuData.categoriesIds,
      });
    }
  }, [
    selectedShopId, menuData?.menuItemsData.menuItems,
    menuData?.menuModifierItemsData.modifiersMenuItems,
    setMenuItemsForShop, menuData?.categoriesIds,
  ]);
};

export default useInitApp;
