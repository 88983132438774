import {
  Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';

export enum StockShowOptionEnum {
  SHOW_NUMBER = 'SHOW_NUMBER',
  SHOW_BOOLEAN = 'SHOW_BOOLEAN',
  HIDE = 'HIDE',
}

export const stockShowOptionValues: readonly [
  StockShowOptionEnum.SHOW_NUMBER,
  StockShowOptionEnum.SHOW_BOOLEAN,
  StockShowOptionEnum.HIDE,
] = [
  StockShowOptionEnum.SHOW_NUMBER,
  StockShowOptionEnum.SHOW_BOOLEAN,
  StockShowOptionEnum.HIDE,
] as const;

export const StockData = t
  .model('StockData', {
    stockable: t.boolean,
    stockBalance: t.maybe(t.number),
    stockShowOption: t.enumeration(stockShowOptionValues),
  });

export interface StockDataInstance extends Instance<typeof StockData> {
}

export interface StockDataSnapshotIn extends SnapshotIn<typeof StockData> {
}

export interface StockDataSnapshotOut extends SnapshotOut<typeof StockData> {
}
