import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import colors from '@constants/colors';

type IProps = {
  color?:string;
  width?:number;
  height?:number;
};

const PlusSvg = ({ color = colors.white, width = 13, height = 13 }: IProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 13 13"
    fill="none"
  >
    <Path
      d="M6.5 1L6.5 12"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path d="M1 6.5H12" stroke={color} strokeWidth={2} strokeLinecap="round" />
  </Svg>
);
export default PlusSvg;
