import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
// import {
//   Enum_Paymenttype_Paymenttypecode,
// } from '@app/infrastructureLayer/generated/graphql';
import type { PaymentType as PaymentTypeTS } from '@app/infrastructureLayer/generated/Api';

export const PaymentType = t
  .model('PaymentType', {
    id: t.identifier,
    title: t.string,
    // type: t.frozen<Enum_Paymenttype_Paymenttypecode>(),
    type: t.frozen<PaymentTypeTS['paymentTypeCode']>(),
  });

export interface PaymentTypeInstance extends Instance<typeof PaymentType> {
}

export interface PaymentTypeSnapshotIn extends SnapshotIn<typeof PaymentType> {
}

export interface PaymentTypeSnapshotOut extends SnapshotOut<typeof PaymentType> {
}
