import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import {
  ProductParam,
  ProductProductParamsType,
} from '@app/domain/store/CoreStore/AppStore/entities/Menu/ProductParam';
import { absurd } from 'fp-ts/function';
import currency from 'currency.js';

export enum EnumProductParamCalculationType {
  None = 'NONE',
  Volume = 'VOLUME',
  Weight = 'WEIGHT',
}

export const BaseItemWithParams = t
  .model('BaseItemWithParams', {
    paramCalculationType: t.frozen<EnumProductParamCalculationType>(),
    params: t.optional(t.array(ProductParam), []),
  })
  .views((self) => ({
    get volumeParam() {
      return self.params.find((param) => String(param.type) === String(EnumProductParamCalculationType.Volume));
    },
    get weightParam() {
      return self.params.find((param) => String(param.type) === String(EnumProductParamCalculationType.Weight));
    },
    get paramTitle() {
      switch (self.paramCalculationType) {
        case EnumProductParamCalculationType.None:
          return 'Параметры';
        case EnumProductParamCalculationType.Volume:
          return 'БЖУ на 100мл.';
        case EnumProductParamCalculationType.Weight:
          return 'БЖУ на 100г.';
        default:
          absurd(self.paramCalculationType);
          return null;
      }
    },
    get fullParamTitle() {
      switch (self.paramCalculationType) {
        case EnumProductParamCalculationType.None:
          return null;
        case EnumProductParamCalculationType.Volume:
        case EnumProductParamCalculationType.Weight:
          return 'БЖУ продукта';
        default:
          absurd(self.paramCalculationType);
          return null;
      }
    },
  }))
  .views((self) => ({
    get paramsFull() {
      const map = new Map<ProductProductParamsType, number>();

      if (self.paramCalculationType === EnumProductParamCalculationType.None) {
        return map;
      }

      let coefficient = 1;

      if (self.paramCalculationType === EnumProductParamCalculationType.Volume) {
        if (!self.volumeParam) {
          return map;
        }

        coefficient = self.volumeParam.value / 100;
      }

      if (self.paramCalculationType === EnumProductParamCalculationType.Weight) {
        if (!self.weightParam) {
          return map;
        }

        coefficient = self.weightParam.value / 100;
      }

      self.params.forEach((param) => {
        map.set(param.type, currency(param.value)
          .multiply(coefficient).value);
      });

      return map;
    },
  }));

export interface BaseItemWithParamsInstance extends Instance<typeof BaseItemWithParams> {
}

export interface BaseItemWithParamsSnapshotIn extends SnapshotIn<typeof BaseItemWithParams> {
}

export interface BaseItemWithParamsSnapshotOut extends SnapshotOut<typeof BaseItemWithParams> {
}
