import React, {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet, useWindowDimensions,
  View,
  Text,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef, SheetManager,
  SheetProps,
  useScrollHandlers,
} from 'react-native-actions-sheet';
import Button from '@components/common/Button';
import useStore from '@app/domain/store/useStore';
import { observer } from 'mobx-react-lite';
import SheetSelectItem from '@components/common/SheetSelectItem';
import ButtonOutline from '@components/common/ButtonOutline';
import { useGetMyCreditCardsQuery } from '@app/infrastructureLayer/generated/graphql';
import compact from 'lodash/compact';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';
import formatAlfaMaskedPan from '@utils/formatAlfaMaskedPan';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 20,
  },
  scrollview: {
    width: '100%',
    paddingBottom: 40,
  },
  bottomRow: {
    gap: 10,
  },
  noCardsText: {
    fontFamily: ubuntuFont.light,
    fontSize: 14,
    color: colors.text,
    marginBottom: 20,
  },
});

const snapPoints = [100];

const SelectCardSheet: FC<SheetProps> = (
  {
    sheetId,
  },
) => {
  const { height } = useWindowDimensions();
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const scrollHandlers = useScrollHandlers<ScrollView>(sheetId, actionSheetRef);

  const {
    data,
    loading,
  } = useGetMyCreditCardsQuery({
    pollInterval: 1000,
  });

  const cards = useMemo(() => compact(data?.myCreditCards?.data.map(
    (card) => (
      {
        id: card.id,
        maskedPan: card.maskedPan,
        expiryDate: card.expiryDate,
      }
    ),
  )), [data]);

  // const isCardsLoaded = !!data;
  const cardsCount = cards.length;

  const {
    appStore: {
      activeCartState,
    },
  } = useStore();

  const [cardId, setCardId] = useState(activeCartState?.cardId);

  useEffect(() => {
    setCardId(activeCartState?.cardId);
  }, [activeCartState?.cardId]);

  const onAddNewCard = useCallback(async () => {
    await SheetManager.hide(sheetId);
    SheetManager.show('add-new-card');
  }, [sheetId]);

  const onConfirm = useCallback(() => {
    if (cardId) {
      activeCartState?.setCardId(cardId);
    }
    actionSheetRef.current?.hide();
  }, [activeCartState, cardId]);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      {loading && (
        <ActivityIndicator color={colors.text} />
      )}
      {!loading && (
        <View style={s.root}>
          <ScrollView
            {...scrollHandlers}
            style={StyleSheet.flatten([s.scrollview, { maxHeight: height * 0.5 }])}
            alwaysBounceVertical={false}
          >
            {cards.map((card, index) => (
              <SheetSelectItem
                key={card.id}
                id={card.id}
                title={formatAlfaMaskedPan(card.maskedPan)}
                // subtitle={card.expiryDate}
                onSelect={setCardId}
                isSelected={card.id === cardId}
                isLast={index + 1 === cards.length}
              />
            ))}
            {cardsCount === 0 && (
              <Text style={s.noCardsText}>
                У вас нет сохранённых карт
              </Text>
            )}
          </ScrollView>
          <View style={s.bottomRow}>
            {cardsCount > 0 && (
              <>
                <Button
                  onPress={onConfirm}
                  title="Подтвердить"
                />
                <ButtonOutline
                  onPress={onAddNewCard}
                  title="Добавить новую карту"
                />
              </>
            )}
            {cardsCount === 0 && (
              <Button
                onPress={onAddNewCard}
                title="Добавить новую карту"
              />
            )}
          </View>
        </View>
      )}
    </ActionSheet>
  );
};

export default observer(SelectCardSheet);
