import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  ActivityIndicator, View, Text,
  StyleSheet, TouchableOpacity, Pressable,
} from 'react-native';
import { IFavoriteItem } from '@screens/Tabs/includes/favorite.types';
import { observer } from 'mobx-react-lite';
import AddPriceCounter from '@screens/Tabs/MenuScreen/includes/AddPriceCounter';
import generalConfig from '@constants/generalConfig';
import HeartEmptySvg from '@svg/HeartEmptySvg';
import HeartFullSvg from '@svg/HeartFullSvg';
import ubuntuFont from '@constants/ubuntuFont';
import useStore from '@app/domain/store/useStore';
import currency from 'currency.js';
import Picture from '@components/common/Picture/Picture';
import compact from 'lodash/compact';
import colors from '@constants/colors';

const s = StyleSheet.create({
  title: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
  },
  root: {
    rowGap: 5,
    marginBottom: 5,
  },
  wrapper: {
    flexDirection: 'row',
  },
  heart: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
  },
  picture: {
    alignSelf: 'flex-start',
  },
  left: {
    marginLeft: 10,
    marginBottom: 5,
  },
  right: {
    alignSelf: 'flex-end',
    marginTop: 10,
  },
  addonsView: {
    width: '90%',
  },
  addons: {
    fontFamily: ubuntuFont.light,
    color: colors.primary,
    fontSize: 12,
    marginLeft: 10,
  },
  noAvailableText: {
    opacity: 0.5,
    textDecorationLine: 'line-through',
  },
  wrapData: {
    flex: 1,
    justifyContent: 'space-between',
  },
  notAvailableView: {
    backgroundColor: colors.error,
    paddingHorizontal: 9,
    paddingTop: 8,
    paddingBottom: 7,
    borderRadius: 10,
  },
  notAvailableText: {
    color: colors.white,
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
  },
  info: {
    fontFamily: ubuntuFont.regular,
    fontSize: 12,
    color: colors.grey,
    marginTop: 2,
  },
});

interface IProps {
  item: IFavoriteItem;
  isFavorite: boolean;
  onHeartPress: () => void;
  onItemPress: (menuItemId: string) => void;
}

const FavoriteItem: FC<IProps> = (
  {
    item,
    isFavorite,
    onHeartPress: _onHeartPress,
    onItemPress: _onItemPress,
  },
) => {
  const {
    appStore: {
      increaseCartItemAmount,
      reduceCartItemAmount,
      products,
      modifiers,
      menuItemByProductId,
      menuModifierItemByModifierId,
    },
  } = useStore();

  let hasUnknownModifiers = false;
  if (item.modifiersData.length > 0) {
    hasUnknownModifiers = item.modifiersData
      .some((modifierItem) => (!modifiers.has(modifierItem.modifierId)));
  }

  const { productId } = item;

  const [isDeletePending, setIsDeletePending] = useState(false);

  const onHeartPress = useCallback(() => {
    setIsDeletePending(true);
    _onHeartPress();
  }, [_onHeartPress]);

  const showFullHeart = Boolean(isFavorite && !isDeletePending);
  const showEmptyHeart = Boolean(!isFavorite && !isDeletePending);

  const normalizeModifiersKeys = useCallback(() => {
    const result: { [key: string]: number } = {};

    item.modifiersData.forEach((mod) => {
      result[mod.modifierId] = Number(mod.modifierAmount);
    });

    return result;
  }, [item.modifiersData]);

  const {
    increase,
    reduce,
  } = useMemo(() => ({
    increase: () => !!productId && increaseCartItemAmount({
      productId,
      modifiers: normalizeModifiersKeys(),
    }),
    reduce: () => !!productId && reduceCartItemAmount({
      productId,
      modifiers: normalizeModifiersKeys(),
    }),
  }), [productId, increaseCartItemAmount, normalizeModifiersKeys, reduceCartItemAmount]);

  const menuItem = menuItemByProductId(productId);
  const product = products.get(productId);

  const onItemPress = useCallback(() => (
    !!menuItem && _onItemPress(menuItem.id)
  ), [menuItem, _onItemPress]);

  const getModifierAmount = useCallback((modifierId: string) => {
    const amount = item.modifiersData.find((mod) => (
      mod.modifierId === modifierId
    ))?.modifierAmount;

    return parseInt(`${amount}`, 10) || 1;
  }, [item.modifiersData]);

  const getProductTitle = useCallback(() => (
    parseInt(item.productAmount, 10) > 1 ? ` x${parseInt(item.productAmount, 10)}` : ''
  ), [item.productAmount]);

  const getModifierTitle = useCallback((modifierId: string) => (
    getModifierAmount(modifierId) > 1 ? ` x${getModifierAmount(modifierId)}` : ''
  ), [getModifierAmount]);

  if (!product) {
    return null;
  }

  if (hasUnknownModifiers) {
    return null;
  }

  let hasNotAvailableModifiers = false;

  const menuModifierItemsForThisFavoriteItem = compact(item.modifiersData.map((modifiersDataItem) => {
    const modifier = modifiers.get(modifiersDataItem.modifierId);

    if (!modifier) {
      hasNotAvailableModifiers = true;

      return null;
    }

    const menuModifierItem = menuModifierItemByModifierId(modifiersDataItem.modifierId);

    if (!menuModifierItem) {
      hasNotAvailableModifiers = true;
    }

    if (menuModifierItem && !menuModifierItem.isAvailable) {
      hasNotAvailableModifiers = true;
    }

    return {
      modifierId: modifiersDataItem.modifierId,
      amount: modifiersDataItem.modifierAmount,
      isAvailable: menuModifierItem?.isAvailable || false,
      price: menuModifierItem?.price ?? undefined,
      cost: menuModifierItem ? currency(menuModifierItem.price)
        .multiply(modifiersDataItem.modifierAmount).value : undefined,
      modifier,
    };
  }));

  const modifiersPriceSum = menuModifierItemsForThisFavoriteItem.reduce((sum, x) => {
    if (!x.cost) {
      return sum;
    }

    return sum.add(x.cost);
  }, currency(0)).value;

  const isAvailable = !!menuItem?.isAvailable && !hasNotAvailableModifiers;
  const isNotAvailable = !isAvailable;

  let priceWithModifiers: undefined | number;
  if (isNotAvailable) {
    priceWithModifiers = undefined;
  } else if (menuItem) {
    priceWithModifiers = currency(menuItem.price + modifiersPriceSum).value;
  }

  const priceTitle = typeof priceWithModifiers !== 'undefined' ? `${priceWithModifiers} ${generalConfig.currencyLabel}` : '';

  return (
    <View style={s.root}>
      <View style={s.wrapper}>
        <TouchableOpacity
          hitSlop={5}
          style={s.heart}
          onPress={onHeartPress}
        >
          {showEmptyHeart && <HeartEmptySvg />}
          {showFullHeart && <HeartFullSvg />}
          {isDeletePending && <ActivityIndicator size={24} color={colors.primary} />}
        </TouchableOpacity>

        <Pressable onPress={onItemPress} hitSlop={5}>
          <View style={s.picture}>
            <Picture
              image={product.imageUrl_100}
              size={80}
            />
          </View>
        </Pressable>

        <View style={s.wrapData}>
          <View style={s.left}>
            <Text
              style={StyleSheet.flatten([
                s.title,
                !menuItem?.isAvailable && s.noAvailableText,
              ])}
            >
              {`${product.title}${getProductTitle()}`}
            </Text>
            {!!menuItem && (
              <Text style={s.info}>
                {`${menuItem.price} ${generalConfig.currencyLabel}`}
              </Text>
            )}
          </View>

          <View style={s.addonsView}>
            {menuModifierItemsForThisFavoriteItem.map((elem) => (
              <Text
                key={elem.modifierId}
                style={StyleSheet.flatten([s.addons, !elem.isAvailable && s.noAvailableText])}
              >
                {`+\u00A0${elem.modifier.title}${elem.price ? ` ${elem.price} ${generalConfig.currencyLabel}` : ''}${getModifierTitle(elem.modifierId)}`}
              </Text>
            ))}
          </View>

          <View style={s.right}>
            {!isNotAvailable && (
              <AddPriceCounter
                count={product.inCartCount}
                price={priceTitle}
                increase={increase}
                reduce={reduce}
              />
            )}
            {isNotAvailable && (
              <View style={s.notAvailableView}>
                <Text style={s.notAvailableText}>Нет в наличии</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default observer(FavoriteItem);
