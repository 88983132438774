import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';

export const Coordinates = t
  .model('Coordinates', {
    latitude: t.number,
    longitude: t.number,
  });

export interface CoordinatesInstance extends Instance<typeof Coordinates> {
}

export interface CoordinatesSnapshotIn extends SnapshotIn<typeof Coordinates> {
}

export interface CoordinatesSnapshotOut extends SnapshotOut<typeof Coordinates> {
}
