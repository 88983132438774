import React, {
  FC, useCallback, useRef,
} from 'react';
import {
  StyleSheet,
  View,
  Text,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
} from 'react-native-actions-sheet';
import Button from '@components/common/Button';
import { observer } from 'mobx-react-lite';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 20,
  },
  text: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
    marginBottom: 40,
  },
});

const snapPoints = [100];

const BonusProgramInfoSheet: FC<SheetProps> = (
  {
    sheetId,
  },
) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);

  const onConfirm = useCallback(() => {
    actionSheetRef.current?.hide();
  }, []);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      <View style={s.root}>
        <Text style={s.text}>
          При регистрации пользователя на него автоматически заводится карта лояльности
          с 5% скидкой и бонусной системой накопления.
          {'\n'}
          {'\n'}
          Повышение скидки до 10% происходит после осуществления заказов, общая сумма которых составляет 10 000 ₽.
          {'\n'}
          {'\n'}
          Ниже под картой в Личном кабинете можно увидеть счетчик,
          на какую сумму осталось заказать, чтобы скидка увеличилась.
        </Text>
        <Button
          onPress={onConfirm}
          title="Понятно"
        />
      </View>
    </ActionSheet>
  );
};

export default observer(BonusProgramInfoSheet);
