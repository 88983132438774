import React, { FC } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

interface IProps {
  title: string,
}

const s = StyleSheet.create({
  button: {
    backgroundColor: colors.button.bgDisabled,
    width: '100%',
    minHeight: 40,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  buttonText: {
    textAlign: 'center',
    paddingTop: 5,
    paddingBottom: 7,
    paddingHorizontal: 20,
    fontFamily: ubuntuFont.regular,
    fontWeight: 'bold',
    color: colors.button.text,
    fontSize: 16,
  },
  wrapper: {
    width: '100%',
  },
});

const ButtonInfoView: FC<IProps> = ({ title }) => (
  <View style={s.wrapper}>
    <View style={s.button}>
      <Text style={s.buttonText}>{title}</Text>
    </View>
  </View>
);

export default ButtonInfoView;
