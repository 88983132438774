import {
  useEffect,
  useState,
  useMemo,
} from 'react';
import {
  HOURS,
  MINUTES,
  WheelTimePickerProps,
  WheelTimePickerValue,
} from '../types';

const useTimePickerLogic = (args: WheelTimePickerProps) => {
  const [
    pickerValue,
    setPickerValue,
  ] = useState<WheelTimePickerValue>({
    hour: args.initialHours || HOURS[0],
    minutes: args.initialMinutes || MINUTES[0],
  });

  useEffect(() => {
    args.onChange(pickerValue);
  }, [args, args.onChange, pickerValue]);

  useEffect(() => {
    if (args.initialHours && args.initialMinutes) {
      setPickerValue({
        hour: args.initialHours,
        minutes: args.initialMinutes,
      });
    }
  }, [args.initialHours, args.initialMinutes]);

  const minutesForThisHour = args.timeSlots.get(pickerValue.hour);
  const hoursOptions = useMemo(() => [...args.timeSlots.keys()], [args.timeSlots]);

  /**
   * Если выбранное время не доступно, то выбираем первое попавшееся
   */
  useEffect(() => {
    if (
      hoursOptions.length
    ) {
      // Выбранный час не имеет доступных минут
      if (!hoursOptions.includes(pickerValue.hour)) {
        const minForZeroHour = args.timeSlots.get(hoursOptions[0]);
        if (!minForZeroHour) {
          return;
        }

        const newValue = {
          hour: hoursOptions[0],
          minutes: minForZeroHour[0],
        };

        setPickerValue(newValue);
      }

      // Выбранный час не имеет доступные минуты, но текущая выбранная не содержится в доступных
      if (minutesForThisHour && !minutesForThisHour.includes(pickerValue.minutes)) {
        const newValue = {
          hour: pickerValue.hour,
          minutes: minutesForThisHour[0],
        };
        setPickerValue(newValue);
      }
    }
  }, [args.timeSlots, hoursOptions, minutesForThisHour, pickerValue.hour, pickerValue.minutes]);

  return [pickerValue, setPickerValue] as const;
};

export default useTimePickerLogic;
