import React, { FC, useMemo } from 'react';
import {
  StyleSheet, View,
} from 'react-native';
import { ImageRequireSource, ImageURISource } from 'react-native/Libraries/Image/ImageSource';
import LazyImage from '@components/common/Picture/LazyImage/LazyImage';

const s = StyleSheet.create({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface IProps {
  notAvailableItem?: boolean,
  image: string | ImageRequireSource,
  size: number,
  maxSize?: number,
  r?: number,
}

const Picture: FC<IProps> = (
  {
    image,
    size,
    r = 15,
    // style = {},
    maxSize,
    notAvailableItem = false,
  },
) => {
  const source: ImageURISource | ImageRequireSource = useMemo(() => {
    if (typeof image === 'number') {
      return image as ImageRequireSource;
    }

    return ({
      uri: image,
    });
  }, [image]);

  return (
    <View
      style={StyleSheet.flatten([
        s.root,
        notAvailableItem && {
          opacity: 0.3,
        },
      ])}
    >
      <LazyImage
        size={size}
        borderRadius={r}
        source={source}
        maxSize={maxSize}
      />
    </View>
  );
};

export default Picture;
