import {
  ActivityIndicator,
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import React, { FC } from 'react';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

interface IProps {
  title: string,
  onPress: () => void,
  disabled?: boolean,
  loading?: boolean,
}

const s = StyleSheet.create({
  button: {
    width: '100%',
    minHeight: 40,
    borderRadius: 10,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 5,
  },
  buttonEnabled: {
    backgroundColor: colors.button.bg,
  },
  buttonDisabled: {
    backgroundColor: colors.button.bgDisabled,
  },
  buttonText: {
    textAlign: 'center',
    paddingVertical: 5,
    paddingHorizontal: 20,
    fontFamily: ubuntuFont.regular,
    fontWeight: 'bold',
    color: colors.button.text,
    fontSize: 16,
  },
  wrapper: {
    width: '100%',
  },
});

const Button: FC<IProps> = (
  {
    onPress,
    title,
    disabled = false,
    loading = false,
  },
) => (
  <TouchableOpacity
    onPress={onPress}
    disabled={disabled || loading}
    style={s.wrapper}
  >
    <View style={StyleSheet.flatten([s.button, disabled ? s.buttonDisabled : s.buttonEnabled])}>
      {loading && (
        <ActivityIndicator color={colors.white} />
      )}
      <Text style={s.buttonText}>{title}</Text>
    </View>
  </TouchableOpacity>
);

export default Button;
