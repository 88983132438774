// orderCookingStatus
// Enum or null
// "Unconfirmed"
// "WaitCooking"
// "ReadyForCooking"
// "CookingStarted"
// "CookingCompleted"
// "Waiting"
// "OnWay"
// "Delivered"
// "Closed"
// "Cancelled"
import React from 'react';
import { Text } from 'react-native';

const getOrderCookingStatusMessage = (orderId: string, orderCookingStatus: string) => {
  switch (orderCookingStatus) {
    case '':
    case 'Unconfirmed':
    case 'WaitCooking':
    case 'ReadyForCooking':
    case 'Waiting':
      return (
        <Text>
          {`Заказ №${orderId} принят!`}
          {'\n'}
          Скоро начнем готовить
        </Text>
      );
    case 'Sending':
      return (
        <Text>
          {`Заказ №${orderId} принят!`}
          {'\n'}
          Отправляем на кухню
        </Text>
      );
    case 'CookingStarted':
      return (
        <Text>
          {`Заказ №${orderId}`}
          {'\n'}
          Готовится
        </Text>
      );
    case 'CookingCompleted':
      return (
        <Text>
          {`Заказ №${orderId}`}
          {'\n'}
          Приготовлен
        </Text>
      );
    case 'OnWay':
      return (
        <Text>
          {`Заказ №${orderId}`}
          {'\n'}
          В пути
        </Text>
      );
    case 'Delivered':
      return (
        <Text>
          {`Заказ №${orderId}`}
          {'\n'}
          Доставлен
        </Text>
      );
    case 'Closed':
      return (
        <Text>
          {`Заказ №${orderId}`}
          {'\n'}
          Закрыт
        </Text>
      );
    case 'Cancelled':
      return (
        <Text>
          {`Заказ №${orderId}`}
          {'\n'}
          Отменён
        </Text>
      );
    default:
      return (
        <Text>
          {`Заказ №${orderId}`}
        </Text>
      );
  }
};

export default getOrderCookingStatusMessage;
