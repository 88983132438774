import React, { FC, useCallback, useMemo } from 'react';
import {
  Enum_Ordertype_Ordertypecode,
  useAddFioToProfileMutation,
  useFastAddOrderMutation,
} from '@app/infrastructureLayer/generated/graphql';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { StyleSheet, Text, View } from 'react-native';
import { RootStackScreenProps } from '@navigation/types';
import { useCheckoutState } from '@components/CheckoutContext';
import { useNavigation } from '@react-navigation/native';
import { SheetManager } from 'react-native-actions-sheet';
import { observer } from 'mobx-react-lite';
import PaymentTypeSelector from '@screens/CartScreen/includes/PaymentTypeSelector';
import MakeOrderButtonView from '@screens/CartScreen/includes/MakeOrderButtonView';
import OrderCustomerName from '@screens/CartScreen/includes/OrderCustomerName';
import FullScreenLoading from '@components/common/FullScreenLoading';
import LegalEntityInput from '@screens/CartScreen/includes/LegalEntityInput';
import useShopIdSync from '@hooks/useShopIdSync';
import DateSelector from '@screens/CartScreen/includes/DateSelector';
import OrderComment from '@screens/CartScreen/includes/OrderComment';
import CardSelector from '@screens/CartScreen/includes/CardSelector';
import RoomNumber from '@screens/CartScreen/includes/RoomNumber';
import ubuntuFont from '@constants/ubuntuFont';
import crossAlert from '@utils/crossAlert';
import SumResult from '@screens/CartScreen/includes/SumResult';
import PromoCode from '@screens/CartScreen/includes/PromoCode';
import TopBlock from '@screens/Tabs/MenuScreen/includes/TopBlock';
import CartItem from '@screens/CartScreen/includes/CartItem';
import useStore from '@app/domain/store/useStore';
import Cutlery from '@screens/CartScreen/includes/Cutlery';
import Bonuses from '@screens/CartScreen/includes/Bonuses';
import Screen from '@components/layout/Screen';
import Button from '@components/common/Button';
import colors from '@constants/colors';
import Back from '@components/layout/Back';

const s = StyleSheet.create({
  root: {
    backgroundColor: colors.background,
  },
  horPadding: {
    paddingHorizontal: 16,
  },
  scroll: {
    flex: 1,
  },
  header: {
    paddingBottom: 10,
  },
  title: {
    color: colors.primary,
    fontFamily: ubuntuFont.regular,
    fontSize: 24,
    marginTop: 8,
  },
  headerNotAvailable: {
    paddingBottom: 10,
  },
  titleNotAvailable: {
    color: colors.error,
    fontFamily: ubuntuFont.regular,
    fontSize: 12,
    marginTop: 8,
  },
  items: {
    marginTop: 20,
    gap: 10,
  },
  rows: {
    gap: 10,
    paddingVertical: 10,
  },
  button: {
    paddingHorizontal: 16,
    marginTop: 20,
    marginBottom: 10,
    alignItems: 'center',
    width: '100%',
  },
  emptyCartRoot: {
    gap: 30,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyCartTitle: {
    fontFamily: ubuntuFont.regular,
    color: colors.text,
    fontSize: 16,
    textAlign: 'center',
  },
});

const CartScreen: FC<RootStackScreenProps<'CartScreen'>> = (
  {
    route,
  },
) => {
  useShopIdSync(route.params?.shopId);

  const navigation = useNavigation();
  const [addFioToProfile] = useAddFioToProfileMutation();

  const {
    profileStore: {
      profile,
    },
    appStore: {
      activeCartState,
      activeMenu,
      selectedShopId,
      shop,
      addOrderInput,
      clearCart,
      activeCartItems,
      orderType,
      cartStates,
      activeCartItemsHaveNotAvailableItems,
    },
  } = useStore();

  const canMakeOrder = useMemo(() => (
    activeCartState?.canMakeOrder
  ), [activeCartState?.canMakeOrder]);

  const cartState = selectedShopId ? cartStates.get(selectedShopId) : undefined;

  const onBack = useCallback(() => {
    if (selectedShopId) {
      navigation.goBack();
    } else {
      navigation.navigate('IndexScreen');
    }
  }, [navigation, selectedShopId]);

  const [addOrder, { loading: addOrderPending }] = useFastAddOrderMutation();

  const {
    data: checkoutData,
    error: checkoutError,
    loading: checkoutOrderLoading,
  } = useCheckoutState();

  const openLoginSheet = useCallback(async () => {
    if (!profile) {
      SheetManager.show('login-sheet');
    }
  }, [profile]);

  const onMakeOrder = useCallback(async () => {
    if (!profile) {
      SheetManager.show('login-sheet');

      return;
    }

    if (!activeCartState || !addOrderInput || !canMakeOrder) {
      return;
    }

    await SheetManager.show('confirm-shop-sheet', {
      payload: {
        onConfirm: async () => {
          const userId = profile.id;

          if (cartState && cartState.customerName.length > 0) {
            const addFioToProfileResponse = await addFioToProfile({
              variables: {
                value: cartState?.customerName.trim(),
              },
            });

            if (addFioToProfileResponse.data?.addFIOToProfile.resultCode !== 'SUCCESS') {
              console.error('Ошибка при сохранении имени во время создания заказа');
              crossAlert('Ошибка при создании заказа, попробуйте снова позже.');

              return;
            }
          }

          const addOrderResponse = await addOrder({
            variables: {
              data: {
                userId: Number(userId),
                request: addOrderInput,
              },
            },
          });

          if (!addOrderResponse.data?.createOrderRequest?.data?.id) {
            crossAlert('Ошибка при создании заказа');
          } else {
            SheetManager.hideAll();
            SheetManager.show('order-created-sheet');
            clearCart();
            cartState?.setCustomerName('');
            activeCartState.setComment('');
            activeCartState.setPromoCode('');
            activeCartState.setBonusesToPayAmount(0);
          }
        },
      },
    });
  }, [
    profile, activeCartState, addOrderInput, canMakeOrder,
    addOrder, cartState, addFioToProfile, clearCart,
  ]);

  const onGoToMenu = useCallback(() => {
    if (selectedShopId) {
      navigation.navigate('Tabs', {
        screen: 'MenuScreen',
        params: {
          shopId: selectedShopId,
        },
      });
    } else {
      navigation.navigate('IndexScreen');
    }
  }, [navigation, selectedShopId]);

  const isDelivery = useMemo(() => (
    orderType?.type === Enum_Ordertype_Ordertypecode.Delivery || false
  ), [orderType?.type]);

  const deliveryMinimumSum = useMemo(() => (
    checkoutData?.deliveryInfo?.deliveryZone.minSum || 0
  ), [checkoutData?.deliveryInfo?.deliveryZone.minSum]);

  const orderTotal = useMemo(() => (
    checkoutData?.orderTotal || 0
  ), [checkoutData?.orderTotal]);

  const totalSumLessThanMinSum = useMemo(() => (
    orderTotal < deliveryMinimumSum
  ), [orderTotal, deliveryMinimumSum]);

  if (!activeMenu || !selectedShopId) {
    return <FullScreenLoading />;
  }

  return (
    <Screen>
      <Back title="Назад" onPress={onBack} />
      <KeyboardAwareScrollView
        bounces={false}
        style={s.scroll}
        contentContainerStyle={s.root}
        keyboardShouldPersistTaps="handled"
      >
        <TopBlock />
        <View style={s.horPadding}>
          <View style={s.header}>
            <Text style={s.title}>
              Корзина
            </Text>
          </View>
          {activeCartItemsHaveNotAvailableItems && (
            <View style={s.headerNotAvailable}>
              <Text style={s.titleNotAvailable}>
                Некоторых товаров в Вашей корзине нет в наличии.
              </Text>
            </View>
          )}
          {activeCartItems.length > 0 && (
            <>
              <View style={s.items}>
                {activeCartItems.map((item) => (
                  <CartItem
                    key={item.id}
                    cartItemId={item.id}
                    shopId={selectedShopId}
                  />
                ))}
              </View>
              <View style={s.rows}>
                {/* <InlinePhoneOTPAuth /> */}
                <OrderCustomerName />
                {shop?.useCutlery && (
                  <Cutlery />
                )}
                <DateSelector />
                <PaymentTypeSelector />
                <LegalEntityInput />
                <CardSelector />
                {shop?.useBonuses && (
                  <Bonuses />
                )}
                {shop?.usePromoCodes && (
                  <PromoCode />
                )}
                {orderType?.type === Enum_Ordertype_Ordertypecode.InRoom && (
                  <RoomNumber />
                )}
                <OrderComment />
                <SumResult />
              </View>
            </>
          )}
          {activeCartItems.length === 0 && (
            <View style={s.emptyCartRoot}>
              <Text style={s.emptyCartTitle}>Корзина пуста</Text>
              <Button
                title="Перейти в меню"
                onPress={onGoToMenu}
              />
            </View>
          )}
        </View>
      </KeyboardAwareScrollView>
      {activeCartItems.length > 0 && (
        <View style={s.button}>
          <MakeOrderButtonView
            loading={addOrderPending || checkoutOrderLoading}
            profile={profile}
            activeCartItemsHaveNotAvailableItems={activeCartItemsHaveNotAvailableItems}
            checkoutData={checkoutData}
            checkoutError={checkoutError}
            canMakeOrder={canMakeOrder}
            isCantDelivery={isDelivery && totalSumLessThanMinSum}
            deliveryMinimumSum={deliveryMinimumSum}
            onLoginPress={openLoginSheet}
            onOrderPress={onMakeOrder}
          />
        </View>
      )}
    </Screen>
  );
};

export default observer(CartScreen);
