import {
  Instance,
  resolveIdentifier,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import {
  Enum_Componentuserdeliveryaddress_Type,
} from '@app/infrastructureLayer/generated/graphql';
import { Coordinates } from '@app/domain/store/CoreStore/AppStore/entities/Coordinates';
import { City } from '@app/domain/store/CoreStore/AppStore/entities/City';

export const DeliveryAddress = t
  .model('DeliveryAddress', {
    id: t.identifier,
    title: t.optional(t.string, ''),
    cityId: t.string,
    type: t.maybe(t.frozen<Enum_Componentuserdeliveryaddress_Type>()),
    address: t.optional(t.string, ''),
    room: t.optional(t.string, ''),
    entrance: t.optional(t.string, ''),
    floor: t.optional(t.string, ''),
    intercomCode: t.optional(t.string, ''),
    comment: t.optional(t.string, ''),
    coordinates: Coordinates,
  })
  .views((self) => ({
    get city() {
      return resolveIdentifier(City, self, self.cityId);
    },
  }))
  .views((self) => ({
    get addressTitle() {
      if (self.title.trim()) {
        return self.title.trim();
      }

      return [
        self.address,
        self.room,
      ]
        .filter(Boolean)
        .join(', ');
    },
  }));

export interface DeliveryAddressInstance extends Instance<typeof DeliveryAddress> {
}

export interface DeliveryAddressSnapshotIn extends SnapshotIn<typeof DeliveryAddress> {
}

export interface DeliveryAddressSnapshotOut extends SnapshotOut<typeof DeliveryAddress> {
}
