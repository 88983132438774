import {
  View, Text, StyleSheet,
} from 'react-native';
import React, {
  FC,
  useCallback,
  useEffect,
} from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useFocusEffect, useIsFocused } from '@react-navigation/native';
import { RootStackScreenProps } from '@navigation/types';
import { useUpdateMyProfileMutation } from '@app/infrastructureLayer/generated/graphql';
import Back from '@components/layout/Back';
import Screen from '@components/layout/Screen';
import crossAlert from '@utils/crossAlert';
import useStore from '@app/domain/store/useStore';
import { observer } from 'mobx-react-lite';
import useProfileSync from '@hooks/useProfileSync/useProfileSync';
import ubuntuFont from '@constants/ubuntuFont';
import EditProfileForm, { EditProfileFormValues } from '@widgets/form/EditProfileForm/EditProfileForm';

const s = StyleSheet.create({
  scroll: {
    flexGrow: 1,
  },
  header: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
    marginTop: 40,
  },
  root: {
    flex: 1,
    padding: 16,
  },
});

const EditProfileScreen: FC<RootStackScreenProps<'EditProfileScreen'>> = (
  {
    navigation,
  },
) => {
  // const navigation = useNavigation();
  const { refetchProfile } = useProfileSync();
  const [updateMyProfile] = useUpdateMyProfileMutation();

  useFocusEffect(useCallback(() => {
    refetchProfile();
  }, [refetchProfile]));

  const {
    profileStore: {
      profile,
    },
  } = useStore();

  const isRegistered = profile?.registrationCompleted;

  const isFocused = useIsFocused();

  useEffect(() => {
    if (!isRegistered && isFocused) {
      navigation.replace('Auth', {
        screen: 'RegistrationScreen',
      });
    }
  }, [isRegistered, navigation, isFocused]);

  const onSave = useCallback(async (data: EditProfileFormValues) => {
    const { broadcastOfferAccepted = true, ...rest } = data;

    const res = await updateMyProfile({
      variables: {
        input: {
          broadcastOfferAccepted,
          ...rest,
        },
      },
    });

    await refetchProfile();

    if (res.data?.updateMyProfile.resultCode !== 'SUCCESS') {
      crossAlert('Ошибка при сохранении данных, попробуйте ещё раз позже.');
    } else {
      crossAlert('Изменения сохранены.');
    }
  }, [refetchProfile, updateMyProfile]);

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate('IndexScreen');
  }, [navigation]);

  return (
    <Screen>
      <KeyboardAwareScrollView
        bounces={false}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={s.scroll}
      >
        <Back title="Назад" onPress={onBack} />
        <View style={s.root}>
          <Text style={s.header}>Редактирование профиля</Text>
          <EditProfileForm
            onSave={onSave}
          />
        </View>
      </KeyboardAwareScrollView>
    </Screen>
  );
};

export default observer(EditProfileScreen);
