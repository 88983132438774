import {
  Instance, resolveIdentifier,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { Modifier } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/Modifier';

export const ChildModifier = t
  .model('ChildModifier', {
    id: t.identifier,
    minAmount: t.refinement(t.number, (value) => value >= 0),
    maxAmount: t.refinement(t.number, (value) => value >= 0),
    defaultAmount: t.refinement(t.number, (value) => value >= 0),
    modifierId: t.string,
    // required: t.boolean,
    // freeOfChargeAmount: t.refinement(t.number, (value) => value >= 0),
  })
  .views((self) => ({
    get modifier() {
      return resolveIdentifier(Modifier, self, self.modifierId);
    },
    get isOneOf() {
      return self.maxAmount === 1;
    },
  }));

export interface ChildModifierInstance extends Instance<typeof ChildModifier> {
}

export interface ChildModifierSnapshotIn extends SnapshotIn<typeof ChildModifier> {
}

export interface ChildModifierSnapshotOut extends SnapshotOut<typeof ChildModifier> {
}
