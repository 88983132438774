import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { FC } from 'react';
import colors from '@constants/colors';

type IProps = {
  color?: string
};

const WorkSvg: FC<IProps> = ({ color = colors.text }) => (
  <Svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    fill="none"
  >
    <Path
      d="M14.8333 3.75016H11.6666V2.16683C11.6666 1.28808 10.962 0.583496 10.0833 0.583496H6.91658C6.03784 0.583496 5.33325 1.28808 5.33325 2.16683V3.75016H2.16659C1.28784 3.75016 0.591169 4.45475 0.591169 5.3335L0.583252 14.0418C0.583252 14.9206 1.28784 15.6252 2.16659 15.6252H14.8333C15.712 15.6252 16.4166 14.9206 16.4166 14.0418V5.3335C16.4166 4.45475 15.712 3.75016 14.8333 3.75016ZM10.0833 3.75016H6.91658V2.16683H10.0833V3.75016Z"
      fill={color}
    />
  </Svg>
);
export default WorkSvg;
