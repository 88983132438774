import { StyleSheet, Text, View } from 'react-native';
import React, { FC, useMemo } from 'react';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import generalConfig from '@constants/generalConfig';

const s = StyleSheet.create({
  root: {
    rowGap: 5,
    marginBottom: 5,
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: ubuntuFont.regular,
    color: colors.text,
    fontSize: 14,
    width: '70%',
  },
});

type IProps = {
  title?: string;
  price?: number;
  color?: string
};
const OrderInfosRow: FC<IProps> = (
  {
    title,
    price,
    color = colors.text,
  },
) => {
  const textStyles = useMemo(() => ({ color }), [color]);

  return (
    <View style={s.root}>
      <View style={s.wrapper}>
        <Text style={s.title}>{title}</Text>
        <Text style={textStyles}>{`${price} ${generalConfig.currencyLabel}`}</Text>
      </View>
    </View>
  );
};

export default OrderInfosRow;
