import {
  View, Text, StyleSheet, ScrollView, Dimensions,
} from 'react-native';
import React, { FC } from 'react';
import Screen from '@components/layout/Screen';
import Back from '@components/layout/Back';
import { RootStackScreenProps } from '@navigation/types';
import DashedLine from '@components/common/DashedLine';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import useStore from '@app/domain/store/useStore';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import Map from '@components/Map/Map';

const s = StyleSheet.create({
  root: {
    flex: 1,
  },
  container: {
    paddingHorizontal: 15,
    marginTop: 20,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 14,
    fontFamily: ubuntuFont.bold,
  },
  // link: {
  //   fontSize: 10,
  //   fontFamily: ubuntuFont.light,
  //   textDecorationLine: 'underline',
  //   color: colors.primary,
  // },
  circle: {
    backgroundColor: colors.grey,
    width: 6,
    height: 6,
    borderRadius: 30,
  },
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
  },
  addressText: {
    fontSize: 14,
    fontFamily: ubuntuFont.light,
  },
  date: {
    fontSize: 12,
    fontFamily: ubuntuFont.light,
    color: colors.grey,
  },
  infoWrapper: {
    rowGap: 5,
  },
  box: {
    marginTop: 12,
    rowGap: 12,
    paddingBottom: 15,
  },
  pb15: {
    paddingBottom: 15,
  },
});

type ShopData = {
  id: string,
  title: string,
  workTime: string,
};

type CityData = {
  id: string,
  title: string,
  shops: ShopData[];
};

const Address: FC<{ title: string, workTime: string }> = (
  {
    workTime,
    title,
  },
) => (
  <View style={s.infoRow}>
    <View style={s.circle} />
    <View style={s.infoWrapper}>
      <Text style={s.addressText}>{title}</Text>
      <Text style={s.date}>{workTime}</Text>
    </View>
  </View>
);

const CityAddresses: FC<CityData> = (
  {
    title,
    shops,
  },
) => (
  <View style={s.pb15}>
    <View style={s.row}>
      <Text style={s.title}>{title}</Text>
      {/* <Text style={s.link}>Показать на карте</Text> TODO: Показывать на карте */}
    </View>
    <View style={s.box}>
      {shops.map((
        {
          id,
          title: shopTitle,
          workTime,
        },
      ) => (
        <Address
          key={id}
          title={shopTitle}
          workTime={workTime}
        />
      ))}
    </View>
    <DashedLine />
  </View>
);

const AddressesScreen: FC<RootStackScreenProps<'AddressesScreen'>> = () => {
  const {
    appStore: {
      citiesWithShops,
      shopsForCity,
    },
  } = useStore();

  const citiesData: CityData[] = computed(() => (
    citiesWithShops.map((city) => {
      const shops = shopsForCity(city.id);

      return ({
        id: city.id,
        title: city.title,
        shops: shops.map((shop) => ({
          id: shop.id,
          title: shop.address,
          workTime: shop.workTimeTitle,
        })),
      });
    })
  ))
    .get();

  return (
    <Screen>
      <View style={s.root}>
        <Back title="Наши адреса" />
        <Map mapHeight={Dimensions.get('window').height / 2} />
        <ScrollView style={s.container}>
          {citiesData.map((city) => (
            <CityAddresses
              key={city.id}
              id={city.id}
              title={city.title}
              shops={city.shops}
            />
          ))}
        </ScrollView>
      </View>
    </Screen>
  );
};

export default observer(AddressesScreen);
