import {
  ZonesByShop,
} from '@screens/SelectDeliveryAddressScreen/includes/SelectDeliveryAddressOnMap/SelectDeliveryAddressOnMap';

const mapNative = (center: [number, number], apiKey: string, zonesByShops: ZonesByShop) => `<!DOCTYPE html>
  <html>
  <head>
     <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
     <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <!--
        This viewport works for phones with notches.
        It's optimized for gestures by disabling global zoom.
       -->
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1.00001, viewport-fit=cover, minimal-ui"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <!--
          Set your own API-key. Testing key is not valid for other web-sites and services.
          Get your API-key on the Developer Dashboard: https://developer.tech.yandex.ru/keys/
      -->
      <script src="https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=${apiKey}" type="text/javascript"></script>
      <script type="text/javascript">
        ymaps.ready(function () {
          var myMap = new ymaps.Map('map', {
              center: [${center[0]}, ${center[1]}],
              zoom: 17,
              controls: ['zoomControl'],
            }); 
  
            window.ReactNativeWebView.postMessage(JSON.stringify([${center[0]}, ${center[1]}]));
          
            myMap.events.add('boundschange', function (event) {
              var newCenter = event.get('newCenter');
              // Отправка сообщения на React Native
              window.ReactNativeWebView.postMessage(JSON.stringify(newCenter));
            });
            const zonesByShops = JSON.parse('${JSON.stringify(zonesByShops)}');
            
           zonesByShops.map(zbs => {
             zbs.zones.map((zone) => {
             const myGeoObject = new ymaps.GeoObject({
                  // Описываем геометрию геообъекта.
                  geometry: {
                      // Тип геометрии - "Многоугольник".
                      type: 'Polygon',
                      // Указываем координаты вершин многоугольника.
                      coordinates: [
                           zone.polygon,
                      ],
                      // Задаем правило заливки внутренних контуров по алгоритму "nonZero".
                      fillRule: 'nonZero',
                  },
                  // Описываем свойства геообъекта.
                  properties:{
                      // Содержимое балуна.
                      balloonContent: zone.name,
                  },
              }, {
                  // Описываем опции геообъекта.
                  // Цвет заливки.
                  fillColor: zone.color,
                  // Цвет обводки.
                  strokeColor: '#000000',
                  // Общая прозрачность (как для заливки, так и для обводки).
                  opacity: 0.2,
                  // Ширина обводки.
                  strokeWidth: 1,
                  // Стиль обводки.
                  strokeStyle: 'shortdash',
              });
             
              // Добавляем многоугольник на карту.
              myMap.geoObjects.add(myGeoObject);
              });
           });
        });
      </script>
      <style>
          html, body, #map {
              width: 100%; height: 100%; padding: 0; margin: 0;
          }
      </style>
  </head>
  <body>
  <div id="map"></div>
  </body>
  </html>`;

export default mapNative;
