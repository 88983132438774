import React, { FC, PropsWithChildren } from 'react';
import colors from '@constants/colors';
import { StyleSheet, View, ViewProps } from 'react-native';

const s = StyleSheet.create({
  root: {
    borderRadius: 10,
    backgroundColor: colors.lightGrey,
    padding: 10,
  },
});

const Block: FC<PropsWithChildren<ViewProps>> = (
  {
    children,
    style,
    ...rest
  },
) => (
  <View
    style={StyleSheet.flatten([s.root, style])}
    {...rest}
  >
    {children}
  </View>
);

export default Block;
