import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';

export const City = t
  .model('City', {
    id: t.identifier,
    title: t.string,
  });

export interface CityInstance extends Instance<typeof City> {
}

export interface CitySnapshotIn extends SnapshotIn<typeof City> {
}

export interface CitySnapshotOut extends SnapshotOut<typeof City> {
}
