import React, {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  StyleSheet,
  View,
  Text,
  Linking,
  // Image,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetManager,
  SheetProps,
} from 'react-native-actions-sheet';
import { observer } from 'mobx-react-lite';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import { RequestOtpCodeResultCode, useRequestOtpCodeMutation } from '@app/infrastructureLayer/generated/graphql';
import validatePhone from '@utils/validatePhone';
import crossAlert from '@utils/crossAlert';
import { absurd } from 'fp-ts/function';
import useStore from '@app/domain/store/useStore';
// import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
// import images from '@constants/images';
import secondsToHumanFormat from '@utils/secondsToHumanFormat';
import Input from '@components/form/Input';
import Button from '@components/common/Button';

const s = StyleSheet.create({
  container: {
    // flex: 1,
    paddingHorizontal: 25,
    paddingBottom: 30,
    backgroundColor: colors.white,
  },
  // scroll: {
  // flex: 1,
  // flexGrow: 1,
  // },
  containerTop: {
    // flex: 1,
    alignItems: 'center',
    marginTop: 20,
  },
  flex1: {
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  policy: {
    fontSize: 12,
    paddingHorizontal: 10,
    paddingBottom: 20,
    textAlign: 'center',
    marginTop: 10,
    marginBottom: 10,
    fontFamily: ubuntuFont.light,
    flexWrap: 'wrap',
  },
  underline: {
    textDecorationLine: 'underline',
    color: colors.text,
    fontSize: 12,
  },
  // logo: {
  //   width: 195,
  //   height: 195,
  // },
  title: {
    fontSize: 16,
    marginTop: 15,
    textAlign: 'center',
    fontFamily: ubuntuFont.light,
  },
  header: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: ubuntuFont.regular,
  },
  input: {
    marginTop: 30,
    borderRadius: 10,
    width: '100%',
    marginBottom: 15,
  },
  messageDuration: {
    paddingTop: 20,
    fontFamily: ubuntuFont.regular,
    color: colors.error,
    fontSize: 14,
  },
});

const snapPoints = [100];

const LoginSheet: FC<SheetProps> = (
  {
    sheetId,
  },
) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);

  const [phone, setPhone] = useState('');

  const [secondsToRetry, setSecondsToRetry] = useState<number>(0);
  const [requestOtpCode, { loading: requestOtpCodeLoading }] = useRequestOtpCodeMutation();

  const navigateToSmsLoginScreen = useCallback(async () => {
    await SheetManager.hide(sheetId);
    await SheetManager.show('sms-login-sheet', {
      payload: {
        phone,
      },
    });
    // await actionSheetRef.current?.hide();
  }, [phone, sheetId]);

  const isPhoneValid = useMemo(() => (
    validatePhone(phone)
  ), [phone]);

  const onRequestSmsCode = useCallback(async () => {
    const res = await requestOtpCode({
      variables: {
        input: {
          phone,
        },
      },
    });

    if (!res.data) {
      crossAlert('Ошибка при авторизации, попробуйте позже.');
      return;
    }

    const { resultCode } = res.data.requestOtpCodeMutation;

    switch (resultCode) {
      case RequestOtpCodeResultCode.Fail:
        crossAlert('Ошибка при запросе смс кода, попробуйте позже.');
        break;
      case RequestOtpCodeResultCode.RateLimited:
        setSecondsToRetry(res.data.requestOtpCodeMutation.seconds);
        break;
      case RequestOtpCodeResultCode.Success:
        navigateToSmsLoginScreen();
        break;
      default:
        absurd(resultCode);
        crossAlert('Ошибка при запросе смс кода, попробуйте позже.');
        break;
    }
  }, [navigateToSmsLoginScreen, phone, requestOtpCode]);

  useEffect(() => {
    if (secondsToRetry > 0) {
      const timeout = setTimeout(() => {
        setSecondsToRetry(secondsToRetry - 1);
      }, 1000);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }

    return undefined;
  }, [secondsToRetry]);

  const { appStore: { globalSettings } } = useStore();
  const policyUrl = globalSettings?.policyUrl;
  const termUrl = globalSettings?.termUrl;

  const onTermPress = useCallback(() => {
    if (!termUrl) {
      return;
    }

    Linking.openURL(termUrl);
  }, [termUrl]);

  const onPolicyPress = useCallback(() => {
    if (!policyUrl) {
      return;
    }

    Linking.openURL(policyUrl);
  }, [policyUrl]);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      {/* <KeyboardAwareScrollView */}
      {/*   bounces={false} */}
      {/*   keyboardShouldPersistTaps="handled" */}
      {/*   contentContainerStyle={s.scroll} */}
      {/* > */}
      {/* <View style={s.scroll}> */}
      <View style={s.container}>
        <View style={s.containerTop}>
          {/* <Image source={images.companyLogoSource} style={s.logo} /> */}
          <Text style={s.title}>Чтобы сделать заказ</Text>
          <Text style={s.header}>Укажите ваш номер телефона</Text>
          {secondsToRetry > 0 && (
          <Text style={s.messageDuration}>
            {`Повторно отправить смс можно через ${secondsToHumanFormat(secondsToRetry)}`}
          </Text>
          )}
          <View style={s.input}>
            <Input
              type="phone"
              onChangeText={setPhone}
              value={phone}
              placeholder="8 ХХХ ХХХ ХХ ХХ"
              editable={!requestOtpCodeLoading}
            />
          </View>
        </View>
        <View style={s.flex1}>
          <Text style={s.policy}>
            Продолжая, вы даёте
            {' '}
            <Text style={s.underline} onPress={onTermPress}>
              Согласие на обработку персональных данных
            </Text>
            {' '}
            и соглашаетесь с условиями
            {' '}
            <Text style={s.underline} onPress={onPolicyPress}>
              Политики конфиденциальности
            </Text>
          </Text>
          <Button
            disabled={!isPhoneValid || requestOtpCodeLoading || secondsToRetry > 0}
            loading={requestOtpCodeLoading}
            title="Получить смс"
            onPress={onRequestSmsCode}
          />
        </View>
      </View>
      {/* </View> */}
      {/* </KeyboardAwareScrollView> */}
    </ActionSheet>
  );
};

export default observer(LoginSheet);
