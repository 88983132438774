import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { FC } from 'react';
import colors from '@constants/colors';

type IProps = {
  color?: string
};

const HomeSvg: FC<IProps> = ({ color = colors.text }) => (
  <Svg
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="none"
  >
    <Path
      d="M9.16663 17.4168V12.8335H12.8333V17.4168C12.8333 17.921 13.2458 18.3335 13.75 18.3335H16.5C17.0041 18.3335 17.4166 17.921 17.4166 17.4168V11.0002H18.975C19.3966 11.0002 19.5983 10.4777 19.2775 10.2027L11.6141 3.30016C11.2658 2.98849 10.7341 2.98849 10.3858 3.30016L2.72247 10.2027C2.4108 10.4777 2.6033 11.0002 3.02497 11.0002H4.5833V17.4168C4.5833 17.921 4.9958 18.3335 5.49997 18.3335H8.24997C8.75413 18.3335 9.16663 17.921 9.16663 17.4168Z"
      fill={color}
    />
  </Svg>
);
export default HomeSvg;
