import React, {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { StyleSheet, Alert } from 'react-native';

import { WebView, WebViewNavigation } from 'react-native-webview';
import { RootStackScreenProps } from '@navigation/types';
import { WebViewSource } from 'react-native-webview/src/WebViewTypes';
import Back from '@components/layout/Back';
import Screen from '@components/layout/Screen';

const styles = StyleSheet.create({
  webView: {
    width: '100%',
    flex: 1,
  },
});

const originWhitelist = ['*'];

const Screen3ds: FC<RootStackScreenProps<'CardAddScreen'>> = (
  {
    navigation,
    route,
  },
) => {
  const { formUrl } = route.params;
  const webviewRef = useRef(null);

  const [currentUrl, setCurrentUrl] = useState<string>('');

  const onNavStateChange = useCallback((navState: WebViewNavigation) => {
    setCurrentUrl(navState.url);
  }, []);

  const onBack = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  useEffect(() => {
    if (currentUrl) {
      if (currentUrl.indexOf('/card-add/success') !== -1) {
        Alert.alert('Карта добавлена');
        navigation.goBack();
      }

      if (currentUrl.indexOf('/card-add/fail') !== -1) {
        Alert.alert('Ошибка при проведении тестового платежа');
        navigation.goBack();
      }
    }
  }, [currentUrl, navigation]);

  const source: WebViewSource = useMemo(
    () => ({ uri: formUrl }),
    [formUrl],
  );

  return (
    <Screen>
      <Back
        title="Назад"
        onPress={onBack}
      />
      <WebView
        ref={webviewRef}
        startInLoadingState
        scrollEnabled
        javaScriptCanOpenWindowsAutomatically
        javaScriptEnabled
        scalesPageToFit={false}
        source={source}
        originWhitelist={originWhitelist}
        style={styles.webView}
        onNavigationStateChange={onNavStateChange}
      />
    </Screen>
  );
};

export default Screen3ds;
