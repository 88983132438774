import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { BaseItemWithParams } from '@app/domain/store/CoreStore/AppStore/entities/Menu/BaseItemWithParams';

export const Modifier = BaseItemWithParams
  .named('Modifier')
  .props({
    id: t.identifier,
    title: t.string,
    description: t.maybe(t.string),
    imageUrl: t.maybe(t.string),
  });

export interface ModifierInstance extends Instance<typeof Modifier> {
}

export interface ModifierSnapshotIn extends SnapshotIn<typeof Modifier> {
}

export interface ModifierSnapshotOut extends SnapshotOut<typeof Modifier> {
}
