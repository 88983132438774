import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useMemo } from 'react';
import {
  StyleSheet,
  Text,
  View,
  Pressable,
} from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import Counter from '@screens/CartScreen/includes/Counter';
import { useModifiersState } from '@screens/MenuItemScreen/model/ModifiersStateModelContext';
import { ChildModifierInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/ChildModifier';
import { GroupModifierInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/GroupModifier';
import useStore from '@app/domain/store/useStore';
import generalConfig from '@constants/generalConfig';
import { StockShowOptionEnum } from '@app/domain/store/CoreStore/AppStore/entities/Menu/StockData';
import isNumber from 'lodash/isNumber';

interface IProps {
  cm: ChildModifierInstance,
  gm: GroupModifierInstance,
  amountInGroup: number,
}

const s = StyleSheet.create({
  modifierRoot: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: colors.lightGrey,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  modifierRootSelected: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    borderRadius: 10,
    backgroundColor: colors.primary,
    borderWidth: 2,
    borderColor: colors.primary,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  modifierTitle: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 14,
    flex: 1,
  },
  modifierTitleSelected: {
    fontFamily: ubuntuFont.light,
    color: colors.white,
    fontSize: 14,
    flex: 1,
  },
  modifierTitleRight: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 14,
    flex: 1,
    textAlign: 'right',
  },
  modifierTitleSelectedRight: {
    fontFamily: ubuntuFont.light,
    color: colors.white,
    fontSize: 14,
    flex: 1,
    textAlign: 'right',
  },
  align: {
    lineHeight: 30,
    height: 30,
    paddingRight: 10,
  },
  notAvailable: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 14,
    flex: 1,
    textAlign: 'right',
    paddingRight: 10,
    lineHeight: 30,
    height: 30,
  },
});

const ModifierBlockItem: FC<IProps> = (
  {
    cm,
    gm,
    amountInGroup,
  },
) => {
  const {
    appStore: {
      shop,
      pricesByModifierId,
      getModifierIsAvailableInActiveMenuByModifierId,
      menuModifierItemByModifierId,
    },
  } = useStore();

  const modifiersStateModel = useModifiersState();

  const modifierId = cm.modifier?.id;
  const menuModifierItem = !!modifierId && menuModifierItemByModifierId(modifierId);

  const maxAmountFromStock = useMemo(() => {
    if (!shop?.useStockBalance || !menuModifierItem) {
      return undefined;
    }

    if (!menuModifierItem.stockable) {
      return undefined;
    }

    if (menuModifierItem.stockShowOption === StockShowOptionEnum.SHOW_NUMBER) {
      return menuModifierItem.stockBalance || 0;
    }

    return undefined;
  }, [shop?.useStockBalance, menuModifierItem]);

  const {
    reduce,
    increase,
  } = useMemo(() => ({
    reduce: () => modifierId && modifiersStateModel.reduceAmount(modifierId),
    increase: () => modifierId && modifiersStateModel.increaseAmount(modifierId),
  }), [modifierId, modifiersStateModel]);

  const isSelected = !!modifierId && !!modifiersStateModel.state.get(modifierId)?.amount;

  const onPressOneOf = useCallback(() => {
    if (!modifierId) {
      return;
    }

    if (isSelected) {
      if (gm.minAmount > 0) {
        return;
      }

      modifiersStateModel.reduceAmount(modifierId);

      return;
    }

    if (gm.isOneOf) {
      const modifiersIds = gm.childModifiers.map((y) => y.modifierId);
      modifiersStateModel.selectOneOf(modifierId, modifiersIds);

      return;
    }

    if (cm.isOneOf) {
      modifiersStateModel.selectOneOf(modifierId, []);
    }
  }, [cm.isOneOf, gm.childModifiers, gm.isOneOf, gm.minAmount, isSelected, modifierId, modifiersStateModel]);

  if (!modifierId || !cm.modifier) {
    console.error('отсутствует модификатор');

    return null;
  }

  const price = pricesByModifierId.get(cm.modifier.id);

  if (typeof price === 'undefined') {
    console.error('отсутствует цена модификатора');

    return null;
  }

  let maxAmount: number | undefined;
  if (gm.maxAmount) {
    maxAmount = gm.maxAmount;
    if (cm.maxAmount > 0) {
      maxAmount = Math.min(cm.maxAmount, gm.maxAmount);
    }

    if (amountInGroup >= gm.maxAmount) {
      maxAmount = 0;
    }
  } else if (cm.maxAmount) {
    maxAmount = cm.maxAmount;
  }

  if (isNumber(maxAmountFromStock)) {
    if (isNumber(maxAmount)) {
      maxAmount = Math.min(maxAmount, maxAmountFromStock);
    } else {
      maxAmount = maxAmountFromStock;
    }
  }

  const isAvailable = getModifierIsAvailableInActiveMenuByModifierId(cm.modifier.id);

  if (!isAvailable) {
    return (
      <View
        key={cm.modifier.id}
        style={isSelected ? s.modifierRootSelected : s.modifierRoot}
      >
        <Text style={isSelected ? s.modifierTitleSelected : s.modifierTitle}>
          {cm.modifier.title}
        </Text>
        <Text style={s.notAvailable}>
          Нет в наличии
        </Text>
      </View>
    );
  }

  if (gm.isOneOf || cm.isOneOf) {
    return (
      <Pressable onPress={onPressOneOf}>
        <View
          key={cm.modifier.id}
          style={isSelected ? s.modifierRootSelected : s.modifierRoot}
        >
          <Text style={isSelected ? s.modifierTitleSelected : s.modifierTitle}>
            {cm.modifier.title}
          </Text>
          <Text
            style={StyleSheet.flatten([
              s.align,
              isSelected ? s.modifierTitleSelectedRight : s.modifierTitleRight,
            ])}
          >
            {`+ ${price} ${generalConfig.currencyLabel}`}
          </Text>
        </View>
      </Pressable>
    );
  }

  return (
    <View
      key={cm.modifier.id}
      style={isSelected ? s.modifierRootSelected : s.modifierRoot}
    >
      <Text style={isSelected ? s.modifierTitleSelected : s.modifierTitle}>
        {cm.modifier.title}
      </Text>
      <View>
        <Text style={isSelected ? s.modifierTitleSelectedRight : s.modifierTitleRight}>
          <Counter
            maxAmount={maxAmount}
            increase={increase}
            reduce={reduce}
            amount={modifiersStateModel.state.get(modifierId)?.amount || 0}
            price={price}
          />
        </Text>
      </View>
    </View>
  );
};

export default observer(ModifierBlockItem);
