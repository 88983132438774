import {
  // useEffect,
  useMemo,
} from 'react';
import {
  // useCheckoutLazyQuery,
  useCheckoutQuery,
} from '@app/infrastructureLayer/generated/graphql';
import useStore from '@app/domain/store/useStore';
import { absurd } from 'fp-ts/function';
import omit from 'lodash/omit';
import map from 'lodash/map';
import compact from 'lodash/compact';

export type CheckoutData = {
  bonuses: number,
  itemsDiscount: number,
  orderTotal: number,
  totalItemCostForPay: number,
  totalItemsCost: number,
  deliveryInfo?: {
    cityId: string,
    shopId: string,
    deliveryZone: {
      id: string,
      deliveryDurationInMinutes: number,
      deliveryPrice: number,
      minSum: number,
      name: string
    },
  }
};

export type CheckoutError = {
  message: string,
  errors?: {
    errorsForInput?: {
      shopId?: string | null,
      orderItems?: string | null,
      paymentTypeId?: string | null,
      orderTypeId?: string | null,
      date?: string | null,
      time?: string | null,
      roomNumber?: string | null,
      cardId?: string | null,
      comment?: string | null,
      legalEntity?: string | null,
      addressId?: string | null,
      bonuses?: string | null,
      promoCode?: string | null,
    } | null,
    errorsForMenuItems: ({
      index: number,
      value: {
        menuItemId?: string | null,
        amount?: string | null
        modifiers?: string | null
      }
    })[],
  }
};

const useCheckout = () => {
  const {
    appStore: {
      activeMenu,
      checkoutOrderInput,
    },
  } = useStore();

  const isMenuReady = activeMenu?.ready || false;

  const {
    data: rawCheckoutData,
    loading,
  } = useCheckoutQuery({
    variables: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      input: checkoutOrderInput,
    },
    fetchPolicy: 'cache-and-network',
    skip: !checkoutOrderInput || !isMenuReady,
  });

  const res: { data: CheckoutData | null, error: CheckoutError | null } = useMemo(() => {
    if (!checkoutOrderInput) {
      return {
        data: null,
        error: null,
      };
    }

    if (!rawCheckoutData?.checkout) {
      return {
        data: null,
        error: null,
      };
    }

    const type = rawCheckoutData.checkout.__typename;

    switch (type) {
      case 'AddOrderResponseError':
        return {
          data: null,
          error: {
            message: rawCheckoutData.checkout.message,
            errors: undefined,
          },
        };
      case 'AddOrderResponseInputValidationError':
        return {
          data: null,
          error: {
            message: rawCheckoutData.checkout.message,
            errors: {
              errorsForInput: omit(rawCheckoutData.checkout.errors.errorsForInput, '__typename'),
              errorsForMenuItems: compact(map(
                rawCheckoutData.checkout.errors.errorsForMenuItems,
                (errorsForMenuItem) => omit(errorsForMenuItem, '__typename'),
              )),
            },
          },
        };
      case 'CheckoutResponseSuccess': {
        const { checkout } = rawCheckoutData;
        if (!rawCheckoutData.checkout) {
          return {
            data: null,
            error: null,
          };
        }

        return {
          data: {
            ...checkout,
            deliveryInfo: !checkout.deliveryInfo ? undefined : {
              ...checkout.deliveryInfo,
            },
          },
          error: null,
        };
      }
      default:
        absurd(type);
        return {
          data: null,
          error: null,
        };
    }
  }, [rawCheckoutData, checkoutOrderInput]);

  return useMemo(() => ({
    data: res.data,
    error: res.error,
    loading,
  }), [res.data, res.error, loading]);
};

export default useCheckout;
