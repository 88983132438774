import { observer } from 'mobx-react-lite';
import {
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {
  BottomTabBarProps,
  BottomTabNavigationEventMap,
} from '@react-navigation/bottom-tabs/src/types';
import React, { FC, useCallback, useMemo } from 'react';
import type {
  NavigationHelpers,
  ParamListBase,
  TabNavigationState,
} from '@react-navigation/native';
import type { BottomTabDescriptorMap } from '@react-navigation/bottom-tabs/lib/typescript/src/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    height: 50,
    backgroundColor: colors.tabBar.bg,
    justifyContent: 'space-evenly',
  },
  button: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface IProps {
  isFocused: boolean;
  route: TabNavigationState<ParamListBase>['routes'][number],
  descriptors: BottomTabDescriptorMap,
  navigation: NavigationHelpers<ParamListBase, BottomTabNavigationEventMap>;
  // route: NavigationRoute<ParamListBase, string | number>,
}

const TabBarItem: FC<IProps> = (
  {
    isFocused,
    route,
    descriptors,
    navigation,
  },
) => {
  const { options } = descriptors[route.key];

  const onPress = useCallback(() => {
    const event = navigation.emit({
      type: 'tabPress',
      target: route.key,
      canPreventDefault: true,
    });
    if (!isFocused && !event.defaultPrevented) {
      // The `merge: true` option makes sure that the params inside the tab screen are preserved

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //  @ts-ignore
      navigation.navigate({
        name: route.name,
        merge: true,
      });
    }
  }, [isFocused, route.key, route.name, navigation]);

  const accessibilityState = useMemo(() => ({
    selected: isFocused,
  }), [isFocused]);

  return (
    <TouchableOpacity
      accessibilityRole="button"
      accessibilityState={accessibilityState}
      accessibilityLabel={options.tabBarAccessibilityLabel}
      onPress={onPress}
      style={s.button}
      key={route.key}
    >
      {!!options.tabBarIcon && options.tabBarIcon({
        focused: isFocused,
        color: isFocused ? colors.tabBar.tabBarActiveTintColor : colors.tabBar.tabBarInactiveTintColor,
        size: 24,
      })}
    </TouchableOpacity>
  );
};

const TabBar: FC<BottomTabBarProps> = (
  {
    state,
    descriptors,
    navigation,
  },
) => {
  const insets = useSafeAreaInsets();

  return (
    <View
      style={StyleSheet.flatten([s.root, {
        paddingBottom: insets.bottom,
        height: s.root.height + insets.bottom,
      }])}
    >
      {state.routes.map((route, index) => {
        const isFocused = state.index === index;

        return (
          <TabBarItem
            key={route.key}
            isFocused={isFocused}
            route={route}
            descriptors={descriptors}
            navigation={navigation}
          />
        );
      })}
    </View>
  );
};

export default observer(TabBar);
