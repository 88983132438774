import {
  View, Text, StyleSheet,
} from 'react-native';
import React, {
  FC,
  useCallback,
  useEffect,
} from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import { AuthStackScreenProps } from '@navigation/types';
import { useUpdateMyProfileMutation } from '@app/infrastructureLayer/generated/graphql';
import Back from '@components/layout/Back';
import Screen from '@components/layout/Screen';
import crossAlert from '@utils/crossAlert';
import useStore from '@app/domain/store/useStore';
import { observer } from 'mobx-react-lite';
import useProfileSync from '@hooks/useProfileSync/useProfileSync';
import RegistrationForm, { RegistrationFormValues } from '@widgets/form/RegistrationForm/RegistrationForm';
import ubuntuFont from '@constants/ubuntuFont';

const s = StyleSheet.create({
  scroll: {
    flexGrow: 1,
  },
  header: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
    marginTop: 40,
  },
  root: {
    flex: 1,
    padding: 16,
  },
});

const RegistrationScreen: FC<AuthStackScreenProps<'RegistrationScreen'>> = () => {
  const navigation = useNavigation();
  const { refetchProfile } = useProfileSync();
  const [updateMyProfile] = useUpdateMyProfileMutation();

  useFocusEffect(useCallback(() => {
    refetchProfile();
  }, [refetchProfile]));

  const {
    profileStore: {
      profile,
    },
  } = useStore();

  const isRegistered = profile?.registrationCompleted;

  const isFocused = useIsFocused();

  useEffect(() => {
    if (isRegistered && isFocused) {
      navigation.navigate('Tabs', {
        screen: 'ProfileScreen',
      });
    }
  }, [isRegistered, navigation, isFocused]);

  const onSave = useCallback(async (data: RegistrationFormValues) => {
    const { broadcastOfferAccepted = true, ...rest } = data;

    const res = await updateMyProfile({
      variables: {
        input: {
          broadcastOfferAccepted,
          ...rest,
        },
      },
    });

    await refetchProfile();

    if (res.data?.updateMyProfile.resultCode !== 'SUCCESS') {
      crossAlert('Ошибка при сохранении данных, попробуйте ещё раз позже.');
    }
  }, [refetchProfile, updateMyProfile]);

  const onBack = useCallback(() => {
    navigation.navigate('IndexScreen');
  }, [navigation]);

  return (
    <Screen>
      <Back title="Назад" onPress={onBack} />
      <KeyboardAwareScrollView
        bounces={false}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={s.scroll}
      >
        <View style={s.root}>
          <Text style={s.header}>Регистрация</Text>
          <RegistrationForm
            onSave={onSave}
            initialFio={profile?.fio || ''}
          />
        </View>

      </KeyboardAwareScrollView>
    </Screen>
  );
};

export default observer(RegistrationScreen);
