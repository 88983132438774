import React, { FC } from 'react';
import Svg, { Path } from 'react-native-svg';
import colors from '@constants/colors';

interface IProps {
  color?: string;
}

const XSvg: FC<IProps> = ({ color = colors.white }) => (
  <Svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="none"
  >
    <Path
      d="M2.82837 3.17139L8.48522 8.82824"
      stroke={color}
      strokeWidth={1.2}
      strokeLinecap="round"
    />
    <Path
      d="M2.82837 8.82861L8.48522 3.17176"
      stroke={color}
      strokeWidth={1.2}
      strokeLinecap="round"
    />
  </Svg>
);

export default XSvg;
