import { PickerValue } from 'react-mobile-picker';

export const HOURS = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'] as const;
export const MINUTES = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'] as const;

export interface WheelTimePickerValue extends PickerValue {
  hour: typeof HOURS[number],
  minutes: typeof MINUTES[number],
}

export type TimeSlots = Map<(typeof HOURS[number]), (typeof MINUTES[number])[]>;

export interface WheelTimePickerProps {
  timeSlots: TimeSlots,
  initialHours?: typeof HOURS[number],
  initialMinutes?: typeof MINUTES[number],
  onChange: (value: WheelTimePickerValue) => void;
}
