import {
  View, Text, StyleSheet, ScrollView, ActivityIndicator,
} from 'react-native';
import React, { FC, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import Screen from '@components/layout/Screen';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import Back from '@components/layout/Back';
import HistoryRow from '@screens/OrdersHistoryScreen/includes/HistoryRow';
import { useGetMyOrdersQuery } from '@app/infrastructureLayer/generated/graphql';
import { RootStackScreenProps } from '@navigation/types';
import moment from 'moment-timezone';

const s = StyleSheet.create({
  scroll: {
    flex: 1,
    paddingHorizontal: 10,
  },
  header: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
    marginBottom: 15,
    marginTop: 5,
  },
  noOrders: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
    textAlign: 'center',
  },
  scrollContent: {
    gap: 10,
    paddingBottom: 20,
  },
});

const OrdersHistoryScreen: FC<RootStackScreenProps<'OrdersHistoryScreen'>> = () => {
  const title = 'История заказов';
  const navigation = useNavigation();

  const { data: ordersData, loading } = useGetMyOrdersQuery({
    variables: {
      sort: ['createdAt:desc'],
    },
    pollInterval: 1000,
  });

  const onRowPress = useCallback((orderId: string) => {
    navigation.navigate('OrderScreen', {
      orderId,
    });
  }, [navigation]);

  const orders = ordersData?.myOrders.data || [];

  return (
    <Screen>
      <Back title="Назад" />
      <View style={s.scroll}>
        <Text style={s.header}>{title}</Text>

        {loading && (
          <ActivityIndicator color={colors.text} />
        )}
        {!loading && (
          <>
            {orders.length === 0 && (
              <Text style={s.noOrders}>
                Ещё нет заказов
              </Text>
            )}
            {/* TODO: Заменить на Flatlist c подгрузкой по сколлу */}
            <ScrollView contentContainerStyle={s.scrollContent}>
              {orders.map((order) => (
                <HistoryRow
                  key={order.id}
                  orderId={order.id}
                  header={`${order.orderType?.title}`}
                  shopTitle={order.shop?.title || ''}
                  status={order.status}
                  date={`${moment(order.date)
                    .format('DD.MM.YYYY')} в ${moment(order.time, 'HH:mm:ss')
                    .format('HH:mm')}`}
                  total={order.total}
                  onPress={onRowPress}
                />
              ))}
            </ScrollView>
          </>
        )}
      </View>
    </Screen>
  );
};

export default observer(OrdersHistoryScreen);
