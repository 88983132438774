import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  StyleSheet,
} from 'react-native';
import {
  YMaps, Map, Placemark,
} from 'react-yandex-maps';
import { observer } from 'mobx-react-lite';

import { getYandexMapKey } from '@utils/env';
import useStore from '@app/domain/store/useStore';
import ShopData from '@app/domain/store/CoreStore/AppStore/entities/ShopData';

type MapObj = {
  [key: string]: any
};

const center = {
  latitude: 55.02654979293527,
  longitude: 82.91569424845933,
};

type IProp = {
  mapHeight: number,
  setShopId?: (value: string) => void,
  forSelectedCity?: boolean,
};

const MapWeb = ({
  mapHeight, setShopId, forSelectedCity,
}: IProp) => {
  const styleSheet = (height: number) => {
    const s = StyleSheet.create({
      map: {
        width: '100%',
        minHeight: height,
        flex: 1,
        marginBottom: 10,
      },
    });
    return s;
  };

  const s = useMemo(() => styleSheet(mapHeight), [mapHeight]);

  const store = useStore();
  const [map, setMap] = useState<MapObj>({});
  const [mapReady, setMapReady] = useState(false);
  const [placemark, setPlacemark] = useState(null);

  const setMapBounds = useCallback(() => {
    map.setBounds(map.geoObjects.getBounds(), { checkZoomRange: true })
      .then(() => { if (map.getZoom() > 20) map.setZoom(17); });
  }, [map]);

  useEffect(() => {
    if (map.geoObjects && map.geoObjects.getBounds()) {
      setMapBounds();
    }
  }, [map, placemark, setMapBounds]);

  useEffect(() => {
    if (mapReady && setShopId) {
      map.geoObjects.events.add('click', (e: any) => {
        setShopId(e.get('target').properties._data.id);
      });
      map.container.events.add('sizechange', () => {
        setMapBounds();
      });
    }
  }, [setShopId, map, mapReady, setMapBounds]);

  const shopsData = useMemo(
    () => (forSelectedCity
      ? store.appStore.shopsDataForCurrentCityForMap
      : store.appStore.shopsDataForMap),
    [forSelectedCity, store.appStore.shopsDataForCurrentCityForMap, store.appStore.shopsDataForMap],
  );

  const apikey = useMemo(() => (
    {
      apikey: getYandexMapKey(),
    }
  ), []);

  const defaultState = useMemo(() => (
    {
      center: [center.latitude, center.longitude],
      zoom: 6,
      controls: ['zoomControl'],
    }
  ), []);

  const modulesMap = useMemo(() => (
    ['control.ZoomControl']
  ), []);

  const modulesPlacemark = useMemo(() => (
    ['geoObject.addon.hint']
  ), []);

  const geometry = useCallback(({ latitude, longitude }: { latitude: number, longitude: number }) => (
    [latitude, longitude]
  ), []);

  const placemarkProps = useCallback((shop: ShopData) => (
    {
      hintContent: shop.address,
      id: shop.id,
    }
  ), []);

  const instanceRefMap = useCallback((mapObj: any) => {
    setMap(mapObj);
  }, []);

  const instanceRefPlacemark = useCallback((placemarkData: any) => {
    setPlacemark(placemarkData);
  }, []);

  const handleMapReady = useCallback(() => {
    setMapReady(true);
  }, []);

  return (
    <YMaps query={apikey}>
      <Map
        style={s.map}
        defaultState={defaultState}
        modules={modulesMap}
        instanceRef={instanceRefMap}
        onLoad={handleMapReady}
      >
        {
          shopsData.map((shop, i) => (
            <Placemark
              key={i}
              geometry={geometry(shop)}
              modules={modulesPlacemark}
              properties={placemarkProps(shop)}
              instanceRef={instanceRefPlacemark}
            />
          ))
        }
      </Map>
    </YMaps>
  );
};

export default observer(MapWeb);
