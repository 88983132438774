import React, {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Pressable,
  ScrollView,
  StyleSheet, useWindowDimensions,
  View,
  Text,
  Dimensions,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
  useScrollHandlers,
} from 'react-native-actions-sheet';
import { observer } from 'mobx-react-lite';
import SheetSelectItem from '@components/common/SheetSelectItem';
import useStore from '@app/domain/store/useStore';
import ListSvg from '@svg/ListSvg';
import Button from '@components/common/Button';
import colors from '@constants/colors';
import MapSvg from '@svg/MapSvg';
import Map from '@components/Map/Map';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 20,
  },
  scrollview: {
    width: '100%',
    paddingBottom: 40,
  },
  shopwMap: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 'auto',
    marginBottom: 20,
    marginTop: 20,
  },
  shopwMapPressed: {
    opacity: 0.2,
  },
  showMapText: {
    color: colors.grey,
    marginLeft: 5,
  },
  displayNone: {
    display: 'none',
  },
});

// const snapPoints = [50, 100];
const snapPoints = [100];

const SelectCitySheet: FC<SheetProps> = ({ sheetId }) => {
  const { height } = useWindowDimensions();
  const actionSheetRef = useRef<ActionSheetRef>(null);
  const scrollHandlers = useScrollHandlers<ScrollView>(sheetId, actionSheetRef);
  const [showMap, setShowMap] = useState(false);

  const {
    appStore: {
      selectedShopId,
      setSelectedShopId,
      shopsForSelectedCity,
      globalSettings,
    },
  } = useStore();

  const [shopId, setShopId] = useState(selectedShopId);

  useEffect(() => {
    setShopId(selectedShopId);
  }, [selectedShopId]);

  const onConfirm = useCallback(() => {
    setSelectedShopId(shopId);
    actionSheetRef.current?.hide();
  }, [setSelectedShopId, shopId]);

  const isHotelMode = globalSettings?.isHotelMode || false;

  const onPressToggleMap = useCallback(() => setShowMap((value) => !value), []);

  const pressableStyle = useCallback(({ pressed }: { pressed: boolean }) => [
    s.shopwMap,
    pressed && s.shopwMapPressed,
  ], []);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled={!showMap}
      defaultOverlayOpacity={0.3}
    >
      <View style={s.root}>

        <ScrollView
          {...scrollHandlers}
          style={showMap ? s.displayNone : StyleSheet.flatten([s.scrollview, { maxHeight: height * 0.5 }])}
          alwaysBounceVertical={false}
        >
          {shopsForSelectedCity.map((shop, index) => (
            <SheetSelectItem
              key={shop.id}
              id={shop.id}
              title={shop.title}
              onSelect={setShopId}
              subtitle={isHotelMode ? shop.workTimeTitle : `${shop.address}\n${shop.workTimeTitle}`}
              isSelected={shop.id === shopId}
              isLast={index + 1 === shopsForSelectedCity.length}
            />
          ))}
        </ScrollView>

        <ScrollView style={!showMap && s.displayNone}>
          <Map mapHeight={Dimensions.get('window').height / 2} setShopId={setShopId} forSelectedCity />
          {shopsForSelectedCity.filter((shop) => shopId === shop.id)
            .map((shop) => shopId === shop.id && (
              <SheetSelectItem
                key={shop.id}
                id={shop.id}
                title={shop.title}
                onSelect={setShopId}
                subtitle={isHotelMode ? shop.workTimeTitle : `${shop.address}\n${shop.workTimeTitle}`}
                isSelected={shop.id === shopId}
                isLast
              />
            ))}
        </ScrollView>

        <Pressable
          style={pressableStyle}
          onPress={onPressToggleMap}
        >
          {
            showMap && <ListSvg />
          }
          {
            !showMap && <MapSvg />
          }
          <Text style={s.showMapText}>{showMap ? 'Вернуться к списку' : 'Выбрать на карте'}</Text>
        </Pressable>
        <Button
          onPress={onConfirm}
          title="Подтвердить"
        />
      </View>
    </ActionSheet>
  );
};

export default observer(SelectCitySheet);
