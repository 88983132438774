import React, {
  FC,
  useCallback,
} from 'react';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import FullScreenLoading from '@components/common/FullScreenLoading';

const GoToProfile: FC = () => {
  const navigation = useNavigation();

  useFocusEffect(useCallback(() => {
    navigation.navigate('Tabs', {
      screen: 'ProfileScreen',
    });
  }, [navigation]));

  return <FullScreenLoading />;
};

export default observer(GoToProfile);
