import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { absurd } from 'fp-ts/function';
import type { ProductProductParamsComponent } from '@app/infrastructureLayer/generated/Api';

export type ProductProductParamsType = Exclude<ProductProductParamsComponent['type'], undefined>;

export const ProductParam = t
  .model('ProductParam', {
    id: t.identifier,
    value: t.number,
    type: t.frozen<ProductProductParamsType>(),
  })
  .views((self) => ({
    get unit() {
      switch (self.type) {
        case 'CALORIES':
          return 'ккал.';
        case 'FAT':
        case 'CARBOHYDRATE':
        case 'PROTEIN':
          return 'г.';
        case 'VOLUME':
          return 'мл.';
        case 'WEIGHT':
          return 'г.';
        default:
          absurd(self.type);
          return '';
      }
    },
    get title() {
      switch (self.type) {
        case 'CALORIES':
          return 'Калории';
        case 'FAT':
          return 'Жиры';
        case 'CARBOHYDRATE':
          return 'Углеводы';
        case 'PROTEIN':
          return 'Белки';
        case 'VOLUME':
          return 'Объём';
        case 'WEIGHT':
          return 'Вес';
        default:
          absurd(self.type);
          return '';
      }
    },
  }));

export interface ProductParamInstance extends Instance<typeof ProductParam> {
}

export interface ProductParamSnapshotIn extends SnapshotIn<typeof ProductParam> {
}

export interface ProductParamSnapshotOut extends SnapshotOut<typeof ProductParam> {
}
