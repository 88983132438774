import { observer } from 'mobx-react-lite';
import React, { FC, useCallback } from 'react';
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import colors from '@constants/colors';
import Input from '@components/form/Input';
import Block from '@components/layout/Block';
import GreyBlock from '@components/layout/GreyBlock';
import ubuntuFont from '@constants/ubuntuFont';
import useStore from '@app/domain/store/useStore';
// import ArrowRightSvg from '@svg/ArrowRightSvg';
import { SheetManager } from 'react-native-actions-sheet';
import { computed } from 'mobx';
import { Enum_Componentuserdeliveryaddress_Type } from '@app/infrastructureLayer/generated/graphql';
import Button from '@components/common/Button';
import { useFocusEffect } from '@react-navigation/native';

import addressSchema from './addressSchema';
import AddressCategory from '../AddressCategory';

const s = StyleSheet.create({
  root: {
    backgroundColor: colors.transparent,
    gap: 10,
  },
  required: {
    fontFamily: ubuntuFont.light,
    color: colors.error,
    fontSize: 12,
  },
  horizontal: {
    flex: 1,
    flexDirection: 'row',
    gap: 10,
  },
  blockCategory: {
    marginTop: 10,
    marginBottom: 5,
  },
  half: {
    flex: 0.5,
  },
  rowText: {
    fontFamily: ubuntuFont.light,
    fontSize: 14,
    color: colors.text,
  },
  categoryTitle: {
    fontFamily: ubuntuFont.light,
    fontSize: 12,
    color: colors.text,
    paddingBottom: 5,
  },
  rowTitle: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
    paddingBottom: 2,
  },
  cityTitleRow: {
    flex: 1,
  },
  // right: {
  //   gap: 10,
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // },
  scrollTypes: {},
  types: {
    flexDirection: 'row',
    gap: 10,
    paddingBottom: 10,
  },
  block: {
    gap: 10,
  },
  rootContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  cityRootPresable: {
    marginBottom: 10,
  },
  cityRoot: {
    flexDirection: 'row',
  },
});

const placeholders = Object.freeze({
  city: 'Город',
  address: 'Улица и дом',
  room: 'Квартира',
  entrance: 'Подъезд',
  floor: 'Этаж',
  intercomCode: 'Код домофона',
  comment: 'Комментарий для курьера',
  title: 'Название',
});

const typeValues = [
  Enum_Componentuserdeliveryaddress_Type.Home,
  Enum_Componentuserdeliveryaddress_Type.Work,
  Enum_Componentuserdeliveryaddress_Type.Other,
] as const;

export type MyDeliveryAddressFormValues = {
  cityId: string,
  address: string,
  title: string,
  room?: string,
  entrance?: string,
  floor?: string,
  intercomCode?: string,
  comment?: string,
  type?: Enum_Componentuserdeliveryaddress_Type,
};

type IProps = {
  addressId?: string;
  formInitialState: Partial<Omit<MyDeliveryAddressFormValues, 'cityId' | 'address'>> & {
    cityId: string,
    address: string,
  },
  onSave: (values: MyDeliveryAddressFormValues) => Promise<void>
};

const AddressForm: FC<IProps> = (
  {
    addressId,
    formInitialState,
    onSave,
  },
) => {
  const { appStore: { cities } } = useStore();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    reset,
    formState: {
      isSubmitting,
      isValid,
    },
  } = useForm<MyDeliveryAddressFormValues>({
    mode: 'all',
    defaultValues: formInitialState,
    resolver: zodResolver(addressSchema),
  });

  useFocusEffect(useCallback(() => {
    reset(formInitialState);
  }, [formInitialState, reset]));

  const values = getValues();

  const onSubmit: SubmitHandler<MyDeliveryAddressFormValues> = useCallback(async (data) => {
    await onSave(data);
  }, [onSave]);

  const city = computed(() => {
    if (!values.cityId) {
      return undefined;
    }

    return cities.get(values.cityId);
  })
    .get();

  const onPressCity = useCallback(async () => {
    const responsePayload: { cityId: string } | undefined = await SheetManager.show('select-address-city-sheet', {
      payload: { cityId: values.cityId },
    });

    const newCityId = responsePayload?.cityId;
    if (newCityId) {
      setValue('cityId', newCityId);
    }
    await trigger('cityId');
  }, [setValue, values.cityId, trigger]);

  const onSelectType = useCallback(async (newType?: Enum_Componentuserdeliveryaddress_Type) => {
    setValue('type', newType);
    await trigger('type');
  }, [setValue, trigger]);

  const onPressSave = useCallback(() => handleSubmit(onSubmit)(), [handleSubmit, onSubmit]);

  return (
    <View style={s.rootContainer}>
      <ScrollView>
        <Pressable onPress={onPressCity} style={s.cityRootPresable} disabled>
          <GreyBlock style={s.cityRoot}>
            <View style={s.cityTitleRow}>
              <Text style={s.rowTitle}>
                Город
                <Text style={s.required}>
                  {' '}
                  *
                </Text>
              </Text>
              <Text style={s.rowText}>
                {!city && 'Укажите город'}
                {city?.title}
              </Text>
            </View>
            {/* <View style={s.right}> */}
            {/*   <ArrowRightSvg */}
            {/*     color={colors.text} */}
            {/*   /> */}
            {/* </View> */}
          </GreyBlock>
        </Pressable>

        <Block style={s.block}>
          <View style={s.root}>
            <Controller
              control={control}
              name="title"
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              render={(
                {
                  field: {
                    ref,
                    onChange,
                    onBlur,
                    value,
                    disabled,
                  },
                  fieldState: {
                    error,
                  },
                },
              ) => (
                <Input
                  ref={ref}
                  editable={!disabled}
                  label={placeholders.title}
                  type="text"
                  placeholder={placeholders.title}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  value={value}
                  errorMessage={error?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="address"
              disabled
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              render={(
                {
                  field: {
                    ref,
                    onChange,
                    onBlur,
                    value,
                    disabled,
                  },
                  fieldState: {
                    error,
                  },
                },
              ) => (
                <Input
                  ref={ref}
                  editable={!disabled}
                  required
                  onBlur={onBlur}
                  label={placeholders.address}
                  type="text"
                  placeholder={placeholders.address}
                  value={value}
                  onChangeText={onChange}
                  errorMessage={error?.message}
                />
              )}
            />

            <View style={s.horizontal}>
              <View style={s.half}>
                <Controller
                  control={control}
                  name="room"
                  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                  render={(
                    {
                      field: {
                        ref,
                        onChange,
                        onBlur,
                        value,
                        disabled,
                      },
                      fieldState: {
                        error,
                      },
                    },
                  ) => (
                    <Input
                      ref={ref}
                      editable={!disabled}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      onChangeText={onChange}
                      label={placeholders.room}
                      type="text"
                      placeholder={placeholders.room}
                      value={value}
                    />
                  )}
                />
              </View>

              <View style={s.half}>
                <Controller
                  control={control}
                  name="entrance"
                  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                  render={(
                    {
                      field: {
                        ref,
                        onChange,
                        onBlur,
                        value,
                        disabled,
                      },
                      fieldState: {
                        error,
                      },
                    },
                  ) => (
                    <Input
                      ref={ref}
                      editable={!disabled}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      label={placeholders.entrance}
                      type="text"
                      placeholder={placeholders.entrance}
                      value={value}
                      onChangeText={onChange}
                    />
                  )}
                />
              </View>
            </View>

            <View style={s.horizontal}>
              <View style={s.half}>
                <Controller
                  control={control}
                  name="floor"
                  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                  render={(
                    {
                      field: {
                        ref,
                        onChange,
                        onBlur,
                        value,
                        disabled,
                      },
                      fieldState: {
                        error,
                      },
                    },
                  ) => (
                    <Input
                      ref={ref}
                      editable={!disabled}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      label={placeholders.floor}
                      type="text"
                      placeholder={placeholders.floor}
                      value={value}
                      onChangeText={onChange}
                    />
                  )}
                />
              </View>

              <View style={s.half}>
                <Controller
                  control={control}
                  name="intercomCode"
                  // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                  render={(
                    {
                      field: {
                        ref,
                        onChange,
                        onBlur,
                        value,
                        disabled,
                      },
                      fieldState: {
                        error,
                      },
                    },
                  ) => (
                    <Input
                      ref={ref}
                      editable={!disabled}
                      onBlur={onBlur}
                      errorMessage={error?.message}
                      label={placeholders.intercomCode}
                      type="text"
                      placeholder={placeholders.intercomCode}
                      value={value}
                      onChangeText={onChange}
                    />
                  )}
                />
              </View>
            </View>

            <Controller
              control={control}
              name="comment"
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              render={(
                {
                  field: {
                    ref,
                    onChange,
                    onBlur,
                    value,
                    disabled,
                  },
                  fieldState: {
                    error,
                  },
                },
              ) => (
                <Input
                  ref={ref}
                  editable={!disabled}
                  onBlur={onBlur}
                  errorMessage={error?.message}
                  label={placeholders.comment}
                  type="text"
                  multiline
                  placeholder={placeholders.comment}
                  value={value}
                  onChangeText={onChange}
                />
              )}
            />
          </View>
        </Block>

        <View style={s.blockCategory}>
          <Text style={s.categoryTitle}>Категория</Text>
          <ScrollView
            horizontal
            style={s.scrollTypes}
            contentContainerStyle={s.types}
          >
            {typeValues
              .map((type) => (
                <AddressCategory
                  key={type}
                  type={type}
                  selected={type === values.type}
                  onSelect={onSelectType}
                  color={type === values.type ? colors.white : colors.text}
                />
              ))}
          </ScrollView>
        </View>
      </ScrollView>
      <Button
        title={addressId ? 'Сохранить' : 'Добавить'}
        onPress={onPressSave}
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
      />
    </View>
  );
};

export default observer(AddressForm);
