import { observer } from 'mobx-react-lite';
import useCurrentLocation from '@hooks/useCurrentLocation';
import React, { useMemo } from 'react';
import { ActivityIndicator } from 'react-native';
import colors from '@constants/colors';
import SelectDeliveryAddressOnMap
  from '@screens/SelectDeliveryAddressScreen/includes/SelectDeliveryAddressOnMap/SelectDeliveryAddressOnMap';

// TODO: Как-то задавать центр по умолчанию
const defaultCenter = {
  latitude: 55.02654979293527,
  longitude: 82.91569424845933,
};

const SelectDeliveryAddressOnMapHOC = () => {
  const currentLocation = useCurrentLocation();

  const center = useMemo(() => {
    if (currentLocation.status === 'success') {
      return {
        latitude: currentLocation.location.coords.latitude,
        longitude: currentLocation.location.coords.longitude,
      };
    }

    return null;
  }, [currentLocation.location?.coords.latitude, currentLocation.location?.coords.longitude, currentLocation.status]);

  if (currentLocation.status === 'waiting') {
    return <ActivityIndicator color={colors.primary} />;
  }

  if (currentLocation.status === 'error') {
    return <SelectDeliveryAddressOnMap currentLocation={defaultCenter} />;
  }

  if (currentLocation.status === 'success' && center) {
    return <SelectDeliveryAddressOnMap currentLocation={center} />;
  }

  return null;
};

export default observer(SelectDeliveryAddressOnMapHOC);
