import {
  addDisposer,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import {
  ShopWorkTimeItemTimed,
} from '@app/domain/store/CoreStore/AppStore/entities/ShopWorkTime/ShopWorkTimeItemTimed';
import {
  ShopWorkTimeItemDayOff,
} from '@app/domain/store/CoreStore/AppStore/entities/ShopWorkTime/ShopWorkTimeItemDayOff';
import {
  ShopWorkTimeItemAroundAClock,
} from '@app/domain/store/CoreStore/AppStore/entities/ShopWorkTime/ShopWorkTimeItemAroundAClock';
import moment from 'moment-timezone';
import dayNumberToDayCode from '@utils/mappers/dayNumberToDayCode';
import getDeliveryDateTitle from '@components/sheets/SelectOrderDateTimeSheet/getDeliveryDateTitle';
import {
  HOURS,
  MINUTES,
  TimeSlots,
} from '@components/WheelTimePicker/types';

type ShopSlotByDate = {
  time: moment.Moment,
};

export const ShopWorkTime = t
  .model('ShopWorkTime', {
    shopId: t.identifier,
    timezone: t.string,
    minOrderDelayInMinutes: t.refinement(t.number, (value) => value >= 0),
    // Шаг в минутах между выбором ближайшее время
    asapStepInMinutes: t.refinement(t.number, (value) => value >= 5),
    // Первый заказ могут отдать раньше. Например minOrderDelayInMinutes = 30м, а firstOrderDelayInMinutes = 10м.
    // Магазин открывается в 7:30, первые заказы смогут принять на 7:40.
    // TODO: Пока что не учитывается, надо учесть
    firstOrderDelayInMinutes: t.refinement(t.number, (value) => value >= 0),
    items: t.optional(
      t.array(t.union(ShopWorkTimeItemTimed, ShopWorkTimeItemDayOff, ShopWorkTimeItemAroundAClock)),
      [],
    ),
    now: t.optional(t.frozen<moment.Moment>(), moment()),
  })
  .views((self) => ({
    getSlotsByDay(day: moment.Moment) {
      // Найдём как работает сегодня магазин
      const dayOfWeek = day.weekday();
      const dayCode = dayNumberToDayCode(dayOfWeek);

      if (!dayCode) {
        return false;
      }

      const item = self.items.find((x) => x.day === dayCode);

      if (!item) {
        return false;
      }

      if (item.type === 'ShopWorkTimeItemDayOff') {
        return false;
      }

      if (item.type === 'ShopWorkTimeItemAroundAClock' || item.type === 'ShopWorkTimeItemTimed') {
        const startOfDay = day.startOf('day')
          .clone();
        const slots: ShopSlotByDate[] = [];
        let isEnd = false;

        const dayInFormatText = day.format('DD.MM.YYYY');

        while (!isEnd) {
          const tick = startOfDay.add(self.asapStepInMinutes, 'minutes');

          const isInclude = tick.format('DD.MM.YYYY') === dayInFormatText;

          if (!isInclude) {
            isEnd = true;
            break;
          }

          slots.push({
            time: tick.clone(),
          });
        }

        if (item.type === 'ShopWorkTimeItemAroundAClock') {
          return slots;
        }

        const minDateTime = moment(`${day.format('DD.MM.YYYY')} ${item.startTime}`, 'DD.MM.YYYY HH:mm:ss');
        const maxDateTime = moment(`${day.format('DD.MM.YYYY')} ${item.endTime}`, 'DD.MM.YYYY HH:mm:ss');

        const minDateTimeUnix = minDateTime.unix();
        const maxDateTimeUnix = maxDateTime.unix();

        return slots.filter((slot) => {
          const u = slot.time.unix();

          return (
            u >= minDateTimeUnix
            && u <= maxDateTimeUnix
          );
        });
      }

      return false;
    },
  }))
  .views((self) => ({
    getAvailableSlotsByDay(
      {
        orderDate,
        now,
      }: {
        orderDate: moment.Moment
        now: moment.Moment
      },
    ) {
      const allSlots = self.getSlotsByDay(orderDate);

      if (!allSlots) {
        return false;
      }

      return allSlots.filter((item) => (
        item.time.unix() >= now.unix() + 60 * self.minOrderDelayInMinutes
      ));
    },
  }))
  .views((self) => ({
    get availableSlotsForWeek() {
      let day = moment()
        .startOf('day');

      const res = new Map<string, ShopSlotByDate[] | false>();

      for (let i = 0; i < 7; i++) {
        const slots = self.getAvailableSlotsByDay({
          orderDate: day,
          now: self.now,
        });

        res.set(day.format('DD.MM.YYYY'), slots);

        day = day.clone()
          .add('1', 'day');
      }

      return res;
    },
    get asapTimeOptions(): false | {
      index: number, // 0,1,2,3...
      value: number, // unix
      title: string
    }[] {
      const slots = self.getAvailableSlotsByDay({
        orderDate: moment(),
        now: self.now,
      });

      if (!slots) {
        return false;
      }

      const x = Array.from(slots)
        .slice(0, 3);

      return x.map((item, index) => {
        const distanceInMinutes = self.minOrderDelayInMinutes + self.asapStepInMinutes * index;
        const distanceInMinutesFromNow = Math.ceil((item.time.unix() - self.now.unix()) / 60);

        return ({
          index,
          value: item.time.unix(),
          title: distanceInMinutesFromNow - self.asapStepInMinutes > distanceInMinutes ? item.time.format('HH:mm') : `через ${distanceInMinutes} минут`,
        });
      });
    },
  }))
  .views((self) => ({
    get availableSlotsDaysForWeek() {
      const days: {
        day: string,
        title: string
      }[] = [];

      self.availableSlotsForWeek.forEach((slots, key) => {
        if (slots) {
          days.push({
            day: key,
            title: getDeliveryDateTitle(key),
          });
        }
      });

      return days;
    },
  }))
  .actions((self) => ({
    setNow: (value: moment.Moment) => {
      self.now = value;
    },
  }))
  .views((self) => ({
    get timeSlots() {
      const result = new Map<string, TimeSlots>();

      self.availableSlotsForWeek.forEach((slots, key) => {
        if (slots && slots.length) {
          const hoursMinutes: TimeSlots = new Map();

          slots.forEach((x) => {
            const h = x.time.format('HH') as typeof HOURS[number];
            const m = x.time.format('mm') as typeof MINUTES[number];

            const item = hoursMinutes.get(h) || [];
            item.push(m);

            hoursMinutes.set(h, item);
          });

          result.set(key, hoursMinutes);

          // console.log('hoursMinutes', hoursMinutes);
        }
      });

      return result;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      addDisposer(self, (() => {
        self.setNow(moment());
        // Раз в 10 секунд перерасчёт слотов
        const interval = setInterval(() => {
          const newNow = moment();
          // console.info('reaction Раз в 10 секунд перерасчёт слотов', newNow.format());
          self.setNow(newNow);
        }, 10000);

        return () => {
          if (interval) {
            clearInterval(interval);
          }
        };
      })());
    },
  }));

export interface ShopWorkTimeInstance extends Instance<typeof ShopWorkTime> {
}

export interface ShopWorkTimeSnapshotIn extends SnapshotIn<typeof ShopWorkTime> {
}

export interface ShopWorkTimeSnapshotOut extends SnapshotOut<typeof ShopWorkTime> {
}
