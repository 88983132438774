import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
  getParent,
  destroy,
} from 'mobx-state-tree';
import generalConfig from '@constants/generalConfig';
import type { AppStoreInstance } from '@app/domain/store/CoreStore/AppStore/AppStore';
import type { MenuItemInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuItem';
import { CartItemModifier } from '@app/domain/store/CoreStore/AppStore/entities/Cart/CartItemModifier';
import { v4 as uuidv4 } from 'uuid';
import type { ProductInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Product';

export const getCartItemKey = (
  {
    productId,
    modifiers,
  }: {
    productId: string,
    modifiers?: {
      [key: string]: number
    };
  },
): string => {
  if (!modifiers || Object.keys(modifiers).length === 0) {
    return productId;
  }

  const modifiersKeys = Object.keys(modifiers)
    .sort();

  const modifiersKeyHash = modifiersKeys.map((key) => `${key}:${modifiers[key]}`)
    .join('|');

  return `${productId}_${modifiersKeyHash}`;
};

export const CartItem = t
  .model('CartItem', {
    id: t.identifier,
    productId: t.string,
    amount: t.refinement(t.number, (value) => value > 0),
    modifiers: t.optional(t.array(CartItemModifier), []),
  })
  .views((self) => ({
    get menuItem() {
      const parent: AppStoreInstance = getParent(self, 2);

      if (!parent.activeMenu) {
        return undefined;
      }

      const activeMenuItems: MenuItemInstance[] = [...parent.activeMenu.menuItems.values()];

      return activeMenuItems
        .find((item) => item.product?.id === self.productId);
    },
    get product(): ProductInstance | undefined {
      // any стоит т.к. если прописать AppStoreInstance то TS сходит с ума
      const parent: any = getParent(self, 2);

      return parent.products.get(self.productId);
    },
  }))
  .views((self) => ({
    get haveNotExistedModifiers() {
      if (!self.modifiers.length) {
        return false;
      }

      // Когда модификатора нет, но есть элемент в корзине
      return self.modifiers.some((m) => !m.modifier);
    },
    get haveNotAvailableModifiers() {
      // Когда нет модификатора на витрине или он обозначен как недоступны, но есть элемент в корзине
      return self.modifiers.some((m) => !m.menuModifierItem?.isAvailable);
    },
    get hasUnknownChildModifiers() {
      const cartItemModifiers = self.modifiers;
      const menuItemModifiers = self.menuItem?.groupModifiers;

      if (!menuItemModifiers) {
        return true;
      }

      const cartItemModifierCategoryIds: string[] = [];
      cartItemModifiers.forEach((modifier) => {
        menuItemModifiers.forEach((gm) => {
          const item = gm.childModifiers.find((cm) => (
            cm.modifierId === modifier.modifierId
          ));

          if (item) {
            cartItemModifierCategoryIds.push(gm.modifierCategoryId);
          }
        });
      });

      let notFoundRequiredModifiers = false;
      let notFoundMinimumGroupModifiers = false;
      let hasMoreGroupModifiersThanMaxAmount = false;

      menuItemModifiers.forEach((menuMod) => {
        if (!notFoundRequiredModifiers) {
          const isOneRequired = menuMod.minAmount === 1 && menuMod.maxAmount === 1;
          if (isOneRequired) {
            notFoundRequiredModifiers = !cartItemModifierCategoryIds.some((v) => (
              v === `${menuMod.modifierCategoryId}`
            ));
          }
        }

        if (!notFoundMinimumGroupModifiers && !hasMoreGroupModifiersThanMaxAmount) {
          const amountInGroup = menuMod.childModifiers.reduce((sum, cm) => {
            const cItem = cartItemModifiers.find((v) => v.modifierId === cm.modifierId);
            const cmAmount = !cItem ? 0 : cItem.amount;

            return sum + cmAmount;
          }, 0);

          if (menuMod.minAmount > 0 && amountInGroup < menuMod.minAmount) {
            notFoundMinimumGroupModifiers = true;
          }

          if (menuMod.maxAmount > 0 && amountInGroup > menuMod.maxAmount) {
            hasMoreGroupModifiersThanMaxAmount = true;
          }
        }
      });

      let hasUnknownChildModifiers = false;
      let notFoundMinimumChildModifiers = false;
      let hasMoreChildModifiersThanMaxAmount = false;

      if (!cartItemModifiers || !cartItemModifiers.length) {
        menuItemModifiers.forEach((menuItemModifier) => {
          if (self.productId === self.menuItem?.productId) {
            if (menuItemModifier.childModifiers.length > cartItemModifiers.length) {
              notFoundMinimumChildModifiers = true;
            }
          }
        });
      } else {
        cartItemModifiers.forEach((cartItemModifier) => {
          if (!hasUnknownChildModifiers) {
            const localModifiersFoundStatus: boolean[] = [];

            menuItemModifiers.forEach((menuMod) => {
              const isFound = menuMod.childModifiers.some((m) => (
                m.modifierId === cartItemModifier.modifierId
              ));

              localModifiersFoundStatus.push(isFound);

              menuMod.childModifiers.forEach((childMod) => {
                if (!notFoundMinimumChildModifiers && !hasMoreChildModifiersThanMaxAmount) {
                  if (childMod.modifierId === cartItemModifier.modifierId) {
                    if (childMod.minAmount > 0 && cartItemModifier.amount < childMod.minAmount) {
                      notFoundMinimumChildModifiers = true;
                    }

                    if (childMod.maxAmount > 0 && cartItemModifier.amount > childMod.maxAmount) {
                      hasMoreChildModifiersThanMaxAmount = true;
                    }
                  }
                }
              });
            });

            if (!localModifiersFoundStatus.some((v) => v)) {
              hasUnknownChildModifiers = true;
            }
          }
        });
      }

      return (
        hasUnknownChildModifiers
        || notFoundRequiredModifiers
        || notFoundMinimumChildModifiers
        || hasMoreChildModifiersThanMaxAmount
        || notFoundMinimumGroupModifiers
        || hasMoreGroupModifiersThanMaxAmount
      );
    },
  }))
  .actions((self) => ({
    addModifier: (modifierId: string) => {
      const cartItemModifier = CartItemModifier.create({
        id: uuidv4(),
        modifierId,
        amount: 1,
      });

      self.modifiers.push(cartItemModifier);
    },
    increaseAmount: () => {
      if (self.amount === generalConfig.maxAmount) {
        return;
      }

      self.amount += 1;
    },
    reduceAmount: () => {
      if (self.amount === 1) {
        // const parent: AppStoreInstance['cart'] = getParent(self);
        // parent.delete(self.id);
        // // detach(self);
        destroy(self);

        return;
      }

      self.amount -= 1;
    },
  }));

export interface CartItemInstance extends Instance<typeof CartItem> {
}

export interface CartItemSnapshotIn extends SnapshotIn<typeof CartItem> {
}

export interface CartItemSnapshotOut extends SnapshotOut<typeof CartItem> {
}
