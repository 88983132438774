import {
  addDisposer,
  Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { autorun } from 'mobx';
import { AppStore } from '@app/domain/store/CoreStore/AppStore/AppStore';
import { LayoutChangeEvent } from 'react-native';

export const MenuScreenUIStore = t
  .model('MenuScreenUIStore', {
    id: t.optional(t.identifier, 'MenuScreenUIStore'),
    waitScrollAnimation: t.optional(t.boolean, false),
    scrollYPosition: t.optional(t.number, 0),
    menuOffset: t.optional(t.number, 0),
    categoryPositions: t.optional(t.map(t.number), {}),
    currentFocusedCategoryId: t.maybe(t.string),
    appStore: t.optional(t.reference(AppStore), 'AppStore'),
    // Какой-то отступ, чтобы переключение категорий срабатывало в нужный момент
    categoryOffset: t.optional(t.literal(60), 60),
  })
  .actions((self) => ({
    setWaitScrollAnimation: (value: boolean) => {
      self.waitScrollAnimation = value;
    },
    setScrollYPosition: (value: number) => {
      self.scrollYPosition = value;
    },
    setMenuOffset: (value: number) => {
      self.menuOffset = value;
    },
    setCurrentFocusedCategoryId: (value: string | undefined) => {
      self.currentFocusedCategoryId = value;
    },
    setCategoryPositionById: (categoryId: string, y: number) => {
      self.categoryPositions.set(categoryId, y);
    },
  }))
  .actions((self) => ({
    onLayoutCategory: (categoryId: string) => (event: LayoutChangeEvent) => {
      self.setCategoryPositionById(categoryId, event.nativeEvent.layout.y);
    },
  }))
  .actions((self) => ({
    afterCreate() {
      addDisposer(self, autorun(() => {
        // когда заскролил до категории она становится активной
        if (self.waitScrollAnimation) {
          return;
        }
        let scrollToY: number | undefined;

        const scrollYPositionInMenuBlock = self.scrollYPosition - self.menuOffset + self.categoryOffset;

        self.categoryPositions.forEach((value, categoryId) => {
          if (
            scrollYPositionInMenuBlock >= value - self.categoryOffset
            && (
              (!!scrollToY && scrollToY < value)
              || !scrollToY
            )
          ) {
            scrollToY = value;
            self.setCurrentFocusedCategoryId(String(categoryId));
          }
        });
      }));

      addDisposer(self, autorun(() => {
        if (self.currentFocusedCategoryId) {
          self.appStore.activeMenu?.setSelectedCategoryId(self.currentFocusedCategoryId);
        }
      }));
    },
  }));

export interface MenuScreenUIStoreInstance extends Instance<typeof MenuScreenUIStore> {
}

export interface MenuScreenUIStoreModelSnapshotIn extends SnapshotIn<typeof MenuScreenUIStore> {
}

export interface MenuScreenUIStoreModelSnapshotOut extends SnapshotOut<typeof MenuScreenUIStore> {
}
