import React, { FC } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  View,
  Text,
} from 'react-native';
import { StatusBar } from 'expo-status-bar';
import colors from '@constants/colors';

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    backgroundColor: colors.background,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface IProps {
  title?: string;
}

const FullScreenLoading: FC<IProps> = ({ title }) => (
  <View style={styles.loading}>
    <ActivityIndicator
      color={colors.primary}
    />
    {!!title && <Text>{title}</Text>}
    {/* eslint-disable-next-line react/style-prop-object */}
    <StatusBar style="light" />
  </View>
);

export default FullScreenLoading;
