import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import GreyBlock from '@components/layout/GreyBlock';
import useStore from '@app/domain/store/useStore';
import Input from '@components/form/Input';
import { StyleSheet, Text } from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    gap: 5,
  },
  title: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
  },
});

const PromoCode: FC = () => {
  const {
    profileStore: {
      profile,
    },
    appStore: {
      cartStates,
      selectedShopId,
    },
  } = useStore();

  const cartState = selectedShopId ? cartStates.get(selectedShopId) : undefined;

  const onChange = useCallback((value: string) => {
    cartState?.setPromoCode(value);
  }, [cartState]);

  const useBonusesToPayAmount = !!cartState?.bonusesToPayAmount;

  if (!cartState) {
    return null;
  }

  return (
    <GreyBlock style={s.root}>
      {!profile && (
        <>
          <Text style={s.title}>
            Промокод
          </Text>
          <Text style={s.title}>
            Авторизуйтесь чтобы воспользоваться промокодом
          </Text>
        </>
      )}
      {!!profile && (
        <>
          {useBonusesToPayAmount && (
            <>
              <Text style={s.title}>
                Промокод
              </Text>
              <Text style={s.title}>
                Промокод нельзя использовать вместе с бонусами
              </Text>
            </>
          )}
          {!useBonusesToPayAmount && (
            <Input
              type="text"
              label="Промокод"
              placeholder="Введите промокод"
              value={cartState.promoCode || ''}
              onChangeText={onChange}
            />
          )}
        </>
      )}
    </GreyBlock>
  );
};

export default observer(PromoCode);
