import React, { FC, useCallback, useMemo } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  useWindowDimensions,
  Platform,
} from 'react-native';
import colors from '@constants/colors';
import Picture from '@components/common/Picture/Picture';
import useStore from '@app/domain/store/useStore';
import { useNavigation } from '@react-navigation/native';
import { Enum_Product_Type } from '@app/infrastructureLayer/generated/graphql';
import { EnumProductParamCalculationType } from '@app/domain/store/CoreStore/AppStore/entities/Menu/BaseItemWithParams';
import MenuItemControls from '@screens/Tabs/MenuScreen/includes/MenuItem/MenuItemControls';
import { useInstance } from 'react-ioc';
import Typograf from 'typograf';
import ubuntuFont from '@constants/ubuntuFont';
import { observer } from 'mobx-react-lite';

interface IProps {
  menuItemId: string,
}

const s = StyleSheet.create({
  titleContainer: {
    width: '100%',
    marginTop: 5,
    minHeight: 80,
    justifyContent: 'space-between',
  },
  volumeHint: {
    fontFamily: ubuntuFont.light,
    fontSize: 10,
    color: colors.grey,
  },
  counterContainer: {
    justifyContent: 'flex-end',
    flexDirection: 'row',
    width: '100%',
  },
  notAvailable: {
    fontFamily: ubuntuFont.light,
    fontSize: 12,
    color: colors.text,
    paddingRight: 10,
    paddingBottom: 10,
  },
});

const MenuItem: FC<IProps> = ({ menuItemId }) => {
  const {
    appStore: {
      activeMenu,
    },
  } = useStore();

  const menuItem = activeMenu?.menuItems.get(menuItemId);
  const { width } = useWindowDimensions();

  const product = menuItem?.product;
  const volumeOrWeightText = useMemo(() => {
    if (!product) {
      return null;
    }

    if (product.type === Enum_Product_Type.Dish) {
      if (product.volumeParam && product.paramCalculationType === EnumProductParamCalculationType.Volume) {
        return `${product.volumeParam.value} ${product.volumeParam.unit}`;
      }

      if (product.weightParam && product.paramCalculationType === EnumProductParamCalculationType.Weight) {
        return `${product.weightParam.value} ${product.weightParam.unit}`;
      }
    }

    return null;
  }, [product]);

  const itemWidth = useMemo(() => {
    // Должно влезать два элемента с учетом всех отступов
    const halfWidth = Math.floor((width - 16 * 3) / 2);
    if (Platform.OS === 'web') {
      const maxWidth = 164;
      if (halfWidth > maxWidth) {
        return maxWidth;
      }
    }

    return halfWidth;
  }, [width]);

  const rootStyle = useMemo(() => ({
    width: itemWidth,
  } as const), [itemWidth]);

  const titleStyle = useMemo(() => ({
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
    flexWrap: 'wrap',
    width: itemWidth,
    marginBottom: 2,
  } as const), [itemWidth]);

  const tp = useInstance(Typograf);

  const navigation = useNavigation();

  const shopId = activeMenu?.shopId;

  const goToProduct = useCallback(() => {
    if (!menuItem || !shopId) {
      return;
    }

    navigation.navigate('MenuItemScreen', {
      shopId,
      menuItemId: menuItem.id,
    });
  }, [navigation, shopId, menuItem]);

  if (!menuItem || !product) {
    return null;
  }

  const isAvailable = menuItem.isAvailable && menuItem.groupModifiersIsCorrect;

  return (
    <View style={rootStyle}>
      <TouchableOpacity
        onPress={goToProduct}
      >
        <Picture
          notAvailableItem={!isAvailable}
          image={menuItem.product.imageUrl_200}
          size={itemWidth}
          r={15}
        />
      </TouchableOpacity>

      <View style={s.titleContainer}>
        <TouchableOpacity
          onPress={goToProduct}
        >
          <View>
            <View>
              <Text style={titleStyle}>
                {tp.execute(product.title)}
              </Text>
            </View>

            {!!volumeOrWeightText && (
              <View>
                <Text style={s.volumeHint}>
                  {volumeOrWeightText}
                </Text>
              </View>
            )}
          </View>
        </TouchableOpacity>
        {!isAvailable && (
          <View style={s.counterContainer}>
            <Text style={s.notAvailable}>
              Нет в наличии
            </Text>
          </View>
        )}

        {isAvailable && (
          <MenuItemControls
            menuItem={menuItem}
          />
        )}
      </View>
    </View>
  );
};

export default observer(MenuItem);
