import React, { FC } from 'react';
import colors from '@constants/colors';
import Svg, { Path } from 'react-native-svg';

interface IProps {
  color?: string;
}

const BookmarkSvg: FC<IProps> = ({ color = colors.text }) => (
  <Svg
    width={18}
    height={18}
    viewBox="0 0 18 18"
    fill="none"
  >
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 1.5C4.65326 1.5 4.08097 1.73705 3.65901 2.15901C3.23705 2.58097 3 3.15326 3 3.75V15.1035C2.99993 15.3006 3.05163 15.4942 3.14993 15.665C3.24822 15.8358 3.38966 15.9778 3.56009 16.0768C3.73051 16.1757 3.92394 16.2282 4.12101 16.2289C4.31808 16.2296 4.51188 16.1785 4.683 16.0807L8.628 13.8263C8.7413 13.7615 8.86952 13.7275 9 13.7275C9.13048 13.7275 9.2587 13.7615 9.372 13.8263L13.317 16.0807C13.4881 16.1785 13.6819 16.2296 13.879 16.2289C14.0761 16.2282 14.2695 16.1757 14.4399 16.0768C14.6103 15.9778 14.7518 15.8358 14.8501 15.665C14.9484 15.4942 15.0001 15.3006 15 15.1035V3.75C15 3.15326 14.7629 2.58097 14.341 2.15901C13.919 1.73705 13.3467 1.5 12.75 1.5H5.25Z"
      fill={color}
    />
  </Svg>
);

export default BookmarkSvg;
