import React, { FC, useCallback } from 'react';
import useStore from '@app/domain/store/useStore';
import { observer } from 'mobx-react-lite';
import Segmented from '@components/common/Segmented/Segmented';
import { comparer, computed } from 'mobx';
import { useNavigation } from '@react-navigation/native';
import { useGeocodeLazyQuery } from '@app/infrastructureLayer/generated/graphql';

const OrderTypeSwitch: FC = () => {
  const {
    profileStore: {
      profile,
    },
    appStore: {
      selectedCityId,
      setSelectedOrderTypeId,
      selectedOrderTypeId,
      selectedShopOrderTypes,
      deliveryOrderType,
      selectedDeliveryAddressId,
      setSelectedDeliveryAddressId,
      setSelectedShopId,
    },
  } = useStore();

  const options = computed(
    () => (
      selectedShopOrderTypes.map((op) => ({
        value: op.id,
        title: op.title,
      }))
        .sort()
    ),
    {
      equals: comparer.structural,
    },
  )
    .get();

  const navigation = useNavigation();

  const [geocode] = useGeocodeLazyQuery();

  const onChange = useCallback(async (value: string | null) => {
    if (deliveryOrderType && value && deliveryOrderType.id === value) {
      if (!selectedDeliveryAddressId) {
        if (profile) {
          if (selectedCityId) {
            const addressForCurrentCity = profile.deliveryAddressesByCityId[selectedCityId];

            if (!!addressForCurrentCity && addressForCurrentCity.length > 0) {
              const deliveryAddress = addressForCurrentCity[0];

              const deliveryData = await geocode({
                variables: {
                  input: {
                    lat: deliveryAddress.coordinates.latitude,
                    lon: deliveryAddress.coordinates.longitude,
                  },
                },
              });

              const shopId = deliveryData.data?.geocode?.addressDelivery.shopId;

              if (!shopId) {
                navigation.navigate('SelectDeliveryAddressScreen');
                return;
              }

              setSelectedOrderTypeId(value);
              setSelectedDeliveryAddressId(deliveryAddress.id);
              setSelectedShopId(shopId);

              return;
            }
          }
          navigation.navigate('SelectDeliveryAddressScreen');
        } else {
          navigation.navigate('Auth', { screen: 'LoginScreen' });
        }

        return;
      }
    }

    setSelectedDeliveryAddressId(null);
    setSelectedOrderTypeId(value);
  }, [
    selectedDeliveryAddressId,
    deliveryOrderType,
    navigation,
    profile,
    setSelectedOrderTypeId,
    selectedCityId,
    setSelectedDeliveryAddressId,
    setSelectedShopId,
    geocode,
  ]);

  return (
    <Segmented
      value={selectedOrderTypeId}
      options={options}
      onChange={onChange}
    />
  );
};

export default observer(OrderTypeSwitch);
