import {
  Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { MenuScreenUIStore } from '@app/domain/store/CoreStore/UI/MenuScreenUIStore';

export const UIStore = t
  .model('UIStore', {
    id: t.optional(t.identifier, 'UIStore'),
    menuScreenUIStore: t.optional(MenuScreenUIStore, {}),
  });

export interface UIStoreInstance extends Instance<typeof UIStore> {
}

export interface UIStoreModelSnapshotIn extends SnapshotIn<typeof UIStore> {
}

export interface UIStoreModelSnapshotOut extends SnapshotOut<typeof UIStore> {
}
