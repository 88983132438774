import AsyncStorage from '@react-native-async-storage/async-storage';

class AsyncNativeStoreMstPersistAdapter {
  static async getItem(name: string) {
    return AsyncStorage.getItem(name);
  }

  static async removeItem(name: string) {
    return AsyncStorage.removeItem(name);
  }

  static async setItem(name: string, value: string) {
    return AsyncStorage.setItem(name, value);
  }

  static async clear() {
    return AsyncStorage.clear();
  }
}

export default AsyncNativeStoreMstPersistAdapter;
