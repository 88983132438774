import React, { FC } from 'react';
import { ProfileEntityInstance } from '@app/domain/store/CoreStore/ProfileStore/entities/ProfileEntity';
import { CheckoutData, CheckoutError } from '@hooks/useCheckout';
import ButtonInfoView from '@components/common/ButtonInfoView';
import generalConfig from '@constants/generalConfig';
import Button from '@components/common/Button';
import { observer } from 'mobx-react-lite';
import map from 'lodash/map';
import compact from 'lodash/compact';

type Props = {
  loading: boolean;
  activeCartItemsHaveNotAvailableItems: boolean;
  profile: ProfileEntityInstance | null;
  checkoutData: CheckoutData | null;
  checkoutError: CheckoutError | null;
  canMakeOrder: { title: string; value: boolean } | undefined;
  isCantDelivery: boolean;
  deliveryMinimumSum: number;
  onLoginPress: () => void;
  onOrderPress: () => void;
};

const getMessageFromCheckoutError = (checkoutError: CheckoutError | null): string | null => {
  if (!checkoutError) {
    return null;
  }

  if (checkoutError.errors) {
    const messageArray = map(checkoutError.errors.errorsForInput, (inputErrorMessage) => inputErrorMessage);
    const message = compact(messageArray)
      .join(', ');

    if (message) {
      return message;
    }

    if (checkoutError.errors.errorsForMenuItems.length > 0) {
      const messageFromItems = checkoutError.errors.errorsForMenuItems.map(
        (errorsForThisItem) => (
          compact(map(errorsForThisItem.value, (text) => text))
            .join(', ')),
      )
        .join(', ');

      if (messageFromItems) {
        return messageFromItems;
      }
    }
  }

  return checkoutError.message;
};

const MakeOrderButtonView: FC<Props> = (
  {
    loading,
    profile,
    checkoutData,
    canMakeOrder,
    isCantDelivery,
    deliveryMinimumSum,
    onLoginPress,
    activeCartItemsHaveNotAvailableItems,
    onOrderPress,
    checkoutError,
  },
) => {
  if (loading) {
    return (
      <Button
        disabled
        loading={loading}
        title="СДЕЛАТЬ ЗАКАЗ"
        onPress={onOrderPress}
      />
    );
  }

  if (activeCartItemsHaveNotAvailableItems) {
    return (
      <ButtonInfoView
        title="Некоторых товаров в Вашей корзине нет в наличии"
      />
    );
  }

  if (checkoutError) {
    const message = getMessageFromCheckoutError(checkoutError);

    return (
      <ButtonInfoView
        title={message ?? 'Не удалось получить данные о заказе'}
      />
    );
  }

  if (!checkoutData && !checkoutError) {
    return (
      <ButtonInfoView
        title="Не удалось получить данные о заказе"
      />
    );
  }

  if (!canMakeOrder) {
    return (
      <ButtonInfoView
        title="Не удалось получить данные о заказе"
      />
    );
  }

  if (!profile) {
    return (
      <Button
        title="АВТОРИЗОВАТЬСЯ"
        onPress={onLoginPress}
      />
    );
  }

  if (!canMakeOrder.value) {
    return (
      <ButtonInfoView
        title={canMakeOrder.title}
      />
    );
  }

  if (isCantDelivery) {
    const value = `${deliveryMinimumSum}${generalConfig.currencyLabel}`;
    return (
      <ButtonInfoView
        title={`Заказ от ${value}.\nДобавьте товары в корзину`}
      />
    );
  }

  return (
    <Button
      title="СДЕЛАТЬ ЗАКАЗ"
      onPress={onOrderPress}
    />
  );
};

export default observer(MakeOrderButtonView);
