import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { FC } from 'react';

interface IProps {
  color: string,
}

const PersonSvg: FC<IProps> = ({ color }) => (
  <Svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
  >
    <Path
      d="M13 10.833a4.333 4.333 0 100-8.667 4.333 4.333 0 000 8.667zM21.667 18.958c0 2.692 0 4.875-8.667 4.875s-8.667-2.183-8.667-4.875 3.88-4.875 8.667-4.875c4.786 0 8.667 2.183 8.667 4.875z"
      fill={color}
    />
  </Svg>
);

export default PersonSvg;
