import React, { FC } from 'react';
import useStore from '@app/domain/store/useStore';
import { observer } from 'mobx-react-lite';
import Segmented from '@components/common/Segmented/Segmented';
import { comparer, computed } from 'mobx';

const ShopSwitch: FC = () => {
  const {
    appStore: {
      setSelectedShopId,
      shopsForSelectedCity,
      selectedShopId,
    },
  } = useStore();

  const options = computed(
    () => (
      shopsForSelectedCity.map((op) => ({
        value: op.id,
        title: op.title,
      }))
        .sort()
    ),
    {
      equals: comparer.structural,
    },
  )
    .get();

  return (
    <Segmented
      value={selectedShopId}
      options={options}
      onChange={setSelectedShopId}
    />
  );
};

export default observer(ShopSwitch);
