import React, { FC, useCallback } from 'react';
import { RootStackScreenProps } from '@navigation/types';
import { useFocusEffect } from '@react-navigation/native';
import useStore from '@app/domain/store/useStore';
import FullScreenLoading from '@components/common/FullScreenLoading';
import crossAlert from '@utils/crossAlert';
import { observer } from 'mobx-react-lite';

const AddCardFailScreen: FC<RootStackScreenProps<'AddCardFailScreen'>> = (
  {
    navigation,
  },
) => {
  const { appStore: { selectedShopId } } = useStore();

  useFocusEffect(useCallback(() => {
    if (selectedShopId) {
      crossAlert('Ошибка, карта не была добавлена');
      navigation.replace('CartScreen', {
        shopId: selectedShopId,
      });
    }
  }, [navigation, selectedShopId]));

  return <FullScreenLoading />;
};

export default observer(AddCardFailScreen);
