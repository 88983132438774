import { ProductParamInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/ProductParam';
import React, { FC } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import {
  Enum_Componentproductproductparams_Type,
} from '@app/infrastructureLayer/generated/graphql';
import { ProductInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Product';
import { observer } from 'mobx-react-lite';
import { EnumProductParamCalculationType } from '@app/domain/store/CoreStore/AppStore/entities/Menu/BaseItemWithParams';
import { useModifiersState } from '@screens/MenuItemScreen/model/ModifiersStateModelContext';
import useStore from '@app/domain/store/useStore';
import { computed } from 'mobx';
import compact from 'lodash/compact';
import currency from 'currency.js';

const s = StyleSheet.create({
  propertyHeader: {
    fontFamily: ubuntuFont.light,
    marginBottom: 10,
    color: colors.grey,
  },
  propertyBody: {
    fontFamily: ubuntuFont.regular,
    color: colors.grey,
    fontSize: 12,
    marginTop: 6,
  },
  property: {
    alignItems: 'center',
    marginTop: 6,
  },
  unit: {
    fontFamily: ubuntuFont.regular,
    fontSize: 12,
    color: colors.grey,
  },
});

interface IProps {
  product: ProductInstance;
  property: ProductParamInstance;
  full?: boolean,
}

const Property: FC<IProps> = (
  {
    product,
    property,
    full = false,
  },
) => {
  const modifiersStateModel = useModifiersState();

  const {
    appStore: {
      modifiers,
    },
  } = useStore();

  if (
    property.type === Enum_Componentproductproductparams_Type.Volume
    && product.paramCalculationType === EnumProductParamCalculationType.Volume
  ) {
    return null;
  }

  if (
    property.type === Enum_Componentproductproductparams_Type.Weight
    && product.paramCalculationType === EnumProductParamCalculationType.Weight
  ) {
    return null;
  }

  let { value } = property;

  // let modifiersShowSeparate = false;

  const addons = computed(() => compact(Object.keys(modifiersStateModel.keyValueState)
    .map((modifierId) => {
      const item = modifiers.get(modifierId);

      if (!item) {
        return null;
      }

      // if (item.paramCalculationType !== product.paramCalculationType) {
      //   modifiersShowSeparate = true;
      // }

      const amount = modifiersStateModel.state.get(item.id)?.amount;
      if (!amount) {
        return null;
      }

      return {
        addon: item,
        amount,
      };
    })))
    .get();

  if (!full) {
    if (addons.length > 0) {
      addons.forEach(({ addon, amount }) => {
        const addonProperty = addon.params.find((x) => x.type === property.type);

        if (addonProperty) {
          value = currency(value)
            .add(currency(addonProperty.value)
              .multiply(amount)).value;
        }
      });
    }
  }

  if (full) {
    const fullValue = product.paramsFull.get(property.type);

    if (typeof fullValue === 'undefined') {
      return null;
    }

    value = fullValue;

    if (addons.length > 0) {
      addons.forEach(({ addon, amount }) => {
        const addonPropertyValue = addon.paramsFull.get(property.type);

        if (addonPropertyValue) {
          value = currency(value)
            .add(currency(addonPropertyValue)
              .multiply(amount)).value;
        }
      });
    }
  }

  return (
    <View style={s.property}>
      <Text style={s.propertyHeader}>
        {property.title}
      </Text>
      <Text style={s.propertyBody}>
        {value}
        {property.unit && (
          <Text style={s.unit}>
            {' '}
            {property.unit}
          </Text>
        )}
      </Text>
    </View>
  );
};

export default observer(Property);
