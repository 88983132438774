const formatAlfaExpiryDate = (value: string) => {
  if (value.length !== 6) {
    return value;
  }

  const month = value.slice(4, 6);
  const year = value.slice(2, 4);

  return `${month}/${year}`;
};

export default formatAlfaExpiryDate;
