import React, { FC } from 'react';
import {
  Text, View, StyleSheet, ViewStyle,
} from 'react-native';
import formatAlfaExpiryDate from '@utils/formatAlfaExpiryDate';
import formatAlfaMaskedPan from '@utils/formatAlfaMaskedPan';
import ArrowRightSvg from '@svg/ArrowRightSvg';
import ubuntuFont from '@constants/ubuntuFont';
import GreyBlock from '@components/layout/GreyBlock';
import DeleteSvg from '@svg/DeleteSvg';
import CardSvg from '@svg/CardSvg';
import colors from '@constants/colors';

interface IProps {
  maskedPan: string;
  expiryDate: string;
  rootStyle?: ViewStyle;
  useDelete?: boolean;
}

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 8,
    paddingVertical: 3,
  },
  text: {
    color: colors.text,
    fontFamily: ubuntuFont.light,
    fontSize: 14,
    height: 14,
  },
  date: {
    color: colors.grey,
    fontFamily: ubuntuFont.light,
    fontSize: 12,
  },
  textView: {
    marginLeft: 10,
    flex: 1,
    rowGap: 3,
  },
});

const CreditCard: FC<IProps> = (
  {
    maskedPan,
    expiryDate,
    rootStyle,
    useDelete,
  },
) => (
  <GreyBlock style={StyleSheet.flatten([s.root, rootStyle])}>
    <CardSvg />
    <View style={s.textView}>
      <Text style={s.text}>{formatAlfaMaskedPan(maskedPan)}</Text>
      <Text style={s.date}>{formatAlfaExpiryDate(expiryDate)}</Text>
    </View>
    {useDelete && <DeleteSvg />}
    {!useDelete && <ArrowRightSvg />}
  </GreyBlock>
);

export default CreditCard;
