import React, { FC, useCallback } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Text,
} from 'react-native';
import DashedLine from '@components/common/DashedLine';
import { Enum_Ordertype_Ordertypecode } from '@app/infrastructureLayer/generated/graphql';
import colors from '@constants/colors';
import ArrowRightSvg from '@svg/ArrowRightSvg';
import { SheetManager } from 'react-native-actions-sheet';
import OrderTypeSwitch from '@components/OrderTypeSwitch/OrderTypeSwitch';
import { observer } from 'mobx-react-lite';
import useStore from '@app/domain/store/useStore';
import ubuntuFont from '@constants/ubuntuFont';
// import { SelectDeliveryLocationSheetPayload } from '@components/sheets/SelectDeliveryLocationSheet';
// import { useNavigation } from '@react-navigation/native';
import CitySelector from '@components/CitySelector/CitySelector';
import ShopSwitch from '@components/ShopSwitch/ShopSwitch';
import LastOrderInfoBanner from '@widgets/LastOrderInfoBanner';

const s = StyleSheet.create({
  root: {
    backgroundColor: colors.lightGrey,
    padding: 10,
    borderRadius: 10,
    gap: 10,
  },
  text: {
    color: colors.primary,
    fontFamily: ubuntuFont.light,
    fontSize: 14,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  greyRoot: {
    backgroundColor: colors.lightGrey,
  },
  horPadding: {},
});

interface Props {
  hideLastOrder?: boolean;
}

const TopBlock: FC<Props> = ({ hideLastOrder }) => {
  const {
    appStore: {
      shop,
      orderType,
      globalSettings,
      selectedDeliveryAddressForCurrentCity,
    },
  } = useStore();

  const isHotelMode = globalSettings?.isHotelMode || false;

  // const navigation = useNavigation();

  const openShopSelectorSheet = useCallback(() => {
    SheetManager.show('select-shop-sheet');
  }, []);

  const openSelectLocationSheet = useCallback(async () => {
    await SheetManager.show('select-delivery-location-sheet');
    //
    // if (payload && payload.goToAddNewAddress) {
    //   // TODO: Нах это надо вообще?
    //   navigation.navigate('CreateMyAddressScreen');
    // }
  }, []);

  return (
    <View style={s.greyRoot}>
      <CitySelector />
      {!hideLastOrder && <LastOrderInfoBanner />}
      <View style={s.horPadding}>
        <View style={s.root}>
          {!isHotelMode && (
            <OrderTypeSwitch />
          )}
          {!isHotelMode && (
            <DashedLine />
          )}
          {isHotelMode && (
            <>
              <ShopSwitch />
              <DashedLine />
            </>
          )}
          {(
            !isHotelMode
            && (
              orderType?.type === Enum_Ordertype_Ordertypecode.DineIn
              || orderType?.type === Enum_Ordertype_Ordertypecode.TakeAway
            )
          ) && (
            <View style={s.buttonRow}>
              <TouchableOpacity
                onPress={openShopSelectorSheet}
                hitSlop={5}
              >
                <View style={s.button}>
                  <Text style={s.text}>
                    {!shop && 'Выбрать точку'}
                    {!!shop && shop.address}
                  </Text>

                  <ArrowRightSvg color={colors.primary} />
                </View>
              </TouchableOpacity>
            </View>
          )}

          {orderType?.type === Enum_Ordertype_Ordertypecode.Delivery && (
            <View style={s.buttonRow}>
              <TouchableOpacity
                onPress={openSelectLocationSheet}
                hitSlop={5}
              >
                <View style={s.button}>
                  <Text style={s.text}>
                    {!selectedDeliveryAddressForCurrentCity && 'Выбрать адрес доставки'}
                    {!!selectedDeliveryAddressForCurrentCity && selectedDeliveryAddressForCurrentCity.addressTitle}
                  </Text>

                  <ArrowRightSvg color={colors.primary} />
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default observer(TopBlock);
