import { registerSheet } from 'react-native-actions-sheet';
import SelectCitySheet from '@components/sheets/SelectCitySheet';
import SelectShopSheet from '@components/sheets/SelectShopSheet';
import SelectPaymentTypeSheet from '@components/sheets/SelectPaymentTypeSheet';
import SelectOrderDateTimeSheet from '@components/sheets/SelectOrderDateTimeSheet/SelectOrderDateTimeSheet';
import BonusProgramInfoSheet from '@components/sheets/BonusProgramInfoSheet';
import AddNewCardViaAlfaSheet from '@components/sheets/AddNewCardViaAlfaSheet/AddNewCardViaAlfaSheet';
import SelectAddressCitySheet from '@components/sheets/SelectAddressCitySheet';
import SelectDeliveryLocationSheet from '@components/sheets/SelectDeliveryLocationSheet';
import OrderCreatePendingSheet from '@components/sheets/OrderCreatePendingSheet';
import SelectCardSheet from '@components/sheets/SelectCardSheet';
import MenuItemGroupModifierSheet from '@components/sheets/MenuItemGroupModifierSheet';
import OrderCreatedSheet from '@components/sheets/OrderCreatedSheet';
import LoginSheet from '@components/sheets/LoginSheet';
import SmsLoginSheet from '@components/sheets/SmsLoginSheet';
import ConfirmShopSheet from '@components/sheets/ConfirmShopSheet';
// import AddNewCardFromUserInputSheet from '@components/sheets/AddNewCardFromUserInputSheet';

/**
 * Registering the sheets here because otherwise sheet closes on
 * hot reload during development.
 */
registerSheet('select-city-sheet', SelectCitySheet);
registerSheet('select-address-city-sheet', SelectAddressCitySheet);
registerSheet('select-shop-sheet', SelectShopSheet);
registerSheet('select-payment-type-sheet', SelectPaymentTypeSheet);
registerSheet('select-order-date-time-sheet', SelectOrderDateTimeSheet);
registerSheet('bonus-program-info', BonusProgramInfoSheet);
registerSheet('menu-item-group-modifier-sheet', MenuItemGroupModifierSheet);
// registerSheet('add-new-card', AddNewCardFromUserInputSheet);
registerSheet('add-new-card', AddNewCardViaAlfaSheet);
registerSheet('select-card-sheet', SelectCardSheet);
registerSheet('select-delivery-location-sheet', SelectDeliveryLocationSheet);
registerSheet('order-create-pending-sheet', OrderCreatePendingSheet);
registerSheet('order-created-sheet', OrderCreatedSheet);
registerSheet('login-sheet', LoginSheet);
registerSheet('sms-login-sheet', SmsLoginSheet);
registerSheet('confirm-shop-sheet', ConfirmShopSheet);
export {};

/**
 * Since we are not importing our Sheets in any component or file, we want to make sure
 * they are bundled by the JS bundler. Hence we will import this file in App.js.
 */
