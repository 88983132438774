import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import useStore from '@app/domain/store/useStore';
import Input from '@components/form/Input';
import {
  StyleSheet,
  View,
  Pressable,
  Text,
} from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    paddingTop: 10,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  inputRoot: {
    flex: 1,
  },
  input: {
    // minHeight: 20,
    // height: 35,
    // paddingTop: 0,
    // paddingBottom: 0,
  },
  buttonClear: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonClearText: {
    fontFamily: ubuntuFont.light,
    fontSize: 12,
    color: colors.primary,
  },
});

const SearchInMenu: FC = () => {
  const {
    appStore: {
      hasSearchQuery,
      searchQuery,
      setSearchQuery,
    },
  } = useStore();

  const onClear = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  return (
    <View style={s.root}>
      <View style={s.inputRoot}>
        <Input
          style={s.input}
          clearButtonMode="always"
          type="text"
          placeholder="Поиск"
          value={searchQuery}
          onChangeText={setSearchQuery}
        />
      </View>
      {hasSearchQuery && (
        <Pressable onPress={onClear} style={s.buttonClear}>
          <Text style={s.buttonClearText}>Очистить</Text>
        </Pressable>
      )}
    </View>
  );
};

export default observer(SearchInMenu);
