import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useMemo } from 'react';
import {
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import colors from '@constants/colors';
import Input from '@components/form/Input';
import ubuntuFont from '@constants/ubuntuFont';
import useStore from '@app/domain/store/useStore';
import {
  Enum_Userspermissionsuser_Sex,
} from '@app/infrastructureLayer/generated/graphql';
import Button from '@components/common/Button';

import GenderSegmented from '@components/form/GenderSegmented';
import EwSwitch from '@components/form/EwSwitch';
import getRegistrationSchema from './registrationSchema';

const s = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
  },
  containerTop: {
    flex: 1,
    alignItems: 'center',
    marginTop: 67,
  },
  box: {
    width: '100%',
    marginTop: 40,
  },
  actions: {
    marginBottom: 10,
  },
  info: {
    width: '80%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  error: {
    fontSize: 12,
    color: colors.error,
    fontFamily: ubuntuFont.light,
    paddingBottom: 20,
  },
});

export type RegistrationFormValues = {
  fio: string,
  birthday: string,
  sex: Enum_Userspermissionsuser_Sex
  broadcastOfferAccepted?: boolean,
};

const defaultFormInitialState: Partial<RegistrationFormValues> = {
  fio: '',
  birthday: '',
  sex: Enum_Userspermissionsuser_Sex.NotSelected,
  broadcastOfferAccepted: true,
};

type IProps = {
  onSave: (values: RegistrationFormValues) => Promise<void>
  initialFio?: string,
};

const RegistrationForm: FC<IProps> = (
  {
    onSave,
    initialFio,
  },
) => {
  const {
    appStore: {
      globalSettings,
    },
  } = useStore();

  const registrationSchema = useMemo(() => getRegistrationSchema({
    useBroadcastOfferAccept: globalSettings?.useBroadcastOfferAccept || false,
    broadcastOfferAcceptedRequired: globalSettings?.broadcastOfferAcceptedRequired || false,
  }), [globalSettings?.useBroadcastOfferAccept, globalSettings?.broadcastOfferAcceptedRequired]);

  const initialState = useMemo(() => ({
    ...defaultFormInitialState,
    fio: initialFio || defaultFormInitialState.fio,
  }), [initialFio]);

  const {
    control,
    handleSubmit,
    formState: {
      isSubmitting,
      isValid,
    },
  } = useForm<RegistrationFormValues>({
    mode: 'all',
    defaultValues: initialState,
    resolver: zodResolver(registrationSchema),
  });

  const onSubmit: SubmitHandler<RegistrationFormValues> = useCallback(async (data) => {
    await onSave(data);
  }, [onSave]);

  const onPressSave = useCallback(() => handleSubmit(onSubmit)(), [handleSubmit, onSubmit]);

  return (
    <View style={s.rootContainer}>
      <View style={s.container}>
        <View style={s.containerTop}>
          <View style={s.box}>
            <View style={s.actions}>
              <Controller
                control={control}
                name="fio"
                // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                render={(
                  {
                    field: {
                      ref,
                      onChange,
                      onBlur,
                      value,
                      disabled,
                    },
                    fieldState: {
                      error,
                    },
                  },
                ) => (
                  <Input
                    ref={ref}
                    editable={!disabled}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    label="Имя"
                    type="text"
                    placeholder="Введите ваше имя"
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            </View>
            <View style={s.actions}>
              <Controller
                control={control}
                name="birthday"
                // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                render={(
                  {
                    field: {
                      ref,
                      onChange,
                      onBlur,
                      value,
                      disabled,
                    },
                    fieldState: {
                      error,
                    },
                  },
                ) => (
                  <Input
                    ref={ref}
                    editable={!disabled}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    label="День рождения"
                    type="date"
                    // placeholder="31.12.2000"
                    placeholder="Введите ваш день рождения"
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            </View>
            <Controller
              control={control}
              name="sex"
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              render={(
                {
                  field: {
                    onChange,
                    value,
                  },
                },
              ) => (
                <GenderSegmented
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </View>
        </View>
        <View>
          {globalSettings?.useBroadcastOfferAccept && (
            <Controller
              control={control}
              name="broadcastOfferAccepted"
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              render={(
                {
                  field: {
                    onChange,
                    value,
                  },
                  fieldState: {
                    error,
                  },
                },
              ) => (
                <View>
                  <View style={s.row}>
                    <Text style={s.info}>
                      Получать информацию о
                      {'\u00A0'}
                      персональных предложениях и
                      {'\u00A0'}
                      акциях
                    </Text>
                    <EwSwitch
                      enabled={!!value}
                      onValueChange={onChange}
                    />
                  </View>
                  {!!error && (
                    <Text style={s.error}>{error.message}</Text>
                  )}
                </View>
              )}
            />
          )}
        </View>
      </View>

      <Button
        title="ПРОДОЛЖИТЬ"
        onPress={onPressSave}
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
      />
    </View>
  );
};

export default observer(RegistrationForm);
