import * as Updates from 'expo-updates';
import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';

const useAppUpdater = () => {
  const [hasUpdates, setHasUpdates] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [initialDone, setInitialDone] = useState(false);

  const checkForUpdates = useCallback(async () => {
    try {
      const update = await Updates.checkForUpdateAsync();

      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        setHasUpdates(true);
      }
    } catch (error) {
      // You can also add an alert() to see the error message in case of an error when fetching updates.
      console.error(`Error fetching latest Expo update: ${error}`);
    }
  }, []);

  useEffect(() => {
    if (__DEV__ || Platform.OS === 'web') {
      return undefined;
    }

    if (!initialDone) {
      (async () => {
        setInProgress(true);
        await checkForUpdates();
        setInProgress(false);
        setInitialDone(true);
      })();

      return undefined;
    }

    if (!inProgress) {
      const timeout = setTimeout(async () => {
        setInProgress(true);
        await checkForUpdates();
        setInProgress(false);
      }, 10000);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }

    return undefined;
  }, [inProgress, checkForUpdates, initialDone]);

  return {
    hasUpdates,
  } as const;
};

export default useAppUpdater;
