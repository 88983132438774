import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigation } from '@react-navigation/native';
import useStore from '@app/domain/store/useStore';

const useShopIdSync = (shopIdFromRoute?: string) => {
  const initialShopId = useRef(shopIdFromRoute).current;
  const [initialShopIdAccepted, setInitialShopIdAccepted] = useState(false);

  const navigation = useNavigation();

  const {
    deviceVarsStore: {
      lastShopId,
    },
    appStore: {
      selectedShopId,
      setSelectedShopId,
    },
  } = useStore();

  const setShopIdInRoute = useCallback((newShopId: string) => {
    navigation.setParams({
      shopId: newShopId,
    });
  }, [navigation]);

  useEffect(() => {
    if (initialShopIdAccepted && selectedShopId) {
      setShopIdInRoute(selectedShopId);
    }
  }, [selectedShopId, setShopIdInRoute, initialShopIdAccepted]);

  useEffect(() => {
    if (!initialShopIdAccepted && initialShopId) {
      setSelectedShopId(initialShopId);
      setInitialShopIdAccepted(true);
    }
  }, [initialShopId, setSelectedShopId, initialShopIdAccepted]);

  useEffect(() => {
    if (!initialShopId) {
      if (lastShopId) {
        navigation.setParams({
          shopId: lastShopId,
        });
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: 'IndexScreen' }],
        });
      }
    }
  }, [initialShopId, navigation, lastShopId]);
};

export default useShopIdSync;
