import * as React from 'react';
import Svg, { Rect } from 'react-native-svg';
import { FC } from 'react';

interface IProps {
  color: string,
}

const ThreeHorLineSvg: FC<IProps> = ({ color }) => (
  <Svg
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
  >
    <Rect
      x={2}
      y={5}
      width={23}
      height={2.78788}
      rx={1.39394}
      fill={color}
    />
    <Rect
      x={2}
      y={11.9697}
      width={23}
      height={2.78788}
      rx={1.39394}
      fill={color}
    />
    <Rect
      x={2}
      y={18.9395}
      width={23}
      height={2.78788}
      rx={1.39394}
      fill={color}
    />
  </Svg>
);

export default ThreeHorLineSvg;
