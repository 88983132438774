import { Alert, Platform } from 'react-native';

interface Args {
  title: string,
  desc?: string,
  onPositivePress: () => void,
  onNegativePress: () => void,
}

export const BinaryAlert = (
  {
    title,
    desc,
    onPositivePress,
    onNegativePress,
  }: Args,
) => {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line no-restricted-globals,no-alert
    const res = window.confirm(`${title}\n${desc || ''}`);
    if (res) {
      onPositivePress();
    }

    onNegativePress();

    return;
  }

  Alert.alert(
    title,
    desc,
    [
      { text: 'Да', onPress: () => onPositivePress() },
      { text: 'Нет', onPress: () => onNegativePress() },
    ],
    { cancelable: false },
  );
};

export default BinaryAlert;
