import React, { FC, useEffect } from 'react';
import { View, Text } from 'react-native';
import { RootStackScreenProps } from '@navigation/types';
import { useFindOrderByAlfaOrderIdQuery } from '@app/infrastructureLayer/generated/graphql';

const PaymentSuccessScreen: FC<RootStackScreenProps<'PaymentSuccessScreen'>> = (
  {
    route,
    navigation,
  },
) => {
  const { data } = useFindOrderByAlfaOrderIdQuery({
    variables: {
      alfaOrderId: route.params.orderId,
    },
  });

  useEffect(() => {
    if (data?.myOrders.data[0].id) {
      navigation.replace('OrderScreen', {
        orderId: data?.myOrders.data[0].id,
      });
    }
  }, [data?.myOrders.data, navigation]);

  return (
    <View>
      <Text>Заказ успешно оплачен</Text>
    </View>
  );
};

export default PaymentSuccessScreen;
