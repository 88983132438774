import {
  Pressable,
  StyleSheet, Text, View,
} from 'react-native';
import React, { FC, useCallback } from 'react';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';
import ArrowRightSvg from '@svg/ArrowRightSvg';
import DeliveryAddressTypeSvg from '@svg/DeliveryAddressTypeSvg';
import { Enum_Componentuserdeliveryaddress_Type } from '@app/infrastructureLayer/generated/graphql';

const s = StyleSheet.create({
  action: {
    backgroundColor: colors.lightGrey,
    height: 60,
    borderRadius: 10,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftSide: {
    rowGap: 5,
    flex: 1,
  },
  rightSide: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    columnGap: 15,
    paddingLeft: 5,
  },
  title: {
    fontSize: 14,
    fontFamily: ubuntuFont.regular,
    color: colors.text,
    flex: 1,
    flexShrink: 1,
  },
  address: {
    fontSize: 14,
    fontFamily: ubuntuFont.light,
    color: colors.grey,
    flex: 1,
    flexShrink: 1,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },
  workIconWrapper: {
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: 10,
  },
});

type IProps = {
  deliveryAddressId: string,
  header: string,
  address: string,
  onPress: (deliveryAddressId: string) => void,
  type?: Enum_Componentuserdeliveryaddress_Type,
};

const MyAddressesRow: FC<IProps> = (
  {
    deliveryAddressId,
    header,
    address,
    onPress: _onPress,
    type,
  },
) => {
  const onPress = useCallback(() => {
    _onPress(deliveryAddressId);
  }, [_onPress, deliveryAddressId]);

  return (
    <Pressable style={s.action} onPress={onPress}>
      <View style={s.row}>
        <View style={s.workIconWrapper}>
          <DeliveryAddressTypeSvg type={type} />
        </View>
        <View style={s.leftSide}>
          <Text numberOfLines={1} style={s.title} ellipsizeMode="tail">{header}</Text>
          <Text numberOfLines={1} style={s.address} ellipsizeMode="tail">{address}</Text>
        </View>
      </View>

      <View style={s.rightSide}>
        <ArrowRightSvg />
      </View>
    </Pressable>
  );
};

export default MyAddressesRow;
