import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import GreyBlock from '@components/layout/GreyBlock';
import {
  Text,
  StyleSheet,
  View,
} from 'react-native';
import useStore from '@app/domain/store/useStore';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import Input from '@components/form/Input';

const s = StyleSheet.create({
  root: {},
  row: {
    gap: 4,
    paddingBottom: 8,
  },
  requiredMark: {
    color: colors.error,
    fontFamily: ubuntuFont.light,
    fontSize: 12,
    position: 'relative',
    top: -2,
  },
  titleHeader: {
    fontFamily: ubuntuFont.regular,
    color: colors.text,
    fontSize: 12,
    paddingBottom: 10,
  },
  title: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
  },
  content: {
    // maxWidth: 400,
  },
});

const LegalEntityInput: FC = () => {
  const {
    appStore: {
      cartStates,
      selectedShopId,
      paymentTypes,
    },
  } = useStore();

  if (!selectedShopId) {
    return null;
  }

  const cartState = cartStates.get(selectedShopId);

  if (!cartState) {
    return null;
  }

  const paymentType = cartState.paymentTypeId ? paymentTypes.get(cartState.paymentTypeId) : undefined;

  if (paymentType?.type !== 'INVOICE') {
    return null;
  }

  return (
    <GreyBlock
      style={s.root}
    >
      <View style={s.content}>
        <Text style={s.titleHeader}>
          Данные ЮЛ
        </Text>
        <View style={s.row}>
          <Text style={s.title}>
            Название
            {'\u00A0'}
            <Text style={s.requiredMark}>*</Text>
          </Text>
          <Input
            type="text"
            placeholder="Введите название"
            multiline
            value={cartState.legalEntity.name}
            onChangeText={cartState.legalEntity.setName}
          />
        </View>
        <View style={s.row}>
          <Text style={s.title}>
            ИНН
            {'\u00A0'}
            <Text style={s.requiredMark}>*</Text>
          </Text>
          <Input
            type="text"
            placeholder="Введите ИНН"
            value={cartState.legalEntity.inn}
            onChangeText={cartState.legalEntity.setInn}
          />
        </View>
        <View style={s.row}>
          <Text style={s.title}>
            КПП
          </Text>
          <Input
            type="text"
            placeholder="Введите КПП"
            value={cartState.legalEntity.kpp}
            onChangeText={cartState.legalEntity.setKpp}
          />
        </View>
        <View style={s.row}>
          <Text style={s.title}>
            Юр. адрес
            {'\u00A0'}
            <Text style={s.requiredMark}>*</Text>
          </Text>
          <Input
            type="text"
            multiline
            placeholder="Введите Юр. адрес"
            value={cartState.legalEntity.legalAddress}
            onChangeText={cartState.legalEntity.setLegalAddress}
          />
        </View>
        <View style={s.row}>
          <Text style={s.title}>
            Телефон
            {'\u00A0'}
            <Text style={s.requiredMark}>*</Text>
          </Text>
          <Input
            type="text"
            placeholder="Введите телефон"
            value={cartState.legalEntity.phone}
            onChangeText={cartState.legalEntity.setPhone}
          />
        </View>
        <View style={s.row}>
          <Text style={s.title}>
            E-mail
          </Text>
          <Input
            type="text"
            placeholder="Введите e-mail"
            value={cartState.legalEntity.email}
            onChangeText={cartState.legalEntity.setEmail}
          />
        </View>
      </View>
    </GreyBlock>
  );
};

export default observer(LegalEntityInput);
