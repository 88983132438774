import {
  addDisposer,
  getEnv,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { autorun } from 'mobx';
import { Platform } from 'react-native';
import * as Updates from 'expo-updates';
import { AppStore } from '@app/domain/store/CoreStore/AppStore/AppStore';
import { UIStore } from '@app/domain/store/CoreStore/UI/UIStore';
import { DeviceVarsStore } from '@app/domain/store/CoreStore/DeviceVarsStore/DeviceVarsStore';
import { ProfileStore } from '@app/domain/store/CoreStore/ProfileStore/ProfileStore';
import { AuthStore } from '@app/domain/store/CoreStore/AuthStore/AuthStore';
import { Env } from '@app/domain/store/CoreStore/env.type';

/**
 * Самый верхний уровень дерева store
 */
export const CoreStore = t
  .model({
    authStore: t.optional(AuthStore, {}),
    deviceVarsStore: t.optional(DeviceVarsStore, {}),
    profileStore: t.optional(ProfileStore, {}),
    appStore: t.optional(AppStore, {}),
    UIStore: t.optional(UIStore, {}),
  })
  .named('CoreStore')
  .views((self) => ({
    // метка что состояние хранилищ восстановлено из сохраненного в localstorage
    get hydrated() {
      return (
        self.authStore.hydrated
        && self.deviceVarsStore.hydrated
      );
    },
  }))
  .views((self) => ({
    get ready() {
      if (!self.hydrated) {
        return false;
      }

      // Если пользователь авторизовался но профиль ещё загрузился.
      if (self.authStore.auth.token && !self.profileStore.profile?.id) {
        return false;
      }

      // Если пользователь не авторизовался но профиль ещё есть.
      if (self.authStore.auth.token && !self.profileStore.profile?.id) {
        return false;
      }

      return true;
    },
  }))
  .actions((self) => ({
    onLogout: () => {
      self.authStore.auth.clearState();
    },
  }))
  .actions((self) => ({
    on401ErrorEvent: () => {
      self.onLogout();
      if (Platform.OS === 'web') {
        window.location.reload();
      } else {
        Updates.reloadAsync();
      }
    },
  }))
  .actions((self) => ({
    afterCreate() {
      const { eventChannelsService: { authenticationEventChannel } } = getEnv<Env>(self);

      authenticationEventChannel.on('on401Error', self.on401ErrorEvent);

      addDisposer(
        self,
        () => {
          authenticationEventChannel.off('on401Error', self.on401ErrorEvent);
        },
      );

      addDisposer(
        self,
        autorun(() => {
          if (self.appStore.selectedShopId) {
            self.deviceVarsStore.setLastShopId(self.appStore.selectedShopId);
          }
        }),
      );

      // Если выбранный адрес доставки для другого города или вообще отсутвует в профиле, то задаём его в null.
      addDisposer(
        self,
        autorun(() => {
          if (self.appStore.selectedDeliveryAddressId && self.profileStore.profile && self.appStore.selectedCityId) {
            const deliveryAddressesForSelectedCity = self.profileStore.profile.deliveryAddressesByCityId;

            if (!deliveryAddressesForSelectedCity) {
              self.appStore.setSelectedDeliveryAddressId(null);

              return;
            }

            const haveSelectedAddressInProfile = (deliveryAddressesForSelectedCity[self.appStore.selectedCityId] || [])
              .some((da) => da.id === self.appStore.selectedDeliveryAddressId);

            if (!haveSelectedAddressInProfile) {
              self.appStore.setSelectedDeliveryAddressId(null);
            }
          }
        }),
      );
    },
  }));

export interface CoreStoreInstance extends Instance<typeof CoreStore> {
}

export interface CoreStoreModelSnapshotIn extends SnapshotIn<typeof CoreStore> {
}

export interface CoreStoreModelSnapshotOut extends SnapshotOut<typeof CoreStore> {
}
