import { useEffect, useState } from 'react';
import * as Location from 'expo-location';

type Result = {
  readonly status: 'success',
  readonly location: Location.LocationObject,
} | {
  readonly status: 'error' | 'waiting',
  readonly location: null,
};

const useCurrentLocation = (): Result => {
  const [location, setLocation] = useState<Location.LocationObject | null>(null);
  const [status, setStatus] = useState<'waiting' | 'error' | 'success'>('waiting');

  useEffect(() => {
    (async () => {
      const { status: accessStatus } = await Location.requestForegroundPermissionsAsync();
      if (accessStatus !== 'granted') {
        setStatus('error');
        setLocation(null);
        return;
      }
      const loc = await Location.getCurrentPositionAsync({});

      setLocation(loc);
    })();
  }, []);

  useEffect(() => {
    if (location) {
      setStatus('success');
    }
  }, [location]);

  if (status === 'success') {
    if (location) {
      return {
        status,
        location,
      };
    }

    return {
      status: 'waiting',
      location: null,
    };
  }

  return {
    status,
    location: null,
  };
};

export default useCurrentLocation;
