import Svg, { Path } from 'react-native-svg';
import * as React from 'react';

const MapSvg = () => (
  <Svg width="15" height="15" viewBox="0 0 15 15" fill="none">
    <Path d="M7.50005 1.25C4.74318 1.25 2.50005 3.49312 2.50005 6.24688C2.48193 10.275 7.31005 13.615 7.50005 13.75C7.50005 13.75 12.5182 10.275 12.5001 6.25C12.5001 3.49313 10.2569 1.25 7.50005 1.25ZM7.50005 8.75C6.1188 8.75 5.00005 7.63125 5.00005 6.25C5.00005 4.86875 6.1188 3.75 7.50005 3.75C8.8813 3.75 10.0001 4.86875 10.0001 6.25C10.0001 7.63125 8.8813 8.75 7.50005 8.75Z" fill="#969696" />
  </Svg>
);

export default MapSvg;
