import React, { FC, useCallback } from 'react';
import { StyleSheet } from 'react-native';
import { observer } from 'mobx-react-lite';
import GreyBlock from '@components/layout/GreyBlock';
import useStore from '@app/domain/store/useStore';
import Input from '@components/form/Input';

const s = StyleSheet.create({
  root: {
    gap: 5,
  },
});

const OrderCustomerName: FC = () => {
  const {
    appStore: {
      cartStates,
      selectedShopId,
    },
    profileStore: {
      profile,
    },
  } = useStore();

  const cartState = selectedShopId ? cartStates.get(selectedShopId) : undefined;

  const onChange = useCallback(async (value: string) => {
    cartState?.setCustomerName(value.trimStart());
  }, [cartState]);

  if (!cartState || !profile || profile.fio) {
    return null;
  }

  return (
    <GreyBlock style={s.root}>
      <Input
        type="text"
        label="Ваше имя"
        placeholder="Введите имя"
        value={cartState.customerName}
        onChangeText={onChange}
      />
    </GreyBlock>
  );
};

export default observer(OrderCustomerName);
