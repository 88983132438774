import React, {
  // CSSProperties,
  FC,
  useMemo,
} from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { LazyImageProps } from '@components/common/Picture/LazyImage/types';
import {
  // ActivityIndicator,
  // View,
  Image,
} from 'react-native';
// import colors from '@constants/colors';
import { StyleProp } from 'react-native/Libraries/StyleSheet/StyleSheet';
import type {
  // ViewStyle,
  ImageStyle,
} from 'react-native/Libraries/StyleSheet/StyleSheetTypes';

// Только для веба
const LazyImage: FC<LazyImageProps> = (
  {
    size,
    borderRadius,
    source,
    maxSize,
  },
) => {
  // const imageUrl = useMemo(() => {
  //   if (typeof source === 'number') {
  //     return '';
  //   }
  //
  //   return source.uri;
  // }, [source]);

  // const source = useMemo(() => {
  //   if (typeof source === 'string') {
  //     return {
  //       uri: source,
  //     };
  //   }
  //
  //   return source.uri;
  // }, [source]);

  const imageStyle: StyleProp<ImageStyle> = useMemo(() => ({
    width: size,
    height: size,
    borderRadius,
    maxWidth: maxSize,
    maxHeight: maxSize,
  }), [borderRadius, maxSize, size]);

  // const placeholderStyle: StyleProp<ViewStyle> = useMemo(() => ({
  //   width: size,
  //   height: size,
  //   borderRadius,
  //   maxWidth: maxSize,
  //   maxHeight: maxSize,
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // }), [borderRadius, maxSize, size]);

  return (
    <Image
      style={imageStyle}
      source={source}
      resizeMode="contain"
    />
    // <LazyLoadImage
    //   style={imageStyle}
    //   effect="opacity"
    //   src={imageUrl}
    //   placeholder={(
    //     <View style={placeholderStyle}>
    //       <ActivityIndicator color={colors.primary} />
    //     </View>
    //   )}
    // />
  );
};

export default LazyImage;
