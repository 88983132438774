import React, { FC, useCallback } from 'react';
import {
  Platform, StyleSheet, Text, View,
} from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import Button from '@components/common/Button';
import colors from '@constants/colors';
import * as Updates from 'expo-updates';

const s = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    paddingHorizontal: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: 24,
    color: colors.primary,
    fontFamily: ubuntuFont.medium,
    marginBottom: 15,
    textAlign: 'center',
  },
  description: {
    fontSize: 16,
    color: colors.text,
    fontFamily: ubuntuFont.light,
    marginBottom: 50,
    textAlign: 'center',
  },
  button: {
    position: 'absolute',
    bottom: 60,
    right: 20,
    left: 20,
  },
});

const FallbackComponent: FC = () => {
  const title = Platform.OS === 'web' ? 'Обновить страницу' : 'Перезагрузить приложение';

  const onPress = useCallback(async () => {
    if (Platform.OS === 'web') {
      window.location.reload();
      return;
    }

    await Updates.reloadAsync();
  }, []);

  return (
    <View style={s.container}>
      <Text style={s.header}>
        Произошла ошибка
      </Text>
      <Text style={s.description}>
        {`Попробуйте ${title.toLowerCase()}`}
      </Text>

      <View style={s.button}>
        <Button title={title.toUpperCase()} onPress={onPress} />
      </View>
    </View>
  );
};

export default FallbackComponent;
