import {
  Linking,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import React, { FC, useCallback, useState } from 'react';
import MinusSvg from '@svg/MinusSvg';
import colors from '@constants/colors';
import PlusSvg from '@svg/PlusSvg';
import ubuntuFont from '@constants/ubuntuFont';
import Markdown from '@components/Markdown';

const s = StyleSheet.create({
  container: {
    // flex: 1,
    borderRadius: 10,
    backgroundColor: colors.background,
    // minHeight: 60,
    marginHorizontal: 15,
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    borderRadius: 10,
    backgroundColor: colors.lightGrey,
  },
  title: {
    fontFamily: ubuntuFont.medium,
    fontSize: 14,
    lineHeight: 19,
  },
  btns: {
    width: 20,
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    paddingHorizontal: 5,
    paddingTop: 10,
    paddingBottom: 10,
  },
  // content: {
  //   fontFamily: ubuntuFont.regular,
  //   fontSize: 12,
  // },
});

const mdStyles = {
  text: {
    fontSize: 14,
    fontFamily: ubuntuFont.light,
  },
  autolink: {
    fontSize: 14,
    fontFamily: ubuntuFont.light,
  },
};

interface IProps {
  title: string;
  content: string;
}

const FAQRowItem: FC<IProps> = (
  {
    title,
    content,
  },
) => {
  const [visible, setVisible] = useState(false);
  const open = useCallback(() => {
    setVisible(true);
  }, []);

  const close = useCallback(() => {
    setVisible(
      false,
    );
  }, []);

  const toggle = useCallback(() => {
    setVisible(((prevState) => !prevState));
  }, []);

  const onLink = useCallback((url: string) => Linking.openURL(url), []);

  return (
    <View style={s.container}>
      <View>
        <TouchableOpacity onPress={toggle}>
          <View style={s.row}>
            <Text style={s.title}>
              {title}
            </Text>
            {visible && (
              <TouchableOpacity onPress={close} style={s.btns}>
                <MinusSvg color={colors.text} />
              </TouchableOpacity>
            )}
            {!visible && (
              <TouchableOpacity onPress={open}>
                <PlusSvg color={colors.text} />
              </TouchableOpacity>
            )}
          </View>
        </TouchableOpacity>
        {visible && (
          <View style={s.content}>
            <Markdown
              styles={mdStyles}
              onLink={onLink}
            >
              {content}
            </Markdown>
          </View>
        )}
      </View>
    </View>
  );
};

export default FAQRowItem;
