import {
  Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import { Enum_Userspermissionsuser_Sex } from '@app/infrastructureLayer/generated/graphql';
import {
  DeliveryAddress,
  DeliveryAddressInstance,
} from '@app/domain/store/CoreStore/ProfileStore/entities/DeliveryAddress';

export const ProfileEntity = t
  .model('ProfileEntity', {
    id: t.identifier,
    fio: t.maybeNull(t.string),
    phone: t.string,
    birthday: t.maybeNull(t.Date),
    broadcastOfferAccepted: t.optional(t.boolean, true),
    sex: t.frozen<Enum_Userspermissionsuser_Sex>(),
    registrationCompleted: t.boolean,
    deliveryAddresses: t.optional(t.map(DeliveryAddress), {}),
  })
  .views((self) => ({
    get deliveryAddressesByCityId() {
      const res: { [key: string]: DeliveryAddressInstance[] | undefined } = {};
      self.deliveryAddresses.forEach((a) => {
        const { cityId } = a;

        if (!res[cityId]) {
          res[cityId] = [];
        }

        const dArray = res[cityId] || [];
        dArray.push(a);

        if (!res[cityId]) {
          res[cityId] = dArray;
        }
      });

      return res;
    },
  }));

export interface ProfileEntityInstance extends Instance<typeof ProfileEntity> {
}

export interface ProfileEntitySnapshotIn extends SnapshotIn<typeof ProfileEntity> {
}

export interface ProfileEntitySnapshotOut extends SnapshotOut<typeof ProfileEntity> {
}
