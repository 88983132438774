import React, { FC, useCallback, useMemo } from 'react';
import { View, StyleSheet, Pressable } from 'react-native';
import { useGetMyOrdersQuery } from '@app/infrastructureLayer/generated/graphql';
import { useNavigation } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import { uniqBy } from 'lodash';
import OrderInfoBanner from '@components/OrderInfoBanner/OrderInfoBanner';
import useStore from '@app/domain/store/useStore';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 10,
  },
});

const LastOrderInfoBanner: FC = () => {
  const {
    profileStore: {
      profile,
    },
  } = useStore();

  const hasAuth = !!profile?.id;

  const { data } = useGetMyOrdersQuery({
    variables: {
      pagination: {
        limit: 1,
      },
      filters: {
        orderCookingStatus: {
          not: {
            in: ['Delivered', 'Closed', 'Cancelled'],
          },
        },
        // orderCookingStatus
        // Enum or null
        // "Unconfirmed"
        // "WaitCooking"
        // "ReadyForCooking"
        // "CookingStarted"
        // "CookingCompleted"
        // "Waiting"
        // "OnWay"
        // "Delivered"
        // "Closed"
        // "Cancelled"
        status: {
          not: {
            eq: 'CANCELED',
          },
        },
      },
      sort: 'id:desc',
    },
    skip: !hasAuth,
  });

  const order = hasAuth ? data?.myOrders?.data[0] : undefined;

  const navigation = useNavigation();

  const onPressOrder = useCallback(() => {
    if (!order) {
      return;
    }

    navigation.navigate('OrderScreen', {
      orderId: order.id,
    });
  }, [navigation, order]);

  const productImagesUrl = useMemo(() => {
    const onlyIdAndURLs = uniqBy(order?.orderItems?.map((item) => ({
      id: item.snapshot.product.id,
      url: item.snapshot.product.imageUrl_100 || item.snapshot.product.imageUrl,
    })), (v) => v.id);

    return onlyIdAndURLs.map((item) => item.url) || [];
  }, [order]);

  if (!order) {
    return <View />;
  }

  return (
    <Pressable onPress={onPressOrder}>
      <View style={s.root}>
        <OrderInfoBanner
          orderCookingStatus={order.orderCookingStatus || ''}
          productImagesUrl={productImagesUrl}
          orderId={order.id}
        />
      </View>
    </Pressable>
  );
};

export default observer(LastOrderInfoBanner);
