import {
  StyleSheet,
  Text,
  TouchableOpacity,
} from 'react-native';
import Typograf from 'typograf';
import React, { JSX, useCallback } from 'react';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import { useInstance } from 'react-ioc';

type IProps<TValue> = {
  isSelected: boolean,
  value: TValue,
  title: string,
  onPress: (value: TValue) => void;
};

const s = StyleSheet.create({
  sectionContainer: {
    flex: 1,
    flexBasis: '30%',
    borderRadius: 9,
    // height: 30,
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: colors.switch.notSelected.bg,
    alignSelf: 'stretch',
  },
  selectedSectionContainer: {
    flex: 1,
    flexBasis: '30%',
    borderRadius: 9,
    // height: 25,
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: colors.switch.selected.bg,
    alignSelf: 'stretch',
  },
  text: {
    textAlign: 'center',
    fontFamily: ubuntuFont.light,
    fontSize: 14,
    marginBottom: 2,
    color: colors.switch.notSelected.text,
  },
  selectedText: {
    textAlign: 'center',
    fontFamily: ubuntuFont.light,
    fontSize: 14,
    marginBottom: 2,
    color: colors.switch.selected.text,
  },
});

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SegmentedItem = <T, >(
  {
    isSelected,
    value,
    onPress: _onPress,
    title,
  }: IProps<T>,
): JSX.Element => {
  const tp = useInstance(Typograf);

  const onPress = useCallback(() => {
    _onPress(value);
  }, [_onPress, value]);

  return (
    <TouchableOpacity onPress={onPress} style={isSelected ? s.selectedSectionContainer : s.sectionContainer}>
      <Text style={isSelected ? s.selectedText : s.text}>
        {tp.execute(title)}
      </Text>
    </TouchableOpacity>
  );
};

export default SegmentedItem;
