import {
  View, ScrollView, StyleSheet, ActivityIndicator,
} from 'react-native';
import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import Screen from '@components/layout/Screen';
import Back from '@components/layout/Back';
import FAQRowItem from '@screens/Info/FaqScreen/includes/FAQRowItem';
import useSWR from 'swr';
import { RootStackScreenProps } from '@navigation/types';
import { useInstance } from 'react-ioc';
import { Api } from '@app/infrastructureLayer/generated/Api';
import { useFocusEffect } from '@react-navigation/native';
import colors from '@constants/colors';

const s = StyleSheet.create({
  scroll: {
    rowGap: 10,
  },
  content: {
    paddingTop: 20,
    paddingBottom: 15,
  },
});

const FAQScreen: FC<RootStackScreenProps<'FAQScreen'>> = (
  {
    navigation,
  },
) => {
  const title = 'Ответы на вопросы';

  const apiClient = useInstance(Api);

  const {
    data,
    mutate,
  } = useSWR(
    'faq',
    async () => {
      const res = await apiClient.faqs.getFaqs({ 'pagination[limit]': 100 });

      return res.data.data;
    },
    {
      refreshInterval: 30000,
      errorRetryInterval: 500,
      errorRetryCount: Infinity,
      shouldRetryOnError: true,
    },
  );

  useFocusEffect(useCallback(() => {
    mutate();
  }, [mutate]));

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate('Tabs', {
      screen: 'ParamsScreen',
    });
  }, [navigation]);

  return (
    <Screen>
      <Back
        title={title}
        onPress={onBack}
      />
      <ScrollView
        contentContainerStyle={s.content}
      >
        <View style={s.scroll}>
          {!data && <ActivityIndicator color={colors.primary} />}
          {!!data && data.map((item) => {
            if (!item.attributes) {
              return null;
            }

            return (
              <FAQRowItem
                key={item.id}
                title={item.attributes?.title}
                content={item.attributes?.content}
              />
            );
          })}
        </View>
      </ScrollView>
    </Screen>
  );
};

export default observer(FAQScreen);
