import { z } from 'zod';
import { Enum_Componentuserdeliveryaddress_Type } from '@app/infrastructureLayer/generated/graphql';

const addressSchema = z.object({
  title: z.string()
    .trim()
    .optional(),
  cityId: z.string()
    .trim()
    .nonempty('Обязательное поле'),
  address: z.string()
    .trim()
    .nonempty('Обязательное поле'),
  room: z.string()
    .optional(),
  entrance: z.string()
    .optional(),
  floor: z.string()
    .optional(),
  intercomCode: z.string()
    .optional(),
  comment: z.string()
    .optional(),
  type: z.nativeEnum(
    Enum_Componentuserdeliveryaddress_Type,
  ).optional(),
});

export default addressSchema;
