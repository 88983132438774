import MinusSvg from '@svg/MinusSvg';
import PlusSvg from '@svg/PlusSvg';
import React, { FC } from 'react';
import {
  Text,
  View,
  StyleSheet,
  Pressable,
} from 'react-native';
import generalConfig from '@constants/generalConfig';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';
import XSvg from '@svg/XSvg';
import { observer } from 'mobx-react-lite';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    backgroundColor: colors.background,
    width: 130,
    height: 30,
    borderRadius: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  rootWitItems: {
    backgroundColor: colors.primary,
  },
  countText: {
    color: colors.text,
    fontSize: 12,
    fontFamily: ubuntuFont.regular,
    height: 14,
  },
  countTextHaveAmount: {
    color: colors.white,
    fontSize: 12,
    fontFamily: ubuntuFont.regular,
    height: 14,
  },
  countRoot: {
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    gap: 5,
  },
});

interface IProps {
  reduce: () => void,
  increase: () => void,
  amount: number,
  price: number,
  maxAmount?: number,
}

const Counter: FC<IProps> = (
  {
    reduce,
    increase,
    amount,
    price,
    maxAmount,
  },
) => {
  const reduceDisabled = amount === 0;
  const increaseDisabled = typeof maxAmount === 'number' && amount >= maxAmount;

  let plusColor = colors.text;

  if (amount > 0) {
    plusColor = colors.white;

    if (increaseDisabled) {
      plusColor = colors.primary;
    }
  } else if (increaseDisabled) {
    plusColor = colors.lightGrey;
  }

  let minusColor = colors.text;

  if (amount > 0) {
    minusColor = colors.white;

    if (increaseDisabled) {
      minusColor = colors.lightGrey;
    }
  } else if (increaseDisabled) {
    minusColor = colors.lightGrey;
  }

  return (
    <View style={StyleSheet.flatten([s.root, amount > 0 && s.rootWitItems])}>

      <Pressable onPress={reduce} hitSlop={10} disabled={reduceDisabled}>
        {/* <MinusSvg color={amount === 0 ? colors.text : colors.white} /> */}
        <MinusSvg color={minusColor} />
      </Pressable>

      <View style={s.countRoot}>
        {price === 0 && (
          <Text style={amount > 0 ? s.countTextHaveAmount : s.countText}>
            {amount}
          </Text>
        )}
        {price > 0 && (
          <>
            {amount > 0 && (
              <>
                <Text style={s.countTextHaveAmount}>
                  {amount}
                </Text>
                <XSvg color={colors.white} />
              </>
            )}
            <Text style={amount > 0 ? s.countTextHaveAmount : s.countText}>
              {`${price} ${generalConfig.currencyLabel}`}
            </Text>
          </>
        )}
      </View>

      <Pressable onPress={increase} hitSlop={10} disabled={increaseDisabled}>
        <PlusSvg color={plusColor} />
      </Pressable>
    </View>
  );
};
export default observer(Counter);
