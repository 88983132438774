import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';

export const GlobalSettings = t
  .model('GlobalSettings', {
    useBroadcastOfferAccept: t.boolean,
    broadcastOfferAcceptedRequired: t.boolean,
    isHotelMode: t.boolean,
    useBonuses: t.boolean,
    useFaq: t.boolean,
    policyUrl: t.string,
    termUrl: t.string,
  });

export interface GlobalSettingsInstance extends Instance<typeof GlobalSettings> {
}

export interface GlobalSettingsSnapshotIn extends SnapshotIn<typeof GlobalSettings> {
}

export interface GlobalSettingsSnapshotOut extends SnapshotOut<typeof GlobalSettings> {
}
