import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';

export const ModifierCategory = t
  .model('ModifierCategory', {
    id: t.identifier,
    title: t.string,
  });

export interface ModifierCategoryInstance extends Instance<typeof ModifierCategory> {
}

export interface ModifierCategorySnapshotIn extends SnapshotIn<typeof ModifierCategory> {
}

export interface ModifierCategorySnapshotOut extends SnapshotOut<typeof ModifierCategory> {
}
