import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import colors from '@constants/colors';

type IProps = {
  color?:string;
  width?:number;
  height?:number;
};

const MinusSvg = ({ color = colors.white, width = 13, height = 11 }: IProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 13 11"
    fill="none"
  >
    <Path d="M1 5.5H12" stroke={color} strokeWidth={2} strokeLinecap="round" />
  </Svg>
);
export default MinusSvg;
