import React, {
  FC, useCallback, useRef, useState,
} from 'react';
import {
  LayoutChangeEvent,
  LayoutRectangle,
  ScrollView, StyleSheet, useWindowDimensions, View,
} from 'react-native';
import { observer } from 'mobx-react-lite';
import useStore from '@app/domain/store/useStore';
import { useFocusEffect } from '@react-navigation/native';

import Category from './includes/Category';

const s = StyleSheet.create({
  scroll: {
    paddingBottom: 5,
  },
  scrollContainer: {
    gap: 10,
    paddingHorizontal: 16,
  },
  root: {
    paddingTop: 10,
  },
});

interface IProps {
  onCategoryPress: (categoryId: string) => void;
}

const Categories: FC<IProps> = (
  {
    onCategoryPress,
  },
) => {
  const scrollViewRef = useRef<ScrollView>(null);

  const {
    appStore: {
      categoriesForActiveMenu,
      activeMenu,
    },
  } = useStore();

  const selectedCategoryId = activeMenu?.selectedCategoryId;
  const setSelectedCategoryId = activeMenu?.setSelectedCategoryId;

  const [
    dataSourceCordsByCategoryId,
    setDataSourceCordsByCategoryId,
  ] = useState<Map<string, LayoutRectangle>>(new Map());

  const { width } = useWindowDimensions();

  const _scrollTo = useCallback(() => {
    if (!selectedCategoryId) {
      return;
    }

    const elementOffset = dataSourceCordsByCategoryId.get(selectedCategoryId);

    if (!elementOffset) {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTo({
          y: 0,
          x: 0,
          animated: false,
        });
      }

      return;
    }

    if (typeof scrollViewRef.current?.scrollTo === 'function') {
      const x = elementOffset.x + elementOffset.width / 2 - width / 2;
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTo({
          y: 0,
          x,
          animated: true,
        });
      }
    }
  }, [width, dataSourceCordsByCategoryId, selectedCategoryId]);

  useFocusEffect(useCallback(() => {
    _scrollTo();
  }, [_scrollTo]));

  const onLayoutCategory = useCallback((id: string) => (event: LayoutChangeEvent) => {
    dataSourceCordsByCategoryId.set(id, event.nativeEvent.layout);
    setDataSourceCordsByCategoryId(dataSourceCordsByCategoryId);
  }, [dataSourceCordsByCategoryId]);

  const onSelect = useCallback((categoryId: string) => {
    if (setSelectedCategoryId) {
      setSelectedCategoryId(categoryId);
    }
    onCategoryPress(categoryId);
  }, [onCategoryPress, setSelectedCategoryId]);

  if (!activeMenu) {
    return null;
  }

  return (
    <View style={s.root}>
      <ScrollView
        ref={scrollViewRef}
        showsHorizontalScrollIndicator={false}
        horizontal
        style={s.scroll}
        contentContainerStyle={s.scrollContainer}
      >
        {categoriesForActiveMenu.map((
          {
            id,
            title,
          },
        ) => (
          <View
            key={id}
            onLayout={onLayoutCategory(id)}
          >
            <Category
              id={id}
              title={title}
              selected={id === selectedCategoryId}
              onSelect={onSelect}
            />
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

export default observer(Categories);
