import { observer } from 'mobx-react-lite';
import { Polygon } from 'react-yandex-maps';
import React, { FC, useMemo } from 'react';
import {
  Zone,
} from '@screens/SelectDeliveryAddressScreen/includes/SelectDeliveryAddressOnMap/SelectDeliveryAddressOnMap';

interface IProps {
  zone: Zone;
}

const DeliveryZonePolygon: FC<IProps> = (
  {
    zone,
  },
) => {
  const geometry = useMemo(() => ([
    zone.polygon,
  ]), [zone.polygon]);

  const properties = useMemo(() => ({
    hintContent: zone.name,
  }), [zone.name]);

  const options = useMemo(() => ({
    fillColor: zone.color,
    strokeColor: '#000000',
    opacity: 0.2,
    strokeWidth: 1,
    strokeStyle: 'shortdash',
  }), [zone.color]);

  return (
    <Polygon
      geometry={geometry}
      properties={properties}
      options={options}
    />
  );
};

export default observer(DeliveryZonePolygon);
