import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { connectReduxDevtools } from 'mst-middlewares';
import { ModifiersStateItemModel } from '@screens/MenuItemScreen/model/ModifiersStateItemModel';
import { Platform } from 'react-native';

export const ModifiersStateModel = t
  .model('ModifiersStateModel', {
    productId: t.string,
    state: t.optional(t.map(ModifiersStateItemModel), {}),
  })
  .views((self) => ({
    get keyValueState() {
      const result: {
        [key: string]: number
      } = {};

      self.state.forEach((item) => {
        result[item.modifierId] = item.amount;
      });

      return result;
    },
  }))
  .actions((self) => ({
    increaseAmount: (modifierId: string) => {
      const item = self.state.get(modifierId);

      if (item) {
        item.increaseAmount();
        return;
      }

      self.state.set(modifierId, ModifiersStateItemModel.create({
        modifierId,
        amount: 1,
      }));
    },
    reduceAmount: (modifierId: string) => {
      const item = self.state.get(modifierId);

      if (!item) {
        return;
      }

      item.reduceAmount();
    },
    selectOneOf: (modifierId: string, otherModifiersIds: string[]) => {
      otherModifiersIds.forEach((id) => {
        self.state.delete(id);
      });

      const item = self.state.get(modifierId);

      if (item) {
        return;
      }

      self.state.set(modifierId, ModifiersStateItemModel.create({
        modifierId,
        amount: 1,
      }));
    },
    afterCreate() {
      if (process.env.NODE_ENV === 'development' && Platform.OS === 'web') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line import/no-extraneous-dependencies
        import('remotedev').then((remotedev) => {
          connectReduxDevtools(remotedev, self);
        });
      }
    },
  }));

export interface ModifiersStateModelInstance extends Instance<typeof ModifiersStateModel> {
}

export interface ModifiersStateModelSnapshotIn extends SnapshotIn<typeof ModifiersStateModel> {
}

export interface ModifiersStateModelSnapshotOut extends SnapshotOut<typeof ModifiersStateModel> {
}
