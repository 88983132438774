import 'react-native-get-random-values';
import '@components/sheets/registerAllSheets';
import 'react-native-reanimated';
import 'moment/locale/ru';
import React, { FC, useCallback, useEffect } from 'react';
import { provider, toFactory, useInstance } from 'react-ioc';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { StyleSheet, Platform, View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { connectReduxDevtools } from 'mst-middlewares';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { NotifierWrapper } from 'react-native-notifier';
import { PortalProvider } from '@gorhom/portal';
import { SheetProvider } from 'react-native-actions-sheet';
import { TokenProvider } from '@app/infrastructureLayer/TokenProvider';
import { StatusBar } from 'expo-status-bar';
import { observer } from 'mobx-react-lite';
import { Store } from '@app/domain/store/Store';
import { Env } from '@app/domain/store/CoreStore/env.type';
import { Api } from '@app/infrastructureLayer/generated/Api';
import EventChannelsServiceFactory from '@app/domain/serviceLayer/eventChannels/service/EventChannelsServiceFactory';
import WebAppVersionSynchronizer from '@components/WebAppVersionSynchronizer.web';
import CheckoutContextProvider from '@components/CheckoutContextProvider';
import EventChannelsService from '@app/domain/serviceLayer/eventChannels/service/EventChannelsService';
import useIdentifySentryUser from '@sentry-data/useIdentifySentryUser';
import usePushNotification from '@hooks/usePushNotification';
import ApolloClientFactory from '@app/infrastructureLayer/ApolloClientFactory';
import useCachedResources from '@hooks/useCachedResources';
import FullScreenLoading from '@components/common/FullScreenLoading';
import FallbackComponent from '@components/FallbackComponent';
import ApiClientFactory from '@app/infrastructureLayer/ApiClientFactory';
import ErrorBoundary from 'react-native-error-boundary';
import useAppUpdater from '@hooks/useAppUpdater';
import initSentry from '@sentry-data/initSentry';
import Navigation from '@navigation/Navigation';
import useStore from '@app/domain/store/useStore';
import Typograf from 'typograf';
import moment from 'moment-timezone';
import * as Updates from 'expo-updates';

moment.locale('ru');

const s = StyleSheet.create({
  root: {
    flex: 1,
  },
});

initSentry();

const App: FC = () => {
  const { hasUpdates } = useAppUpdater();
  usePushNotification();
  const { hydrated } = useStore();
  const apolloClient = useInstance(ApolloClient);
  const isLoadingCachedResourcesComplete = useCachedResources();

  useEffect(() => {
    if (hasUpdates) {
      setTimeout(async () => {
        await Updates.reloadAsync();
      }, 500);
    }
  }, [hasUpdates]);

  useIdentifySentryUser();

  const onError = useCallback((error: Error, stackTrace: string) => {
    console.error('error: ', error);
    console.info('stackTrace: ', stackTrace);
  }, []);

  if (hasUpdates) {
    return null;
  }

  const isLoading = !hydrated || !isLoadingCachedResourcesComplete;

  return (
    <ErrorBoundary onError={onError} FallbackComponent={FallbackComponent}>
      <StatusBar
        /* eslint-disable-next-line react/style-prop-object */
        style="dark"
        hidden={false}
        translucent
      />
      {Platform.OS === 'web' && !__DEV__ && (
        <WebAppVersionSynchronizer />
      )}
      <ApolloProvider client={apolloClient}>
        <CheckoutContextProvider>
          <SafeAreaProvider style={s.root}>
            <SheetProvider>
              <GestureHandlerRootView style={s.root}>
                <PortalProvider>
                  <NotifierWrapper>
                    <View style={s.root}>
                      {isLoading && <FullScreenLoading title="Загрузка" />}
                      {!isLoading && (
                        <Navigation />
                      )}
                    </View>
                  </NotifierWrapper>
                </PortalProvider>
              </GestureHandlerRootView>
            </SheetProvider>
          </SafeAreaProvider>
        </CheckoutContextProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

export default provider(
  [EventChannelsService, toFactory(() => EventChannelsServiceFactory.create())],

  [TokenProvider, toFactory(() => TokenProvider.create())],

  [
    ApolloClient,
    toFactory(
      [EventChannelsService, TokenProvider],
      (eventChannelsService, tokenProvider) => ApolloClientFactory.create(tokenProvider, eventChannelsService),
    ),
  ],

  [
    Api,
    toFactory([TokenProvider], (tokenProvider) => ApiClientFactory.create(tokenProvider)),
  ],
  [
    Typograf,
    toFactory(() => new Typograf({ locale: ['ru', 'en-US'] })),
  ],
  [
    Store,
    toFactory(
      [EventChannelsService, TokenProvider],
      (eventChannelsService, tokenProvider) => {
        const env: Env = {
          tokenProvider,
          eventChannelsService,
        };

        const storeInstance = Store.create(env);

        // https://github.com/MargaretKrutikova/mst-react-ts-guide#connect-to-redux-devtools
        if (process.env.NODE_ENV === 'development' && Platform.OS === 'web') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line import/no-extraneous-dependencies
          import('remotedev').then((remotedev) => {
            connectReduxDevtools(remotedev, storeInstance);
          });
        }

        return storeInstance;
      },
    ),
  ],
)(observer(App));
