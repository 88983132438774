/* eslint-disable */
import React from 'react';
import head from 'lodash/head';
import includes from 'lodash/includes';
import map from 'lodash/map';
import noop from 'lodash/noop';
import some from 'lodash/some';
import size from 'lodash/size';
import { Image, Text, View } from 'react-native';
import SimpleMarkdown from 'simple-markdown';
// @ts-ignore
import Lightbox from 'react-native-lightbox';

export default (styles: any, opts: any = {}) => {
  const enableLightBox = opts.enableLightBox || false;
  const { navigator } = opts;

  const LINK_INSIDE = '(?:\\[[^\\]]*\\]|[^\\]]|\\](?=[^\\[]*\\]))*';
  const LINK_HREF_AND_TITLE = '\\s*<?([^\\s]*?)>?(?:\\s+[\'"]([\\s\\S]*?)[\'"])?\\s*';
  const pressHandler = (target: any) => {
    if (opts.onLink) {
      opts.onLink(target)
        .catch((error: any) => {
          console.error('There has been a problem with this action. ', error.message);
          throw error;
        });
    }
  };
  const parseInline = (parse: any, content: any, state: any) => {
    const isCurrentlyInline = state.inline || false;
    state.inline = true;
    const result = parse(content, state);
    state.inline = isCurrentlyInline;
    return result;
  };

  const parseCaptureInline = (capture: any[], parse: any, state: any) => ({
    content: parseInline(parse, capture[2], state),
  });

  return {
    autolink: {
      react: (node:any, output:any, { ...state }) => {
        state.withinText = true;
        const _pressHandler = () => {
          pressHandler(node.target);
        };
        return React.createElement(Text, {
          key: state.key,
          style: styles.autolink,
          onPress: _pressHandler,
          selectable: true,
        }, output(node.content, state));
      },
    },
    blockQuote: {
      react: (node:any, output:any, { ...state }) => {
        state.withinQuote = true;

        const img = React.createElement(View, {
          key: state.key - state.key,
          style: [styles.blockQuoteSectionBar, styles.blockQuoteBar],
        });

        const blockQuote = React.createElement(Text, {
          key: state.key,
          style: styles.blockQuoteText,
          selectable: true,
        }, output(node.content, state));

        return React.createElement(View, {
          key: state.key,
          style: [styles.blockQuoteSection, styles.blockQuoteText],
        }, [img, blockQuote]);
      },
    },
    br: {
      react: (node:any, output:any, { ...state }) => React.createElement(Text, {
        key: state.key,
        style: styles.br,
        selectable: true,
      }, '\n\n'),
    },
    codeBlock: {
      react: (node:any, output:any, { ...state }) => {
        state.withinText = true;
        return React.createElement(Text, {
          key: state.key,
          style: styles.codeBlock,
          selectable: true,
        }, node.content);
      },
    },
    del: {
      react: (node:any, output:any, { ...state }) => {
        state.withinText = true;
        return React.createElement(Text, {
          key: state.key,
          style: styles.del,
          selectable: true,
        }, output(node.content, state));
      },
    },
    em: {
      react: (node:any, output:any, { ...state }) => {
        state.withinText = true;
        state.style = {
          ...(state.style || {}),
          ...styles.em,
        };
        return React.createElement(Text, {
          key: state.key,
          style: styles.em,
          selectable: true,
        }, output(node.content, state));
      },
    },
    heading: {
      match: SimpleMarkdown.blockRegex(/^ *(#{1,6}) *([^\n]+?) *#* *(?:\n *)+/),
      react: (node:any, output:any, { ...state }) => {
        state.withinText = true;
        state.withinHeading = true;

        state.style = {
          ...(state.style || {}),
          ...styles[`heading${node.level}`],
        };

        return React.createElement(Text, {
          key: state.key,
          style: state.style,
          selectable: true,
        }, output(node.content, state));
      },
    },
    hr: {
      react: (node:any, output:any, { ...state }) => React.createElement(View, {
        key: state.key,
        style: styles.hr,
      }),
    },
    image: {
      react: (node:any, output:any, { ...state }) => {
        const imageParam = opts.imageParam ? opts.imageParam : '';
        const target = node.target + imageParam;
        const image = React.createElement(Image, {
          key: state.key,
          source: { uri: target },
          style: styles.image,
        });
        if (enableLightBox) {
          return React.createElement(Lightbox, {
            activeProps: styles.imageBox,
            key: state.key,
            navigator,
            onOpen: opts.onImageOpen,
            onClose: opts.onImageClose,
          }, image);
        }
        return image;
      },
    },
    inlineCode: {
      parse: parseCaptureInline,
      react: (node:any, output:any, { ...state }) => {
        state.withinText = true;
        return React.createElement(Text, {
          key: state.key,
          style: styles.inlineCode,
          selectable: true,
        }, output(node.content, state));
      },
    },
    link: {
      match: SimpleMarkdown.inlineRegex(new RegExp(
        `^\\[(${LINK_INSIDE})\\]\\(${LINK_HREF_AND_TITLE}\\)`,
      )),
      react: (node:any, output:any, { ...state }) => {
        state.withinLink = true;
        const _pressHandler = () => {
          pressHandler(node.target);
        };
        const link = React.createElement(Text, {
          key: state.key,
          style: styles.autolink,
          onPress: _pressHandler,
          selectable: true,
        }, output(node.content, state));
        state.withinLink = false;
        return link;
      },
    },
    list: {
      react: (node:any, output:any, { ...state }) => {
        let numberIndex = 1;
        const items = map(node.items, (item, i) => {
          let bullet;
          state.withinList = false;

          if (node.ordered) {
            bullet = React.createElement(Text, {
              key: 0,
              style: styles.listItemNumber,
              selectable: true,
            }, `${numberIndex}. `);
          } else {
            bullet = React.createElement(Text, {
              key: 0,
              style: styles.listItemBullet,
              selectable: true,
            }, '\u2022 ');
          }

          if (item.length > 1) {
            if (item[1].type === 'list') {
              state.withinList = true;
            }
          }

          const content = output(item, state);
          let listItem;
          // @ts-ignore
          if (includes(['text', 'paragraph', 'strong'], (head(item) || {}).type) && state.withinList === false) {
            state.withinList = true;
            listItem = React.createElement(Text, {
              style: [styles.listItemText, { marginBottom: 0 }],
              key: 1,
              selectable: true,
            }, content);
          } else {
            listItem = React.createElement(View, {
              style: styles.listItemText,
              key: 1,
            }, content);
          }
          state.withinList = false;
          numberIndex++;

          return React.createElement(View, {
            key: i,
            style: styles.listRow,
          }, [bullet, listItem]);
        });

        return React.createElement(View, {
          key: state.key,
          style: styles.list,
        }, items);
      },
    },
    sublist: {
      react: (node:any, output:any, { ...state }) => {
        const items = map(node.items, (item, i) => {
          let bullet;
          if (node.ordered) {
            bullet = React.createElement(Text, {
              key: 0,
              style: styles.listItemNumber,
              selectable: true,
            }, `${i + 1}. `);
          } else {
            bullet = React.createElement(Text, {
              key: 0,
              style: styles.listItemBullet,
              selectable: true,
            }, '\u2022 ');
          }

          const content = output(item, state);
          let listItem;
          state.withinList = true;
          // @ts-ignore
          if (includes(['text', 'paragraph', 'strong'], (head(item) || {}).type)) {
            listItem = React.createElement(Text, {
              style: styles.listItemText,
              key: 1,
              selectable: true,
            }, content);
          } else {
            listItem = React.createElement(View, {
              style: styles.listItem,
              key: 1,
            }, content);
          }
          state.withinList = false;
          return React.createElement(View, {
            key: i,
            style: styles.listRow,
          }, [bullet, listItem]);
        });

        return React.createElement(View, {
          key: state.key,
          style: styles.sublist,
        }, items);
      },
    },
    mailto: {
      react: (node:any, output:any, { ...state }) => {
        state.withinText = true;
        return React.createElement(Text, {
          key: state.key,
          style: styles.mailto,
          onPress: noop,
          selectable: true,
        }, output(node.content, state));
      },
    },
    newline: {
      react: (node:any, output:any, { ...state }) => React.createElement(Text, {
        key: state.key,
        style: styles.newline,
        selectable: true,
      }, '\n'),
    },
    paragraph: {
      react: (node:any, output:any, { ...state }) => {
        let paragraphStyle = styles.paragraph;
        if (some(node.content, { type: 'image' })) {
          state.withinParagraphWithImage = true;
          const paragraph = React.createElement(View, {
            key: state.key,
            style: styles.paragraphWithImage,
          }, output(node.content, state));
          state.withinParagraphWithImage = false;
          return paragraph;
        }
        if (size(node.content) < 3 && some(node.content, { type: 'strong' })) {
          paragraphStyle = styles.paragraphCenter;
        }
        if (state.withinList) {
          paragraphStyle = [paragraphStyle, styles.noMargin];
        }
        return React.createElement(Text, {
          key: state.key,
          style: paragraphStyle,
          selectable: true,
        }, output(node.content, state));
      },
    },
    strong: {
      react: (node:any, output:any, { ...state }) => {
        state.withinText = true;
        state.style = {
          ...(state.style || {}),
          ...styles.strong,
        };
        return React.createElement(Text, {
          key: state.key,
          style: state.style,
          selectable: true,
        }, output(node.content, state));
      },
    },
    table: {
      react: (node:any, output:any, { ...state }) => {
        const headers = map(node.header, (content, i) => React.createElement(Text, {
          key: i,
          style: styles.tableHeaderCell,
          selectable: true,
        }, output(content, state)));

        const header = React.createElement(View, {
          key: -1,
          style: styles.tableHeader,
        }, headers);

        const rows = map(node.cells, (row, r) => {
          const cells = map(row, (content, c) => React.createElement(View, {
            key: c,
            style: styles.tableRowCell,
          }, output(content, state)));
          const rowStyles = [styles.tableRow];
          // @ts-ignore
          if (node.cells.length - 1 === r) {
            rowStyles.push(styles.tableRowLast);
          }
          return React.createElement(View, {
            key: r,
            style: rowStyles,
          }, cells);
        });

        return React.createElement(View, {
          key: state.key,
          style: styles.table,
        }, [header, rows]);
      },
    },
    text: {
      react: (node:any, output:any, { ...state }) => {
        let textStyle = {
          ...styles.text,
          ...(state.style || {}),
        };

        if (state.withinLink) {
          textStyle = [styles.text, styles.autolink];
        }

        if (state.withinQuote) {
          textStyle = [styles.text, styles.blockQuoteText];
        }

        return React.createElement(Text, {
          key: state.key,
          style: textStyle,
          selectable: true,
        }, node.content);
      },
    },
    u: {
      react: (node:any, output:any, { ...state }) => {
        state.withinText = true;
        state.style = {
          ...(state.style || {}),
          ...styles.u,
        };
        return React.createElement(Text, {
          key: state.key,
          style: styles.strong,
          selectable: true,
        }, output(node.content, state));
      },
    },
    url: {
      react: (node:any, output:any, { ...state }) => {
        state.withinText = true;
        const _pressHandler = () => {
          pressHandler(node.target);
        };
        return React.createElement(Text, {
          key: state.key,
          style: styles.autolink,
          onPress: _pressHandler,
          selectable: true,
        }, output(node.content, state));
      },
    },
  };
};
/* eslint-enable */
