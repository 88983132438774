import colors from '@constants/colors';
import * as React from 'react';
import { FC } from 'react';
import { Switch } from 'react-native';

interface IEwSwitch {
  enabled: boolean,
  onValueChange: (value: boolean) => void,
}

const EwSwitch: FC<IEwSwitch> = ({ enabled, onValueChange }) => (
  <Switch
    trackColor={colors.switch2.trackColor}
    thumbColor={
      enabled ? colors.switch2.thumbColor.true : colors.switch2.thumbColor.false
    }
    ios_backgroundColor={colors.switch2.iosBg}
    onValueChange={onValueChange}
    value={enabled}
  />
);

export default EwSwitch;
