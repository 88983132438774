import { Platform } from 'react-native';
import Constants from 'expo-constants';
import { z } from 'zod';

export const extraCustomSchema = z.object({
  PRIMARY_COLOR: z
    .string()
    .regex(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/),
  COMPANY_LOGO: z.string()
    .url(),
  LINKING_URL: z.string()
    .url(),
  API_BASE: z.string()
    .url(),
  SENTRY_DSN: z.optional(z.string()),
  YANDEX_MAP_KEY: z.string(),
});

interface Env {
  API_BASE: string;
  SENTRY_DSN?: string;
  YANDEX_MAP_KEY: string;
}

export const getCustomConfig = () => {
  const extra = Constants?.expoConfig?.extra;

  if (!extra) {
    throw new Error('Не указаны параметры extra');
  }

  const res = extraCustomSchema.safeParse(extra.custom);

  if (!res.success) {
    console.error(res.error);
    throw new Error('Параметры extra имеют недопустимые значения');
  }

  return res.data;
};

export const getLinkingScheme = () => {
  const config = Constants?.expoConfig;

  if (!config) {
    throw new Error('Не указаны параметры config');
  }

  if (!config.scheme) {
    throw new Error('Не указана схема linking');
  }

  return config.scheme;
};

const sourceEnv = Platform.OS === 'web' ? window._env_ : process.env;

export function getEnvironment(): Env {
  const isProduction = process.env.NODE_ENV === 'production';
  const customConfig = getCustomConfig();

  if (isProduction && Platform.OS !== 'web') {
    return {
      API_BASE: customConfig.API_BASE,
      SENTRY_DSN: customConfig.SENTRY_DSN || '',
      YANDEX_MAP_KEY: customConfig.YANDEX_MAP_KEY,
    };
  }

  return {
    API_BASE: sourceEnv.EXPO_PUBLIC_API_BASE || '',
    SENTRY_DSN: sourceEnv.EXPO_PUBLIC_SENTRY_DSN || '',
    YANDEX_MAP_KEY: sourceEnv.EXPO_PUBLIC_YANDEX_MAP_KEY,
  };
}

export const getApiBase = () => getEnvironment().API_BASE;
export const getSentryDSN = () => getEnvironment().SENTRY_DSN;
export const getYandexMapKey = () => getEnvironment().YANDEX_MAP_KEY;
