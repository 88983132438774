import React, {
  FC, useCallback,
} from 'react';
import {
  TouchableOpacity, Text, StyleSheet,
  View,
} from 'react-native';
import colors from '@constants/colors';
import ArrowDownSvg from '@svg/ArrowDownSvg';
import ubuntuFont from '@constants/ubuntuFont';
import useStore from '@app/domain/store/useStore';
import { observer } from 'mobx-react-lite';
import { SheetManager } from 'react-native-actions-sheet';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
  },
  text: {
    fontFamily: ubuntuFont.light,
    fontSize: 14,
    color: colors.text,
  },
  citySelector: {
    padding: 16,
    paddingBottom: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

const CitySelector: FC = () => {
  const {
    appStore: {
      city,
      citiesWithShops,
    },
  } = useStore();

  const openCitySelectorSheet = useCallback(() => {
    SheetManager.show('select-city-sheet');
  }, []);

  if (citiesWithShops.length < 2) {
    return null;
  }

  return (
    <View style={s.citySelector}>
      <TouchableOpacity
        style={s.root}
        onPress={openCitySelectorSheet}
        hitSlop={5}
      >
        <Text style={s.text}>
          {!city && 'Не выбран'}
          {!!city && city.title}
        </Text>
        <ArrowDownSvg />
      </TouchableOpacity>
    </View>
  );
};

export default observer(CitySelector);
