import {
  StyleSheet, Text, View,
} from 'react-native';
import React, { FC, useCallback, useEffect } from 'react';
import Back from '@components/layout/Back';
import Screen from '@components/layout/Screen';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

import { RootStackScreenProps } from '@navigation/types';
import { observer } from 'mobx-react-lite';
import useStore from '@app/domain/store/useStore';
import FullScreenLoading from '@components/common/FullScreenLoading';
import { useIsFocused } from '@react-navigation/native';
import {
  useDeleteMyDeliveryAddressMutation,
  useUpdateMyDeliveryAddressMutation,
} from '@app/infrastructureLayer/generated/graphql';
import { getSnapshot } from 'mobx-state-tree';
import useProfileSync from '@hooks/useProfileSync/useProfileSync';
import crossAlert from '@utils/crossAlert';
import BinaryAlert from '@utils/BinaryAlert';
import AddressForm, { MyDeliveryAddressFormValues } from '@widgets/form/AddressForm/AddressForm';

const s = StyleSheet.create({
  root: {
    flex: 1,
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  scroll: {
    flex: 1,
  },
  header: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
    marginBottom: 15,
    marginTop: 5,
  },
});

const EditMyAddressScreen: FC<RootStackScreenProps<'EditMyAddressScreen'>> = (
  {
    route,
    navigation,
  },
) => {
  const addressId = route.params?.deliveryAddressId;

  const { profileStore: { profile } } = useStore();

  const [updateMyDeliveryAddress] = useUpdateMyDeliveryAddressMutation();
  const [deleteMyDeliveryAddress] = useDeleteMyDeliveryAddressMutation();

  const address = profile?.deliveryAddresses.get(addressId);

  const isFocused = useIsFocused();

  const { refetchProfile } = useProfileSync();

  useEffect(() => {
    if (!isFocused) {
      return;
    }

    if (!addressId) {
      navigation.replace('MyAddressesScreen');

      return;
    }

    if (profile && !address) {
      navigation.replace('MyAddressesScreen');
    }
  }, [address, addressId, isFocused, navigation, profile]);

  const goToList = useCallback(() => {
    navigation.navigate('MyAddressesScreen');
  }, [navigation]);

  const onSave = useCallback(async (data: MyDeliveryAddressFormValues) => {
    const res = await updateMyDeliveryAddress({
      variables: {
        id: addressId,
        data,
      },
    });
    await refetchProfile();

    if (res.data?.updateMyDeliveryAddress.resultCode !== 'SUCCESS') {
      crossAlert('Ошибка при сохранении адреса. Попробуйте снова позже.');
    }
  }, [updateMyDeliveryAddress, addressId, refetchProfile]);

  const onDelete = useCallback(async () => {
    BinaryAlert({
      title: 'Вы действительно хотите удалить этот адрес?',
      onPositivePress: async () => {
        const res = await deleteMyDeliveryAddress({
          variables: {
            id: addressId,
          },
        });
        await refetchProfile();

        if (res.data?.deleteMyDeliveryAddress.resultCode !== 'SUCCESS') {
          crossAlert('Ошибка при удалении адреса. Попробуйте снова позже.');

          return;
        }

        navigation.replace('MyAddressesScreen');
      },
      onNegativePress: () => {

      },
    });
  }, [deleteMyDeliveryAddress, addressId, refetchProfile, navigation]);

  if (!addressId || !address) {
    return <FullScreenLoading />;
  }

  return (
    <Screen>
      <Back
        title="Назад"
        onPress={goToList}
        onDelete={onDelete}
      />
      <View style={s.root}>
        <View style={s.scroll}>
          <Text style={s.header}>
            Редактирование адреса
          </Text>
          <AddressForm
            addressId={address.id}
            formInitialState={getSnapshot(address)}
            onSave={onSave}
          />
        </View>
      </View>
    </Screen>
  );
};

export default observer(EditMyAddressScreen);
