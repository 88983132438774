import {
  ActivityIndicator,
  Text,
  View,
  StyleSheet,
} from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import React, { FC } from 'react';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: colors.text,
    paddingBottom: 10,
    fontSize: 14,
    fontFamily: ubuntuFont.light,
  },
});

const MenuLoading: FC = () => (
  <View style={s.root}>
    <Text style={s.text}>
      Загрузка меню
    </Text>
    <ActivityIndicator size={20} color={colors.primary} />
  </View>
);

export default MenuLoading;
