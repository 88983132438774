import padStart from 'lodash/padStart';

const secondsToHumanFormat = (seconds: number): string => {
  if (seconds <= 0) {
    return '0:00';
  }

  const m = Math.floor(seconds / 60);
  const s = seconds % 60;

  return `${m}:${padStart(String(s), 2, '0')}`;
};

export default secondsToHumanFormat;
