import { Enum_Day_Daycode } from '@app/infrastructureLayer/generated/graphql';

// const codeToNumber = {
//   [Enum_Day_Daycode.Mon]: 1,
//   [Enum_Day_Daycode.Tue]: 2,
//   [Enum_Day_Daycode.Wed]: 3,
//   [Enum_Day_Daycode.Thu]: 4,
//   [Enum_Day_Daycode.Fri]: 5,
//   [Enum_Day_Daycode.Sat]: 6,
//   [Enum_Day_Daycode.Sun]: 7,
// };

const numberToCode = {
  0: Enum_Day_Daycode.Mon,
  1: Enum_Day_Daycode.Tue,
  2: Enum_Day_Daycode.Wed,
  3: Enum_Day_Daycode.Thu,
  4: Enum_Day_Daycode.Fri,
  5: Enum_Day_Daycode.Sat,
  6: Enum_Day_Daycode.Sun,
} as const;

const dayNumberToDayCode = (dayNumber: number): Enum_Day_Daycode | undefined => {
  if (dayNumber < 0 || dayNumber > 6) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return numberToCode[dayNumber] as Enum_Day_Daycode;
};

export default dayNumberToDayCode;
