import {
  Ubuntu_400Regular,
  Ubuntu_500Medium,
  Ubuntu_700Bold,
  Ubuntu_300Light,
} from '@expo-google-fonts/ubuntu';
import { useFonts } from 'expo-font';

const useCachedResources = () => {
  const [fontsLoaded] = useFonts({
    Ubuntu_400Regular,
    Ubuntu_500Medium,
    Ubuntu_700Bold,
    Ubuntu_300Light,
    // [ubuntuFont.italic]: UbuntuFont.Ubuntu_300Light_Italic,
    // [ubuntuFont.regular]: Ubuntu_400Regular,
    // [ubuntuFont.medium]: Ubuntu_500Medium
    // [ubuntuFont.mediumItalic]: require('../../assets/fonts/Ubuntu/Ubuntu-MediumItalic.ttf'),
    // [ubuntuFont.bold]: Ubuntu_700Bold,
    // [ubuntuFont.boldItalic]: require('../../assets/fonts/Ubuntu/Ubuntu-BoldItalic.ttf'),
    // [ubuntuFont.light]: Ubuntu_300Light,
    // [ubuntuFont.lightItalic]: require('../../assets/fonts/Ubuntu/Ubuntu-LightItalic.ttf'),
  });

  return fontsLoaded;
};

export default useCachedResources;
