import {
  StyleSheet, Text, View,
} from 'react-native';
import React, { FC, useCallback, useMemo } from 'react';
import Back from '@components/layout/Back';
import Screen from '@components/layout/Screen';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

import { RootStackScreenProps } from '@navigation/types';
import { observer } from 'mobx-react-lite';
import {
  useAddMyDeliveryAddressMutation,
} from '@app/infrastructureLayer/generated/graphql';
import useProfileSync from '@hooks/useProfileSync/useProfileSync';
import crossAlert from '@utils/crossAlert';
import AddressForm, { MyDeliveryAddressFormValues } from '@widgets/form/AddressForm/AddressForm';
import useStore from '@app/domain/store/useStore';

const s = StyleSheet.create({
  root: {
    flex: 1,
    paddingBottom: 15,
    paddingHorizontal: 15,
  },
  scroll: {
    flex: 1,
  },
  header: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
    marginBottom: 15,
    marginTop: 5,
  },
});

const CreateMyAddressScreen: FC<RootStackScreenProps<'CreateMyAddressScreen'>> = (
  {
    navigation,
    route,
  },
) => {
  const address = route?.params?.address;
  const cityId = route?.params?.cityId;
  const latitude = route?.params?.latitude;
  const longitude = route?.params?.longitude;
  const selectAfterSave = route?.params?.selectAfterSave || false;

  const [addMyDeliveryAddress] = useAddMyDeliveryAddressMutation();

  const { refetchProfile } = useProfileSync();

  const { appStore: { setSelectedDeliveryAddressId } } = useStore();

  const onBackPress = useCallback(() => {
    if (selectAfterSave) {
      navigation.navigate('IndexScreen');

      return;
    }

    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate('MyAddressesScreen');
  }, [navigation, selectAfterSave]);

  const onSave = useCallback(async (data: MyDeliveryAddressFormValues) => {
    const res = await addMyDeliveryAddress({
      variables: {
        data: {
          ...data,
          cityId,
          address,
          longitude: parseFloat(String(longitude)),
          latitude: parseFloat(String(latitude)),
        },
      },
    });
    await refetchProfile();

    if (res.data?.addMyDeliveryAddress.resultCode !== 'SUCCESS' || !res.data.addMyDeliveryAddress.addressId) {
      crossAlert('Ошибка при добавлении адреса. Попробуйте снова позже.');

      return;
    }

    if (selectAfterSave) {
      setSelectedDeliveryAddressId(res.data.addMyDeliveryAddress.addressId);
      navigation.navigate('IndexScreen');

      return;
    }

    navigation.replace('MyAddressesScreen');
  }, [
    setSelectedDeliveryAddressId,
    addMyDeliveryAddress,
    cityId,
    address,
    longitude,
    latitude,
    refetchProfile,
    navigation,
    selectAfterSave,
  ]);

  const formInitialState = useMemo(() => ({
    cityId,
    address,
    longitude,
    latitude,
  }), [address, cityId, latitude, longitude]);

  return (
    <Screen>
      <Back
        title="Назад"
        onPress={onBackPress}
      />
      <View style={s.root}>
        <View style={s.scroll}>
          <Text style={s.header}>
            Добавление адреса
          </Text>
          <AddressForm
            formInitialState={formInitialState}
            onSave={onSave}
          />
        </View>
      </View>
    </Screen>
  );
};

export default observer(CreateMyAddressScreen);
