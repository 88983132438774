import React, { FC } from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

interface IProps {
  checked: boolean;
}

const SelectCheckboxSvg: FC<IProps> = ({ checked }) => (
  <Svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
  >
    <Circle cx={10} cy={10} r={9.5} fill="white" stroke={checked ? '#79B5B6' : '#D9D9D9'} />
    {checked && (
      <>
        <Path
          d="M6.45837 9.43494L9.12085 13.1927"
          stroke="#79B5B6"
          strokeWidth={2}
          strokeLinecap="round"
        />
        <Path
          d="M13.5416 6.80713L9.12085 13.1927"
          stroke="#79B5B6"
          strokeWidth={2}
          strokeLinecap="round"
        />
      </>
    )}

  </Svg>
);

export default SelectCheckboxSvg;
