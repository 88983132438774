import {
  Instance,
  types as t,
} from 'mobx-state-tree';

export interface ITokenProvider {
  /**
   * Авторизационный токен
   */
  token: string | undefined,
  /**
   * Задание нового токена
   */
  setToken: (token: string | undefined) => void,
}

const TokenProviderModel = t.model({
  token: t.maybe(t.string),
})
  .named('TokenProvider')
  .actions((self) => ({
    setToken: (value: string | undefined) => {
      self.token = value;
    },
  }));

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class TokenProvider {
  static create() {
    return TokenProviderModel.create();
  }
}

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export interface TokenProvider extends Instance<typeof TokenProviderModel> {
}
