import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import GreyBlock from '@components/layout/GreyBlock';
import {
  Text,
  StyleSheet,
} from 'react-native';
import useStore from '@app/domain/store/useStore';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import Input from '@components/form/Input';

const s = StyleSheet.create({
  root: {
    gap: 5,
  },
  title: {
    color: colors.text,
    fontFamily: ubuntuFont.light,
    fontSize: 12,
  },
  accent: {
    color: colors.primary,
    fontFamily: ubuntuFont.light,
    fontSize: 12,
  },
  required: {
    color: colors.error,
    fontFamily: ubuntuFont.light,
    fontSize: 12,
    paddingRight: 5,
  },
});

const RoomNumber: FC = () => {
  const {
    deviceVarsStore: {
      roomNumber,
      setRoomNumber,
    },
  } = useStore();

  return (
    <GreyBlock style={s.root}>
      <Text style={s.title}>
        <Text style={s.required}>*</Text>
        Ваш номер в
        {' '}
        <Text style={s.accent}>отеле</Text>
        :
      </Text>
      <Input
        type="text"
        placeholder="Введите номер в отеле"
        value={typeof roomNumber !== 'undefined' ? roomNumber : ''}
        onChangeText={setRoomNumber}
      />
    </GreyBlock>
  );
};

export default observer(RoomNumber);
