import * as React from 'react';
import {
  View,
  Text,
  StyleSheet,
  useWindowDimensions,
} from 'react-native';
import {
  forwardRef, useCallback, useEffect, useImperativeHandle,
} from 'react';
import { observer } from 'mobx-react-lite';
import {
  CodeField, Cursor, useBlurOnFulfill, useClearByFocusCell,
} from 'react-native-confirmation-code-field';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';

const CELL_COUNT = 4;

const s = StyleSheet.create({
  codeFieldRoot: {},
  cell: {
    maxHeight: 42,
    maxWidth: 72,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.text,
    marginHorizontal: 8,
    borderWidth: 1,
  },
  focusCell: {
    borderColor: colors.primary,
    borderWidth: 1,
  },
  cellText: {
    fontSize: 16,
    color: colors.text,
    textAlign: 'center',
    fontFamily: ubuntuFont.regular,
  },
  error: {
    borderColor: colors.error,
    borderWidth: 1,
  },
});

export interface OtpInputRef {
  focus: () => void;
  clear: () => void;
}

interface IProps {
  value: string;
  setValue: (value: string) => void;
  onEndEditing?: () => void;
  hasError?: boolean
  disabled?: boolean,
}

const OtpInput = forwardRef<OtpInputRef, IProps>((
  {
    value,
    setValue,
    onEndEditing,
    hasError = false,
  },
  forwardedRef,
) => {
  const cellInputRef = useBlurOnFulfill({
    value,
    cellCount: CELL_COUNT,
  });

  const { width, height } = useWindowDimensions();

  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  useEffect(() => {
    setTimeout(() => cellInputRef.current?.focus(), 750);
  }, [cellInputRef]);

  useImperativeHandle(forwardedRef, () => ({
    focus: () => cellInputRef.current?.focus(),
    clear: () => setValue(''),
  }));

  const renderCell = useCallback((
    {
      index,
      symbol,
      isFocused,
    }: {
      index: number;
      symbol: string | null;
      isFocused: boolean;
    },
  ) => (
    <View
      key={index}
      style={StyleSheet.flatten([s.cell,
        isFocused && s.focusCell,
        hasError && s.error, { width: width * 0.18, height: height * 0.07 }])}
    >
      <Text
        style={s.cellText}
        onLayout={getCellOnLayoutHandler(index)}
      >
        {symbol || (isFocused ? <Cursor /> : null)}
      </Text>
    </View>
  ), [getCellOnLayoutHandler, hasError, width, height]);

  return (
    <CodeField
      ref={cellInputRef}
      {...props}
      rootStyle={s.codeFieldRoot}
      // Use `caretHidden={false}` when users can't paste a text value, because context menu doesn't appear
      value={value}
      onChangeText={setValue}
      cellCount={CELL_COUNT}
      keyboardType="number-pad"
      textContentType="oneTimeCode"
      renderCell={renderCell}
      onEndEditing={onEndEditing}
    />
  );
});

export default observer(OtpInput);
