import {
  View,
  Text,
  ScrollView,
  StyleSheet,
} from 'react-native';
import React, { FC, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import Screen from '@components/layout/Screen';
import Back from '@components/layout/Back';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import MyAddressesRow from '@widgets/form/MyAddressesRow';
import { RootStackScreenProps } from '@navigation/types';
import useStore from '@app/domain/store/useStore';
import Button from '@components/common/Button';
import { observer } from 'mobx-react-lite';

const s = StyleSheet.create({
  scroll: {
    flex: 1,
    paddingHorizontal: 15,
  },
  header: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
    color: colors.primary,
    marginBottom: 15,
    marginTop: 5,
  },
  flex: {
    flex: 1,
  },
  scrollContent: {
    gap: 10,
  },
  emptyViewWrapper: {
    flex: 1,
    justifyContent: 'space-between',
    marginVertical: 10,
  },
  emptyView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyText: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
    marginBottom: 10,
  },
  emptyTextDescription: {
    fontSize: 16,
    fontFamily: ubuntuFont.light,
    textAlign: 'center',
  },
  bottom: {
    paddingVertical: 20,
  },
});

const MyAddressesScreen: FC<RootStackScreenProps<'MyAddressesScreen'>> = () => {
  const navigation = useNavigation();

  const { profileStore: { profile } } = useStore();

  const deliveryAddresses = profile?.deliveryAddresses;

  const goToOrderCreation = useCallback(() => {
    navigation.navigate('CreateMyAddressOnMapScreen');
  }, [navigation]);

  const redirectToEditorCreateAddressInfo = useCallback((deliveryAddressId: string | undefined) => {
    if (!deliveryAddressId) {
      goToOrderCreation();

      return;
    }

    navigation.navigate('EditMyAddressScreen', {
      deliveryAddressId,
    });
  }, [navigation, goToOrderCreation]);

  return (
    <Screen>
      <Back title="Назад" />
      <View style={s.scroll}>
        <Text style={s.header}>
          Мои адреса
        </Text>
        {(!deliveryAddresses || deliveryAddresses.size === 0) && (
          <View style={s.emptyViewWrapper}>
            <View style={s.emptyView}>
              <Text style={s.emptyText}>Нет адресов</Text>
              <Text style={s.emptyTextDescription}>Добавить новый адрес, чтобы делать заказ быстрее</Text>
            </View>

            <Button
              title="Добавить новый адрес"
              onPress={goToOrderCreation}
            />
          </View>
        )}

        {deliveryAddresses && deliveryAddresses.size > 0 && (
          <>
            <ScrollView
              showsHorizontalScrollIndicator={false}
              style={s.flex}
              contentContainerStyle={s.scrollContent}
            >
              {[...deliveryAddresses.values()].map((d) => (
                <MyAddressesRow
                  key={d.id}
                  type={d.type}
                  deliveryAddressId={d.id}
                  header={d.title || d.address}
                  address={d.title ? d.address : ''}
                  onPress={redirectToEditorCreateAddressInfo}
                />
              ))}
            </ScrollView>
            <View style={s.bottom}>
              <Button
                onPress={goToOrderCreation}
                title="Добавить новый адрес"
              />
            </View>
          </>
        )}
      </View>
    </Screen>
  );
};

export default observer(MyAddressesScreen);
