import {
  Instance, resolveIdentifier,
  SnapshotIn,
  SnapshotOut,
  types as t,
} from 'mobx-state-tree';
import { ModifierCategory } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/ModifierCategory';
import { ChildModifier } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/ChildModifier';

export const GroupModifier = t
  .model('GroupModifier', {
    id: t.identifier,
    minAmount: t.refinement(t.number, (value) => value >= 0),
    maxAmount: t.refinement(t.number, (value) => value >= 0),
    // TODO: Учесть эти поля в логике
    // required: t.boolean,
    // defaultAmount: t.refinement(t.number, (value) => value >= 0),
    // freeOfChargeAmount: t.refinement(t.number, (value) => value >= 0),
    modifierCategoryId: t.string,
    childModifiers: t.optional(t.array(ChildModifier), []),
  })
  .views((self) => ({
    get modifierCategory() {
      return resolveIdentifier(ModifierCategory, self, self.modifierCategoryId);
    },
    get isOneOf() {
      return self.maxAmount === 1;
    },
  }));

export interface GroupModifierInstance extends Instance<typeof GroupModifier> {
}

export interface GroupModifierSnapshotIn extends SnapshotIn<typeof GroupModifier> {
}

export interface GroupModifierSnapshotOut extends SnapshotOut<typeof GroupModifier> {
}
