import React, {
  FC, useCallback, useEffect, useRef,
} from 'react';
import ActionSheet, { ActionSheetRef, SheetManager, SheetProps } from 'react-native-actions-sheet';
import {
  ActivityIndicator, StyleSheet, View, Text,
} from 'react-native';
import { observer } from 'mobx-react-lite';
import ButtonOutline from '@components/common/ButtonOutline';
import ubuntuFont from '@constants/ubuntuFont';
import useStore from '@app/domain/store/useStore';
import Button from '@components/common/Button';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    paddingHorizontal: 25,
    paddingVertical: 20,
  },
  bottomRow: {
    gap: 10,
  },
  header: {
    color: colors.primary,
    fontSize: 16,
    fontFamily: ubuntuFont.medium,
    marginVertical: 15,
  },
  question: {
    color: colors.text,
    fontSize: 14,
    fontFamily: ubuntuFont.light,
    marginBottom: 30,
  },
  warning: {
    color: colors.text,
    fontSize: 10,
    fontFamily: ubuntuFont.light,
    textAlign: 'center',
  },
});

const snapPoints = [100];

const ConfirmShopSheet: FC<SheetProps> = ({ sheetId, payload: { onConfirm } }) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);

  const {
    appStore: {
      shop,
    },
  } = useStore();

  const loading = false;

  useEffect(() => {
  }, []);

  const onChange = useCallback(async () => {
    await SheetManager.hide('confirm-shop-sheet');
    SheetManager.show('select-shop-sheet');
  }, []);

  const onConfirmPress = useCallback(async () => {
    await SheetManager.hide('confirm-shop-sheet');

    return onConfirm();
  }, [onConfirm]);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      {loading && (
        <ActivityIndicator color={colors.text} />
      )}
      {!loading && (
        <View style={s.root}>
          <View>
            <Text style={s.header}>{shop?.address || '???'}</Text>
            <Text style={s.question}>Это верный адрес заказа?</Text>
            <Text style={s.warning}>При изменении адреса цены могут измениться</Text>
          </View>

          <View style={s.bottomRow}>
            <ButtonOutline
              onPress={onChange}
              title="ИЗМЕНИТЬ АДРЕС"
            />
            <Button
              onPress={onConfirmPress}
              title="ПОДТВЕРДИТЬ"
            />
          </View>
        </View>
      )}
    </ActionSheet>
  );
};

export default observer(ConfirmShopSheet);
