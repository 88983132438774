import React, { FC } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    width: 18,
    height: 18,
  },
  amountText: {
    position: 'absolute',
    top: 2,
    left: 0,
    fontSize: 12,
    fontWeight: '600',
    color: colors.primary,
    width: 18,
    textAlign: 'center',
  },
});

interface IProps {
  amount?: number;
}

const CartIconSvg: FC<IProps> = (
  {
    amount = 0,
  },
) => {
  if (amount === 0) {
    return (
      <View style={s.root}>
        <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
          <Path
            d="M14.994 0C15.4757 3.88649e-05 15.9412 0.173945 16.305 0.489755C16.6688 0.805565 16.9063 1.24206 16.974 1.719L16.991 1.875L17.866 15.875C17.899 16.4003 17.7237 16.9175 17.3779 17.3143C17.0322 17.7112 16.5439 17.9558 16.019 17.995L15.869 18H2C1.4737 18 0.968595 17.7926 0.594205 17.4227C0.219814 17.0528 0.00631561 16.5503 0 16.024L0.00300002 15.875L0.878 1.875C0.908093 1.39407 1.11081 0.940174 1.44887 0.596786C1.78693 0.253398 2.2376 0.0436091 2.718 0.00600004L2.874 0H14.994ZM11.934 4C11.6891 4.00003 11.4527 4.08996 11.2696 4.25272C11.0866 4.41547 10.9697 4.63975 10.941 4.883L10.934 5C10.934 5.53043 10.7233 6.03914 10.3482 6.41421C9.97314 6.78929 9.46443 7 8.934 7C8.40357 7 7.89486 6.78929 7.51979 6.41421C7.14471 6.03914 6.934 5.53043 6.934 5C6.934 4.73478 6.82864 4.48043 6.64111 4.29289C6.45357 4.10536 6.19922 4 5.934 4C5.66878 4 5.41443 4.10536 5.22689 4.29289C5.03936 4.48043 4.934 4.73478 4.934 5C4.934 6.06087 5.35543 7.07828 6.10557 7.82843C6.85572 8.57857 7.87313 9 8.934 9C9.99487 9 11.0123 8.57857 11.7624 7.82843C12.5126 7.07828 12.934 6.06087 12.934 5C12.934 4.73478 12.8286 4.48043 12.6411 4.29289C12.4536 4.10536 12.1992 4 11.934 4Z"
            fill="white"
          />
        </Svg>
      </View>
    );
  }

  return (
    <View style={s.root}>
      <Svg width="18" height="18" viewBox="0 0 18 18" fill="none">
        <Path
          d="M 14.994 0 C 15.4757 0 15.9412 0.1739 16.305 0.4898 C 16.6688 0.8056 16.9063 1.2421 16.974 1.719 L 16.991 1.875 L 17.866 15.875 C 17.899 16.4003 17.7237 16.9175 17.3779 17.3143 C 17.0322 17.7112 16.5439 17.9558 16.019 17.995 L 15.869 18 H 2 C 1.4737 18 0.9686 17.7926 0.5942 17.4227 C 0.2198 17.0528 0.0063 16.5503 0 16.024 L 0.003 15.875 L 0.878 1.875 C 0.9081 1.3941 1.1108 0.9402 1.4489 0.5968 C 1.7869 0.2534 2.2376 0.0436 2.718 0.006 L 2.874 0 H 14.994 Z Z"
          fill="white"
        />
      </Svg>
      <Text style={s.amountText}>
        {amount > 99 ? '99+' : amount}
      </Text>
    </View>
  );
};

export default CartIconSvg;
