import {
  Instance, SnapshotIn, SnapshotOut, types as t,
} from 'mobx-state-tree';
import {
  ProfileEntity, ProfileEntitySnapshotIn, ProfileEntitySnapshotOut,
} from '@app/domain/store/CoreStore/ProfileStore/entities/ProfileEntity';

export const ProfileStore = t
  .model('ProfileStore', {
    id: t.optional(t.identifier, 'ProfileStore'),
    profile: t.maybeNull(ProfileEntity),
  })
  .actions((self) => ({
    setProfile: (value: ProfileEntitySnapshotIn | ProfileEntitySnapshotOut | null) => {
      if (!value) {
        self.profile = null;

        return;
      }

      self.profile = ProfileEntity.create(value);
    },
  }));

export interface ProfileStoreInstance extends Instance<typeof ProfileStore> {
}

export interface ProfileStoreSnapshotIn extends SnapshotIn<typeof ProfileStore> {
}

export interface ProfileStoreSnapshotOut extends SnapshotOut<typeof ProfileStore> {
}
