import * as SecureStore from 'expo-secure-store';

class SecureStoreMstPersistAdapter {
  static async getItem(name: string) {
    return SecureStore.getItemAsync(name);
  }

  static async removeItem(name: string) {
    return SecureStore.deleteItemAsync(name);
  }

  static async setItem(name: string, value: string) {
    return SecureStore.setItemAsync(name, value);
  }

  static async clear() {
    throw new Error('not implemented');
  }
}

export default SecureStoreMstPersistAdapter;
