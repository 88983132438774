import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { StyleSheet, Text } from 'react-native';
import { StockShowOptionEnum } from '@app/domain/store/CoreStore/AppStore/entities/Menu/StockData';
import type { ShopInstance } from '@app/domain/store/CoreStore/AppStore/entities/Shop';
import { MenuItem, MenuItemInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuItem';
import type {
  ModifierMenuItemInstance,
} from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/ModifierMenuItem';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';

interface IProps {
  shop: ShopInstance,
  item: MenuItemInstance | ModifierMenuItemInstance,
}

const s = StyleSheet.create({
  stock: {
    fontFamily: ubuntuFont.medium,
    fontSize: 12,
    color: colors.success,
  },
  outOfStock: {
    fontFamily: ubuntuFont.medium,
    fontSize: 12,
    color: colors.error,
  },
});

const ItemStockInfo: FC<IProps> = (
  {
    shop,
    item,
  },
) => {
  let isAvailable: boolean;
  if (MenuItem.is(item)) {
    isAvailable = item.isAvailable && item?.groupModifiersIsCorrect;
  } else {
    isAvailable = item.isAvailable;
  }

  const { useStockBalance } = shop;
  if (useStockBalance && item.stockable && item.stockShowOption !== StockShowOptionEnum.HIDE) {
    return (
      <>
        {item.stockShowOption === StockShowOptionEnum.SHOW_NUMBER && (
          <Text style={(item.stockBalance || 0) > 0 ? s.stock : s.outOfStock}>
            {(item.stockBalance || 0) > 0 && `Доступно ${item.stockBalance || 0} ед.`}
            {(item.stockBalance || 0) <= 0 && 'Нет на складе'}
          </Text>
        )}
        {item.stockShowOption === StockShowOptionEnum.SHOW_BOOLEAN && (
          <Text style={isAvailable ? s.stock : s.outOfStock}>
            {isAvailable && 'Есть на складе'}
            {!isAvailable && 'Нет на складе'}
          </Text>
        )}
      </>
    );
  }

  return null;
};

export default observer(ItemStockInfo);
