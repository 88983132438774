import {
  Text, View, StyleSheet, Image,
} from 'react-native';
import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { observer } from 'mobx-react-lite';
import Screen from '@components/layout/Screen';
import {
  AuthViaOtpResultCode,
  RequestOtpCodeResultCode,
  useAuthViaOtpMutation, useRequestOtpCodeMutation,
} from '@app/infrastructureLayer/generated/graphql';
import crossAlert from '@utils/crossAlert';
import useStore from '@app/domain/store/useStore';
import OtpInput from '@components/form/OtpInput';
import { AuthStackScreenProps } from '@navigation//types';

import Button from '@components/common/Button';
import ButtonOutline from '@components/common/ButtonOutline';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';
import Back from '@components/layout/Back';
import { absurd } from 'fp-ts/function';
import secondsToHumanFormat from '@utils/secondsToHumanFormat';
import images from '@constants/images';

const s = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 25,
    paddingBottom: 30,
    backgroundColor: colors.white,
  },
  scroll: {
    flexGrow: 1,
  },
  containerTop: {
    flex: 1,
    alignItems: 'center',
    marginTop: 67,
  },
  flex1: {
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  logo: {
    width: 195,
    height: 195,
  },
  title: {
    fontSize: 14,
    marginTop: 15,
    fontFamily: ubuntuFont.light,
  },
  link: {
    textDecorationLine: 'underline',
    marginTop: 5,
    fontSize: 10,
    fontFamily: ubuntuFont.light,
  },
  header: {
    fontSize: 24,
    fontFamily: ubuntuFont.regular,
  },
  description: {
    fontSize: 14,
    textAlign: 'center',
    fontFamily: ubuntuFont.light,
  },
  optWrapper: {
    marginVertical: 20,
  },
  messageDuration: {
    textAlign: 'center',
    fontSize: 14,
    fontFamily: ubuntuFont.light,
    color: colors.error,
  },
  error: {
    color: colors.error,
    textAlign: 'center',
    marginTop: 3,
    fontSize: 10,
  },
});

const SmsLoginScreen: FC<AuthStackScreenProps<'SmsLoginScreen'>> = (
  {
    navigation: localNavigation,
    route,
  },
) => {
  const {
    authStore: {
      auth: {
        setToken,
      },
    },
  } = useStore();

  const [secondsToRetry, setSecondsToRetry] = useState<number>(0);
  const phone = route.params?.phone;
  const [code, setCode] = useState('');

  const [requestOtpCode, { loading: requestOtpCodeLoading }] = useRequestOtpCodeMutation();
  const [authViaOtp, { loading: authViaOtpLoading }] = useAuthViaOtpMutation();

  // TODO: вывести ошибку и при нахождении на другой странице возвращать на эту
  const hasError = false;

  const navigateToLoginScreen = useCallback(() => {
    localNavigation.navigate('LoginScreen');
  }, [localNavigation]);

  const navigateToRegisterScreen = useCallback(() => {
    localNavigation.navigate('RegistrationScreen');
  }, [localNavigation]);

  const onAuthViaOtp = useCallback(async () => {
    const res = await authViaOtp({
      variables: {
        input: {
          phone,
          code,
        },
      },
    });

    if (!res.data) {
      crossAlert('Ошибка при авторизации, попробуйте позже.');
      setCode('');
      return;
    }

    const { resultCode } = res.data.authViaOtpMutation;

    switch (resultCode) {
      case AuthViaOtpResultCode.Success: {
        const { jwt } = res.data.authViaOtpMutation;
        if (jwt) {
          setToken(jwt);
        } else {
          crossAlert('Ошибка при авторизации, попробуйте позже.');
        }
        setCode('');
        return;
      }
      case AuthViaOtpResultCode.InvalidOtpCode:
        crossAlert('Указан неверный код.');
        break;
      case AuthViaOtpResultCode.Fail:
        crossAlert('Ошибка при авторизации, попробуйте позже.');
        break;
      case AuthViaOtpResultCode.UserBlocked:
        crossAlert('Пользователь заблокирован.');
        break;
      default:
        absurd(resultCode);
        crossAlert('Ошибка при авторизации, попробуйте позже.');
        break;
    }

    setCode('');
  }, [authViaOtp, code, phone, setToken]);

  useEffect(() => {
    if (secondsToRetry > 0) {
      const timeout = setTimeout(() => {
        setSecondsToRetry(secondsToRetry - 1);
      }, 1000);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }

    return undefined;
  }, [secondsToRetry]);

  const [pending, setPending] = useState(false);

  useEffect(() => {
    (async () => {
      if (code.length === 4 && !pending) {
        setPending(true);
        try {
          await onAuthViaOtp();
        } catch (e) {
          console.error(e);
        }
        setPending(false);
      }
    })();
  }, [authViaOtpLoading, code, onAuthViaOtp, pending]);

  const subtitle = useMemo(() => `Ожидайте смс на номер ${phone}`, [phone]);

  const onRequestSmsCode = useCallback(async () => {
    const res = await requestOtpCode({
      variables: {
        input: {
          phone,
        },
      },
    });

    setCode('');

    if (!res.data) {
      crossAlert('Ошибка при авторизации, попробуйте позже.');
      return;
    }

    const { resultCode } = res.data.requestOtpCodeMutation;

    switch (resultCode) {
      case RequestOtpCodeResultCode.Fail:
        crossAlert('Ошибка при запросе смс кода, попробуйте позже.');
        break;
      case RequestOtpCodeResultCode.RateLimited:
        setSecondsToRetry(res.data.requestOtpCodeMutation.seconds);
        break;
      case RequestOtpCodeResultCode.Success:
        break;
      default:
        absurd(resultCode);
        crossAlert('Ошибка при запросе смс кода, попробуйте позже.');
        break;
    }
  }, [phone, requestOtpCode]);

  const onBack = useCallback(() => {
    localNavigation.navigate('LoginScreen');
  }, [localNavigation]);

  return (
    <Screen>
      <KeyboardAwareScrollView
        bounces={false}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={s.scroll}
      >
        <Back title="Назад" onPress={onBack} />
        <View style={s.container}>
          <View style={s.containerTop}>
            <Image source={images.companyLogoSource} style={s.logo} />
            <Text style={s.header}>
              Введите код
            </Text>
            <Text style={s.title}>{subtitle}</Text>

            <Text style={s.link} onPress={navigateToLoginScreen}>
              Изменить номер
            </Text>

            <View style={s.optWrapper}>
              <OtpInput
                value={code}
                setValue={setCode}
                hasError={hasError}
              />
              {hasError && (
                <Text style={s.error}>
                  Указан неверный код. Попробуйте еще раз
                </Text>
              )}
            </View>

            <Text style={s.description}>
              Введите код из смс
            </Text>
          </View>
          <View style={s.flex1}>
            {secondsToRetry > 0 && (
              <Text style={s.messageDuration}>
                {`Повторно отправить смс можно через ${secondsToHumanFormat(secondsToRetry)}`}
              </Text>
            )}
            <ButtonOutline
              disabled={authViaOtpLoading || requestOtpCodeLoading || secondsToRetry > 0}
              loading={requestOtpCodeLoading}
              title="Отправить код повторно"
              onPress={onRequestSmsCode}
            />
            <Button
              loading={authViaOtpLoading}
              title="Продолжить"
              onPress={navigateToRegisterScreen}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
    </Screen>
  );
};

export default observer(SmsLoginScreen);
