import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useMemo } from 'react';
import {
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import colors from '@constants/colors';
import Input from '@components/form/Input';
import ubuntuFont from '@constants/ubuntuFont';
import useStore from '@app/domain/store/useStore';
import {
  Enum_Userspermissionsuser_Sex,
} from '@app/infrastructureLayer/generated/graphql';
import Button from '@components/common/Button';

import GenderSegmented from '@components/form/GenderSegmented';
import EwSwitch from '@components/form/EwSwitch';
import moment from 'moment-timezone';

import getEditProfileSchema from './editProfileSchema';

const s = StyleSheet.create({
  rootContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
  },
  containerTop: {
    flex: 1,
    alignItems: 'center',
    marginTop: 67,
  },
  phoneContainer: {
    flex: 1,
    color: colors.text,
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 11,
    paddingBottom: 11,
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
    borderRadius: 10,
    borderWidth: 1,
    flexDirection: 'row',
    borderColor: colors.grey,
    paddingVertical: 10,
    minHeight: 38,
  },
  phoneLabel: {
    fontFamily: ubuntuFont.light,
    color: colors.text,
    fontSize: 12,
    marginBottom: 5,
  },
  phoneText: {
    color: colors.grey,
  },
  box: {
    width: '100%',
    marginTop: 40,
  },
  actions: {
    marginBottom: 10,
  },
  info: {
    width: '80%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
  },
  error: {
    fontSize: 12,
    color: colors.error,
    fontFamily: ubuntuFont.light,
    paddingBottom: 20,
  },
});

export type EditProfileFormValues = {
  fio: string,
  birthday: string,
  sex: Enum_Userspermissionsuser_Sex
  broadcastOfferAccepted?: boolean,
};

type IProps = {
  onSave: (values: EditProfileFormValues) => Promise<void>
};

const EditProfileForm: FC<IProps> = (
  {
    onSave,
  },
) => {
  const {
    profileStore: {
      profile,
    },
    appStore: {
      globalSettings,
    },
  } = useStore();

  const editProfileSchema = useMemo(() => getEditProfileSchema({
    useBroadcastOfferAccept: globalSettings?.useBroadcastOfferAccept || false,
    broadcastOfferAcceptedRequired: globalSettings?.broadcastOfferAcceptedRequired || false,
  }), [globalSettings?.useBroadcastOfferAccept, globalSettings?.broadcastOfferAcceptedRequired]);

  const defaultFormInitialState: EditProfileFormValues = {
    fio: profile!.fio!,
    birthday: moment(profile!.birthday!).format('DD.MM.YYYY'),
    sex: profile!.sex!,
    broadcastOfferAccepted: profile!.broadcastOfferAccepted!,
  };

  const {
    control,
    handleSubmit,
    formState: {
      isSubmitting,
      isValid,
    },
  } = useForm<EditProfileFormValues>({
    mode: 'all',
    defaultValues: defaultFormInitialState,
    resolver: zodResolver(editProfileSchema),
  });

  const onSubmit: SubmitHandler<EditProfileFormValues> = useCallback(async (data) => {
    await onSave(data);
  }, [onSave]);

  const onPressSave = useCallback(() => handleSubmit(onSubmit)(), [handleSubmit, onSubmit]);

  return (
    <View style={s.rootContainer}>
      <View style={s.container}>
        <View style={s.containerTop}>
          <View style={s.box}>
            <View style={s.actions}>
              <Text style={s.phoneLabel}>
                Телефон
              </Text>

              <View style={s.phoneContainer}>
                <Text style={s.phoneText}>
                  {profile?.phone}
                </Text>
              </View>
            </View>
            <View style={s.actions}>
              <Controller
                control={control}
                name="fio"
                // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                render={(
                  {
                    field: {
                      ref,
                      onChange,
                      onBlur,
                      value,
                      disabled,
                    },
                    fieldState: {
                      error,
                    },
                  },
                ) => (
                  <Input
                    ref={ref}
                    editable={!disabled}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    label="Имя"
                    type="text"
                    placeholder="Введите ваше имя"
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            </View>
            <View style={s.actions}>
              <Controller
                control={control}
                name="birthday"
                // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
                render={(
                  {
                    field: {
                      ref,
                      onChange,
                      onBlur,
                      value,
                      disabled,
                    },
                    fieldState: {
                      error,
                    },
                  },
                ) => (
                  <Input
                    ref={ref}
                    editable={!disabled}
                    onBlur={onBlur}
                    errorMessage={error?.message}
                    label="День рождения"
                    type="date"
                    // placeholder="31.12.2000"
                    placeholder="Введите ваш день рождения"
                    value={value}
                    onChangeText={onChange}
                  />
                )}
              />
            </View>
            <Controller
              control={control}
              name="sex"
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              render={(
                {
                  field: {
                    onChange,
                    value,
                  },
                },
              ) => (
                <GenderSegmented
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </View>
        </View>
        <View>
          {globalSettings?.useBroadcastOfferAccept && (
            <Controller
              control={control}
              name="broadcastOfferAccepted"
              // eslint-disable-next-line react-perf/jsx-no-new-function-as-prop
              render={(
                {
                  field: {
                    onChange,
                    value,
                  },
                  fieldState: {
                    error,
                  },
                },
              ) => (
                <View>
                  <View style={s.row}>
                    <Text style={s.info}>
                      Получать информацию о
                      {'\u00A0'}
                      персональных предложениях и
                      {'\u00A0'}
                      акциях
                    </Text>
                    <EwSwitch
                      enabled={!!value}
                      onValueChange={onChange}
                    />
                  </View>
                  {!!error && (
                    <Text style={s.error}>{error.message}</Text>
                  )}
                </View>
              )}
            />
          )}
        </View>
      </View>

      <Button
        title="СОХРАНИТЬ"
        onPress={onPressSave}
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
      />
    </View>
  );
};

export default observer(EditProfileForm);
