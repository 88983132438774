import { observer } from 'mobx-react-lite';
import React, { FC, useCallback } from 'react';
import {
  ActivityIndicator,
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';
import generalConfig from '@constants/generalConfig';
import CartIconSvg from '@svg/CartIconSvg';
import useStore from '@app/domain/store/useStore';
import { useNavigation } from '@react-navigation/native';
import { useCheckoutState } from '@components/CheckoutContext';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 20,
  },
  flex: {
    flex: 1,
  },
  totalCostInfo: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 10,
    backgroundColor: colors.primary,
    height: 40,
    alignItems: 'center',
    paddingHorizontal: 10,
    flex: 1,
  },
  totalCostInfoText: {
    fontFamily: ubuntuFont.regular,
    color: colors.white,
  },
  cartButton: {
    borderRadius: 10,
    backgroundColor: colors.primary,
    height: 40,
    paddingVertical: 11,
    paddingHorizontal: 10,
  },
});

const BottomCartBlock: FC = () => {
  const {
    appStore: {
      totalAmount,
      selectedShopId,
    },
  } = useStore();

  const { data: checkoutData, loading: checkoutOrderLoading } = useCheckoutState();

  const showTotalCost = totalAmount > 0;
  const navigation = useNavigation();

  const goToCart = useCallback(() => {
    if (!selectedShopId) {
      return;
    }

    navigation.navigate('CartScreen', {
      shopId: selectedShopId,
    });
  }, [navigation, selectedShopId]);

  return (
    <View style={s.root}>
      {showTotalCost && (
        <TouchableOpacity
          onPress={goToCart}
          style={s.flex}
        >
          <View
            style={s.totalCostInfo}
          >
            <Text style={s.totalCostInfoText}>
              Корзина
            </Text>

            {checkoutOrderLoading && (
              <ActivityIndicator color={colors.white} />
            )}
            {!checkoutOrderLoading && !!checkoutData && (
              <Text style={s.totalCostInfoText}>
                {checkoutData.orderTotal}
                {' '}
                {generalConfig.currencyLabel}
              </Text>
            )}
          </View>
        </TouchableOpacity>
      )}

      {!showTotalCost && (
        <View />
      )}

      <TouchableOpacity onPress={goToCart}>
        <View
          style={s.cartButton}
        >
          <CartIconSvg amount={totalAmount} />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default observer(BottomCartBlock);
