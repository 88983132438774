import React, { FC } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import MinusSvg from '@svg/MinusSvg';
import PlusSvg from '@svg/PlusSvg';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import XSvg from '@svg/XSvg';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 31,
    borderRadius: 10,
    paddingHorizontal: 10,
    columnGap: 10,
  },
  priceContent: {
    gap: 5,
    backgroundColor: colors.midGrey,
  },
  price: {
    fontFamily: ubuntuFont.regular,
    fontSize: 12,
  },
  counterContent: {
    gap: 10,
    backgroundColor: colors.primary,
  },
  countText: {
    color: colors.white,
    fontSize: 12,
    fontFamily: ubuntuFont.regular,
    height: 14,
  },
  countRoot: {
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    gap: 5,
  },
});

interface IProps {
  count: number;
  price: string;
  increase: () => void;
  reduce: () => void;
}

const AddPriceCounter: FC<IProps> = (
  {
    count,
    price,
    increase,
    reduce,
  },
) => {
  if (count === 0) {
    return (
      <TouchableOpacity onPress={increase}>
        <View
          style={StyleSheet.flatten([s.root, s.priceContent])}
        >
          <Text style={s.price}>
            {price}
          </Text>

          <PlusSvg color={colors.text} />
        </View>
      </TouchableOpacity>
    );
  }

  return (
    <View
      style={StyleSheet.flatten([s.root, s.counterContent])}
    >
      <TouchableOpacity onPress={reduce} hitSlop={10}>
        <MinusSvg color={colors.white} />
      </TouchableOpacity>

      <View style={s.countRoot}>
        <Text style={s.countText}>
          {count}
        </Text>
        <XSvg />
        <Text style={s.countText}>
          {price}
        </Text>
      </View>

      <TouchableOpacity onPress={increase} hitSlop={10}>
        <PlusSvg color={colors.white} />
      </TouchableOpacity>
    </View>
  );
};

export default AddPriceCounter;
