import React, { FC } from 'react';
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { observer } from 'mobx-react-lite';
import useStore from '@app/domain/store/useStore';
import colors from '@constants/colors';

import DeliveryDayVariant from './DeliveryDayVariant';

const s = StyleSheet.create({
  root: {
    flexDirection: 'row',
    gap: 5,
  },
  scrollContainer: {},
});

interface IProps {
  value: string | null;
  onChange: (newValue: string | null) => void;
}

const DeliveryDayVariants: FC<IProps> = (
  {
    value,
    onChange,
  },
) => {
  const { appStore: { shop } } = useStore();

  return (
    <ScrollView
      horizontal
      contentContainerStyle={s.scrollContainer}
      showsHorizontalScrollIndicator={false}
    >
      {!shop && (
        <ActivityIndicator color={colors.primary} />
      )}
      {shop && (
        <View style={s.root}>
          {shop.workTime.availableSlotsDaysForWeek.map((variant) => {
            const haveTimeSlots = shop.workTime.timeSlots.get(variant.day);

            if (!haveTimeSlots || haveTimeSlots.size === 0) {
              return null;
            }

            return (
              <DeliveryDayVariant
                key={variant.day}
                value={variant.day}
                selected={variant.day === value}
                title={variant.title}
                onSelect={onChange}
              />
            );
          })}
        </View>
      )}
    </ScrollView>
  );
};

export default observer(DeliveryDayVariants);
