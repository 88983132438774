import React, { FC, useCallback } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import { observer } from 'mobx-react-lite';

const s = StyleSheet.create({
  root: {
    padding: 10,
    color: colors.grey,
    fontFamily: ubuntuFont.light,
    fontSize: 14,
  },
  wrapper: {
    borderRadius: 10,
    backgroundColor: colors.background,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedWrapper: {
    backgroundColor: colors.primary,
    borderRadius: 10,
  },
  selected: {
    color: colors.white,
    fontFamily: ubuntuFont.regular,
  },
});

interface IProps {
  title: string,
  id: string,
  selected: boolean,
  onSelect: (id: string) => void,
}

const Category: FC<IProps> = (
  {
    title,
    id,
    selected,
    onSelect,
  },
) => {
  const onPress = useCallback(
    () => onSelect(id),
    [onSelect, id],
  );

  return (
    <TouchableOpacity
      onPress={onPress}
      style={StyleSheet.flatten(selected ? [s.wrapper, s.selectedWrapper] : s.wrapper)}
    >
      <Text style={StyleSheet.flatten(selected ? [s.root, s.selected] : s.root)}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export default observer(Category);
