import React, { Component } from 'react';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import { View, StyleSheet } from 'react-native';
import SimpleMarkdown, { ParserRules } from 'simple-markdown';
import styles from './styles';

import getRules from './rules';

export interface MarkdownProps {
  enableLightBox?: boolean;
  onLink?: (url: string) => void;
  onImageOpen?: () => void;
  onImageClose?: () => void;
  onLoad?: () => void;
  styles?: object;
  rules?: ParserRules;
  imageParam?: string;
  navigator?: never;
  children: string;
}

class Markdown extends Component<MarkdownProps> {
  constructor(props: MarkdownProps) {
    super(props);
    if (props.enableLightBox && !props.navigator) {
      throw new Error('props.navigator must be specified when enabling lightbox');
    }

    const opts = {
      enableLightBox: props.enableLightBox,
      navigator: props.navigator,
      imageParam: props.imageParam,
      onLink: props.onLink,
      onImageOpen: props.onImageOpen,
      onImageClose: props.onImageClose,
      rules: props.rules,
    };

    const mergedStyles = merge({}, styles, props.styles);
    let rules = getRules(mergedStyles, opts);
    rules = merge({}, SimpleMarkdown.defaultRules, rules, opts.rules);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const parser = SimpleMarkdown.parserFor(rules);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.parse = (source: string) => {
      const blockSource = `${source}\n\n`;
      return parser(blockSource, { inline: false });
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.renderer = SimpleMarkdown.outputFor<ParserRules, string>(rules, 'react');
  }

  componentDidMount() {
    const { onLoad } = this.props;
    if (onLoad) {
      onLoad();
    }
  }

  shouldComponentUpdate(nextProps: {
    children: string;
  }) {
    const { children } = this.props;
    return !isEqual(nextProps.children, children);
  }

  render() {
    const { children, styles: s } = this.props;

    const child = isArray(children) ? children.join('') : children;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const tree = this.parse(child);

    return (
      <View
        style={StyleSheet.flatten([
          styles.view,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          s?.view,
        ])}
      >
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {this.renderer(tree)}
      </View>
    );
  }
}

export default Markdown;
