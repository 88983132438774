import Svg, { Ellipse, Path } from 'react-native-svg';
import * as React from 'react';

const ListSvg = () => (
  <Svg width="13" height="10" viewBox="0 0 13 10" fill="none">
    <Path d="M2.53125 1.29419H11.7813" stroke="#969696" strokeLinecap="round" />
    <Path d="M2.53125 4.01465H11.7813" stroke="#969696" strokeLinecap="round" />
    <Path d="M2.53125 6.73535H11.7813" stroke="#969696" strokeLinecap="round" />
    <Path d="M2.53125 9.45581H11.7813" stroke="#969696" strokeLinecap="round" />
    <Ellipse cx="0.796875" cy="1.29412" rx="0.578125" ry="0.544118" fill="#969696" />
    <Ellipse cx="0.796875" cy="4.01482" rx="0.578125" ry="0.544118" fill="#969696" />
    <Ellipse cx="0.796875" cy="6.73516" rx="0.578125" ry="0.544118" fill="#969696" />
    <Ellipse cx="0.796875" cy="9.45586" rx="0.578125" ry="0.544118" fill="#969696" />
  </Svg>

);

export default ListSvg;
