import {
  ScrollView,
  StyleSheet,
  Linking,
  ActivityIndicator,
} from 'react-native';
import React, { FC, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import Screen from '@components/layout/Screen';
import Back from '@components/layout/Back';
import useSWR from 'swr';
import { RootStackScreenProps } from '@navigation/types';
import { useInstance } from 'react-ioc';
import { Api } from '@app/infrastructureLayer/generated/Api';
import { useFocusEffect } from '@react-navigation/native';
import colors from '@constants/colors';
import ubuntuFont from '@constants/ubuntuFont';
import Markdown from '@components/Markdown';

const s = StyleSheet.create({
  content: {
    padding: 20,
  },
});

const mdStyles = {
  text: {
    fontSize: 14,
    fontFamily: ubuntuFont.light,
  },
  autolink: {
    fontSize: 14,
    fontFamily: ubuntuFont.light,
  },
};

const CustomScreen: FC<RootStackScreenProps<'CustomScreen'>> = (
  {
    navigation,
    route,
  },
) => {
  const id = route?.params?.id;

  const apiClient = useInstance(Api);

  const {
    data,
    mutate,
  } = useSWR(
    `custom-screen-${id}`,
    async () => {
      const res = await apiClient.customScreens.getCustomScreensId(Number(id));

      return res.data.data;
    },
    {
      refreshInterval: 30000,
      errorRetryInterval: 500,
      errorRetryCount: Infinity,
      shouldRetryOnError: true,
    },
  );

  useFocusEffect(useCallback(() => {
    if (data?.attributes?.title) {
      navigation.setOptions({
        title: data.attributes.title,
      });
    }
  }, [navigation, data?.attributes?.title]));

  const onLink = useCallback((url: string) => Linking.openURL(url), []);

  useFocusEffect(useCallback(() => {
    mutate();
  }, [mutate]));

  const onBack = useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();

      return;
    }

    navigation.navigate('Tabs', {
      screen: 'ParamsScreen',
    });
  }, [navigation]);

  return (
    <Screen>
      <Back
        title={data?.attributes?.title || id}
        onPress={onBack}
      />
      <ScrollView
        contentContainerStyle={s.content}
      >
        {!data?.attributes?.content && (
          <ActivityIndicator color={colors.primary} />
        )}
        {!!data?.attributes?.content && (
          <Markdown
            styles={mdStyles}
            onLink={onLink}
          >
            {data.attributes.content}
          </Markdown>
        )}
      </ScrollView>
    </Screen>
  );
};

export default observer(CustomScreen);
