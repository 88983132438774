import {
  Text, StyleSheet, Pressable, View,
} from 'react-native';
import React, { FC, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import ArrowLeftSvg from '@svg/ArrowLeftSvg';
import DashedLine from '@components/common/DashedLine';

const s = StyleSheet.create({
  root: {
    marginHorizontal: 15,
  },
  title: {
    paddingBottom: 10,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
    marginTop: 15,
  },
  titleText: {
    fontFamily: ubuntuFont.light,
    fontSize: 14,
    lineHeight: 16.4,
  },
  delete: {
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
    color: colors.error,
  },
  row: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

type IProps = {
  title: string;
  onPress?: () => void,
  onDelete?: () => void
  onDeleteTitle?: string,
};

const Back: FC<IProps> = (
  {
    title,
    onPress,
    onDelete,
    onDeleteTitle = 'Удалить',
  },
) => {
  const navigation = useNavigation();

  const navigationBack = useCallback(() => {
    if (onPress) {
      onPress();

      return;
    }
    navigation.goBack();
  }, [navigation, onPress]);

  return (
    <View style={s.root}>
      <View style={s.row}>
        <Pressable style={s.title} onPress={navigationBack} hitSlop={5}>
          <ArrowLeftSvg />
          <Text style={s.titleText}>{title}</Text>
        </Pressable>
        {onDelete && (
          <Pressable onPress={onDelete} hitSlop={10}>
            <Text style={s.delete}>{onDeleteTitle}</Text>
          </Pressable>
        )}
      </View>

      <DashedLine />
    </View>
  );
};

export default Back;
