import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import ubuntuFont from '@constants/ubuntuFont';
import useStore from '@app/domain/store/useStore';
import colors from '@constants/colors';
import DashedLine from '@components/common/DashedLine';
import { Enum_Ordertype_Ordertypecode } from '@app/infrastructureLayer/generated/graphql';
import generalConfig from '@constants/generalConfig';
import { useCheckoutState } from '@components/CheckoutContext';

const s = StyleSheet.create({
  root: {
    gap: 10,
  },
  row: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  rowText: {
    fontFamily: ubuntuFont.light,
    fontSize: 14,
    color: colors.text,
  },
  rowTextBold: {
    fontFamily: ubuntuFont.regular,
    fontSize: 18,
    color: colors.text,
  },
});

const SumResult: FC = () => {
  const { data: checkoutData, loading } = useCheckoutState();

  const {
    appStore: {
      orderType,
    },
  } = useStore();

  const isDelivery = orderType?.type === Enum_Ordertype_Ordertypecode.Delivery;

  if (!checkoutData) {
    return null;
  }

  if (loading && !checkoutData) {
    return (
      <View style={s.root}>
        <View style={s.row}>
          <Text style={s.rowTextBold}>
            ИТОГО
          </Text>

          <ActivityIndicator color={colors.primary} />
        </View>
      </View>
    );
  }

  return (
    <View style={s.root}>
      <View style={s.row}>
        <Text style={s.rowText}>
          Заказ на сумму
        </Text>

        <Text style={s.rowText}>
          {`${checkoutData.totalItemsCost} ${generalConfig.currencyLabel}`}
        </Text>
      </View>

      {isDelivery && !!checkoutData.deliveryInfo && (
        <View style={s.row}>
          <Text style={s.rowText}>
            Доставка
          </Text>

          <Text style={s.rowText}>
            {`${checkoutData.deliveryInfo?.deliveryZone.deliveryPrice} ${generalConfig.currencyLabel}`}
          </Text>
        </View>
      )}

      {!!checkoutData.itemsDiscount && (
        <View style={s.row}>
          <Text style={s.rowText}>
            Скидка
          </Text>

          <Text style={s.rowText}>
            {`-${checkoutData.itemsDiscount} ${generalConfig.currencyLabel}`}
          </Text>
        </View>
      )}

      {!!checkoutData.bonuses && (
        <View style={s.row}>
          <Text style={s.rowText}>
            Использованные бонусы
          </Text>

          <Text style={s.rowText}>
            {`-${checkoutData.bonuses} ${generalConfig.currencyLabel}`}
          </Text>
        </View>
      )}

      <DashedLine />

      <View style={s.row}>
        <Text style={s.rowTextBold}>
          ИТОГО
        </Text>

        <Text style={s.rowTextBold}>
          {`${checkoutData.orderTotal} ${generalConfig.currencyLabel}`}
        </Text>
      </View>

    </View>
  );
};

export default observer(SumResult);
