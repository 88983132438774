import { Alert, Platform } from 'react-native';

const crossAlert: typeof Alert['alert'] = (title, ...args) => {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line no-alert
    window.alert(title);
  } else {
    Alert.alert(title, ...args);
  }
};

export default crossAlert;
