import React, { FC, PropsWithChildren } from 'react';
import { ViewProps, StyleSheet, View } from 'react-native';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    backgroundColor: colors.lightGrey,
    borderRadius: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: colors.lightGrey,
  },
  focusUserAttention: {
    borderWidth: 1,
    borderColor: colors.primary,
  },
});

type IProps = PropsWithChildren<ViewProps> & {
  focusUserAttention?: boolean,
};

const GreyBlock: FC<IProps> = (
  {
    style,
    children,
    focusUserAttention = false,
    ...rest
  },
) => (
  <View
    style={StyleSheet.flatten([s.root, focusUserAttention && s.focusUserAttention, style])}
    {...rest}
  >
    {children}
  </View>
);

export default GreyBlock;
