import React, {
  FC,
  useCallback,
  useEffect, useMemo,
  useRef,
} from 'react';
import {
  ScrollView,
  StyleSheet,
  useWindowDimensions,
  View,
  Text, Pressable,
} from 'react-native';
import ActionSheet, {
  ActionSheetRef,
  SheetProps,
  useScrollHandlers,
} from 'react-native-actions-sheet';
import Button from '@components/common/Button';
import { observer } from 'mobx-react-lite';
import { ModifiersStateModelInstance } from '@screens/MenuItemScreen/model/ModifiersStateModel';
import ModifierBlockItem from '@screens/MenuItemScreen/includes/Modifier/ModifierBlockItem';
import { GroupModifierInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/GroupModifier';
import {
  ModifiersStateModelContext,
} from '@screens/MenuItemScreen/model/ModifiersStateModelContext';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';

const s = StyleSheet.create({
  root: {
    paddingVertical: 20,
  },
  bottomRow: {
    gap: 10,
    paddingHorizontal: 25,
  },
  scrollview: {
    width: '100%',
    paddingBottom: 40,
    paddingHorizontal: 25,
  },
  header: {
    fontSize: 16,
    color: colors.text,
    fontFamily: ubuntuFont.medium,
    paddingBottom: 10,
    paddingHorizontal: 25,
  },
  items: {
    gap: 10,
    paddingBottom: 10,
  },
});

const snapPoints = [100];

type Payload = {
  gm: GroupModifierInstance,
  modifiersStateModel: ModifiersStateModelInstance
};

const MenuItemGroupModifierSheet: FC<SheetProps<Payload>> = (
  {
    sheetId,
    payload,
  },
) => {
  const actionSheetRef = useRef<ActionSheetRef>(null);

  useEffect(() => {
    if (!payload) {
      actionSheetRef.current?.hide();
    }
  }, [payload]);

  const onClosePress = useCallback(() => {
    actionSheetRef.current?.hide();
  }, []);

  const scrollHandlers = useScrollHandlers<ScrollView>(sheetId, actionSheetRef);

  const { height } = useWindowDimensions();

  const scrollStyle = useMemo(() => StyleSheet.flatten([s.scrollview, { maxHeight: height * 0.8 }]), [height]);

  if (!payload) {
    return null;
  }

  const {
    gm,
    modifiersStateModel,
  } = payload;

  const amountInGroup = gm.childModifiers
    .reduce((sum, cm) => {
      const cmAmount = modifiersStateModel.state.get(cm.modifierId)?.amount || 0;

      return sum + cmAmount;
    }, 0);

  return (
    <ActionSheet
      id={sheetId}
      ref={actionSheetRef}
      snapPoints={snapPoints}
      initialSnapIndex={0}
      statusBarTranslucent
      drawUnderStatusBar
      gestureEnabled
      defaultOverlayOpacity={0.3}
    >
      <View style={s.root}>
        <ModifiersStateModelContext.Provider value={modifiersStateModel}>
          <>
            <Text style={s.header}>
              {gm.modifierCategory?.title}
              {gm.maxAmount > 1 && ` (${amountInGroup} из ${gm.maxAmount})`}
            </Text>

            <ScrollView
              {...scrollHandlers}
              style={scrollStyle}
              alwaysBounceVertical={false}
              scrollEnabled
            >
              <Pressable>
                <View style={s.items}>
                  {gm.childModifiers.map((cm) => (
                    <ModifierBlockItem
                      key={cm.id}
                      cm={cm}
                      gm={gm}
                      amountInGroup={amountInGroup}
                    />
                  ))}
                </View>
              </Pressable>
            </ScrollView>
          </>
        </ModifiersStateModelContext.Provider>
        <View style={s.bottomRow}>
          <Button
            onPress={onClosePress}
            title="Закрыть"
          />
        </View>
      </View>
    </ActionSheet>
  );
};

export default observer(MenuItemGroupModifierSheet);
