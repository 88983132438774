import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StackActions, useNavigation } from '@react-navigation/native';
import {
  YMaps,
  Map,
  GeolocationControl,
  SearchControl,
  ZoomControl,
} from 'react-yandex-maps';
import {
  View,
  StyleSheet,
  Text,
  ActivityIndicator,
  Platform,
} from 'react-native';
import { useGeocodeQuery, useGetShopsDeliveryZonesQuery } from '@app/infrastructureLayer/generated/graphql';
import colors from '@constants/colors';
import { observer } from 'mobx-react-lite';
import PlaceMarkSvg from '@screens/SelectDeliveryAddressScreen/includes/PlaceMarkSvg';
import {
  SelectDeliveryAddressOnMapProps,
} from '@screens/SelectDeliveryAddressScreen/includes/SelectDeliveryAddressOnMap/types';
import { LayoutChangeEvent } from 'react-native/Libraries/Types/CoreEventTypes';
import ubuntuFont from '@constants/ubuntuFont';
import generalConfig from '@constants/generalConfig';
import Button from '@components/common/Button';
import { WebView } from 'react-native-webview';
import mapNative from '@screens/SelectDeliveryAddressScreen/includes/mapNative';
import { getYandexMapKey } from '@utils/env';
import compact from 'lodash/compact';
import DeliveryZonePolygon from './DeliveryZonePolygon';

const s = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
  },
  root2: {
    position: 'absolute',
    left: 10,
    bottom: 40,
    padding: 40,
    borderRadius: 10,
    backgroundColor: colors.background,
    zIndex: 9999,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  mark: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    zIndex: 9999,
    transform: [
      {
        translateX: -(64 / 2),
      },
      {
        translateY: -64,
      },
    ],
  },
  text: {
    fontFamily: ubuntuFont.light,
    fontSize: 20,
    lineHeight: 22,
    color: colors.text,
  },
  textError: {
    color: colors.error,
    fontSize: 22,
    lineHeight: 24,
  },
});

const oLeft = { float: 'left' };
const oRight = { float: 'right' };

const yandexApiKey = getYandexMapKey();

export type Zone = {
  minSum: number;
  color: string;
  polygon: number[][];
  name: string;
  id: string;
  deliveryPrice: number
};

export type ZonesByShop = {
  shopId: string;
  zones: Zone[]
}[];

const SelectDeliveryAddressOnMap: FC<SelectDeliveryAddressOnMapProps> = (
  {
    currentLocation,
  },
) => {
  const initialCenter: [number, number] = useRef([
    currentLocation.latitude, currentLocation.longitude,
  ] as [number, number]).current;

  // TODO: Дефолтную точку для карты надо как-то задавать
  const [center, setCenter] = useState<[number, number]>(() => initialCenter);

  const { data: dataZones } = useGetShopsDeliveryZonesQuery();

  const zonesByShops: ZonesByShop = useMemo(() => (
    compact(dataZones?.shops?.data.map((x) => {
      if (!x.id || !x?.attributes?.deliveryZones?.length) {
        return null;
      }

      return ({
        shopId: x.id,
        zones: compact(x.attributes.deliveryZones.map((dz) => {
          if (!dz) {
            return null;
          }

          return ({
            id: dz.id,
            name: dz.name,
            color: dz.color,
            minSum: dz.minSum,
            deliveryPrice: dz.deliveryPrice,
            polygon: dz.polygon.split('\n')
              .map((item) => item.split(',')
                .map(parseFloat))
              .map(([a, b]) => [b, a]),
          });
        })),
      });
    }))
  ), [dataZones]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      return undefined;
    }

    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const defaultState = useMemo(() => ({
    center,
    zoom: 17,
  }), [center]);

  const {
    data,
    loading,
  } = useGeocodeQuery({
    variables: {
      input: {
        lat: center[0],
        lon: center[1],
      },
    },
    // Чтобы не переполучал данные и не тратил лимиты по ключам геокодирования
    pollInterval: 0,
  });

  const handleMessage = useCallback((event: any) => {
    try {
      // Handle incoming messages from the web content
      const newCenter = JSON.parse(event.nativeEvent.data);
      setCenter(newCenter);
    } catch (e) {
      console.error(e);
    }
  }, []);

  const geocodeData = data?.geocode;

  const navigation = useNavigation();

  const onPressAdd = useCallback(() => {
    if (!geocodeData) {
      return;
    }

    navigation.dispatch(
      StackActions.replace('CreateMyAddressScreen', {
        cityId: geocodeData.addressDelivery.cityId,
        address: geocodeData.address,
        longitude: center[1],
        latitude: center[0],
        selectAfterSave: true,
      }),
    );
  }, [geocodeData, navigation, center]);

  const handleMapChange = useCallback((e: any) => {
    setCenter(e.get('newCenter'));
  }, []);

  const [size, setSize] = useState<{ width: number, height: number } | null>(null);

  const onLayout = useCallback((event: LayoutChangeEvent) => {
    setSize({
      width: event.nativeEvent.layout.width,
      height: event.nativeEvent.layout.height,
    });
  }, []);

  const mapSourceForWeb = useMemo(() => (
    { html: mapNative(initialCenter, yandexApiKey, zonesByShops) }
  ), [initialCenter, zonesByShops]);

  return (
    <View
      onLayout={onLayout}
      style={s.root}
    >
      {!!size && (
        <>
          <View
            style={StyleSheet.flatten([
              s.root2,
              {
                width: size.width - 20,
              },
            ])}
          >
            <Text style={s.text}>
              {loading && <ActivityIndicator color={colors.primary} />}
              {!loading && (
                <>
                  {!geocodeData && (
                    <Text style={s.textError}>
                      Адрес вне зоны доставки
                    </Text>
                  )}
                  {!!geocodeData && (
                    <Text>
                      {`Адрес: ${geocodeData.address}`}
                      {'\n'}
                      {geocodeData.addressDelivery.deliveryZone.minSum > 0 && (
                        <Text>
                          {`Минимальная сумма заказа: ${geocodeData.addressDelivery.deliveryZone.minSum}${generalConfig.currencyLabel}`}
                          {'\n'}
                        </Text>
                      )}
                      {`Стоимость доставки: ${geocodeData.addressDelivery.deliveryZone.deliveryPrice}${generalConfig.currencyLabel}`}
                    </Text>
                  )}
                </>
              )}
            </Text>
            {!!geocodeData && (
              <Button
                title="Добавить"
                onPress={onPressAdd}
              />
            )}
          </View>
          {Platform.OS !== 'web' && (
            <>
              {!dataZones && <ActivityIndicator color={colors.primary} />}
              {!!dataZones && (
                <>
                  <View style={s.mark}>
                    <PlaceMarkSvg />
                  </View>
                  <WebView
                    source={mapSourceForWeb}
                    onMessage={handleMessage}
                  />
                </>
              )}
            </>
          )}
          {Platform.OS === 'web' && (
            <YMaps>
              <View style={s.mark}>
                <PlaceMarkSvg />
              </View>
              <Map
                width={size.width}
                height={size.height}
                defaultState={defaultState}
                onBoundsChange={handleMapChange}
              >
                <GeolocationControl options={oLeft} />
                <SearchControl options={oRight} />
                <ZoomControl options={oRight} />

                {/* // TODO: Смотри https://yandex.ru/dev/maps/jsbox/2.1/polygon/ */}
                {zonesByShops.map((zbs) => zbs.zones.map((zone) => (
                  <DeliveryZonePolygon zone={zone} key={zone.id} />
                )))}
              </Map>
            </YMaps>
          )}
        </>
      )}
    </View>
  );
};

export default observer(SelectDeliveryAddressOnMap);
