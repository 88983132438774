import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import colors from '@constants/colors';

const DeleteSvg = () => (
  <Svg
    viewBox="0 0 90 70"
    width={36}
    height={36}
  >
    <Path
      d="M81.46 0H8.54A8.54 8.54 0 0 0 0 8.54v51.74a8.54 8.54 0 0 0 8.54 8.54h72.92A8.54 8.54 0 0 0 90 60.28V8.54A8.54 8.54 0 0 0 81.46 0ZM8.54 4.51h72.92a4 4 0 0 1 4 4v6.18h-81V8.54a4 4 0 0 1 4.08-4.03Zm72.92 59.8H8.54a4 4 0 0 1-4-4v-31.9h81v31.87a4 4 0 0 1-4.08 4.03Z"
      fill={colors.text}
    />
  </Svg>
);

export default DeleteSvg;
