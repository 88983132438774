import { observer } from 'mobx-react-lite';
import React, {
  FC, useCallback, useMemo,
} from 'react';
import {
  View,
  StyleSheet,
  SectionList,
  Text,
  useWindowDimensions,
  Platform,
} from 'react-native';
import useStore from '@app/domain/store/useStore';
import MenuLoading from '@screens/Tabs/MenuScreen/includes/MenuLoading';
import { MenuItemInstance } from '@app/domain/store/CoreStore/AppStore/entities/Menu/MenuItem';
import MenuItem from '@screens/Tabs/MenuScreen/includes/MenuItem/MenuItem';
import ubuntuFont from '@constants/ubuntuFont';
import colors from '@constants/colors';
import CellRendererComponent from '@screens/Tabs/MenuScreen/includes/Menu/includes/CellRendererComponent';

const s = StyleSheet.create({
  scrollContainer: {
    flex: 1,
    paddingHorizontal: 16,
  },
  scrollContent: {
    paddingTop: 16,
    paddingBottom: 60,
    gap: 20,
    rowGap: 40,
  },
  productsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: 16,
  },
  title: {
    fontFamily: ubuntuFont.regular,
    fontSize: 16,
    color: colors.text,
    marginBottom: 16,
  },
  noResults: {
    fontFamily: ubuntuFont.regular,
    fontSize: 14,
    color: colors.text,
  },
});

const renderItem = ({ item }: { item: MenuItemInstance }) => (
  <MenuItem
    key={item.id}
    menuItemId={item.id}
  />
);

const Menu: FC = () => {
  const {
    appStore: {
      hasSearchQuery,
      activeMenu,
      activeMenuSections,
    },
  } = useStore();

  const keyExtractor = useCallback((item: MenuItemInstance) => item.id, []);

  const { width } = useWindowDimensions();

  const headerStyle = useMemo(() => ({ width: width - 32 }), [width]);

  const renderSectionHeader = useCallback((
    {
      section: {
        title,
      },
    }: {
      section: {
        title: string,
        categoryId: string
      }
    },
  ) => (
    <View style={headerStyle}>
      <Text style={s.title}>
        {title}
      </Text>
    </View>
  ), [headerStyle]);

  if (!activeMenu) {
    return (
      <View style={s.scrollContainer}>
        <MenuLoading />
      </View>
    );
  }

  if (!activeMenuSections.length && hasSearchQuery) {
    return (
      <View style={s.scrollContainer}>
        <Text style={s.noResults}>
          По этому запросу ничего не найдено.
        </Text>
      </View>
    );
  }

  return (
    <View style={s.scrollContainer}>
      <View style={s.scrollContent}>
        <SectionList
          removeClippedSubviews
          nestedScrollEnabled
          scrollEnabled={Platform.OS === 'web'}
          windowSize={2}
          initialNumToRender={2}
          maxToRenderPerBatch={10}
          sections={activeMenuSections}
          stickySectionHeadersEnabled={false}
          keyExtractor={keyExtractor}
          contentContainerStyle={s.productsContainer}
          CellRendererComponent={CellRendererComponent}
          renderItem={renderItem}
          renderSectionHeader={renderSectionHeader}
        />
      </View>
    </View>
  );
};

export default observer(Menu);
