import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type IProps = {
  width?: number
  height?: number
};

const ArrowLeftSvg = ({ width = 7, height = 12 }: IProps) => (
  <Svg
    width={width}
    height={height}
    viewBox="0 0 7 12"
    fill="none"
  >
    <Path
      d="M6.00098 1L1.07169 5.92929C1.03263 5.96834 1.03263 6.03166 1.07169 6.07071L6.00098 11"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);
export default ArrowLeftSvg;
