import {
  Instance,
  SnapshotIn,
  SnapshotOut,
  types as t,
  destroy, resolveIdentifier, getParent,
} from 'mobx-state-tree';
import generalConfig from '@constants/generalConfig';
import { Modifier } from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/Modifier';
import type { AppStoreInstance } from '@app/domain/store/CoreStore/AppStore/AppStore';
import type {
  ModifierMenuItemInstance,
} from '@app/domain/store/CoreStore/AppStore/entities/Menu/Modifer/ModifierMenuItem';

export const CartItemModifier = t
  .model('CartItemModifier', {
    id: t.identifier,
    modifierId: t.string,

    amount: t.refinement(t.number, (value) => value > 0),
  })
  .views((self) => ({
    get modifier() {
      return resolveIdentifier(Modifier, self, self.modifierId);
    },
  }))
  .views((self) => ({
    get menuModifierItem() {
      const parent: AppStoreInstance = getParent(self, 4);

      if (!parent.activeMenu) {
        return undefined;
      }

      const activeMenuItems: ModifierMenuItemInstance[] = [...parent.activeMenu.modifierMenuItems.values()];

      return activeMenuItems.find((item) => item.modifierId === self.modifierId);
    },
  }))
  .actions((self) => ({
    increaseAmount: () => {
      if (self.amount === generalConfig.maxAmount) {
        return;
      }

      self.amount += 1;
    },
    reduceAmount: () => {
      if (self.amount === 1) {
        destroy(self);

        return;
      }

      self.amount -= 1;
    },
  }));

export interface CartItemModifierInstance extends Instance<typeof CartItemModifier> {
}

export interface CartItemModifierSnapshotIn extends SnapshotIn<typeof CartItemModifier> {
}

export interface CartItemModifierSnapshotOut extends SnapshotOut<typeof CartItemModifier> {
}
